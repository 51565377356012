import { logout, updateSavedChanges } from 'config/config';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function UnSavedChanges() {
  const hasUnsavedChanges = useSelector((state) => state.auth.hasUnsavedChanges);
  const unsavedChangedType = useSelector((state) => state.auth.unsavedChangedType);
  useEffect(() => {
    if (hasUnsavedChanges && unsavedChangedType === 'COMPANY_DELETED') {
      logout();
    }
    updateSavedChanges(false);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return <></>;
}

export default UnSavedChanges;
