import React from 'react';
import PropTypes from 'prop-types';
import doubleDownArrow from '../../../assets/images/icons/double-down-arrow.svg';
import doubleUpArrow from '../../../assets/images/icons/double-up-arrow-white.svg';
import infoIcon from '../../../assets/images/icons/info-circle.svg';
import infoIconWhite from '../../../assets/images/icons/info-circle-white.svg';
import { useTranslation } from 'react-i18next';

const GeneralHeader = ({ title, icon, onClick, isSelected, showInfoIcon = true, classes }) => {
  const { t } = useTranslation();

  return (
    <div className={`company-header ${isSelected ? 'active' : ''} mt-3 `} onClick={() => onClick()}>
      <div className='d-flex gap-2'>
        <img src={icon} className={`${classes ? classes : ''}`} />
        <label className='theme-size-1 theme-font-inter-medium '>{t(title)}</label>
        {showInfoIcon && <img className='' src={isSelected ? infoIconWhite : infoIcon} />}
      </div>
      <div>
        <img src={isSelected ? doubleUpArrow : doubleDownArrow} />
      </div>
    </div>
  );
};

GeneralHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showInfoIcon: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.string,
};

export default GeneralHeader;
