import React from 'react';
import PropTypes from 'prop-types';
import NumberRangeField from 'components/form-components/NumberRangeField';

const NumberRangeFieldPreview = ({ field }) => {
  return (
    <div className='d-flex gap-2 justify-content-center align-items-center'>
      <NumberRangeField
        name='numberRange'
        value={Math.round(
          (field.properties?.basicProperties?.maxRange -
            (field.properties?.basicProperties?.minRange - 1)) /
            2,
        )}
        minRange={Number(field.properties?.basicProperties?.minRange ?? 1)}
        maxRange={Number(field.properties?.basicProperties?.maxRange ?? 10)}
        handleChange={() => {}}
        disabled
        classes='w-100'
      />
    </div>
  );
};

NumberRangeFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
};

export default NumberRangeFieldPreview;
