import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableComponent from 'components/table-component/Table';
import { CallAPI } from 'actions/General';
import { attendanceTableHeader, employeeTrackerTableHeader, timeSheetTableHeader } from './utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

const AttendanceTableBody = ({
  filterData,
  setLoader,
  setApiError,
  type,
  selectedRows,
  setSelectedRows,
}) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const [attendanceMediaNote, setAttendanceMediaNote] = useState({});
  const [dataList, setDataList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [employeeTrackingHeader, setEmployeeTrackingHeader] = useState([]);

  const getEmployeeTrackingStats = async (page = 1, limit = 10) => {
    const payload = { page: page, limit: limit, ...filterData };
    let API =
      type === 'employeeTrackerHeader'
        ? 'GET_TRACK_EMPLOYEE_STATS'
        : type === 'attendance'
        ? 'FETCH_TRACK_ATTENDANCE'
        : 'FETCH_TRACK_TIME_SHEET';
    const result = await CallAPI(
      API,
      payload,
      null,
      setLoader ? setLoader : null,
      setApiError ? setApiError : null,
      null,
    );
    if (result.status) {
      let data = result.data.attendance ? result.data.attendance : result.data.timeSheet;
      const attendance = data.map((a, index) => ({ ...a, sr: index + 1 }));
      if (type === 'attendance') {
        let attendanceMediaNoteObj = {};
        attendance.map((singleAttendanceRecord) => {
          let checkInObj = {};
          let checkOutObj = {};
          if (singleAttendanceRecord.notes) {
            checkInObj['note'] = singleAttendanceRecord.notes;
          }
          if (singleAttendanceRecord.clockOutNotes) {
            checkOutObj['note'] = singleAttendanceRecord.clockOutNotes;
          }
          singleAttendanceRecord.clockInMedia.map((singleMediaObj) => {
            if (singleMediaObj.mediaType === 'clock_in') checkInObj['media'] = [singleMediaObj.media];
            if (singleMediaObj.mediaType === 'clock_out')
              checkOutObj['media'] = [singleMediaObj.media];
          });
          attendanceMediaNoteObj[singleAttendanceRecord._id] = {
            check_in: checkInObj,
            check_out: checkOutObj,
          };
        });
        setAttendanceMediaNote(attendanceMediaNoteObj);
      }
      setDataList(attendance);
      if (result.data.totalCount) setTotalCount(result.data.totalCount);
    }
  };
  const onRowsSelection = (name, value) => {
    let selectedIds = cloneDeep(selectedRows);
    if (name === 'all') {
      dataList.map((single_tracking_record) => {
        if (value && selectedIds.map((e) => e.id).indexOf(single_tracking_record._id) === -1)
          selectedIds.push(single_tracking_record._id);
        if (!value) selectedIds = [];
        setSelectedRows(selectedIds);
      });
    } else {
      if (value) {
        selectedIds.push(name);
      } else selectedIds.splice(selectedIds.map((e) => e).indexOf(name), 1);
      setSelectedRows(selectedIds);
    }
  };
  useEffect(() => {
    setDataList([]);
    setTotalCount(0);
    getEmployeeTrackingStats();
  }, [filterData, type]);

  useEffect(() => {
    let selectedTabHeader =
      type === 'employeeTrackerHeader'
        ? employeeTrackerTableHeader
        : type === 'attendance'
        ? attendanceTableHeader
        : timeSheetTableHeader;
    if (auth.customizedTableHeader && auth.customizedTableHeader[type]) {
      selectedTabHeader = auth.customizedTableHeader[type];
    }
    setEmployeeTrackingHeader(selectedTabHeader);
  }, [auth, type]);
  const translateValueFunc = (val) => {
    return t(val);
  };
  return (
    <div>
      <TableComponent
        header={employeeTrackingHeader}
        data={dataList}
        props={{ translateValueFunc: translateValueFunc, attendanceMediaNote, type: 'attendance' }}
        totalRecords={totalCount}
        filterEnabled={false}
        loader={false}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={true}
        maintainTableHeight={type === 'employeeTrackerHeader' ? false : true}
        isSelectionEnabled={type === 'timeSheet' ? true : false}
        isActionsEnabled={false}
        selectedRows={selectedRows}
        onSelection={onRowsSelection}
        selectionKey='_id'
        paginationFunction={getEmployeeTrackingStats}
      />
    </div>
  );
};

AttendanceTableBody.propTypes = {
  filterData: PropTypes.object.isRequired,
  setLoader: PropTypes.func,
  setApiError: PropTypes.func,
  type: PropTypes.string.isRequired,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
};

export default AttendanceTableBody;
