import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NumberFieldWithTag = ({
  name,
  tagText,
  placeholder,
  value,
  shrink,
  handleChange,
  classes,
}) => {
  const { t } = useTranslation();
  const shrinkField = shrink ? 'shrink-field' : '';
  const shrinkTag = shrink ? 'shrink-tag' : '';

  return (
    <div className={`custom-tag-input-box${classes ? ' ' + classes : ''}`}>
      <div className='custom-tag-input-field'>
        <div className='input-tag-box'>
          <label className={shrinkTag}>{tagText}</label>
        </div>
        <input
          type='number'
          id={name}
          name={name}
          className={shrinkField}
          placeholder={t(placeholder)}
          value={value}
          autoComplete='off'
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </div>
    </div>
  );
};

NumberFieldWithTag.propTypes = {
  name: PropTypes.string.isRequired,
  tagText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.number.isRequired,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

export default NumberFieldWithTag;
