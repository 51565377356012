import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import plusIcon from 'assets/images/icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { addDataSourceColumnSchema, typeOptions } from '../utils';
import { CallAPI } from 'actions/General';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Error from 'components/Error';
import TextField from 'components/form-components/TextField';
import SelectField from 'components/form-components/SelectField';
import selectArrow from 'assets/images/select-arrow.png';

const AddColumnDropdown = ({ datasource_id, refreshLists, fieldsList }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    columnName: '',
    dataType: 'text',
  });

  const handleColumnNameChange = (field, value) => {
    if (!value.includes('.')) setValues({ ...values, [field]: value });
  };

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const onCancel = () => {
    setShow(false);
  };

  const verifyAPIData = () => {
    let allColumns = [];
    fieldsList.map((singleField) => {
      allColumns.push(singleField.name.toLowerCase());
    });

    if (allColumns.indexOf(values.columnName.trim().toLowerCase()) > -1) {
      setErrors({ columnName: 'Column Name already exist' });
      return false;
    }
    return true;
  };

  const onSave = async () => {
    try {
      await addDataSourceColumnSchema.validate(values, { abortEarly: false });
      let verifyData = verifyAPIData();
      if (verifyData) {
        let result = await CallAPI(
          'ADD_COLUMN_IN_DATASOURCE',
          {
            dataSourceId: datasource_id,
            columnName: values.columnName.trim(),
            dataType: values.dataType,
          },
          null,
          setLoader,
          setAPIError,
          null,
        );
        if (result.status) {
          refreshLists();
          onCancel();
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  const popover = (
    <Popover className='min-width-176' id='popover-basic'>
      <Popover.Header className='theme-background-white-2 theme-text-black-1' as='h3'>
        {t('button_create_column')}
      </Popover.Header>
      <Popover.Body>
        {APIError && <Error msg={APIError} />}
        <div className='d-flex w-100 gap-3 '>
          <div className='d-flex flex-column gap-3 w-100 '>
            <TextField
              name='columnName'
              displayName=''
              placeholder={t('field_data_source_column_name')}
              handleChange={handleColumnNameChange}
              value={values.columnName}
              error={errors.columnName}
              classes='mb-1'
              shrink={true}
            />
            <SelectField
              label={t(
                values.dataType === 'dateTime' ? 'select_date_time' : 'select_' + values.dataType,
              )}
              handleChange={handleChange}
              name='dataType'
              listValues={typeOptions}
              icon={selectArrow}
              isAutoClose={true}
              shrink={true}
              variant='white-1'
            />

            <div className='d-flex w-100 gap-2 '>
              <FormButton text='button_cancel' variant='white-1' onClick={() => onCancel()} />
              <FormLoadingButton
                loading={loader}
                text='button_save'
                variant='green-1'
                onClick={() => onSave()}
              />
            </div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        trigger='click'
        onToggle={() => setShow(!show)}
        show={show}
        placement='bottom'
        overlay={popover}
      >
        <div className='custom-form-button theme-button-green-2'>
          <img src={plusIcon} />
          {t('button_create_column')}
        </div>
      </OverlayTrigger>
    </div>
  );
};

AddColumnDropdown.propTypes = {
  datasource_id: PropTypes.string.isRequired,
  refreshLists: PropTypes.func.isRequired,
  fieldsList: PropTypes.array.isRequired,
};

export default AddColumnDropdown;
