import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import backIcon from '../../../assets/images/icons/long-arrow-right.svg';
import tagIcon from '../../../assets/images/icons/tag-grey.svg';
import plusIcon from '../../../assets/images/icons/plus-grey.svg';
import searchIcon from '../../../assets/images/icons/search.svg';
import { Tag } from './Tag';
import { CallAPI } from 'actions/General';

const AddTags = ({ close, userDetail, userTags, onTagListUpate }) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [allTagsList, setAllTagsList] = useState([]);
  const [searchText, setSearchText] = useState('');

  const getAllTags = async () => {
    let result = await CallAPI('GET_ALL_TAGS', {}, null, null, null, null);
    if (result.status) {
      let allTags = [];
      result.data.map((singleTag) => {
        if (userTags.indexOf(singleTag.name) === -1) {
          allTags.push(singleTag.name);
        }
      });
      setAllTagsList(allTags);
    }
  };

  useEffect(() => {
    setTags(userTags);
    getAllTags();
  }, []);

  const addNewTag = async (tag) => {
    if (tag !== '') {
      let result = await CallAPI('Add_TAG', { name: tag }, null, null, null, null);
      if (result.status) {
        AssignTag(tag);
      }
    }
  };

  const AssignTag = async (tag) => {
    let tagsList = [...tags];
    tagsList.push(tag);

    let AssignObject = {
      ...userDetail,
      tags: tagsList,
    };

    let result = await CallAPI('ASSIGN_TAG', { users: [AssignObject] }, null, null, null, null);

    if (result.status) {
      setTags(tagsList);
      let filterTagList = allTagsList.filter((tempTag) => tempTag != tag);
      setAllTagsList(filterTagList);
      if (onTagListUpate) onTagListUpate(tagsList);
    }
  };

  const removeTag = async (tag) => {
    let filterTagList = tags.filter((tempTag) => tempTag != tag);
    let AssignObject = {
      ...userDetail,
      tags: filterTagList,
    };

    let result = await CallAPI('ASSIGN_TAG', { users: [AssignObject] }, null, null, null, null);

    if (result.status) {
      setTags(filterTagList);
      let allTags = [...allTagsList];
      allTags.push(tag);
      setAllTagsList(allTags);
      if (onTagListUpate) onTagListUpate(filterTagList);
    }
  };

  return (
    <div className='tags-list'>
      <div className='overlay'></div>
      <div className='side-popup'>
        <div className='px-4 mt-4 mb-3 d-flex justify-content-between align-items-center'>
          <img src={backIcon} alt='back' className='cursor-pointer' onClick={close} />
          <div className='theme-size-1_3 theme-font-inter-medium theme-text-black-1'>
            {t('Usertags')}
          </div>
          <div></div>
        </div>
        <hr />
        <div className='p-4 d-flex flex-wrap gap-1'>
          {tags &&
            tags.length > 0 &&
            tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  title={tag}
                  icon={true}
                  onDelete={() => {
                    removeTag(tag);
                  }}
                />
              </div>
            ))}
          <div className='d-inline-flex align-items-center gap-2 py-1 px-2 border rounded rounded-4'>
            <img src={plusIcon} alt='Loading...' />
            <input
              className='tag-input'
              placeholder={t('Create new Tag')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  event.currentTarget.blur();
                }
              }}
              onBlur={(e) => {
                addNewTag(e.target.value);
              }}
            />
          </div>
        </div>

        <hr />
        <div className='px-4'>
          <TextField
            value={searchText}
            name='search'
            handleChange={(field, value) => {
              setSearchText(value);
            }}
            placeholder='field_search'
            icon={searchIcon}
            shrink
            classes='w-100 mb-3'
          />

          {allTagsList &&
            allTagsList.length > 0 &&
            allTagsList
              .filter((x) => x?.toLowerCase().includes(searchText?.toLowerCase()))
              .map((tag, index) => (
                <div
                  key={index}
                  className='d-flex align-items-center gap-3 px-2 py-2 border-bottom cursor-pointer list-item'
                  onClick={() => AssignTag(tag)}
                >
                  <img src={tagIcon} alt='tag' />
                  <div className='theme-size-1 theme-font-inter-regular theme-text-black-1'>
                    {tag}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

AddTags.propTypes = {
  close: PropTypes.func,
  userDetail: PropTypes.object.isRequired,
  userTags: PropTypes.array.isRequired,
  onTagListUpate: PropTypes.func,
};
export default AddTags;
