import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { arrayMove, List } from 'react-movable';
import checkListGreyIcon from 'assets/images/icons/check-list-grey.svg';
import editIcon from 'assets/images/icons/edit.svg';
import fieldPreviewIcon from 'assets/images/icons/field_preview_Icon.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import trashGreyIcon from 'assets/images/icons/trash-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import ColorChangeModal from 'components/common-components/ColorChangeModal';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';

const ResponseSetAddModal = ({ show, editId, onCancel, refreshList }) => {
  const [loader, setLoader] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [choiceName, setChoiceName] = useState('');
  const itemDefault = { responseText: '', value: '', itemColor: '#000000FF' };
  const [optionList, setOptionList] = useState([]);
  const [fontStyleModalOpen, setFontStyleModalOpen] = useState([]);

  const closeFontStyleModals = () => setFontStyleModalOpen([...fontStyleModalOpen].fill(false));

  const toggleFontStyleModal = (index) => {
    const temp = [...fontStyleModalOpen].fill(false);
    temp[index] = !temp[index];
    setFontStyleModalOpen(temp);
  };

  const addOptionToList = () => {
    const newOptionList = [...optionList, { ...itemDefault }];
    setOptionList(newOptionList);

    setFontStyleModalOpen(Array.from(Array(newOptionList.length).keys()).fill(false));
  };

  const removeOptionFromList = (index) => {
    const tempOptionList = [...optionList];
    tempOptionList.splice(index, 1);
    setOptionList(tempOptionList);

    setFontStyleModalOpen(Array.from(Array(tempOptionList.length).keys()).fill(false));
  };

  const handleOptionChange = (index, field, value) => {
    const newOptionList = [...optionList];
    newOptionList[index][field] = value;
    newOptionList[index] = { ...newOptionList[index], [field]: value };

    setOptionList(newOptionList);
  };

  const validateOptionList = () => {
    return optionList.every((option) => option.responseText !== '' && option.value !== '');
  };

  const createNewResponseSet = async () => {
    if (!validateOptionList()) {
      toast.error('All options must have response and its value');
    } else {
      const payload = { choiceName, choiceValue: JSON.stringify(optionList) };
      if (editId) {
        const result = await CallAPI('UPDATE_RESPONSE_SET', payload, editId, setLoader, null, null);
        if (result.status) {
          toast.success('Response Set updated successfully');
          onCancel();
          refreshList();
        }
      } else {
        const result = await CallAPI('ADD_RESPONSE_SET', payload, null, setLoader, null, null);
        if (result.status) {
          toast.success('Response Set added successfully');
          onCancel();
          refreshList();
        }
      }
    }
  };

  const getResponseSetDetail = async () => {
    const result = await CallAPI(
      `GET_RESPONSE_SET_DETAIL`,
      null,
      editId,
      setFetchLoader,
      null,
      null,
    );
    if (result.status) {
      setChoiceName(result.data.choiceName);
      setOptionList(JSON.parse(result.data.choiceValue));
    }
  };

  useEffect(() => {
    if (editId) getResponseSetDetail();
  }, [editId]);

  return (
    <div>
      <AlertModal show={show} actionButtons={[]} noPadding>
        <div className='w-100'>
          <div className='d-flex gap-2 justify-content-center py-3 border-bottom-grey-1-h-1'>
            <img src={checkListGreyIcon} />
            <label className='theme-size-1_3'>Multiple Response Set</label>
          </div>

          {fetchLoader ? (
            <div className='text-center my-3'>
              <Spinner className='theme-text-green-1' />
            </div>
          ) : (
            <div className='px-4 py-3'>
              <TextField
                value={choiceName}
                name='choiceName'
                classes='w-auto'
                handleChange={(_, value) => setChoiceName(value)}
                placeholder='field_name'
                displayName='field_name'
                shrinkFieldOnly
              />

              <div className='d-flex gap-2 py-2'>
                <img src={plusIcon} />
                <label
                  className='theme-text-green-1 cursor-pointer'
                  onClick={() => addOptionToList()}
                >
                  Add Response
                </label>
              </div>
              <List
                values={optionList}
                onChange={({ oldIndex, newIndex }) =>
                  setOptionList(arrayMove(optionList, oldIndex, newIndex))
                }
                renderList={({ children, props, isDragged }) => (
                  <div
                    {...props}
                    style={{ padding: 0, cursor: isDragged ? 'grabbing' : undefined }}
                  >
                    {children}
                  </div>
                )}
                renderItem={({ value: option, props, index, isDragged }) => {
                  return (
                    <div
                      {...props}
                      // eslint-disable-next-line react/prop-types
                      key={props.key}
                      style={{
                        // eslint-disable-next-line react/prop-types
                        ...props.style,
                        zIndex: isDragged ? 99999 : undefined,
                      }}
                      className='d-flex gap-3 align-items-center mt-3'
                    >
                      <img src={fieldPreviewIcon} className='cursor-pointer' />
                      <TextField
                        value={option.responseText}
                        name='responseText'
                        handleChange={(field, value) => handleOptionChange(index, field, value)}
                        placeholder='field_response'
                        shrinkFieldOnly
                      />
                      <TextField
                        value={option.value}
                        name='value'
                        handleChange={(field, value) => handleOptionChange(index, field, value)}
                        placeholder='field_value'
                        shrinkFieldOnly
                      />
                      <div className='position-relative'>
                        <div
                          role='button'
                          className='px-4 py-2 rounded-sm cursor-pointer'
                          style={{ backgroundColor: option.itemColor }}
                          onClick={() => toggleFontStyleModal(index)}
                        >
                          <img src={editIcon} />
                        </div>
                        {fontStyleModalOpen[index] && (
                          <ColorChangeModal
                            name='item'
                            closeModal={closeFontStyleModals}
                            styleProperties={option}
                            handleChange={(field, value) => handleOptionChange(index, field, value)}
                            hideFontSizeOption
                            hideOpacityOption
                          />
                        )}
                      </div>
                      <img
                        src={trashGreyIcon}
                        role='button'
                        className='cursor-pointer'
                        onClick={() => removeOptionFromList(index)}
                      />
                    </div>
                  );
                }}
              />
            </div>
          )}

          <div className='d-flex gap-2 justify-content-end p-3 border-top-grey-1-h-1'>
            <FormButton text={'button_cancel'} variant='white-1' onClick={onCancel} />
            <FormLoadingButton
              text={editId ? 'button_update' : 'button_create'}
              variant='green-1'
              loading={loader}
              disabled={choiceName === '' || optionList.length < 2}
              onClick={createNewResponseSet}
            />
          </div>
        </div>
      </AlertModal>
    </div>
  );
};

ResponseSetAddModal.propTypes = {
  show: PropTypes.bool.isRequired,
  editId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
};

export default ResponseSetAddModal;
