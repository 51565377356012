import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import phoneIcon from 'assets/images/icons/phone.svg';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import MobileNumberField from 'components/form-components/MobileNumberField';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';
import { CallAPI } from 'actions/General';

const SuperAdminDetails = ({ companyDetails, getCompanyDetail }) => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+966',
    mobileNumber: '',
    officeNumber: '',
    notes: '',
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    setValues({
      firstName: companyDetails?.supportContact?.firstName || '',
      lastName: companyDetails?.supportContact?.lastName || '',
      email: companyDetails?.supportContact?.email || '',
      countryCode: companyDetails?.supportContact?.countryCode || '',
      mobileNumber: companyDetails?.supportContact?.mobileNumber || '',
      officeNumber: companyDetails?.supportContact?.officeNumber || '',
      notes: companyDetails?.supportContact?.notes || '',
    });
  }, [companyDetails]);

  const submit = async () => {
    try {
      const result = await CallAPI(
        'UPDATE_COMPANY_DETAIL',
        { supportContact: values },
        null,
        setLoading,
        setApiError,
      );

      if (result.status) {
        toast.success('Company details updated successfully');
        getCompanyDetail();
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  return (
    <div className='px-4 py-3'>
      {apiError && (
        <div className='mb-2'>
          <Error msg={apiError} />
        </div>
      )}
      <div className='company-settings-style'>
        <div className='responsive-box'>
          <TextField
            name='firstName'
            displayName='field_first_name'
            placeholder='field_first_name'
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            handleChange={handleChange}
            isHorizontal={true}
            horizontalLabelClass={'min-width-176'}
            shrinkFieldOnly
          />
          <TextField
            name='lastName'
            displayName='field_last_name'
            placeholder='field_last_name'
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            handleChange={handleChange}
            isHorizontal={true}
            horizontalLabelClass={'min-width-176'}
            shrinkFieldOnly
          />
        </div>
      </div>
      <div className='company-settings-style mt-3'>
        <div className='responsive-box'>
          <TextField
            name='email'
            displayName='field_email'
            placeholder='field_email'
            value={values.email}
            error={errors.email}
            touched={touched.email}
            handleChange={handleChange}
            isHorizontal={true}
            horizontalLabelClass={'min-width-176'}
            shrinkFieldOnly
          />
          <MobileNumberField
            fieldProps={{ name: 'mobileNumber', placeholder: '123123123' }}
            displayName='field_phone_number'
            icon={phoneIcon}
            value={{ countryCode: values.countryCode, mobileNumber: values.mobileNumber }}
            error={errors.mobileNumber}
            touched={touched.mobileNumber}
            handleChange={handleChange}
            isHorizontal
            shrinkFieldOnly
          />
        </div>
      </div>

      <TextField
        name='officeNumber'
        displayName='field_office_number'
        placeholder='field_office_number'
        value={values.officeNumber}
        error={errors.officeNumber}
        touched={touched.officeNumber}
        handleChange={handleChange}
        classes='mt-3'
        isHorizontal={true}
        horizontalLabelClass={'min-width-176'}
        shrinkFieldOnly
      />

      <TextArea
        name='notes'
        rows={4}
        displayName='field_additional_contact_note'
        placeholder='field_additional_contact_note'
        value={values.notes}
        error={errors.notes}
        touched={touched.notes}
        handleChange={handleChange}
        classes='mt-3'
        isHorizontal={true}
        horizontalLabelClass={'min-width-176'}
      />

      {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
        <div className='mt-3 w-100 d-flex justify-content-end gap-3'>
          <FormButton text='button_cancel' variant='white-1' />
          <FormLoadingButton
            loading={loading}
            onClick={submit}
            text='button_save'
            variant='green-1'
          />
        </div>
      )}
    </div>
  );
};

SuperAdminDetails.propTypes = {
  companyDetails: PropTypes.object,
  getCompanyDetail: PropTypes.func.isRequired,
};

export default SuperAdminDetails;
