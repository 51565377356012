import React, { useEffect, useState } from 'react';
import TableComponent from 'components/table-component/Table';
import { profilesTableHeader } from './utils';
import { useNavigate } from 'react-router-dom';
import FormButton from 'components/form-components/FormButton';
import plusIcon from 'assets/images/icons/plus.svg';
import { CallAPI } from 'actions/General';
import Loader from 'components/common-components/Loader';
import Error from 'components/Error';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';

function ProfilesList() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [tableData, setTableData] = useState([]);
  const getAllProfiles = async () => {
    const result = await CallAPI(
      'LIST_ALL_ADMIN_PROFILES',
      null,
      null,
      setLoader,
      setAPIError,
      null,
    );
    if (result.status) {
      setTableData(result.data);
    }
  };
  useEffect(() => {
    getAllProfiles();
  }, []);
  const profileNameClickCallBack = (row) => {
    console.log(row);
    if (row.profileName !== 'Owner')
      navigate('/profiles/edit', {
        relative: 'path',
        state: {
          type: 'edit',
          profile_id: row._id,
        },
      });
  };
  const AddProfile = (row) => {
    console.log(row);
    navigate('/profiles/add', {
      relative: 'path',
      state: {
        type: 'add',
      },
    });
  };
  return (
    <div className='pb-5'>
      {loader ? <Loader variant='green-1' color='green-1' /> : null}
      {APIError ? <Error msg={APIError} /> : <></>}
      <TableComponent
        header={profilesTableHeader}
        data={tableData}
        props={{
          type: 'profiles_list',
          profileNameClickCallBack: profileNameClickCallBack,
        }}
        totalRecords={2}
        filterEnabled={false}
        loader={false}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={false}
        isActionsEnabled={false}
        isSelectionEnabled={false}
        selectionKey={null}
        paginationFunction={null}
        onSelection={null}
        selectedRows={[]}
        maintainTableHeight={false}
      />
      {isPermissionAvailable(permissionKeys.system_profiles_update) && (
        <div className='add-profile'>
          <FormButton
            text={'button_add_profile'}
            onClick={AddProfile}
            variant='green-2'
            icon={plusIcon}
          />
        </div>
      )}
    </div>
  );
}

export default ProfilesList;
