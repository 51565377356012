import React from 'react';
import PropTypes from 'prop-types';
import minusIcon from 'assets/images/icons/minus.svg';
import plusWhiteIcon from 'assets/images/icons/plus.svg';
import starIcon from 'assets/images/icons/star.svg';
import FormButton from 'components/form-components/FormButton';

const RatingBox = ({ rating, changeRating }) => {
  return (
    <div className='rating-box'>
      <FormButton
        text=''
        variant='grey-2'
        icon={minusIcon}
        minWidth
        classes='px-1'
        onClick={() => changeRating(rating - 1)}
      />
      <span>{rating}</span>
      <img src={starIcon} />
      <FormButton
        text=''
        variant='green-1'
        icon={plusWhiteIcon}
        iconClasses='svg-white'
        minWidth
        classes='px-1'
        onClick={() => changeRating(rating + 1)}
      />
    </div>
  );
};

RatingBox.propTypes = {
  rating: PropTypes.number.isRequired,
  changeRating: PropTypes.func.isRequired,
};

export default RatingBox;
