import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TabSelectionField = ({ name, value, optionList, handleChange, classes }) => {
  const { t } = useTranslation();
  return (
    <div className={`tab-selection-field${classes ? ` ${classes}` : ''}`}>
      {optionList.map((option, index) => (
        <div
          key={index}
          className={value === option.value ? 'active px-2' : 'px-2'}
          onClick={() => handleChange(name, option.value)}
        >
          {t(option.displayValue)}
        </div>
      ))}
    </div>
  );
};

TabSelectionField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  optionList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

export default TabSelectionField;
