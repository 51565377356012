import React, { useEffect, useState } from 'react';

const Icons = () => {
  const [iconList, setIconList] = useState([]);
  const importAll = (r) => {
    return r
      .keys()
      .map(r)
      .map((m) => m.default);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const listOfImages = importAll(require.context('../assets/images/icons/', false));
    setIconList(listOfImages);
  }, []);
  return (
    <div>
      <h1>Icons</h1>
      <div className='d-flex flex-wrap' style={{ width: '100%', boxSizing: 'border-box' }}>
        {iconList.map((image, index) => (
          <div
            key={index}
            className='d-flex flex-column justify-content-center align-items-center border border-2 border-dark rounded p-2'
            style={{ width: '6.667%' }}
          >
            <span className='theme-size-0_8'>{image.split('.')[0].split('/')[3]}</span>
            <img style={{ height: 'auto', maxHeight: '1.2rem' }} src={image} alt='info' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Icons;
