import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';
import AlertModal from 'components/common-components/AlertModal';
import EmailInputModal from 'components/common-components/EmailInputModal';
import FormButton from 'components/form-components/FormButton';
import { CallAPI } from 'actions/General';
import { notificationKey } from 'config/config';
import { useSelector } from 'react-redux';

function SubmissionActions({ ids, actionsCallBack, isActionButton, type, otherDetail }) {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.language);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEmailInputModal, setShowEmailInputModal] = useState(false);
  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [loader, setLoader] = useState(false);

  const deleteSubmission = async () => {
    let APICall = 'DELETE_ARCHIVE_SUBMISSIONS';
    let APIData = {};
    if (otherDetail.listType === 'live-data' || otherDetail.listType === 'test-data') {
      APICall = 'DELETE_LIVE_SUBMISSIONS';
      APIData = {
        isTestSubmission: otherDetail.listType === 'live-data' ? false : true,
        form: { id: otherDetail.formId, title: ids[0].title },
        submissions: ids,
      };
    } else {
      APIData = {
        submissions: ids,
      };
    }
    const result = await CallAPI(APICall, APIData, null, null, null, null);
    setShowDeleteConfirm(false);
    actionsCallBack('delete');
    if (!result.status) {
      toast(result.error, {
        style: { borderRadius: '8px', background: 'red', color: '#fff' },
      });
    }
  };
  const submissionAction = async (type) => {
    let APIData = {
      action: type,
      form: { id: otherDetail.formId, title: ids[0].title },
      submissions: ids,
    };
    await CallAPI('SUBMISSION_ACTION_API', APIData, null, null, null, null);
    setShowDeleteConfirm(false);
    actionsCallBack('delete');
  };
  const confirmDeletion = () => setShowDeleteConfirm(true);

  const getPreviewId = async (type) => {
    const result = await CallAPI('GET_PREVIEW_ID', {
      previewOf: 'submission',
      formId: otherDetail.formId,
      submissionId: ids[0].id,
    });
    if (result.status) {
      const dataType = otherDetail.listType === 'live-data' ? 'real' : 'test';
      const reportLink = `${window.location.href.replace(
        window.location.pathname,
        '',
      )}/report/${type}/${dataType}/${result.data._id}`;
      setTimeout(() => {
        navigator.clipboard.writeText(reportLink);
      }, 0);
    }
  };

  const getPreviewLink = (type) => {
    toast.promise(
      getPreviewId(type),
      {
        loading: 'Checking...',
        success: <span className='theme-text-white-1'>Copied report link to clipboard.</span>,
        error: <span className='theme-text-white-1'>Could not copy link.</span>,
      },
      { style: { borderRadius: '8px', background: '#000', color: '#fff' } },
    );
  };
  const exportCSV = async () => {
    let submissionIds = ids.map((singleSubmission) => singleSubmission.id);
    let API = 'EXPORT_SUBMISSIONS';
    let APIData = {
      form: otherDetail.formId,
      formVersion: otherDetail.formVersion,
      submissions: submissionIds,
      submissionDataOf:
        otherDetail.listType === 'live-data'
          ? 'real_data'
          : otherDetail.listType === 'test-data'
          ? 'test_data'
          : 'archived',
      exportMode: 'multiple',
      exportTo: 'csv',
      locale: lang,
    };
    toast(t(notificationKey['submission_export_process_started']), {
      style: { borderRadius: '8px', background: '#000', color: '#fff' },
    });
    await CallAPI(API, APIData);
  };
  const downloadAllAttachments = async () => {
    let API = 'DOWNLOAD_SUBMISSIONS_MEDIA';
    let APIData = {
      form: otherDetail.formId,
      version: otherDetail.formVersion,
      customFolderId: ids[0].customFolderId,
      submissionDataOf:
        otherDetail.listType === 'live-data'
          ? 'real_data'
          : otherDetail.listType === 'test-data'
          ? 'test_data'
          : 'archived',
    };
    await CallAPI(API, APIData, null, null, null);
  };
  useEffect(() => {
    let submissionActionsList = [
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_delete',
        permission: true,
        action: confirmDeletion,
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_archive',
        permission: otherDetail.listType === 'live-data' || otherDetail.listType === 'test-data',
        action: () => submissionAction('archive'),
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_restore',
        permission: !(otherDetail.listType === 'live-data' || otherDetail.listType === 'test-data'),
        action: () => submissionAction('archive_restore'),
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_copy_web_link',
        permission: ids.length === 1,
        action: () => {
          getPreviewLink('web_link');
        },
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_pdf_preview_report',
        permission: ids.length === 1,
        action: () => {
          getPreviewLink('pdf_preview');
        },
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_send_pdf_copy',
        permission: ids.length === 1,
        action: () => {
          setShowEmailInputModal(true);
        },
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_download_all_attachments',
        permission: ids.length === 1,
        action: () => downloadAllAttachments(),
      },
      {
        allowedFrom: ['row_submission_actions', 'submissions_actions'],
        name: 'action_export_csv',
        permission: true,
        action: () => {
          exportCSV();
        },
      },
    ];
    let menuList = submissionActionsList.filter(
      (item) => item.allowedFrom.indexOf(type) > -1 && item.permission,
    );
    setDisplayedMenu(menuList);
  }, [type, ids]);

  const sendPDFCopyToUser = async (email) => {
    const result = await CallAPI('GET_PREVIEW_ID', {
      previewOf: 'submission',
      formId: otherDetail.formId,
      submissionId: ids[0].id,
    });
    if (result.status) {
      await CallAPI(
        'GET_PREVIEW_DETAIL',
        {
          previewId: result.data._id,
          dataOf: otherDetail.listType === 'live-data' ? 'real' : 'test',
          action: 'pdf_send',
          email: email.email,
        },
        null,
        setLoader,
        null,
        null,
      );
    }
    setShowEmailInputModal(false);
  };

  const sendPDFCopy = async (email) => {
    toast.promise(
      sendPDFCopyToUser(email),
      {
        loading: 'Sending Email...',
        success: <span className='theme-text-white-1'>PDF Copy sent successfully.</span>,
        error: <span className='theme-text-white-1'>Something went wrong.</span>,
      },
      { style: { borderRadius: '8px', background: '#000', color: '#fff' } },
    );
  };
  return (
    <Fragment>
      {showEmailInputModal && (
        <EmailInputModal
          showModal={showEmailInputModal}
          setShowModal={setShowEmailInputModal}
          onSubmit={sendPDFCopy}
          loading={loader}
        />
      )}
      <div className='dropdown-actions'>
        {displayedMenu && displayedMenu.length > 0 && (
          <DropdownButton
            disabled={ids.length > 0 ? false : true}
            as={ButtonGroup}
            drop='bottom'
            variant='secondary'
            title={
              <>
                {isActionButton ? (
                  <img src={dotsIcon} alt='Loading...' />
                ) : (
                  <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
                )}
              </>
            }
          >
            {displayedMenu.map((button, index) => (
              <Dropdown.Item
                onClick={button.action}
                key={index}
                className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
              >
                {t(button.name)}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
        <AlertModal
          show={showDeleteConfirm}
          showCloseButton={true}
          closeModal={() => setShowDeleteConfirm(false)}
          variant='delete'
          message={t('alert_delete_submission')}
          actionButtons={[
            {
              text: t('button_confirm'),
              variant: 'red-1',
              onClick: () => deleteSubmission('delete'),
            },
            {
              text: t('button_cancel'),
              variant: 'white-1',
              onClick: () => setShowDeleteConfirm(false),
            },
          ]}
        />
      </div>
    </Fragment>
  );
}
SubmissionActions.propTypes = {
  ids: PropTypes.array.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  otherDetail: PropTypes.object.isRequired,
};

export default SubmissionActions;
