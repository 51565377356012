import React, { useState } from 'react';
import TabHeader from 'components/tab-components/TabHeader';
import TabLayout from 'components/tab-components/TabLayout';
import TabBody from 'components/tab-components/TabBody';
import { dataSourceEntriesTabsList } from './utils';
import DatasourceList from './DatasourceList';
import DataSourceFilter from './DataSourceFilter';

const DataSourceListView = () => {
  const [selectedTab, setSelectedTab] = useState(dataSourceEntriesTabsList[0]);
  const [enabledFilterTab, setEnabledFilterTab] = useState(true);

  return (
    <TabLayout>
      <TabHeader
        tabList={dataSourceEntriesTabsList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        disabledId={!enabledFilterTab ? [2] : []}
      />
      <TabBody>
        {selectedTab.value === 'data_source' ? (
          <DatasourceList setEnabledFilterTab={setEnabledFilterTab} />
        ) : selectedTab.value === 'data_filter' ? (
          <DataSourceFilter />
        ) : (
          <div></div>
        )}
      </TabBody>
    </TabLayout>
  );
};

export default DataSourceListView;
