import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import activityLogIcon from 'assets/images/form-icons/activity-log.svg';
import announcementIcon from 'assets/images/form-icons/announcement.svg';
import appScreenIcon from 'assets/images/form-icons/app-screen.svg';
import arrowLeftIcon from 'assets/images/icons/arrow-left.svg';
import arrowRightIcon from 'assets/images/icons/arrow-right.svg';
import attendanceIcon from 'assets/images/form-icons/attendance.svg';
import billingIcon from 'assets/images/form-icons/billing.svg';
import cameraPlusIcon from 'assets/images/icons/camera-plus.svg';
import companySettingsIcon from 'assets/images/form-icons/company-settings.svg';
import dashboardIcon from 'assets/images/form-icons/dashboard.svg';
import filePlusIcon from 'assets/images/icons/file-plus.svg';
import mapIcon from 'assets/images/icons/map.svg';
import supportIcon from 'assets/images/form-icons/support.svg';
import videoPlusIcon from 'assets/images/icons/video-plus.svg';
import AlertModal from 'components/common-components/AlertModal';
import CheckListFieldPreview from './common-components/CheckListFieldPreview';
import DateTimeFieldPreview from './common-components/DateTimeFieldPreview';
import FormButton from 'components/form-components/FormButton';
import ImageFieldPreview from './common-components/ImageFieldPreview';
import NumberRangeFieldPreview from './common-components/NumberRangeFieldPreview';
import RatingFieldPreview from './common-components/RatingFieldPreview';
import SignatureFieldPreview from './common-components/SignatureFieldPreview';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';
import { cloneDeep, groupBy } from 'lodash';

const PreviewModal = ({ setShowPreviewModal, formTitle, formIcon, userFields }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(null);

  const closeModal = () => {
    setState(null);
    setShowPreviewModal(false);
  };

  return (
    <div>
      <AlertModal
        show={true}
        showCloseButton={true}
        closeModal={closeModal}
        title='alert_preview'
        actionButtons={[{ text: t('button_ok'), variant: 'grey-1', onClick: closeModal }]}
      >
        <FormPreview {...{ state, setState, formTitle, formIcon, userFields }} />
      </AlertModal>
    </div>
  );
};

PreviewModal.propTypes = {
  setShowPreviewModal: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
  formIcon: PropTypes.string.isRequired,
  userFields: PropTypes.array.isRequired,
};

export default PreviewModal;

const FormPreview = ({ state, setState, formTitle, formIcon, userFields }) => {
  const formTypeValues = [
    { value: 'announcement', labelImage: announcementIcon },
    { value: 'activity-log', labelImage: activityLogIcon },
    { value: 'app-screen', labelImage: appScreenIcon },
    { value: 'billing', labelImage: billingIcon },
    { value: 'attendance', labelImage: attendanceIcon },
    { value: 'dashboard', labelImage: dashboardIcon },
    { value: 'company', labelImage: companySettingsIcon },
    { value: 'support', labelImage: supportIcon },
  ];
  const formTypeValuesMap = Object.fromEntries(formTypeValues.map((v) => [v.value, v.labelImage]));

  const pageAndSectionElements = userFields.filter((element) =>
    ['page', 'section'].includes(element.elementType),
  );

  const getFieldsByPageNumber = (pageNumber) => {
    return userFields.filter(
      (field) => field.pageNumber === pageNumber && field.elementType !== 'page',
    );
  };

  const getFieldsByPageAndSectionNumber = (pageNumber, sectionNumber) => {
    return userFields.filter(
      (field) =>
        field.pageNumber === pageNumber &&
        field.sectionNumber === sectionNumber &&
        !['page', 'section'].includes(field.elementType),
    );
  };

  return (
    <div className='preview-modal'>
      <div className='d-flex gap-3 justify-content-center'>
        <img src={formTypeValuesMap[formIcon]} />
        <label className='theme-size-1_2 theme-text-black-1 theme-font-inter-medium'>
          {formTitle}
        </label>
      </div>
      {state && (
        <div className='back-arrow' onClick={() => setState(null)}>
          <img src={arrowLeftIcon} />
        </div>
      )}

      {!state ? (
        <FormPages pageAndSectionElements={pageAndSectionElements} setState={setState} />
      ) : state.selected === 'page' ? (
        <FieldsPreview userFields={getFieldsByPageNumber(state.pageNumber)} />
      ) : state.selected === 'section' ? (
        <FieldsPreview
          userFields={getFieldsByPageAndSectionNumber(state.pageNumber, state.sectionNumber)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

FormPreview.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
  formIcon: PropTypes.string.isRequired,
  userFields: PropTypes.array.isRequired,
};

const FormPages = ({ pageAndSectionElements, setState }) => {
  const [sectionOpened, setSectionOpened] = useState(false);

  const groupSectionsByPage = groupBy(
    pageAndSectionElements.filter((field) => field.elementType === 'section'),
    (element) => element.pageNumber,
  );
  return (
    <div className='page-listing'>
      {pageAndSectionElements
        .filter((field) => (!sectionOpened && field.elementType === 'page') || sectionOpened)
        .map((element, index) => {
          let elementProps = cloneDeep(element.properties);
          console.log('elementProps', elementProps);
          return (
            <div key={index} className='d-flex gap-2 align-items-center'>
              {element.elementType === 'page' ? (
                <Fragment>
                  <div
                    className='cursor-pointer flex-grow-1 py-2 d-flex gap-2'
                    onClick={() =>
                      setState({ selected: element.elementType, pageNumber: element.pageNumber })
                    }
                  >
                    <img src={arrowLeftIcon} className={'rotate-180'} />
                    <label>{elementProps?.basicProperties?.pageTitle}</label>
                  </div>

                  {groupSectionsByPage[element.pageNumber]?.length && (
                    <div
                      className='cursor-pointer px-1'
                      onClick={() => setSectionOpened(!sectionOpened)}
                    >
                      <img
                        src={arrowRightIcon}
                        className={`svg-black ${sectionOpened ? 'rotate-270' : 'rotate-90'}`}
                      />
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <div
                    className='cursor-pointer flex-grow-1 py-2 px-3 d-flex gap-2'
                    onClick={() =>
                      setState({
                        selected: element.elementType,
                        pageNumber: element.pageNumber,
                        sectionNumber: element.sectionNumber,
                      })
                    }
                  >
                    <img src={arrowLeftIcon} className={'rotate-180'} />
                    <label>{elementProps?.basicProperties?.sectionTitle}</label>
                  </div>
                </Fragment>
              )}
            </div>
          );
        })}
    </div>
  );
};

FormPages.propTypes = {
  pageAndSectionElements: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
};

const FieldsPreview = ({ userFields }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column gap-4'>
      {!userFields.length && <label>There are no fields in this Page / Section</label>}
      {userFields.map((field, index) => {
        let fieldProps = field.properties;
        return field.elementType === 'section' ? (
          <div key={index} className='theme-background-green-1 p-3 rounded'>
            <label className='theme-text-white-1'>
              {fieldProps?.basicProperties?.sectionTitle}
            </label>
          </div>
        ) : (
          <div
            key={index}
            className={`field-component-box${
              fieldProps?.layoutProperties?.withoutFrame ? '' : ' with-frame'
            }`}
          >
            <div className='d-flex flex-column mb-2'>
              <label className='theme-size-1_1 theme-text-black-1 theme-font-inter-medium'>
                {fieldProps?.basicProperties?.fieldTitle}
              </label>
              {fieldProps?.basicProperties?.fieldHintText &&
                fieldProps?.basicProperties?.fieldHintText !== '' && (
                  <label className='theme-size-0_9 theme-font-inter-regular'>
                    {fieldProps?.basicProperties?.fieldHintText}
                  </label>
                )}
            </div>
            {['text', 'number'].includes(field.elementType) ? (
              <TextField
                name='fieldTitle'
                value=''
                classes='w-100'
                placeholder={fieldProps?.basicProperties?.fieldPlaceholder ?? ''}
                handleChange={() => {}}
                shrink
                disabled
              />
            ) : field.elementType === 'location' ? (
              <div className='d-flex gap-2'>
                <TextArea
                  name='location'
                  value=''
                  placeholder='Address'
                  classes='w-100'
                  handleChange={() => {}}
                  rows={3}
                  disabled
                />
                <FormButton text='Map' variant='green-1' icon={mapIcon} onClick={() => {}} />
              </div>
            ) : field.elementType === 'dataSource' ? (
              <div className='border-grey-1-h-1 rounded-sm d-flex justify-content-center align-items-center p-2'>
                <label>Data Source</label>
              </div>
            ) : field.elementType === 'dateTime' ? (
              <DateTimeFieldPreview field={field} />
            ) : field.elementType === 'image' ? (
              <ImageFieldPreview field={field} />
            ) : field.elementType === 'signature' ? (
              <SignatureFieldPreview field={field} />
            ) : field.elementType === 'checkList' ? (
              <CheckListFieldPreview field={field} />
            ) : field.elementType === 'ratingSlider' ? (
              <RatingFieldPreview field={field} />
            ) : field.elementType === 'numberSlider' ? (
              <NumberRangeFieldPreview field={field} />
            ) : (
              <></>
            )}

            {(fieldProps?.mediaProperties?.allowNotes ||
              fieldProps?.mediaProperties?.allowPictures ||
              fieldProps?.mediaProperties?.allowVideos) && (
              <div className='d-flex align-items-center gap-4 mt-3'>
                <div className='d-flex align-items-center gap-3'>
                  <label className='theme-text-black-1'>{t('text_add')} :</label>
                  <div className='vertical-line'></div>
                </div>
                {fieldProps?.mediaProperties?.allowNotes && (
                  <div className='d-flex align-items-center gap-2'>
                    <img src={filePlusIcon} />
                    <label className='theme-text-black-1'>{t('text_note')}</label>
                  </div>
                )}
                {fieldProps?.mediaProperties?.allowPictures && (
                  <div className='d-flex align-items-center gap-2'>
                    <img src={cameraPlusIcon} />
                    <label className='theme-text-black-1'>{t('text_picture')}</label>
                  </div>
                )}
                {fieldProps?.mediaProperties?.allowVideos && (
                  <div className='d-flex align-items-center gap-2'>
                    <img src={videoPlusIcon} />
                    <label className='theme-text-black-1'>{t('text_video')}</label>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

FieldsPreview.propTypes = {
  userFields: PropTypes.array.isRequired,
};
