import React, { useState } from 'react';
import { tabList } from './utils';
import TabLayout from '../../components/tab-components/TabLayout';
import TabHeader from '../../components/tab-components/TabHeader';
import TabBody from '../../components/tab-components/TabBody';
import DatabaseList from './database/DatabaseList';
import CommingSoon from 'pages/CommingSoon';

const DataSource = () => {
  const [selectedTab, setSelectedTab] = useState(tabList[0]);

  return (
    <TabLayout>
      <TabHeader tabList={tabList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <TabBody>{selectedTab.id === tabList[0].id ? <DatabaseList /> : <CommingSoon />}</TabBody>
    </TabLayout>
  );
};

export default DataSource;
