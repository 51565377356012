import React from 'react';
import PropTypes from 'prop-types';
import doubleUDownArrowIcon from '../../assets/images/icons/double-down-arrow.svg';
import { useTranslation } from 'react-i18next';

const ToggleBoxHeader = ({ show, toggleBoxOpen, title }) => {
  const { t } = useTranslation();
  return (
    <div className={`div-toggler-header${show ? ' active' : ''}`} onClick={() => toggleBoxOpen()}>
      <span>{t(title)}</span>
      <img className={show ? 'svg-white rotate-180' : ''} src={doubleUDownArrowIcon} />
    </div>
  );
};

ToggleBoxHeader.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleBoxOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ToggleBoxHeader;
