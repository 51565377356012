import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import AlertModal from 'components/common-components/AlertModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Loader from 'components/common-components/Loader';
import OTPField from 'components/form-components/OTPField';
import TextField from 'components/form-components/TextField';
import { GetOtpForOperation } from 'actions/General';
import { logout, updateSavedChanges } from 'config/config';
import { verifyOTPAndDeleteCompany } from 'pages/auth-pages/utils';

const CompanyDeleteModal = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();

  const authState = useSelector((state) => state.auth);

  const [values, setValues] = useState({
    deleteText: '',
    otp: '',
    countryCode: authState.profile.countryCode,
    mobileNumber: authState.profile.mobileNumber,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [askForOtp, setAskForOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const [publicKey, setPublicKey] = useState('');

  const handleChange = (field, value) => {
    setApiError('');
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const confirmTypeDelete = async () => {
    setTouched({});
    if (values.deleteText !== 'Delete') {
      setErrors({ deleteText: 'Incorrect typed' });
    } else {
      setLoading(true);
      const result = await GetOtpForOperation(
        {
          otpType: 'delete',
          countryCode: values.countryCode,
          mobileNumber: values.mobileNumber,
        },
        setApiError,
        setLoading,
      );
      if (result.status) {
        setAskForOtp(true);
        setPublicKey(result.publicKey);
      }
    }
  };

  const onResendOTP = async () => {
    setResendOTPLoading(true);
    const result = await GetOtpForOperation(
      {
        otpType: 'delete',
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
      },
      setApiError,
      setResendOTPLoading,
    );
    if (result.status) {
      setResendOTP(true);
      setPublicKey(result.publicKey);
      toast(t('New OTP sent'), {
        style: { borderRadius: '8px', background: '#000', color: '#fff' },
      });
    }
    setResendOTPLoading(false);
  };

  const onSubmitOTP = async () => {
    setTouched({});
    setLoading(true);
    if (values.otp.length < 4) {
      setErrors({ otp: 'At least 4 digits' });
      setLoading(false);
    } else {
      const payload = { otp: values.otp, publicKey };

      const result = await verifyOTPAndDeleteCompany(payload, setApiError, setLoading);

      if (result) {
        setOtpVerified(true);
        updateSavedChanges(true, 'COMPANY_DELETED');
      }
    }
  };

  return (
    <AlertModal
      show={showModal}
      showCloseButton={!otpVerified && askForOtp}
      closeModal={() => setShowModal(false)}
      actionButtons={[]}
    >
      <div className='w-75 d-flex flex-column gap-3 align-items-center'>
        {apiError ? <Error msg={apiError} /> : <></>}

        {otpVerified ? (
          <Fragment>
            <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_5'>
              {t('text_company_suspended')}
            </label>
            <label className='text-justify'>{t('text_company_suspended_explanation')}</label>
            <FormButton
              text='button_sign_out'
              variant='red-1'
              classes='w-100'
              largeSize
              onClick={logout}
            />
          </Fragment>
        ) : (
          <Fragment>
            {!askForOtp ? (
              <Fragment>
                <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_5'>
                  {t('alert_delete_company_confirm_title')}
                </label>

                <TextField
                  name='deleteText'
                  displayName='field_type_delete'
                  placeholder='field_type_delete'
                  value={values.deleteText}
                  error={errors.deleteText}
                  touched={touched.deleteText}
                  handleChange={handleChange}
                />
                <div className='w-100 d-flex justify-content-around'>
                  <label
                    className='theme-font-inter-medium theme-text-black-1 theme-size-1_2 cursor-pointer'
                    onClick={() => setShowModal(false)}
                  >
                    {t('button_cancel')}
                  </label>
                  <label className='d-flex gap-2'>
                    <span
                      className='theme-font-inter-medium theme-text-green-1 theme-size-1_2 cursor-pointer'
                      onClick={() => confirmTypeDelete()}
                    >
                      {t('button_confirm')}
                    </span>
                    {loading && <Loader color='green-1' />}
                  </label>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <label className='theme-font-inter-medium theme-text-red-1 theme-size-1_5'>
                  {t('alert_delete_company_confirm_title')}
                </label>
                <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_5'>
                  {t('text_otp_verification')}
                </label>
                <label className='theme-text-black-1 w-75 text-center'>
                  {t('text_sent_otp_on_this_number')}
                  {`: ${values.countryCode}${values.mobileNumber}`}
                </label>
                <OTPField
                  name='otp'
                  handleChange={handleChange}
                  error={errors.otp}
                  touched={touched.otp}
                />
                <FormLoadingButton
                  text='button_verify'
                  variant='green-1'
                  loading={loading}
                  classes='w-100'
                  largeSize
                  onClick={onSubmitOTP}
                />
                {!resendOTP && (
                  <Fragment>
                    <div>
                      <span className='helper-text'>{t('text_did_not_receive_otp')} </span>
                      <span
                        {...(!resendOTPLoading && { onClick: () => onResendOTP() })}
                        className='theme-text-green-1 cursor-pointer'
                      >
                        {t('text_resend_otp')}
                      </span>
                      {resendOTPLoading && (
                        <span>
                          <Spinner size='sm' className='theme-text-green-1 ms-2' />
                        </span>
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </AlertModal>
  );
};

CompanyDeleteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default CompanyDeleteModal;
