import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import submissionAttachment from 'assets/images/icons/submission-attachment.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const TableHeaderSubmission = ({
  filterEnabled,
  mediaEnabled,
  tableFields,
  onSelection,
  isActionsEnabled,
  isSelectionEnabled,
  selectionValue,
  debounceFunc,
  filteringData,
  setFilteringData,
  setHeaderWidthMap,
}) => {
  const [hoverTitleShowMap, setHoverTitleShowMap] = useState({});

  const toggleShowMap = (name) =>
    setHoverTitleShowMap({ ...hoverTitleShowMap, [name]: !(hoverTitleShowMap[name] ?? false) });

  const popover = ({ fullFieldTitle }) => (
    <Popover className='min-width-176' id='popover-basic'>
      <Popover.Body>
        <label>{fullFieldTitle}</label>
      </Popover.Body>
    </Popover>
  );

  return (
    <thead>
      {filterEnabled && (
        <tr>
          {isSelectionEnabled && <th className='filter-field'></th>}
          {tableFields.map((singleHeaderField, index) => {
            return singleHeaderField.isSelected ? (
              <th key={index} className='filter-field'>
                {singleHeaderField.isFilterAble ? (
                  <TextField
                    value={
                      filteringData[singleHeaderField.name]
                        ? filteringData[singleHeaderField.name]
                        : ''
                    }
                    name={singleHeaderField.name}
                    debounceFunc={debounceFunc}
                    handleChange={setFilteringData}
                    placeholder=''
                    shrink
                    classes='filter-field'
                  />
                ) : (
                  ''
                )}
              </th>
            ) : null;
          })}
          {isActionsEnabled && <th className='filter-field'></th>}
        </tr>
      )}
      <tr>
        {isSelectionEnabled && (
          <th className='table-checkbox'>
            <SingleCheckBoxField
              name={'all'}
              value={selectionValue}
              handleChange={onSelection}
              text={<></>}
              classes=''
            />
          </th>
        )}
        {tableFields.map((singleHeaderField, index) => {
          return singleHeaderField.isSelected ? (
            <th key={index} className='nowrap' style={{ maxWidth: 'unset' }}>
              {['basicFields', 'supervisorFields'].includes(singleHeaderField.tableType) ? (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  onToggle={() => toggleShowMap(singleHeaderField.name)}
                  show={hoverTitleShowMap[singleHeaderField.name]}
                  placement='top'
                  rootClose
                  overlay={popover({
                    fullFieldTitle:
                      singleHeaderField.hintText && singleHeaderField.hintText !== ''
                        ? singleHeaderField.hintText
                        : singleHeaderField.displayName,
                  })}
                >
                  <div>
                    <TableHeaderColumn
                      setHeaderWidthMap={setHeaderWidthMap}
                      mediaEnabled={mediaEnabled}
                      singleHeaderField={singleHeaderField}
                    />
                  </div>
                </OverlayTrigger>
              ) : (
                <TableHeaderColumn
                  setHeaderWidthMap={setHeaderWidthMap}
                  mediaEnabled={mediaEnabled}
                  singleHeaderField={singleHeaderField}
                />
              )}
            </th>
          ) : null;
        })}
        {isActionsEnabled && <th></th>}
      </tr>
    </thead>
  );
};

TableHeaderSubmission.propTypes = {
  tableFields: PropTypes.array.isRequired,
  onSelection: PropTypes.func,
  filterEnabled: PropTypes.bool.isRequired,
  mediaEnabled: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  setHeaderWidthMap: PropTypes.func.isRequired,
};

export default TableHeaderSubmission;

const TableHeaderColumn = ({ singleHeaderField, mediaEnabled, setHeaderWidthMap }) => {
  const { t } = useTranslation();
  const resizableHeaderRef = useRef();

  useEffect(() => {
    if (!resizableHeaderRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      // Do what you want to do when the size of the element changes
      if (entries.length) {
        const contentReact = entries[0].contentRect;
        const fieldIdKey = entries[0].target.dataset?.fieldId;
        if (fieldIdKey) {
          setHeaderWidthMap((prevState) => ({ ...prevState, [fieldIdKey]: contentReact.width }));
        }
      }
    });
    resizeObserver.observe(resizableHeaderRef.current);
    // clean up
    return () => resizeObserver.disconnect();
  }, [resizableHeaderRef.current]);

  return (
    <div
      className='d-flex justify-content-between border-right-grey-10-h-2'
      id='resizable-header'
      data-field-id={singleHeaderField.name}
      style={
        singleHeaderField.associatedKey !== 'indexed' && singleHeaderField.type !== 'status'
          ? {
              resize: 'horizontal',
              overflowX: 'hidden',
              ...(singleHeaderField?.isMediaAttached &&
                mediaEnabled && { position: 'relative', minWidth: '3rem' }),
            }
          : {}
      }
      ref={resizableHeaderRef}
    >
      <span>{t(singleHeaderField.displayName)}</span>

      {singleHeaderField?.isMediaAttached && mediaEnabled && (
        <img
          className='px-2 theme-background-white-2'
          style={{ position: 'sticky', right: 0 }}
          src={submissionAttachment}
        />
      )}
    </div>
  );
};

TableHeaderColumn.propTypes = {
  singleHeaderField: PropTypes.object.isRequired,
  mediaEnabled: PropTypes.bool,
  setHeaderWidthMap: PropTypes.func.isRequired,
};
