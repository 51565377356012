import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrowCircleRightIcon from 'assets/images/icons/arrow-circle-right.svg';
import dotGreyIcon from 'assets/images/icons/dot-grey.svg';
import midaniLogoArabic from 'assets/images/midani-logo-arabic.svg';
import midaniLogoEnglish from 'assets/images/midani-logo-english.svg';
import FormButton from 'components/form-components/FormButton';
import Loader from 'components/common-components/Loader';
import { CallAPI } from 'actions/General';
import { updateTotalCompanies } from 'actions/ReduxActions';
import { isRTL } from 'config/config';

const ListCompanies = ({ isSwitchCompany, closeModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [loader, setLoader] = useState(false);

  const GetAllSystemModules = async () => {
    await CallAPI('GET_SYSTEM_MODULES_LIST', null, null, null, null, null);
  };
  const GetAllCompanies = async () => {
    let result = await CallAPI('LIST_COMPANIES', { platform: 'web' }, null, setLoader, null, null);
    updateTotalCompanies(result.data.companies.length);
    if (result.status) setCompanies(result.data.companies);
  };
  const SelectCompany = async (id) => {
    await CallAPI('SELECT_COMPANY', { companyId: id }, null, setLoader, null, navigate);
    if (isSwitchCompany) closeModal();
  };
  useEffect(() => {
    GetAllCompanies();
    GetAllSystemModules();
  }, []);
  return (
    <div className='auth-form-container'>
      <div className='d-flex flex-column align-items-center'>
        <img
          className={isSwitchCompany ? 'mb-3 w-25' : 'mb-3 listing-logo'}
          src={isRTL() ? midaniLogoArabic : midaniLogoEnglish}
        />

        {!isSwitchCompany ? (
          <>
            <p className='theme-size-1_5 theme-text-black-1 theme-font-inter-medium mb-1'>
              {t('text_welcome')}
            </p>
            <p>{t('text_select_company')}</p>
          </>
        ) : (
          <p>{t('text_switch_company')}</p>
        )}
      </div>
      {loader && <Loader color='green-1' />}
      <div className='space-10'></div>
      <div className='companies-list'>
        <div className='list'>
          {companies.map((singleCompany, index) => {
            return (
              <div
                key={singleCompany.company._id + index}
                onClick={() => SelectCompany(singleCompany.company._id)}
                className='company-record'
              >
                <img src={dotGreyIcon} />
                <label className='theme-font-inter-regular'>
                  {singleCompany.company.companyName}
                </label>
                <img className={isRTL() ? 'rotate-180' : ''} src={arrowCircleRightIcon} />
              </div>
            );
          })}
        </div>
        {!isSwitchCompany && (
          <FormButton
            text='button_add_new_company'
            variant='white-1'
            classes='mt-3'
            onClick={() => navigate('/add_company', { relative: 'path' })}
          />
        )}
      </div>
    </div>
  );
};

ListCompanies.propTypes = {
  isSwitchCompany: PropTypes.bool,
  closeModal: PropTypes.func,
};
export default ListCompanies;
