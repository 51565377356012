import React from 'react';
import PropTypes from 'prop-types';
import crossIcon from 'assets/images/icons/cross-grey.svg';
import tagIcon from 'assets/images/icons/tag-grey.svg';

export const Tag = ({ title, icon, onDelete }) => {
  return (
    <div className='d-inline-flex align-items-center justify-content-between gap-2 px-2 py-1 theme-background-green-3 rounded rounded-4'>
      {icon && <img src={tagIcon} alt='Loading...' />}
      <span className='pb-1'>{title}</span>
      {onDelete && (
        <img className='cursor-pointer' src={crossIcon} alt='Loading...' onClick={onDelete} />
      )}
    </div>
  );
};

Tag.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.bool,
  onDelete: PropTypes.func,
};
