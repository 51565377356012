import React from 'react';
import PropTypes from 'prop-types';
import SwappingComponent from './SwappingComponent';
import { supervisorFieldList } from '../utils';
import FieldDetailView from './FieldDetailView';
import { useTranslation } from 'react-i18next';
import elementPreviewIcon from 'assets/images/icons/field_preview_Icon.svg';

const fieldTypeMap = Object.fromEntries([...supervisorFieldList].map((f) => [f.elementType, f]));

const SupervisorFieldComponent = ({
  field,
  selectComponent,
  changeFormElementOrder,
  isSelected,
  elementIndex,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`field-component ${isSelected ? 'field-component-selected' : ''}`}>
      <div className='field-component-header'>
        <div className='d-flex gap-3 flex-grow-1' onClick={() => selectComponent()}>
          <img
            src={elementPreviewIcon}
            className={`field-type-icon ${!isSelected ? 'svg-black' : ''}`}
          />
          <p className='mb-0 theme-text-black-1'>
          {field.properties?.basicProperties?.fieldTitle
            ? field.properties?.basicProperties?.fieldTitle
            : t('form_untitled_field')}
          </p>
        </div>
        <SwappingComponent
          elementType={t(fieldTypeMap[field.elementType].displayName)}
          elementIcon={fieldTypeMap[field.elementType].icon}
          type='supervisor_field'
          props={{ position: elementIndex }}
          changeFormElementOrder={changeFormElementOrder}
        />
      </div>
      <div className='field-component-body'>
        {/* <p className='theme-text-black-1 theme-font-jakartaSans-medium'>
          {field.properties?.basicProperties?.fieldTitle
            ? field.properties?.basicProperties?.fieldTitle
            : t('form_untitled_field')}
        </p> */}
        <p>
          {field.properties?.basicProperties?.fieldHintText
            ? field.properties?.basicProperties?.fieldHintText
            : t('form_hint')}
        </p>
        <FieldDetailView element={field} dataSourcesList={[]} />
      </div>
    </div>
  );
};

SupervisorFieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  selectComponent: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  elementIndex: PropTypes.number.isRequired,
};

export default SupervisorFieldComponent;
