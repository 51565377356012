import calendarDateTimeIcon from 'assets/images/icons/calendar-date-time.svg';
import checkListIcon from 'assets/images/icons/check-list.svg';
import databaseExportIcon from 'assets/images/icons/database-export.svg';
import hashIcon from 'assets/images/icons/hash.svg';
import infoSquareIcon from 'assets/images/icons/info-square.svg';
import imageIcon from 'assets/images/icons/image.svg';
import locationIcon from 'assets/images/icons/location.svg';
import noteIcon from 'assets/images/icons/note.svg';
import numberSliderIcon from 'assets/images/icons/number-slider.svg';
import pageIcon from 'assets/images/icons/page.svg';
import sectionIcon from 'assets/images/icons/section.svg';
import signatureIcon from 'assets/images/icons/signature.svg';
import starIcon from 'assets/images/icons/star.svg';
import textIcon from 'assets/images/icons/text.svg';

export const userFieldList = [
  { elementType: 'text', displayName: 'form_text', icon: textIcon },
  { elementType: 'number', displayName: 'form_number', icon: hashIcon },
  { elementType: 'checkList', displayName: 'form_choice_list', icon: checkListIcon },
  { elementType: 'image', displayName: 'form_image', icon: imageIcon },
  { elementType: 'location', displayName: 'form_location', icon: locationIcon },
  { elementType: 'dateTime', displayName: 'form_date_time', icon: calendarDateTimeIcon },
  { elementType: 'signature', displayName: 'form_signature', icon: signatureIcon },
  { elementType: 'ratingSlider', displayName: 'form_star_rating', icon: starIcon },
  { elementType: 'numberSlider', displayName: 'form_number_slider', icon: numberSliderIcon },
  { elementType: 'page', displayName: 'form_page', icon: pageIcon },
  { elementType: 'section', displayName: 'form_section', icon: sectionIcon },
  { elementType: 'dataSource', displayName: 'form_data_source', icon: databaseExportIcon },
];

export const supervisorFieldList = [
  { elementType: 'note', displayName: 'form_note', icon: noteIcon },
  { elementType: 'number', displayName: 'form_number', icon: hashIcon },
  { elementType: 'dateTime', displayName: 'form_date_time', icon: calendarDateTimeIcon },
  { elementType: 'signature', displayName: 'form_signature', icon: signatureIcon },
  { elementType: 'ratingSlider', displayName: 'form_star_rating', icon: starIcon },
  { elementType: 'status', displayName: 'form_status', icon: infoSquareIcon },
];

export const defaultElementStructure = (elementType, position, pageNumber, sectionNumber) => {
  const basicProperties = getUserElementDefaultBasicPropertiesByElementType(elementType);
  const validationProperties = getUserElementDefaultValidationPropertiesByElementType(elementType);
  const layoutProperties = {};
  const otherProperties = getUserElementDefaultOtherProperties();
  const mediaProperties = getUserElementDefaultMediaProperties();
  return {
    elementType,
    position,
    pageNumber,
    sectionNumber,
    properties: {
      basicProperties,
      ...(elementType !== 'image' ? { validationProperties } : {}),
      layoutProperties,
      ...(!['page', 'section', 'image'].includes(elementType) ? { otherProperties } : {}),
      ...(!['page', 'section', 'image'].includes(elementType) ? { mediaProperties } : {}),
    },
  };
};

export const defaultSupervisorElementStructure = (elementType, position) => {
  const basicProperties = getSupervisorFieldDefaultBasicPropertiesByElementType(elementType);
  const validationProperties =
    getSupervisorFieldDefaultValidationPropertiesByElementType(elementType);
  const otherProperties = getSupervisorFieldDefaultOtherProperties();

  return {
    elementType,
    position,
    pageNumber: 0,
    sectionNumber: 0,
    properties: { basicProperties, validationProperties, otherProperties },
  };
};

const getSupervisorFieldDefaultBasicPropertiesByElementType = (elementType) => {
  const fieldDefaultProperties = { fieldTitle: '', fieldHintText: '' };

  switch (elementType) {
    case 'note':
    case 'signature':
      return { ...fieldDefaultProperties };

    case 'status':
      return { ...fieldDefaultProperties, optionList: [] };

    case 'dateTime':
      return { ...fieldDefaultProperties, subType: 'dateAndTime' };

    case 'ratingSlider':
      return { ...fieldDefaultProperties, lowLabel: 'Bad', highLabel: 'Good', ratingPointers: 5 };

    default:
      return {};
  }
};

const getSupervisorFieldDefaultValidationPropertiesByElementType = (elementType) => {
  const fieldDefaultProperties = { required: false, notifyUser: false, visibleToAppUser: false };

  switch (elementType) {
    case 'note':
    case 'dateTime':
    case 'signature':
    case 'ratingSlider':
    case 'status':
      return { ...fieldDefaultProperties };

    case 'number':
      return { ...fieldDefaultProperties, restriction: false, minRange: 1, maxRange: 10 };

    default:
      return {};
  }
};

const getSupervisorFieldDefaultOtherProperties = () => {
  return {
    fieldUniqueId: `field_${Math.ceil(Date.now() / 1000)}`,
    defaultValue: '',
    shortTitle: '',
  };
};

const getUserElementDefaultBasicPropertiesByElementType = (elementType) => {
  const fieldDefaultProperties = {
    fieldTitleColor: '#000000ff',
    fieldTitleSize: '14px',
    fieldTitleFontType: [],
    fieldHintTextColor: '#000000ff',
    fieldHintTextSize: '12px',
    fieldHintTextFontType: [],
    allowMediaNotes: false,
  };

  switch (elementType) {
    case 'text':
    case 'number':
    case 'image':
    case 'location':
    case 'signature':
      return { ...fieldDefaultProperties };

    case 'checkList':
      return {
        ...fieldDefaultProperties,
        subType: 'choiceList',
        optionList: [],
        multipleChoice: false,
      };

    case 'dataSource':
      return {
        ...fieldDefaultProperties,
        subType: 'choiceList',
        optionList: [],
        multipleChoice: false,
        dataSource: null,
        displayColumn: null,
        valueColumn: null,
        bindingList: [],
      };

    case 'dateTime':
      return { ...fieldDefaultProperties, subType: 'dateAndTime' };

    case 'ratingSlider':
      return { ...fieldDefaultProperties, lowLabel: 'Bad', highLabel: 'Good', ratingPointers: 5 };

    case 'numberSlider':
      return { ...fieldDefaultProperties, minRange: 1, maxRange: 10 };

    case 'page':
      return { pageTitleColor: '#000000ff', pageTitleSize: '16px', pageTitleFontType: [] };

    case 'section':
      return {
        sectionTitleColor: '#000000ff',
        sectionTitleSize: '14px',
        sectionTitleFontType: [],
        sectionHintTextColor: '#000000ff',
        sectionHintTextSize: '14px',
        sectionHintTextFontType: [],
        repeatable: false,
      };

    default:
      return {};
  }
};

const getUserElementDefaultValidationPropertiesByElementType = (elementType) => {
  const fieldDefaultProperties = { required: false, readOnly: false, visibility: true };

  switch (elementType) {
    case 'text':
      return { ...fieldDefaultProperties, restriction: false, minRange: 1, maxRange: 50 };

    case 'number':
      return { ...fieldDefaultProperties, restriction: false, minRange: 1, maxRange: 10 };

    case 'dateTime':
      return { ...fieldDefaultProperties, restriction: false };

    case 'location':
    case 'ratingSlider':
    case 'numberSlider':
      return { ...fieldDefaultProperties };

    case 'signature':
    case 'checkList':
    case 'dataSource':
      return { required: false, visibility: true };

    case 'section':
    case 'page':
      return { visibility: true };

    default:
      return {};
  }
};

const getUserElementDefaultMediaProperties = () => {
  return {
    allowNotes: false,
    allowPictures: false,
    allowVideos: false,
    showTimeStamp: false,
    showTitle: false,
    restrictCameraOrientation: false,
    allowDownload: false,
    minPictures: 1,
    maxPictures: 3,
    videoMinutes: 0,
    videoSeconds: 0,
    cameraOrientation: 'vertical',
  };
};

const getUserElementDefaultOtherProperties = () => {
  return {
    fieldUniqueId: `field_${Math.ceil(Date.now() / 1000)}`,
    defaultValue: '',
    shortTitle: '',
    isPersonalData: false,
  };
};

export const defaultResponseSet = [
  [
    { responseText: 'Yes', value: '2', itemColor: '#38cb89ff' },
    { responseText: 'No', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'Maybe', value: '0', itemColor: '#83898cff' },
  ],
  [
    { responseText: 'Good', value: '3', itemColor: '#38cb89ff' },
    { responseText: 'Fair', value: '2', itemColor: '#ffa600ff' },
    { responseText: 'Poor', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'N/A', value: '0', itemColor: '#83898cff' },
  ],
  [
    { responseText: 'Pass', value: '2', itemColor: '#38cb89ff' },
    { responseText: 'Fail', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'N/A', value: '0', itemColor: '#83898cff' },
  ],
  [
    { responseText: 'Compliant', value: '2', itemColor: '#38cb89ff' },
    { responseText: 'Non-Compliant', value: '1', itemColor: '#ec3434ff' },
    { responseText: 'N/A', value: '0', itemColor: '#83898cff' },
  ],
];
