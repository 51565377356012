import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectionComponent from 'components/common-components/SelectionComponent';

function FolderPermission({ folders, setFolders }) {
  const [showFolders, setShowFolder] = useState(false);
  useEffect(() => {
    setShowFolder(true);
  }, []);
  return (
    showFolders && (
      <SelectionComponent
        title='text_folders'
        type='folders'
        selectedDataList={folders}
        setSelectedList={setFolders}
        loading={false}
        buttonsPosition='bottom'
        showButtons={false}
        submit={(ids) => {
          console.log(ids, 'groups');
        }}
      />
    )
  );
}
FolderPermission.propTypes = {
  folders: PropTypes.array.isRequired,
  setFolders: PropTypes.func.isRequired,
};
export default FolderPermission;
