import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import editBlackIcon from 'assets/images/icons/edit-black.svg';
import minimizeIcon from 'assets/images/icons/minimize.svg';
import settingGreyIcon from 'assets/images/icons/setting-grey.svg';
import AttendanceTableHeader from 'pages/attendance/AttendanceTableHeader';
import AttendanceTableBody from 'pages/attendance/AttendanceTableBody';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import { employeeTrackerTableHeader } from 'pages/attendance/utils';
import { setUserCustomizedTableHeader } from 'actions/ReduxActions';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { CallAPI } from 'actions/General';
import Loader from 'components/common-components/Loader';
import WidgetNameModal from './WidgetNameModal';
import { isRTL } from 'config/config';

const EmployeeTracker = ({ deleteWidget, groupListing, widgetDetails }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const [widgetName, setWidgetName] = useState(widgetDetails.name ? widgetDetails.name : '');
  const [selectedGroup, setSelectedGroup] = useState(
    widgetDetails.group_id ? widgetDetails.group_id : '',
  );
  const [openWidgetNameModal, setOpenWidgetNameModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [employeeTrackerTableHeaderState, setEmployeeTrackerTableHeaderState] = useState([]);
  const [minimize, setMinimize] = useState(false);
  const [filterData, setFilterData] = useState({
    filterByDate: 'all',
    searchText: '',
    groupId: selectedGroup,
  });

  useEffect(() => {
    if (filterData.groupId !== selectedGroup) {
      setSelectedGroup(filterData.groupId);
      updateWidgetGroup(filterData.groupId);
    }
  }, [filterData]);

  const updateWidgetGroup = (group_id) => {
    setSelectedGroup(group_id);
    updatePersonalSettings({ groupId: group_id, title: widgetDetails.name }, widgetDetails.id);
  };
  const updateWidgetName = (name) => {
    setWidgetName(name);
    updatePersonalSettings({ groupId: widgetDetails.groupId, title: name }, widgetDetails.id);
  };

  const updatePersonalSettings = async (payload, id) => {
    if (id !== '') {
      const result = await CallAPI('UPDATE_PERSONAL_SETTINGS', payload, id, setLoading, null);
      if (result.status) {
        if (result.status) {
          toast.success('Widget setting updated');
        }
      }
    }
  };

  useEffect(() => {
    let selectedTabHeader = cloneDeep(employeeTrackerTableHeader);
    if (auth.customizedTableHeader && auth.customizedTableHeader['employeeTrackerHeader']) {
      selectedTabHeader = auth.customizedTableHeader['employeeTrackerHeader'];
    }
    setEmployeeTrackerTableHeaderState(selectedTabHeader);
  }, []);

  const handleSelected = (field) => {
    const currentFieldsSelection = cloneDeep(employeeTrackerTableHeaderState);
    const foundIndex = currentFieldsSelection.findIndex((f) => f.name === field.name);
    currentFieldsSelection[foundIndex].isSelected = !field.isSelected;
    setEmployeeTrackerTableHeaderState(currentFieldsSelection);
    setUserCustomizedTableHeader('employeeTrackerHeader', currentFieldsSelection);
  };

  return (
    <div className='today-statistics'>
      {loading ? <Loader color='green-1' type='modal-spinner' /> : null}
      <div className='px-3 py-2 d-flex gap-3 justify-content-between align-items-center'>
        <div className='d-flex gap-3 align-items-center'>
          <label className='theme-text-black-1 theme-size-1_3 theme-font-inter-medium'>
            {widgetName}
          </label>
          <img
            src={editBlackIcon}
            className='cursor-pointer'
            onClick={() => setOpenWidgetNameModal(true)}
          />
        </div>
        <div className='d-flex gap-3'>
          <div className='dropdown-actions'>
            <DropdownButton
              align='end'
              variant='secondary'
              autoClose={'outside'}
              title={<img src={settingGreyIcon} className='svg-black' />}
            >
              <Dropdown.Item
                className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                onClick={() => deleteWidget(widgetDetails.id)}
              >
                {t('action_delete')}
              </Dropdown.Item>
              <DropdownButton
                align={isRTL() ? 'start' : 'end'}
                variant='secondary'
                autoClose={'outside'}
                className={`items${
                  !isRTL() ? ' backward-opened' : ''
                } no-hover-affect theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer`}
                title={<div className='theme-text-grey-1 child-dropdown'>{t('action_manage')}</div>}
              >
                {employeeTrackerTableHeaderState.map(
                  (field, index) =>
                    field.displayName !== '#' && (
                      <Dropdown.Item
                        key={index}
                        className='items px-3 py-1 cursor-pointer'
                        onClick={() => handleSelected(field)}
                      >
                        <SingleCheckBoxField
                          name='attendanceSummary'
                          value={field.isSelected}
                          handleChange={() => {}}
                          text={<span>{t(field.displayName)}</span>}
                          shrink
                          disabled={field.isRequired}
                        />
                      </Dropdown.Item>
                    ),
                )}
              </DropdownButton>
            </DropdownButton>
          </div>
          <img
            src={minimizeIcon}
            className='svg-black cursor-pointer'
            onClick={() => setMinimize(!minimize)}
          />
        </div>
      </div>

      {!minimize && (
        <Fragment>
          <div className='d-flex flex-column gap-4 p-4 border-top-grey-10-h-1'>
            <AttendanceTableHeader
              filterData={filterData}
              setFilterData={setFilterData}
              groupListing={groupListing}
              type={'employeeTrackerHeader'}
            />
          </div>
          <AttendanceTableBody filterData={filterData} type={'employeeTrackerHeader'} />
        </Fragment>
      )}
      {openWidgetNameModal && (
        <WidgetNameModal
          onUpdate={updateWidgetName}
          closeModal={() => setOpenWidgetNameModal(false)}
          title={widgetDetails.name}
        />
      )}
    </div>
  );
};

EmployeeTracker.propTypes = {
  widgetDetails: PropTypes.object.isRequired,
  deleteWidget: PropTypes.func.isRequired,
  groupListing: PropTypes.array.isRequired,
};

export default EmployeeTracker;
