import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectionComponent from 'components/common-components/SelectionComponent';
import { CallAPI } from 'actions/General';

function UpdateUserGroups({ userDetail, close }) {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupsIds, setSelectedGroupsIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const submit = async () => {
    let addedGroupsList = [];
    let removedGroupsList = [];
    let apiSuccuss = true;
    selectedGroups.map((singleGroup) => {
      if (selectedGroupsIds.indexOf(singleGroup.id) === -1)
        addedGroupsList.push({ id: singleGroup.id, title: singleGroup.name });
    });
    userDetail.groups.map((singlePreviousSelectedGroup) => {
      let groupDetail = selectedGroups.filter(
        (singleSelectedGroup) => singleSelectedGroup.id === singlePreviousSelectedGroup._id,
      );
      if (groupDetail.length === 0)
        removedGroupsList.push({
          id: singlePreviousSelectedGroup._id,
          title: singlePreviousSelectedGroup.groupName,
        });
    });
    if (addedGroupsList.length > 0) {
      let data = {
        id: userDetail.user_company_id,
        title: userDetail.contact.firstName + ' ' + userDetail.contact.lastName,
        groups: addedGroupsList,
      };
      let result = await CallAPI('ASSIGN_GROUP_TO_USER', data, null, setLoader, null, null);
      if (!result.status) {
        apiSuccuss = false;
      }
    }
    if (removedGroupsList.length > 0) {
      let data = {
        id: userDetail.user_company_id,
        title: userDetail.contact.firstName + ' ' + userDetail.contact.lastName,
        groups: removedGroupsList,
      };
      let result = await CallAPI('REMOVE_GROUP_FROM_USER', data, null, setLoader, null, null);
      if (!result.status) {
        apiSuccuss = false;
      }
    }
    if (apiSuccuss) close();
  };

  useEffect(() => {
    setSelectedGroups(
      userDetail.groups
        ? userDetail.groups.map((group) => ({
            id: group._id,
            name: group.groupName,
          }))
        : [],
    );
    setSelectedGroupsIds(userDetail.groups ? userDetail.groups.map((group) => group._id) : []);
  }, [userDetail]);

  return (
    <div>
      <SelectionComponent
        type='groups'
        title='User Groups'
        onCancel={close}
        submit={submit}
        selectedDataList={selectedGroups}
        setSelectedList={setSelectedGroups}
        buttonsPosition='bottom'
        loading={loader}
      />
    </div>
  );
}
UpdateUserGroups.propTypes = {
  close: PropTypes.func.isRequired,
  userDetail: PropTypes.object.isRequired,
};
export default UpdateUserGroups;
