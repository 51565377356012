import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import infoCircleIcon from 'assets/images/icons/info-circle.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import qrCodeIcon from 'assets/images/icons/qr-code.svg';
import trashIcon from 'assets/images/icons/trash-grey.svg';
import usersIcon from 'assets/images/icons/users.svg';
import AlertModal from 'components/common-components/AlertModal';
import DateField from 'components/form-components/DateField';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import MobileNumberField from 'components/form-components/MobileNumberField';
import MoreFieldsDropdown from 'components/common-components/MoreFieldsDropdown';
import NumberField from 'components/form-components/NumberField';
import SelectField from 'components/form-components/SelectField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';
import { bulkInviteUserSchema } from './utils';
import { useTranslation } from 'react-i18next';

const AddUserByForm = ({ fieldList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultValues = {
    firstName: '',
    lastName: '',
    countryCode: '+966',
    mobileNumber: '',
    email: null,
    department: '',
    branch: '',
    title: '',
    employmentType: '',
    personalIdNumber: '',
    personalIdExpiry: null,
    nationality: '',
    birthDate: null,
    requiredFields: [],
    groups: [],
  };

  const [values, setValues] = useState([defaultValues]);
  const [sendInvitationChecked, setSendInvitationChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [errors, setErrors] = useState([{}]);
  const [touched, setTouched] = useState([{}]);
  const [alertType, setAlertType] = useState('');
  const [groupListing, setGroupListing] = useState([]);

  const handleInvitationCheck = (_, value) => setSendInvitationChecked(value);

  const [fieldListSelection, setFieldListSelection] = useState(fieldList);

  const handleChange = (field, value) => {
    const [label, index] = field.split('_');

    const currentValues = [...values];
    currentValues[index] = { ...currentValues[index], [label]: value };
    setValues(currentValues);

    const currentTouched = [...touched];
    currentTouched[index] = { ...currentTouched[index], [label]: true };
    setTouched(currentTouched);

    const currentErrors = [...errors];
    currentErrors[index] = { ...currentErrors[index], [label]: undefined };
    setErrors(currentErrors);
  };

  const addRow = () => {
    setValues([...values, defaultValues]);
    setErrors([...errors, {}]);
    setTouched([...touched, {}]);
  };

  const removeRow = (index) => {
    if (values.length > 1) {
      const currentValues = [...values];
      currentValues.splice(index, 1);
      setValues(currentValues);

      const currentErrors = [...errors];
      currentErrors.splice(index, 1);
      setErrors(currentErrors);

      const currentTouched = [...touched];
      currentTouched.splice(index, 1);
      setTouched(currentTouched);
    }
  };

  const inviteUser = async (e) => {
    e.preventDefault();
    setTouched(values.map(() => ({})));

    try {
      await bulkInviteUserSchema.validate(values, { abortEarly: false });

      if (values.length) {
        let response = await CallAPI(
          'ADD_USER',
          { sendInvite: sendInvitationChecked, users: values },
          null,
          setLoading,
          setAPIError,
          null,
        );
        if (response.status) setAlertType('success');
        else {
          setAlertType('delete');
        }
      }
    } catch (error) {
      const schemaErrors = values.map(() => ({}));

      error.inner?.forEach((err) => {
        const [indexString, label] = err.path.split('].');
        schemaErrors[indexString.substring(1)][label] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  const getGroupListing = async () => {
    const result = await CallAPI('GET_ALL_GROUPS', {}, null, null, null, null);
    if (result.status) {
      setGroupListing(result.data);
    }
  };

  useEffect(() => {
    getGroupListing();
  }, []);

  const groupValues = groupListing.map((group) => ({
    value: group._id,
    displayValue: group.groupName,
  }));
  const groupValuesMap = Object.fromEntries(groupValues.map((v) => [v.value, v.displayValue]));

  return (
    <div className='add-user-form-section'>
      <AlertModal
        show={alertType !== ''}
        showCloseButton={true}
        closeModal={() => setAlertType('')}
        headerIcon={qrCodeIcon}
        title={alertType === 'success' ? 'alert_success' : 'alert_error'}
        message={alertType === 'success' ? 'alert_invitation_sent' : APIError}
        variant={alertType}
        actionButtons={[
          {
            text: t('button_ok'),
            variant: 'green-1',
            onClick: () => {
              if (alertType === 'success') navigate('/users');
              else setAlertType('');
            },
          },
        ]}
      />
      <div className='d-flex'>
        <div className='add-user-form'>
          <div className='add-user-form-fields'>
            {fieldListSelection
              .filter((field) => field.isSelected)
              .map((field, index) => (
                <div key={index} className='add-user-form-field'>
                  <div className='field-header'>
                    <img src={usersIcon} />
                    <span>{t(field.displayName)}</span>
                  </div>
                  {values.map((value, index) => (
                    <div key={index} className='field-body'>
                      {field.type === 'text' ? (
                        <TextField
                          name={`${field.name}_${index}`}
                          placeholder={field.displayName}
                          value={value[field.name] ?? ''}
                          error={errors[index][field.name]}
                          touched={touched[index][field.name]}
                          handleChange={handleChange}
                        />
                      ) : field.type === 'number' ? (
                        <NumberField
                          name={`${field.name}_${index}`}
                          placeholder={field.displayName}
                          value={value[field.name]}
                          handleChange={handleChange}
                        />
                      ) : field.type === 'date' ? (
                        <DateField
                          name={`${field.name}_${index}`}
                          value={value[field.name] ?? ''}
                          handleChange={handleChange}
                        />
                      ) : field.type === 'mobile' ? (
                        <MobileNumberField
                          fieldProps={{ name: `mobileNumber_${index}`, placeholder: '123123123' }}
                          codeName={`countryCode_${index}`}
                          value={{
                            countryCode: value.countryCode,
                            mobileNumber: value.mobileNumber,
                          }}
                          error={errors[index][field.name]}
                          touched={touched[index][field.name]}
                          handleChange={handleChange}
                        />
                      ) : field.type === 'group_selection' ? (
                        <SelectField
                          label={groupValuesMap[value.groups[0]] ?? 'text_select_groups'}
                          listValues={groupValues}
                          icon={arrowDownIcon}
                          handleChange={(_, value) => handleChange(`groups_${index}`, [value])}
                          selectedValues={[]}
                          name={`groups_${index}`}
                          isAutoClose={true}
                          classes='border-grey-1-h-1 rounded-md py-2'
                          menuClasses={'w-100'}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            <div className='header-space-filler'></div>
          </div>
        </div>
        <div className='add-user-action'>
          <MoreFieldsDropdown
            fieldList={fieldListSelection}
            setFieldListSelection={setFieldListSelection}
          />

          {values.map((_, index) => (
            <div key={index} className='fields-input-action'>
              <img
                src={trashIcon}
                className={values.length < 2 ? 'd-none' : ''}
                onClick={() => removeRow(index)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='space-4'></div>
      <FormButton text='button_add_user' variant='green-2' onClick={addRow} icon={plusIcon} />
      <div className='d-flex flex-column align-items-end gap-3 add-user-button-layout'>
        <div className='d-flex justify-content-start gap-3'>
          <FormButton text='button_cancel' variant='white-1' onClick={() => navigate('/users')} />
          <FormLoadingButton
            onClick={inviteUser}
            text='button_confirm'
            variant='green-1'
            loading={loading}
          />
        </div>
        <div className='d-flex justify-content-start gap-2'>
          <SingleCheckBoxField
            name='check'
            value={sendInvitationChecked}
            handleChange={handleInvitationCheck}
            text={<span>{t('field_send_an_invitation')}</span>}
          />
          <img src={infoCircleIcon} />
        </div>
      </div>
    </div>
  );
};

AddUserByForm.propTypes = {
  fieldList: PropTypes.array.isRequired,
};

export default AddUserByForm;
