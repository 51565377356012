import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import crossIcon from 'assets/images/icons/cross.svg';
import duplicateIcon from 'assets/images/icons/duplicate.svg';
import swapDownIcon from 'assets/images/icons/swap-down.svg';
import swapUpIcon from 'assets/images/icons/swap-up.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';

const SwappingComponent = ({ elementType, elementIcon, type, props, changeFormElementOrder }) => {
  const { t } = useTranslation();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  let { pageNumber, totalPages } = { ...props };
  return (
    <Fragment>
      {elementType !== 'page' && (
        <>
          <img src={elementIcon} />
          <span className='text-capitalize'>{elementType}</span>
        </>
      )}
      <div className='form-element-swapping'>
        {elementType !== 'Status' && (
          <img
            onClick={() => changeFormElementOrder(type, { ...props }, 'duplicate')}
            src={duplicateIcon}
          />
        )}
        <img
          className={`${type}${type === 'page' ? ' rotate-270' : ''}${
            type === 'page' && pageNumber === 1 ? ' d-none' : ''
          }`}
          onClick={() => changeFormElementOrder(type, { ...props }, 'decrease')}
          src={swapUpIcon}
        />
        <img
          className={`${type}${type === 'page' ? ' rotate-270' : ''}${
            type === 'page' && totalPages === pageNumber ? ' d-none' : ''
          }`}
          onClick={() => changeFormElementOrder(type, { ...props }, 'increase')}
          src={swapDownIcon}
        />
        <img
          className={`${type}${type === 'page' && totalPages === 1 ? ' d-none' : ''} svg-black`}
          onClick={() => setDeleteConfirmationModal(true)}
          src={crossIcon}
        />
      </div>

      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_form_element_deletion_title')}
        message={
          type === 'page'
            ? t('alert_page_deletion_message')
            : type === 'section'
            ? t('alert_section_deletion_message')
            : t('alert_field_deletion_message')
        }
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setDeleteConfirmationModal(false),
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              changeFormElementOrder(type, { ...props }, 'delete');
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
    </Fragment>
  );
};

SwappingComponent.propTypes = {
  props: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  elementIcon: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
};

export default SwappingComponent;
