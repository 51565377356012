import React, { Fragment, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import emailIcon from 'assets/images/icons/mail.svg';
import eyeIcon from 'assets/images/icons/eye-off.svg';
import phoneIcon from 'assets/images/icons/phone.svg';
import BackNavigation from './BackNavigation';
import Error from 'components/Error';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import MobileNumberField from 'components/form-components/MobileNumberField';
import OTPField from 'components/form-components/OTPField';
import PasswordField from 'components/form-components/PasswordField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import TextField from 'components/form-components/TextField';
import { otpSchema, registerSchema, userRegister, verifyOTP } from './utils';
import { GetOtpForOperation } from 'actions/General';

const Register = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+966',
    mobileNumber: '',
    password: '',
    check: false,
    otp: '',
  });
  const [apiError, setApiError] = useState('');

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);

  const [publicKey, setPublicKey] = useState('');
  const [askForOtp, setAskForOtp] = useState(false);
  const navigate = useNavigate();

  const onBackClick = () => {
    if (askForOtp) {
      setAskForOtp(false);
    } else {
      navigate('/login', { replace: true });
    }
  };

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };

  const onSubmitRegister = async (e) => {
    e.preventDefault();
    setTouched({});
    try {
      await registerSchema.validate(values, { abortEarly: false });

      await userRegister(values, setApiError, setLoading, setPublicKey, setAskForOtp);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  const onResendOTP = async () => {
    setResendOTPLoading(true);
    let result = await GetOtpForOperation(
      { otpType: 'register', countryCode: values.countryCode, mobileNumber: values.mobileNumber },
      setApiError,
      setResendOTPLoading,
    );
    setResendOTPLoading(false);
    if (result.status) {
      setPublicKey(result.publicKey);
      setResendOTP(true);
    }
  };

  const onSubmitOTP = async (e) => {
    e.preventDefault();
    setTouched({});
    setLoading(true);
    try {
      await otpSchema.validate(values, { abortEarly: false });

      const payload = {
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
        otp: values.otp,
        publicKey,
      };
      const verified = await verifyOTP(payload, setApiError, setLoading, 'register');
      if (verified) {
        toast.success('OTP verified successfully');
        navigate('/add_company', {
          relative: 'path',
          state: { email: values.email },
        });
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <BackNavigation onClick={onBackClick} />
      <div className='auth-form-container'>
        {!askForOtp ? (
          <Fragment>
            <h3 className='main-heading'>{t('text_welcome_to_midani')}</h3>
            <p className='sub-heading'>{t('text_create_account')}</p>
            {apiError ? <Error msg={apiError} /> : <></>}
            <TextField
              name='firstName'
              displayName='field_first_name'
              placeholder='field_your_first_name'
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              handleChange={handleChange}
              classes='mb-3'
            />
            <TextField
              name='lastName'
              displayName='field_last_name'
              placeholder='field_your_last_name'
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
              handleChange={handleChange}
              classes='mb-3'
            />
            <TextField
              name='email'
              displayName='field_email'
              placeholder='field_your_email'
              icon={emailIcon}
              value={values.email}
              error={errors.email}
              touched={touched.email}
              handleChange={handleChange}
              classes='mb-3'
            />
            <MobileNumberField
              fieldProps={{ name: 'mobileNumber', placeholder: '123123123' }}
              displayName='field_phone_number'
              icon={phoneIcon}
              value={{
                countryCode: values.countryCode,
                mobileNumber: values.mobileNumber,
              }}
              error={errors.mobileNumber}
              touched={touched.mobileNumber}
              handleChange={handleChange}
              classes='mb-3'
            />
            <PasswordField
              name='password'
              displayName='field_password'
              placeholder='field_enter_password'
              icon={eyeIcon}
              value={values.password}
              error={errors.password}
              touched={touched.password}
              handleChange={handleChange}
              classes='mb-3'
            />
            <SingleCheckBoxField
              name='check'
              value={values.check}
              error={errors.check}
              touched={touched.check}
              handleChange={handleChange}
              text={
                <span>
                  {t('text_i_accept_the')} <span className='theme-text-green-1'>{t('T&C')}</span>{' '}
                  {t('text_of_midani')}
                </span>
              }
              classes='mb-3'
            />
            <div className='space-10'></div>
            <FormLoadingButton
              text='button_create_account'
              variant='green-1'
              loading={loading}
              onClick={onSubmitRegister}
            />

            <div className='space-20'></div>
            <p className='helper-text text-left'>
              {t('text_already_have_an_account')}{' '}
              <NavLink to='/login'>
                <span className='theme-text-green-1'>{t('text_login_here')}</span>
              </NavLink>
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <h3 className='main-heading'>{t('text_otp_verification')}</h3>
            <p className='sub-heading'>
              {t('text_sent_otp_on_this_number')} {values.countryCode} {values.mobileNumber}
            </p>
            {apiError ? <Error msg={apiError} /> : <></>}
            <OTPField
              name='otp'
              handleChange={handleChange}
              error={errors.otp}
              touched={touched.otp}
            />
            <div className='space-20'></div>
            <FormLoadingButton
              text='button_verify'
              variant='green-1'
              loading={loading}
              onClick={onSubmitOTP}
            />
            {!resendOTP && (
              <Fragment>
                <div className='space-20'></div>
                <div>
                  <span className='helper-text'>{t('text_did_not_receive_otp')} </span>
                  <span
                    {...(!resendOTPLoading && { onClick: () => onResendOTP() })}
                    className='theme-text-green-1 cursor-pointer'
                  >
                    {t('text_resend_otp')}
                  </span>
                  {resendOTPLoading && (
                    <span>
                      <Spinner size='sm' className='theme-text-green-1 ms-2' />
                    </span>
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Register;
