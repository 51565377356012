import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthReducer } from 'redux/auth';
import { useTranslation } from 'react-i18next';
import { cloneDeep, orderBy } from 'lodash';
import deleteIcon from 'assets/images/icons/trash-menu.svg';
import groupIcon from 'assets/images/icons/group-users-green.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import AuthorizeError from 'pages/AuthorizeError';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import GroupListHeader from './GroupListHeader';
import Loader from 'components/common-components/Loader';
import TableComponent from 'components/table-component/Table';
import 'assets/styles/groups.scss';
import { CallAPI } from 'actions/General';
import { GroupTableFields } from './utils';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import DownArrow from 'assets/images/icons/swap-down.svg';
import UpArrow from 'assets/images/icons/swap-up.svg';
import GroupActions from './Actions';

const AuthAction = AuthReducer.actions;

const ListGroups = () => {
  const { t } = useTranslation();
  const [deletedSegmentId, setDeletedSegmentId] = useState('');
  const [deletedSegmentHasAnyGroup, setDeletedSegmentHasAnyGroup] = useState(true);
  const [segmentsList, setSegmentsList] = useState([]);
  const [displayedSegmentsList, setDisplayedSegmentsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.employees_groups_read),
  );
  const [openSegments, setOpenSegments] = useState({});
  const [actionsDetail, setActionsDetail] = useState({
    selectedRows: [],
    selectedRowsSegmentsList: [],
    allSegmentsList: [],
    defaultSegmentId: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      AuthAction.updateBreadCrumb({
        breadcrumbs: { icon: groupIcon, items: ['sidebar_groups', 'sidebar_groups'] },
      }),
    );
  }, [dispatch]);

  const addGroup = (segmentId) => {
    navigate(`/groups/add-group`, { relative: 'path', state: { type: 'add', segmentId } });
  };

  const getAllGroups = async () => {
    if (hasPermissionToAccess) {
      let defaultSegmentId = '';
      let result = await CallAPI(
        'GET_ALL_SEGMENT_WITH_GROUPS',
        null,
        null,
        setLoading,
        setApiError,
        null,
      );
      if (result.status) {
        let segmentsList = [];
        let allSegmentsList = [];
        let segmentsOpen = {};
        result.data.map((singleSegment) => {
          let segmentGroups = [];
          singleSegment.groups.map((singleGroup) => {
            segmentGroups.push({ ...singleGroup, segment_id: singleSegment._id });
          });
          segmentsList.push({
            id: singleSegment._id,
            name: singleSegment.segmentName,
            groups: segmentGroups,
            isDefault: singleSegment.isDefault ? singleSegment.isDefault : false,
          });
          segmentsOpen[singleSegment._id] = segmentsOpen[singleSegment._id]
            ? segmentsOpen[singleSegment._id]
            : true;
          if (singleSegment.isDefault) defaultSegmentId = singleSegment._id;
          else
            allSegmentsList.push({
              id: singleSegment._id,
              title: singleSegment.segmentName,
            });
        });
        setActionsDetail({
          ...actionsDetail,
          allSegmentsList: allSegmentsList,
          defaultSegmentId,
          selectedRows: [],
          selectedRowsSegmentsList: [],
        });
        setOpenSegments(segmentsOpen);
        segmentsList = orderBy(segmentsList, (item) => item.isDefault);
        setSegmentsList(segmentsList);
        setDisplayedSegmentsList(segmentsList);
      }

      if (defaultSegmentId === '') {
        await createDefaultSegment();
      }
    }
  };
  const createDefaultSegment = async () => {
    await CallAPI(
      'ADD_SEGMENT',
      { segmentName: 'Unassigned Groups', isDefault: true },
      null,
      setLoading,
      setApiError,
      null,
    );
    getAllGroups();
  };
  useEffect(() => {
    getAllGroups();
  }, []);

  const removeSegment = (segmentIndex) => {
    setDeletedSegmentId(segmentsList[segmentIndex].id);
    setDeletedSegmentHasAnyGroup(segmentsList[segmentIndex].groups.length > 0 ? true : false);
  };

  const searchChanged = (name, val) => {
    let filteredData = [];
    if (val !== '') {
      segmentsList.map((singleSegment) => {
        let groupsList = [];
        groupsList = singleSegment.groups.filter(
          (group) => group.groupName.toLowerCase().indexOf(val.toLowerCase()) > -1,
        );
        if (groupsList.length > 0)
          filteredData.push({ groups: groupsList, id: singleSegment.id, name: singleSegment.name });
      });
    } else {
      filteredData = cloneDeep(segmentsList);
    }

    setSearchText(val);
    setDisplayedSegmentsList(filteredData);
  };

  const deleteSegment = async () => {
    await CallAPI('DELETE_SEGMENT', null, deletedSegmentId, setLoading, null, null);
    setDeletedSegmentId('');
    setDeletedSegmentHasAnyGroup(false);
    getAllGroups();
  };
  const openCloseSegments = async (segment_id) => {
    let openCloseSegments = cloneDeep(openSegments);
    openCloseSegments = { ...openCloseSegments, [segment_id]: !openCloseSegments[segment_id] };
    setOpenSegments(openCloseSegments);
  };
  const onRowsSelection = (name, value, index) => {
    let selectedIds = cloneDeep(actionsDetail.selectedRows);
    let selectedRowsSegmentsList = cloneDeep(actionsDetail.selectedRowsSegmentsList);
    if (name === 'all') {
      segmentsList[index].groups.map((single_group) => {
        let foundIndex = -1;
        selectedIds.map((singleSelectedId, index) => {
          if (singleSelectedId.id === single_group._id) {
            foundIndex = index;
          }
        });
        if (value) {
          if (foundIndex === -1) {
            selectedIds.push({ id: single_group._id, title: single_group.groupName });
            selectedRowsSegmentsList.push(segmentsList[index].id);
          }
        } else {
          selectedRowsSegmentsList.splice(
            selectedRowsSegmentsList.indexOf(segmentsList[index].id),
            1,
          );
          selectedIds.splice(foundIndex, 1);
        }
      });
      setActionsDetail({ ...actionsDetail, selectedRows: selectedIds, selectedRowsSegmentsList });
    } else {
      if (value) {
        let title = '';
        segmentsList[index].groups.map((single_group) => {
          if (single_group._id === name) title = single_group.groupName;
        });
        selectedIds.push({ id: name, title: title });
        selectedRowsSegmentsList.push(segmentsList[index].id);
      } else {
        selectedIds.splice(selectedIds.map((e) => e.id).indexOf(name), 1);
        selectedRowsSegmentsList.splice(
          selectedRowsSegmentsList.indexOf(segmentsList[index].id),
          1,
        );
      }
      setActionsDetail({ ...actionsDetail, selectedRows: selectedIds, selectedRowsSegmentsList });
    }
  };
  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <div className='theme-background-white-1 custom-shadow rounded-sm'>
          <GroupListHeader
            getAllGroups={getAllGroups}
            searchText={searchText}
            searchChanged={searchChanged}
          />
          {loading && <Loader color='green-1' type='modal-spinner' />}
          {apiError && <Error msg={apiError} />}
          <div className='pb-3'>
            {displayedSegmentsList.map((singleSegment, index) => {
              let isSegmentAllGroupsSelected = false;
              let selectedRecordsCount = 0;
              singleSegment.groups.map((singleGroup) => {
                let groupElement = actionsDetail.selectedRows.filter(
                  (singleSelectedItem) => singleSelectedItem.id === singleGroup._id,
                );
                if (groupElement.length > 0) selectedRecordsCount++;
              });
              if (selectedRecordsCount === singleSegment.groups.length)
                isSegmentAllGroupsSelected = true;
              return (
                <div className='py-2 mx-5' key={index}>
                  <div className=' d-flex justify-content-between mb-2'>
                    <div className='d-flex gap-3 align-items-center'>
                      <span className='stroke-heading'>{singleSegment.name}</span>
                      <img
                        className='cursor-pointer'
                        onClick={() => openCloseSegments(singleSegment.id)}
                        src={openSegments[singleSegment.id] ? UpArrow : DownArrow}
                      />
                      {!singleSegment.isDefault && (
                        <img
                          className='cursor-pointer'
                          onClick={() => removeSegment(index)}
                          src={deleteIcon}
                        />
                      )}
                      {index === 0 && (
                        <GroupActions
                          row={{}}
                          ids={actionsDetail.selectedRows}
                          isActionButton={false}
                          actionsCallBack={getAllGroups}
                          actionData={{ ...actionsDetail }}
                        />
                      )}
                    </div>
                    <span>
                      {singleSegment.groups.length} {t('text_groups')}
                    </span>
                  </div>
                  <div>
                    {openSegments[singleSegment.id] && singleSegment.groups.length > 0 ? (
                      <TableComponent
                        header={GroupTableFields}
                        data={singleSegment.groups}
                        props={{
                          actionsCallBack: getAllGroups,
                          actionsType: 'groups',
                          isAllRecordsSelected: isSegmentAllGroupsSelected,
                        }}
                        totalRecords={1}
                        filterEnabled={false}
                        loader={false}
                        isFieldSelectionEnabled={false}
                        isPaginationEnabled={false}
                        maintainTableHeight={false}
                        isActionsEnabled={true}
                        isSelectionEnabled={true}
                        selectionKey='_id'
                        onSelection={(name, value) => {
                          onRowsSelection(name, value, index);
                        }}
                        selectedRows={actionsDetail.selectedRows}
                      />
                    ) : null}
                  </div>
                  {isPermissionAvailable(permissionKeys.employees_groups_create) && (
                    <FormButton
                      text='button_add_groups'
                      variant='green-2'
                      icon={plusIcon}
                      classes='mt-3'
                      onClick={() => {
                        addGroup(singleSegment.id);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <ConfirmationModal
        show={deletedSegmentId ? true : false}
        title={t('alert_segment_deletion_title')}
        message={deletedSegmentHasAnyGroup ? t('alert_group_segment_deletion_message') : ''}
        actionList={[
          { color: 'black-1', text: t('button_cancel'), onClick: () => setDeletedSegmentId('') },
          ...(deletedSegmentHasAnyGroup === true
            ? [{ color: 'grey-2', text: t('button_delete'), onClick: () => {} }]
            : [
                {
                  color: 'red-1',
                  text: t('button_delete'),
                  loading: loading,
                  onClick: () => deleteSegment(),
                },
              ]),
        ]}
      />
    </>
  );
};

export default ListGroups;
