import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import homeGreyIcon from 'assets/images/icons/home-grey.svg';
import AlertModal from './common-components/AlertModal';
import { DEV_ONLY, updateSavedChanges } from 'config/config';
import SwitchUrlConfirmContent from './SwitchUrlConfirmContent';

const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [authorizedRoutes, setAuthorizedRoutes] = useState(auth.companyPermissions);
  const [showNavigateConfirmModal, setShowNavigateConfirmModal] = useState(false);
  const [toUrl, setToUrl] = useState(null);

  useEffect(() => {
    setAuthorizedRoutes(auth.companyPermissions);
  }, [auth.companyPermissions]);

  const navigateToUrl = (url) => {
    if (auth.hasUnsavedChanges && auth.unsavedChangedType) {
      setToUrl(url);
      setShowNavigateConfirmModal(true);
    } else {
      updateSavedChanges(false);
      navigate(url);
    }
  };

  const closeModal = () => {
    setShowNavigateConfirmModal(false);
    setToUrl(null);
  };

  return (
    <div className='sidebar'>
      {toUrl && showNavigateConfirmModal && (
        <AlertModal show={showNavigateConfirmModal} closeModal={closeModal} actionButtons={[]}>
          <SwitchUrlConfirmContent toUrl={toUrl} closeModal={closeModal} />
        </AlertModal>
      )}
      {Object.values(authorizedRoutes).map((singleModule) => {
        return (
          <section key={singleModule.order}>
            {singleModule.subMenu ? (
              <>
                <p>{t(singleModule.displayName)}</p>
                <ul>
                  {Object.values(singleModule.subMenu).map((singleSubModule, index) => {
                    return (
                      <li
                        className={`${
                          location.pathname.startsWith('/' + singleSubModule.url) ? 'active' : ''
                        }`}
                        onClick={() => navigateToUrl(singleSubModule.url)}
                        key={singleModule.order + ' ' + index}
                      >
                        <img src={singleSubModule.icon} />
                        <span>{t(singleSubModule.displayName)}</span>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <ul>
                <li
                  className={`${
                    location.pathname.startsWith('/' + singleModule.url) ? 'active' : ''
                  }`}
                  onClick={() => navigateToUrl(singleModule.url)}
                >
                  <img src={singleModule.icon} />
                  <span>{t(singleModule.displayName)}</span>
                </li>
              </ul>
            )}
          </section>
        );
      })}
      {DEV_ONLY && (
        <section>
          <ul>
            <li onClick={() => window.open('/icons', '_blank')}>
              <img src={homeGreyIcon} />
              <span>{t('Icons')}</span>
            </li>
            <li onClick={() => window.open('/buttons', '_blank')}>
              <img src={homeGreyIcon} />
              <span>{t('Buttons')}</span>
            </li>
          </ul>
        </section>
      )}
    </div>
  );
};

export default Sidebar;
