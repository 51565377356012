import React from 'react';

function Privacy() {
  return (
    <div className='terms_privacy bg-white theme-size-1_1 theme-font-inter-regular'>
      <div className='d-flex flex-column justify-content-center align-items-center theme-font-inter-bold theme-height-224 theme-text-green-1 theme-background-green-4 theme-size-3_5 px-4 text-center lh-sm'>
        Privacy Notice
      </div>
      <div className='content mx-auto my-5 px-3 text-justify'>
        <p>
          We at Midani (“Midani“, “us“, “we“, or “our“) recognize and
          respect the importance of maintaining the privacy of our Customers and their Personnel.
          This Privacy Notice describes the types of Personal Data (as defined below) we collect
          from you when you visit our website (“Site“) and/or use our Platform or App (collectively,
          the “Services“), as well as the ways in which we collect, process, transfer, store and
          disclose such Personal Data. It also describes how you may control certain uses of the
          Personal Data. If not otherwise defined herein, capitalized terms have the meaning given
          to them in the Terms of Service, available at https://midani.com/terms-conditions/
          (“Terms“). “You” means any user of the Site, App, Platform and/or Services.
        </p>

        <p>
          “Personal Data” or “Personal Information” means any information that refers to, is related
          to, or is associated with an identified or identifiable individual or household.
        </p>

        <p className='theme-font-inter-bold'>Our Role</p>

        <p>
          – Data Processor. When we collect, process and manage Personal Data in the context of
          providing Services to our business customers who accepted our Terms or otherwise entered
          into an agreement with us (“Customers”), the Customer is the data controller with respect
          to such Personal Data and we are the data processor. This includes Personal Data uploaded
          to our Platform/App by our Customers’ employees, service providers and independent
          contractors (“Customer Data”). Our processing of such Customer Data on behalf and under
          the instruction of the respective Customer is governed by our Data Processing Addendum
          with them. For more information, please refer to Section 11 below.
        </p>

        <p>
          This Privacy Notice which describes Midani’s independent privacy and data processing
          practices as a “data controller” – does not apply to the processing of Customer Data
          (unless otherwise specifically stated). Such processing is detailed here for completeness
          and informational purposes only. If you have any questions or requests regarding Customer
          Data, please contact your Account Owner directly.
        </p>

        <p>
          – Controller. When we process the Personal Data of Prospects and Users (excluding Customer
          Data, as detailed above) in their use of our Services, as specified under Section 11 to
          this Privacy Notice, we are the data controller.
        </p>

        <p className='theme-font-inter-bold'>Representative:</p>

        <p>
          – Maetzler Rechtsanwalts GmbH & Co KG (Prighter) has been designated as Midani’s
          representative in the European Union for data protection matters pursuant to Article 27 of
          the GDPR. Maetzler Rechtsanwalts GmbH & Co KG may be contacted only on matters related to
          the processing of Personal Data in the EU. To make such an inquiry, please contact
          Maetzler Rechtsanwalts GmbH & Co KG through this contact form:
          https://prighter.com/q/14267474.
        </p>

        <p>
          – Prighter Ltd has been designated as Midani’s representative in the United Kingdom
          for data protection matters pursuant to Article 27 of the UK-GDPR. Prighter Ltd may be
          contacted only on matters related to the processing of Personal Data in the UK. To make
          such an inquiry, please contact Prighter Ltd. through this contact form:
          https://prighter.com/q/14267474.
        </p>

        <p className='theme-font-inter-bold'>Privacy Notice Key Points:</p>

        <p>
          The key points listed below are detailed further throughout this Privacy Notice. You can
          click on any section to find out more about the below topics.
        </p>
        <p className='theme-font-inter-bold'>
          1. Personal Data We Collect, Uses and Legal Basis <br /> 1.1. Prospects
          <br /> 1.2. Account Owners, Administrators & Employees
          <br />
          2. Communications
          <br />
          3. Sharing the Personal Data We Collect
          <br />
          4. International Transfer
          <br />
          5. Security
          <br />
          6. Your Rights
          <br />
          7. Data Retention
          <br />
          8. Cookies and Similar Technologies
          <br />
          9. Third-Party Applications and Services
          <br />
          10. Opt-Out of Sale and Sharing
          <br />
          11. Controller/Processor
          <br />
          12. Children
          <br />
          13. Changes to the Privacy Notice
          <br />
          14. Comments and Questions
          <br />
          15. DPO
        </p>

        <ol>
          <li>
            Personal Data We Collect, Uses and Legal Basis.{' '}
            <span>
              Depending on the ways in which you use our Services, we collect different types of
              Personal Data, and we and any of our third-party contractors and service providers use
              the Personal Data we collect for different business and commercial purposes, as
              specified below. You are under no legal obligation to provide us with your Personal
              Data. However, if you refuse to provide such Personal Data, we may not be able to
              provide you with our Services. Midani does not collect, use or disclose sensitive
              Personal Data.
            </span>
            <ol>
              <li>
                Prospects.{' '}
                <span>
                  If you are a visitor to our Site, participant at one of our events, or otherwise a
                  prospective User (as defined below) who visits our Site or interacts with any of
                  our online ads, emails or communications under our control, we collect the
                  following types of Personal Data:
                </span>
                <ol>
                  <li>
                    Contact Information
                    <ol>
                      <li>
                        What Personal Data We Collect: When you (1) request information from us
                        through the Site, (2) fill out (online) forms, (3) participate in our
                        events, (4) contact us using a referral link, or (5) contact us for any
                        other reason, we will collect any data you (or our Users who have referred
                        you, as further described in Section 2 below) provide, such as your name
                        and/or email address, and any additional information submitted via the Site,
                        online ads, emails, or other communication.
                      </li>
                      <li>
                        How we use this data & Legal Basis: (1) To provide support, respond to your
                        request or inquiry (Legitimate Interest); (2) to provide you with
                        informational newsletters, and promotional materials relating to our
                        Services, including via email (Legitimate Interest; Consent where
                        applicable). For more information about our direct marketing activities and
                        how you can control your preferences, please see the Promotional
                        Communications section below.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Automatically Collected Data
                    <ol>
                      <li>
                        What Personal Data We Collect: When you visit the Site we automatically
                        collect information about your computer or mobile device, including
                        non-Personal Data such as your operating system and browser type, internet
                        service provider (ISP), and Personal Data such as your IP address, sessions
                        recordings, browsing history on our site, as well as referring and exit
                        pages (the pages you clicked to reach our site and the pages you clicked
                        from our site), how often you visit the Site, which pages you visit and
                        when, which ad or email messages were presented to you and other information
                        regarding your viewing history on our Site. For more information about the
                        cookies and similar technologies we use, including how to adjust your
                        preferences on our Site, please see our Cookie Policy.
                      </li>
                      <li>
                        How we use this data & Legal Basis: (1) to understand how our Prospects and
                        Users engage with our Site, to help facilitate our operations, including
                        collecting and analyzing aggregated, non-personal analytics, developing new
                        products or services, and improving our current content, products, and
                        services (Legitimate Interest); (2) to prevent fraud, protect the security
                        of our Site and address any problems with the Site (Legitimate Interest).
                      </li>
                    </ol>
                  </li>
                  <li>
                    Data Collected from Third Parties.
                    <span>
                      If you are a prospect (as detailed under 1.1) we may receive Personal Data
                      about you from third-party sources. We may use this data for sales & marketing
                      efforts (Legitimate Interest; Consent (where applicable)).
                    </span>
                  </li>
                </ol>
              </li>
              <li>
                Account Owners, Administrators & Employees.{' '}
                <span>
                  If you are an Account Owner, Administrator, or an Employee (including a service
                  provider or an independent contractor of one of our Customers) (collectively, a
                  “User”), using the Platform and/or an App in connection with a Customer account,
                  we collect the following types of Personal Data:
                </span>
                <ol>
                  <li>
                    Registration Data
                    <ol>
                      <li>
                        What Personal Data We Collect:{' '}
                        <span>
                          If you are an Account Owner, in order to use our Services, you will be
                          required to register and provide Personal Data requested by us, including
                          your full name, email address and phone number. Administrators and
                          Employees may be requested to provide additional details such as job
                          title, profile picture or any other information as required by either your
                          Administrator or Account Owner.
                        </span>
                      </li>
                      <li>
                        How we use this data & Legal Basis:{' '}
                        <span>
                          (1) to provide you access to our Services, to provide support, respond to
                          your inquiries and requests and to contact and communicate with you
                          (Performance of a Contract); (2) to prevent fraud, protect the security of
                          and address any problems with the our Services (Legitimate Interest); (3)
                          to provide you with informational newsletters, and romotional materials
                          relating to our Services, including via email or phone (Legitimate
                          Interest; Consent where applicable). For more information about our direct
                          marketing activities and how you can control your preferences, please see
                          the Promotional Communications section below; and (4) to create a general
                          profile of Customers and usage profiles of their respective Users in order
                          to improve and optimize our Services (Legitimate Interest).
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Payment Data
                    <ol>
                      <li>
                        What Personal Data We Collect:{' '}
                        <span>
                          If and when you make a payment (using a credit card) to Midani for use
                          of our Services, we receive information related to such a purchase,
                          including the last four digits of your credit/debit card number, CVV,
                          expiration date, and the name of the cardholder.
                        </span>
                      </li>
                      <li>
                        How we use this data & Legal Basis:{' '}
                        <span>
                          To process the payment for your purchase (Performance of a Contract) and
                          for the purposes of fraud prevention (Legitimate Interest).
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Communications Data
                    <ol>
                      <li>
                        What Personal Data We collect:{' '}
                        <span>
                          Personal Data contained in any forms and inquiries that you may submit to
                          us, including support requests, interactions through instant messaging
                          apps, registrations to our events, participation in our online and offline
                          communities and activities; surveys, feedback and testimonials, phone call
                          and video conference recordings, as well as written correspondences,
                          screen recordings, screenshots, and related information that may be
                          automatically recorded, tracked, transcribed and analyzed. In addition,
                          any User may refer a friend to our Services, in which case such a User
                          will be required to provide us with such User’s contact details (full
                          name, email address and phone number) (“Referral Registration Data”).
                        </span>
                      </li>
                      <li>
                        How we use this data & Legal Basis:{' '}
                        <span>
                          (1) for analytics (Legitimate Interest), quality control and improvements
                          (Legitimate Interest); training, and record-keeping purposes (Performance
                          of a Contract; Legitimate Interest); (2) To facilitate, operate, enhance,
                          and provide our Services (Performance of Contract; Legitimate Interests);
                          and (3) To provide our Customers and Users with support, to test and
                          monitor the Services, or diagnose or fix problems, and to train our
                          Customers and Customer-facing staff (Performance of Contract; Legitimate
                          Interests); and (4) in the case of Referral Registration Data, to contact
                          you (including via email) with referral activities, to provide you with
                          informational newsletters and promotional materials relating to our
                          Services (Legitimate Interest; Consent where applicable).
                        </span>
                      </li>
                    </ol>
                    <p>
                      For more information about our direct marketing activities and how you can
                      control your preferences, please see the Promotional Communications section
                      below.
                    </p>
                  </li>
                  <li>
                    Automatically Collected Data
                    <ol>
                      <li>
                        What Personal Data We Collect:{' '}
                        <span>
                          When you use our Services, we automatically collect information about your
                          computer or mobile device, including nonpersonal data such as your
                          operating system and browser type, internet service provider (ISP) and
                          language settings, and Personal Data such as your IP address, browsing
                          history, including referring and exit pages, connectivity, session
                          recordings, technical and usage data, activity logs, the relevant cookies
                          and pixels installed or utilized on your device, clicks, use of features
                          and other interactions, and any information regarding your viewing history
                          on the Platform or App, browser language, and browser time zone. This data
                          is collected and generated automatically, including through the use of
                          analytics tools (including cookies and pixels) which collects data such
                          as: how often Users use the Services, how Users interact with and use the
                          Services, including technical data concerning the performance,
                          functionality, potential fraudulent activities and stability of the
                          Platform. For more information about the cookies and similar technologies
                          we use and how to adjust your preferences on the Platform or App, please
                          see the section “Cookies and Similar Technologies” below.
                        </span>
                      </li>
                      <li>
                        How we use this data & Legal Basis:{' '}
                        <span>
                          (1) to analyze usage of our Services, including in an aggregated
                          non-specific manner, develop new products or services and improve current
                          content, products, and services (Legitimate Interest); (2) to prevent
                          fraud and maintain the security of our Services and address any problems
                          with the Services (Legitimate Interest); (3) to provide you with
                          customized content, targeted offers, and advertising related to our
                          products and services, based on your usage history on the Services on
                          other third-party sites or apps you may visit and/or use, or via e-mail
                          (Legitimate Interest, and Consent where applicable).
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Materials You Upload
                    <ol>
                      <li>
                        What Personal Data We Collect:{' '}
                        <span>
                          Any personal data, including text, documents and images you upload to the
                          Platform.
                        </span>
                      </li>
                      <li>
                        How we use this data:{' '}
                        <span>
                          Midani does not use this data for its own purposes and acts only as a
                          “data processor” with respect to such data. The usage of this Personal
                          Data and the relevant legal basis will be determined by the Customer to
                          which your account is associated. For more information on this see Section
                          11 below.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Geo-location
                    <ol>
                      <li>
                        What Personal Data We Collect:{' '}
                        <span>
                          {' '}
                          When you use specific functionalities in our App, you may be asked to
                          share your mobile device’s precise (GPS) geo-location information. Such
                          geo-location information may include physical locations visited (latitude
                          & longitude). Whether or not we collect this geo-location data is entirely
                          determined by your Account Owner or Administrator in their sole discretion
                          and Midani acts only as a “data processor” with respect to such
                          information. For example, they may only allow you to clock-in or clock out
                          if they are able to verify your location via your device. If you do not
                          wish to allow the collection of your precise location, in most cases you
                          will be able to turn off such data collection at any time by accessing the
                          privacy settings of your mobile device and/or adjusting the permissions
                          for the App. Please note, however, that some of the App’s features may not
                          work properly if you turn this off, and we are not responsible for any
                          implication of turning off such geo-location tracking.
                        </span>
                      </li>
                      <li>
                        How we use this data:{' '}
                        <span>
                          Midani does not use geo-location data for its own purposes. The use of
                          geo-location data and the underlying legal basis for its use is determined
                          by the Customer to which your account is associated. For more information
                          on this see Section 11 below.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Data Collected from Third Parties.{' '}
                    <span>
                      {' '}
                      If you are an Account Owner or Administrator we may receive Personal Data
                      about you from third-party sources. We may use this data to improve our sales
                      & marketing efforts (Legitimate Interest, and Consent where applicable).
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <p>
            For the purposes of the California Consumer Privacy Act (CCPA), specifically in the last
            twelve (12) months, we have collected the following categories of Personal Information:
            Identifiers; Customer Record Information; Internet or other electronic network activity
            information; Geolocation data; Audio, Electronic, Visual, or Similar Information;
            Commercial Information; Professional or Employment-Related information; and Inferences.
          </p>

          <li>
            Communications
            <p>
              We engage in service and promotional communications, through e-mail, phones, SMS and
              push notifications.
            </p>
            <ol>
              <li>
                Service Communications.{' '}
                <span>
                  We may send you service-related communications, including service announcements
                  and administrative messages (such as registration confirmations, log-in attempts
                  or password reset attempts). Please note that you will not be able to opt-out of
                  receiving certain communications which are integral to the operation and use of
                  our Services (like password resets).
                </span>
              </li>
              <li>
                Promotional Communications.{' '}
                <span>
                  If you are an Account Owner or Administrator, we may use your Personal Data to let
                  you know about our products and services that we believe will be of interest to
                  you. We may contact you by email or through other communication channels approved
                  by you. We will always respect your preferences regarding how/whether you would
                  like us to communicate with you. To ensure you have control over how we contact
                  you with marketing offers:
                </span>
                <ol>
                  <li>
                    <span>
                      We will take steps to limit direct marketing to a reasonable level and only
                      send you communications which we believe may be of interest or relevance to
                      you.
                    </span>
                  </li>
                  <li>
                    <span>
                      You can ask us to stop sending email marketing by following the “unsubscribe”
                      link you will find on all the email marketing messages we send you.
                      Alternatively, you can contact us at support@midani.com.
                    </span>
                  </li>
                  <li>
                    <span>
                      You can change the way your browser manages cookies, which may be used to
                      deliver online advertising, by following the settings on your browser as
                      explained in our Cookie Policy.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            Disclosing the Personal Data We Collect.
            <span>We disclose your Personal Data to other entities as follows:</span>
            <ol>
              <li>
                Customers and other Users.{' '}
                <span>
                  If you are an Employee, Account Owner or Administrator, we may disclose your
                  Personal Data to the Customer (or any individual acting on their behalf, for
                  example an Account Owner or Administrator) with whom your account is associated,
                  in our capacity as a “data processor”. This includes where we are requested to
                  disclose Personal Data regarding your usage of the Platform or App, your chats, IP
                  address, or geo-location that we have collected on our Customers’ behalf. Other
                  Users within the account may also have access to some of your Personal Data
                  depending on the settings within the account (set by you or your Account
                  Owner/Administrator).
                </span>
              </li>
              <li>
                Affiliates.{' '}
                <span>
                  We may disclose information, including your Personal Data, to our affiliates and
                  subsidiaries (whether existing now or those that may be incorporated in the
                  future).
                </span>
              </li>
              <li>
                Service Providers, and Subcontractors.{' '}
                <span>
                  We disclose information, including Personal Data we collect from and/or about you,
                  to our trusted service providers and subcontractors, who have agreed to
                  confidentiality restrictions and who use such information solely on our behalf in
                  order to: (1) help us provide you with our Services; (2) aid in our understanding
                  of how you use our Services; (3) improve, optimize and send promotional
                  communications (see Section 2 above for more details); (4) deliver advertisements
                  and (5) provide us with IT and system administration, data backup, security, and
                  storage services, data analysis, data enrichment, and payment processing services.
                </span>
              </li>
              <li>
                Service Integrations.{' '}
                <span>
                  Our Customers may choose to use a third-party service to integrate with our
                  Services (“Third-Party Provider”), or to enhance the usage of the Services
                  (provided that our Services support such integration). The provider of such
                  third-party service may receive certain relevant data about or from your account
                  on the Services, or disclose certain relevant data from your account on the
                  Third-Party Provider’s service to our Services, depending on the nature and
                  purpose of such integration. This could include Users’ Personal Data and/or
                  Customer Data. The processing of your Personal Data by the Third-Party Provider is
                  subject to the agreement of the relevant Customer (or you) with such Third-Party
                  Provider.
                </span>
              </li>
              <li>
                Change of Control; Business Interest.{' '}
                <span>
                  Should Midani or any of its subsidiaries or affiliates undergo any change in
                  control or ownership, including by means of merger, acquisition or purchase of
                  substantially all or part of its assets, your Personal Data may be disclosed to or
                  transferred to the parties involved in such an event. We may disclose Personal
                  Data to a third-party during negotiation of, in connection with or as an asset in
                  such a corporate business transaction. Personal Data may also be disclosed in the
                  event of insolvency, bankruptcy or receivership. In addition, we may disclose your
                  Personal Data where such disclosure is required to protect our legitimate business
                  interests, including the security or integrity of our products and services.
                </span>
              </li>
              <li>
                Law Enforcement Related Disclosure.{' '}
                <span>
                  We may disclose your Personal Data to third parties: (1) if we believe in good
                  faith that disclosure is appropriate to protect our or a third-party’s rights,
                  property or safety (including the enforcement of the Terms and this Privacy
                  Notice); (2) when required by law, regulation subpoena, court order or other law
                  enforcement related issues, agencies and/or authorities; or (3) as is necessary to
                  comply with any legal and/or regulatory obligation.
                </span>
              </li>
              <li>
                Feedback or Recommendations.{' '}
                <span>
                  If you submit a public review or feedback, note that we may (at our discretion)
                  store and present your review publicly on our Sites and Services. If you wish to
                  remove your public review, please contact us at support@midani.com. If you
                  choose to send others an email or message inviting them to use the Services, we
                  may use the contact information you provide us to automatically send such
                  invitation email or message on your behalf. Your name and email address may be
                  included in the invitation email or message.
                </span>
              </li>
            </ol>
          </li>

          <p>
            For the purposes of the CCPA, in the past twelve (12) months, we may have disclosed
            Identifiers; Customer Record Information; Internet or other electronic network activity
            information; Geolocation data; Audio, Electronic, Visual, or Similar Information;
            Commercial Information; Professional or Employment-Related information; and Inferences
            to Customers, Affiliates, Service Providers, or for Law Enforcement Related Disclosures.
            We may have disclosed Identifiers; Customer Record Information; Internet or other
            electronic network activity information; and Commercial Information for Service
            Integrations. We may have disclosed Identifiers; Customer Record Information; Commercial
            Information; Professional or Employment-Related Information; or Audio, Electronic,
            Visual or Similar Information related to Feedback or Recommendations. For the avoidance
            of doubt, Midani may disclose your Personal Data in additional manners, pursuant to
            your explicit approval, if we are legally obligated to do so, or if we have successfully
            rendered such data non-personal and anonymous.
          </p>

          <li>
            International Transfer.{' '}
            <span>
              We have an affiliate in the US, and we use subcontractors and service providers
              located in the EEA, the US, Israel and other jurisdictions, who process and store
              Personal Data. We conduct such international transfers for the purposes described
              above. We ensure that any third parties based in a third country and receiving
              Personal Data are subject to written agreements ensuring the same level of privacy and
              data protection as set forth in this Privacy Notice.
            </span>
            <ol>
              <li>
                Midani is headquartered in Israel, a jurisdiction which is considered by the
                European Commission, the UK Secretary of State, and the Swiss Federal Data
                Protection and Information Commissioner, to be offering an adequate level of
                protection for Personal Data of individuals residing in EU Member States, the UK and
                Switzerland, respectively. We transfer data from the EEA, the UK and Switzerland to
                Israel on this basis.
              </li>
              <li>
                Whenever we transfer your Personal Data to third parties based outside of the
                European Economic Area (“EEA”), the United Kingdom, and Switzerland to a third
                country which isn’t covered by an “adequacy decision” we ensure a similar degree of
                protection is afforded to it by signing specific contracts approved by the European
                Commission, the FDPIC and the UK Secretary of State, which give Personal Data the
                same protection it has in the EEA, Switzerland and the UK.
              </li>
              <li>
                Please contact us at dpo@midani.com if you would like further information on the
                specific mechanism used by us when transferring your Personal Data out of the EEA,
                Switzerland, and the UK.
              </li>
            </ol>
          </li>

          <li>
            Security.{' '}
            <span>
              We have implemented and maintain appropriate technical and organization security
              measures, policies and procedures designed to reduce the risk of accidental
              destruction or loss, and the unauthorized disclosure of or access to Personal Data
              appropriate to the Personal Data concerned. The measures we take include:
            </span>
            <ol>
              <li>
                Safeguards{' '}
                <span>
                  - The physical, electronic, and procedural safeguards we employ to protect your
                  Personal Data include secure servers, firewalls, antivirus, and SSL encryption of
                  data.
                </span>
              </li>
              <li>
                Access Control{' '}
                <span>
                  - We manage system entries and limit access to authorized personnel on a
                  need-to-know basis of least privilege rules, review permissions quarterly, and
                  revoke access immediately after termination of our employees.
                </span>
              </li>
              <li>
                Internal Policies{' '}
                <span>
                  - We maintain and regularly review and update our privacy related and information
                  security policies.
                </span>
              </li>
              <li>
                Personnel{' '}
                <span>
                  - We require new employees to sign non-disclosure agreements according to
                  applicable law and industry customary practice.
                </span>
              </li>
              <li>
                Encryption{' '}
                <span>- We encrypt data in transit using secure TLS/ SSL protocols.</span>
              </li>
              <li>
                Database Backup{' '}
                <span>
                  - Our databases are backed up periodically for certain data and verified
                  regularly. Backups are encrypted and stored within the production environment to
                  preserve their confidentiality and integrity, are tested regularly to ensure
                  availability, and are accessible only by authorized personnel.
                </span>
              </li>
            </ol>
          </li>

          <p>
            While we strive to use commercially acceptable means to protect your Personal Data, we
            cannot guarantee that our Services will be immune from any malfunctions, unlawful
            interceptions or access, or other kinds of abuse and misuse.
          </p>

          <p>
            As the security of information depends in part on the security of the computer you use
            to communicate with us and the security you use to protect user IDs and passwords,
            please take appropriate measures to protect this information.
          </p>

          <li>
            Your Rights - How to Access and Limit Our Use of Certain Personal Data.{' '}
            <span>
              Subject to applicable law, and in some cases dependent upon the processing activity we
              are undertaking, you may have certain rights in relation to your Personal Data as
              detailed below:
            </span>
            <ol>
              <li>
                Right of Access.{' '}
                <span>
                  To know what Personal Data we collect about you and, in some cases, to have such
                  Personal Data communicated to you. Subject to applicable law, we may charge you a
                  fee for this access. Please note that we may not be able to provide you with all
                  the information you request, and, in such case, we will endeavor to explain why.
                </span>
              </li>
              <li>
                Right to Data Portability.{' '}
                <span>
                  If the processing is based on your consent or the performance of a contract with
                  you and processing is being carried out by automated means, you may be entitled to
                  (request that we) provide you or another party with a copy of the Personal Data
                  you provided to us in a structured, commonly-used, and machine-readable format.
                </span>
              </li>
              <li>
                Right to Correct Personal Data.{' '}
                <span>
                  You may request that we update, complete, correct or delete inaccurate,
                  incomplete, or outdated Personal Data.
                </span>
              </li>
              <li>
                Deletion of Personal Data (“Right to Be Forgotten”).{' '}
                <span>
                  You may request that we delete your Personal Data if either: (i) it is no longer
                  needed for the purpose for which it was collected; (ii) our processing was based
                  on your consent and you have withdrawn your consent (iii) you have successfully
                  exercised your Right to Object (see below); (iv) processing was unlawful; or (iv)
                  we are required to erase it in order to comply with a legal obligation. We cannot
                  restore information once it has been deleted. Please note that in order to ensure
                  we do not collect any further Personal Data, you should also delete our App from
                  your mobile devices, terminate your account with us, and clear our cookies from
                  any device where you have used our Platform or our App. We may retain certain
                  Personal Data (including following your request to delete it) for audit and
                  recordkeeping purposes, or as otherwise permitted and/or required under applicable
                  law.
                </span>
              </li>
              <li>
                Right to Restrict Processing{' '}
                <span>
                  You may ask us to limit the processing of your Personal Data if either: (i) you
                  have contested its accuracy and wish us to limit processing until this is
                  verified; (ii) the processing is unlawful, but you do not wish for us to erase the
                  Personal Data; (iii) it is no longer needed for the purposes for which it was
                  collected, but we still need it to establish, exercise, or defend against a legal
                  claim; or (iv) you have exercised your Right to Object (as described below) and we
                  are in the process of verifying our legitimate grounds for processing. We may
                  continue to use your Personal Data after a restriction request under certain
                  circumstances.
                </span>
              </li>
              <li>
                Right to Object.{' '}
                <span>
                  You may object to any processing of your Personal Data which has our legitimate
                  interests as its legal basis, if you believe your fundamental rights and freedoms
                  outweigh our legitimate interests. If you raise an objection, we are granted the
                  opportunity to demonstrate that we have compelling legitimate interests which
                  override your rights and freedoms.
                </span>
              </li>
              <li>
                Withdrawal of Consent.{' '}
                <span>
                  You may withdraw your consent in connection with any processing of your Personal
                  Data based on previously granted consent. This will not affect the lawfulness of
                  any processing prior to such withdrawal.
                </span>
              </li>
              <li>
                Right to Lodge a Complaint with Your Local Supervisory Authority.{' '}
                <span>
                  {' '}
                  You may have the right to submit a complaint to the relevant supervisory data
                  protection authority if you have any concerns about how we are processing your
                  Personal Data, though we ask that as a courtesy you please attempt to resolve any
                  issues with us first.
                </span>
              </li>
              <li>
                Right to opt-out the sale or sharing of personal information.{' '}
                <span>
                  You may have the right to opt-out the sale or sharing your Personal Information.
                  For more information on how to opt-out the sale or sharing of your Personal
                  Information, please refer to Section 9 below.
                </span>
              </li>
              <li>
                Right to equal services and prices.{' '}
                <span>
                  You may have the right not to be discriminated against when you exercise your
                  privacy rights.
                </span>
              </li>
            </ol>
          </li>

          <p>
            If you wish to exercise any of the above rights, please contact us at
            dpo@midani.com. We will investigate and attempt to resolve complaints and disputes
            and make every reasonable effort to honor your wish to exercise your rights as quickly
            as possible, and, in any event, within the timescales provided by applicable data
            protection laws. We reserve the right to ask for reasonable evidence to verify your
            identity before we provide you with any information and/or comply with any of your
            requests, which may include asking you to sign into your account in the Services. To the
            extent applicable to you, you may also designate an authorized agent, in writing or
            through a power of attorney, to request to exercise your privacy rights on your behalf.
            The authorized agent may submit a request to exercise these rights by emailing us. If
            you are an Employee, for any requests to exercise such rights with respect to
            information we hold about you, please contact the applicable Customer directly.
          </p>

          <li>
            Data Retention
            <ol>
              <li>
                <span>
                  Subject to applicable law, we retain Personal Data as necessary to maintain and
                  expand our relationship with you and provide you with our Services and offerings;
                  in order to comply with our legal and contractual obligations; or to protect
                  ourselves from any potential disputes (i.e. as required by laws applicable to
                  log-keeping, records and bookkeeping, and in order to have proof and evidence
                  concerning our relationship, should any legal issues arise following your
                  discontinuance of use), all in accordance with our data retention policy and at
                  our reasonable discretion. We may delete information from our systems without
                  notice to you once we deem it is no longer necessary for these purposes.
                </span>
              </li>
              <li>
                <span>
                  In some circumstances, we may store your Personal Data for longer periods of time,
                  for instance, where we are required to do so in accordance with legal, regulatory,
                  tax, audit, accounting requirements, for an accurate record of your dealings with
                  us in the event of any legal challenges or complaints, or if we reasonably believe
                  there is a prospect of litigation relating to your Personal Data or concerning our
                  relationship. To determine the appropriate retention period, we consider the
                  amount, nature, and sensitivity of the Personal Data, the potential risk of harm
                  from unauthorized use or disclosure of your Personal Data, the purposes for which
                  we process your Personal Data, and whether those purposes can be achieved through
                  other means, as well as applicable legal requirements.
                </span>
              </li>
              <li>
                <span>
                  With respect to data within our Services for which we serve as data processors
                  (see “Materials you Upload” in section 1.2.4 above), we will retain that data for
                  as long as directed by the applicable Customer. For example, a Customer may choose
                  to archive Personal Data within the Platform of Employees who have been
                  terminated, in which case such archived data will be retained until deleted by the
                  Customer. If you have any questions regarding such Personal Data, please contact
                  the relevant Customer directly.
                </span>
              </li>
              <li>
                <span>
                  Please contact us at dpo@midani.com if you have any questions regarding our
                  data retention periods.
                </span>
              </li>
            </ol>
          </li>

          <li>
            Cookies and Similar Technologies.{' '}
            <span>
              We and our service providers use cookies, pixels, tags and other technologies in order
              for us to provide and monitor our Services and Site, to ensure that they perform
              properly, to analyze our performance and marketing activities, and to personalize your
              experience. Such cookies and similar files or tags may also be temporarily placed on
              your device. Certain cookies and other technologies serve to recall personal data,
              such as IP address, as indicated by you. To learn more about our practices concerning
              cookies and tracking, please see our Cookie Policy.
            </span>
          </li>
          <li>
            Opt-Out of Sale and Sharing.{' '}
            <span>
              Under some US data protection laws, like the CCPA, our disclosure of certain internet
              activity and device information with third parties through cookies or pixels may be
              considered a “sale” or “sharing” of Personal Information. We do so in pursuit of the
              business and commercial purposes described in Section 2 above. For the purposes of the
              CCPA, in the last 12 months we have “sold” or “shared” Internet or other electronic
              network activity information, Geolocation Data, and Commercial Information with our
              analytics and advertising partners and service providers. Midani has not knowingly
              sold or shared the personal information of individuals under the age of 16.
            </span>
          </li>

          <p>
            To opt out of all cookies that may result in a “sale” and/or “sharing” of your Personal
            Information in the following ways: ● On the cookie banner, click the “Do Not Sell Or
            Share My Personal Information” button, and move the toggle switch “Sale or Share of
            Personal Data” off. ● Click the “Do Not Sell Or Share My Personal Information” button
            (available in our website’s footer), and move the toggle switch “Sale or Share of
            Personal Data” off. Please note: If you visit us from a different device or browser, or
            clear cookies, then you need to return to this screen to re-select your preferences. ●
            Set the Global Privacy Control (GPC) for each participating browser system that you use
            to opt out of the use of third-party Advertising cookies (instructions on how to
            download and use GPC are available here).
          </p>

          <li>
            Third-Party Applications and Services.{' '}
            <span>
              All use of third-party applications or services is at your own risk and subject to
              such third party’s terms and privacy policies.
            </span>
          </li>

          <li>
            Controller/Processor.
            <ol>
              <li>
                <span>
                  Certain data protection laws and regulations, such as the GDPR or the CCPA,
                  typically distinguish between two main roles for parties processing personal data:
                  the “data controller” (or under the CCPA, “Business”), who determines the purposes
                  and means of processing; and the “data processor” (or under the CCPA, “Services
                  Provider”), who processes such data on behalf of the data controller (or
                  business). Below we explain how these roles apply to our Services.
                </span>
              </li>
              <li>
                <span>
                  Midani is the “data controller” of: (1) its Prospects’ and Customers’ Personal
                  Data, (2) Account Owners’ & Administrators’ Registration Data, Payment Data, and
                  Automatically Collected Data, (3) its Users’ Communications Data, and (4)
                  Employees’ Automatically Collected Data, as detailed in Section 1 above.
                  Accordingly, we assume the responsibilities of a data controller (solely to the
                  extent applicable under law), as set forth in this Privacy Notice.
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  Midani is the “data processor” of: (1) Materials Uploaded by Users, (2)
                  Employees’ Registration Data, and (3) Geo-Location Data, as submitted by our
                  Customers and their Users to the Services, or automatically collected by our
                  Services during the use of our Services by said Users. We process such data on
                  behalf of our Customer (who is the “data controller” of such data) and in
                  accordance with its reasonable instructions, subject to our Terms, our Data
                  Processing Addendum (to the extent applicable) and other commercial agreements
                  with such Customer.
                </span>
              </li>
              <li>
                <span>
                  Our Customers are solely responsible for determining whether and how they wish to
                  use our Services, and for ensuring that all individuals using the Services on the
                  Customer’s behalf or at their request, as well as all individuals whose Personal
                  Data may be submitted to the Services, have been provided adequate notice and
                  given informed consent to the processing of their Personal Data, where such
                  consent is necessary or advised, and that all legal requirements applicable to the
                  collection, use or other processing of data through our Services are fully met by
                  the Customer. Our Customers are also responsible for handling data subject
                  requests under applicable law, by their Users and other individuals whose data
                  they process through the Services.
                </span>
              </li>
              <li>
                <span>
                  If you would like to make any requests or queries regarding Personal Data we
                  process as a data processor on our Customer’s behalf, including accessing,
                  correcting or deleting your data, please contact the Customer’s Account Owner or
                  Administrator directly.
                </span>
              </li>
            </ol>
          </li>

          <li>
            Children.{' '}
            <span>
              We do not knowingly collect Personal Data from children under the age of sixteen. In
              the event that you become aware that an individual under the age of sixteen has
              enrolled without parental permission, please advise us immediately.
            </span>
          </li>
          <li>
            Changes to the Privacy Notice.{' '}
            <span>
              We may update this Privacy Notice from time to time to keep it up to date with legal
              requirements and the way we operate our business. We will place any updates on this
              webpage. Please check this page regularly to make sure you are familiar with the
              latest version. If we make material changes to this Privacy Notice, we will inform you
              by notice on our Site, through the Servcies or by email.
            </span>
          </li>
          <li>
            Comments and Questions.{' '}
            <span>
              {' '}
              If you have any comments or questions about this Privacy Notice or if you wish to
              exercise any of your legal rights as set out herein, please contact us at
              dpo@midani.com.
            </span>
          </li>
          <li>
            DPO.{' '}
            <span>
              We have appointed a data protection officer (DPO) who is responsible for overseeing
              our privacy and data protection practices you may contact our DPO using the details
              set out below:
            </span>
          </li>

          <p>Email address: dpo@midani.com Last updated: March 28, 2024</p>
        </ol>
      </div>
    </div>
  );
}

export default Privacy;
