import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import announcementGreenIcon from 'assets/images/icons/announcement-green.svg';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import attendanceGreenIcon from 'assets/images/icons/attendance-green.svg';
import editBlackIcon from 'assets/images/icons/edit-black.svg';
import formGreenIcon from 'assets/images/icons/form-green.svg';
import minimizeIcon from 'assets/images/icons/minimize.svg';
import settingGreyIcon from 'assets/images/icons/setting-grey.svg';
import SelectField from 'components/form-components/SelectField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import { CallAPI } from 'actions/General';
import toast from 'react-hot-toast';
import Loader from 'components/common-components/Loader';
import WidgetNameModal from './WidgetNameModal';
import { isRTL } from 'config/config';

const TodayStatistics = ({ widgetDetails, deleteWidget, groupListing }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openWidgetNameModal, setOpenWidgetNameModal] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(
    widgetDetails.group_id ? widgetDetails.group_id : undefined,
  );
  const [widgetName, setWidgetName] = useState(widgetDetails.name ? widgetDetails.name : '');

  const [statsBarConfiguration, setStatsBarConfiguration] = useState({
    ...widgetDetails.detail,
  });
  const [submissionCount, setSubmissionCount] = useState(0);
  const [attendanceSummary, setAttendanceSummary] = useState({
    employeeCount: 0,
    checkedInCount: 0,
    onLeaveCount: 0,
  });

  const updateWidgetGroup = (group_id) => {
    setSelectedGroup(group_id);
    updatePersonalSettings(
      { homeStatisticsValue: statsBarConfiguration, groupId: group_id, title: widgetDetails.name },
      widgetDetails.id,
    );
  };
  const updateWidgetName = (name) => {
    setWidgetName(name);
    updatePersonalSettings(
      { homeStatisticsValue: statsBarConfiguration, groupId: widgetDetails.groupId, title: name },
      widgetDetails.id,
    );
  };

  const handleChange = (field, value) => {
    let details = { ...statsBarConfiguration, [field]: value };
    setStatsBarConfiguration(details);

    updatePersonalSettings(
      { homeStatisticsValue: details, groupId: widgetDetails.group_id, title: widgetDetails.name },
      widgetDetails.id,
    );
  };

  const values = groupListing.map((group) => ({ value: group._id, displayValue: group.groupName }));
  values.unshift({ value: '', displayValue: 'Show All' });
  const valuesMap = Object.fromEntries(values.map((v) => [v.value, v.displayValue]));

  const getFormStats = async () => {
    const payload = selectedGroup === '' ? {} : { groupId: selectedGroup };
    const result = await CallAPI('GET_FORM_STATS', payload, null, null, null, null);
    if (result.status) {
      setSubmissionCount(result.data.totalSubmissions);
    }
  };

  const getAttendanceStats = async () => {
    const payload = selectedGroup === '' ? {} : { groupId: selectedGroup };
    const result = await CallAPI('GET_ATTENDANCE_STATS', payload, null, null, null, null);
    if (result.status) {
      setAttendanceSummary({
        ...attendanceSummary,
        employeeCount: result.data.employeeCount,
        checkedInCount: result.data.checkedInCount,
        onLeaveCount: result.data.onLeaveCount,
      });
    }
  };

  useEffect(() => {
    getFormStats();
    getAttendanceStats();
  }, [selectedGroup, widgetDetails]);

  const updatePersonalSettings = async (payload, id) => {
    if (id !== '') {
      const result = await CallAPI('UPDATE_PERSONAL_SETTINGS', payload, id, setLoading, null);
      if (result.status) {
        if (result.status) {
          toast.success('Widget setting updated');
        }
      }
    }
  };
  const moveToLink = (linkType) => {
    navigate(linkType === 'dashboard' ? '/dashboard' : '/attendance/track', { relative: 'path' });
  };
  return (
    <div className='today-statistics'>
      {loading ? <Loader color='green-1' type='modal-spinner' /> : null}
      <div className='px-3 py-2 d-flex gap-3 justify-content-between align-items-center'>
        <div className='d-flex gap-3 align-items-center'>
          <label className='theme-text-black-1 theme-size-1_3 theme-font-inter-medium'>
            {widgetName}
          </label>
          <img
            src={editBlackIcon}
            className='cursor-pointer'
            onClick={() => setOpenWidgetNameModal(true)}
          />
        </div>
        <div className='d-flex gap-3'>
          <div className='dropdown-actions'>
            <DropdownButton
              align='end'
              variant='secondary'
              autoClose={'outside'}
              title={<img src={settingGreyIcon} className='svg-black' />}
            >
              <Dropdown.Item
                className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                onClick={() => deleteWidget(widgetDetails.id)}
              >
                {t('action_delete')}
              </Dropdown.Item>
              <DropdownButton
                align={isRTL() ? 'start' : 'end'}
                variant='secondary'
                autoClose={'outside'}
                className={`items${
                  !isRTL() ? ' backward-opened' : ''
                } no-hover-affect theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer`}
                title={<div className='theme-text-grey-1 child-dropdown'>{t('action_manage')}</div>}
              >
                <Dropdown.Item
                  className='items px-3 py-1 cursor-pointer'
                  onClick={() =>
                    handleChange('attendanceSummary', !statsBarConfiguration.attendanceSummary)
                  }
                >
                  <SingleCheckBoxField
                    name='attendanceSummary'
                    value={statsBarConfiguration.attendanceSummary}
                    handleChange={() => {}}
                    text={<span>{t('text_attendance_summary')}</span>}
                    shrink
                  />
                </Dropdown.Item>
                <Dropdown.Item
                  className='items theme-size-1 theme-text-grey-1 px-3 py-1 cursor-pointer'
                  onClick={() =>
                    handleChange('formStatistics', !statsBarConfiguration.formStatistics)
                  }
                >
                  <SingleCheckBoxField
                    name='formStatistics'
                    value={statsBarConfiguration.formStatistics}
                    handleChange={() => {}}
                    text={<span>{t('text_forms_statistics')}</span>}
                    shrink
                  />
                </Dropdown.Item>
              </DropdownButton>
            </DropdownButton>
          </div>
          <img
            src={minimizeIcon}
            className='svg-black cursor-pointer'
            onClick={() => setMinimize(!minimize)}
          />
        </div>
      </div>
      {!minimize && (
        <div className='d-flex flex-column gap-4 p-4 border-top-grey-10-h-1'>
          <SelectField
            label={valuesMap[selectedGroup] ?? 'text_select_groups'}
            listValues={values}
            icon={arrowDownIcon}
            handleChange={(_, value) => updateWidgetGroup(value)}
            selectedValues={[]}
            name='group'
            isAutoClose={true}
            variant='white-1'
            classes='width-max-content'
            menuClasses={'width-max-content'}
          />
          <div className='w-100 px-3 d-flex gap-3 justify-content-end align-items-center'>
            <div className='theme-background-green-1 d-flex align-items-center rounded-circle p-3'>
              <img src={announcementGreenIcon} className='svg-white' />
            </div>
            <label className='theme-size-2 theme-text-black-1'>{new Date().toDateString()}</label>
          </div>
          <div className='d-flex gap-4'>
            {statsBarConfiguration.attendanceSummary && (
              <div className='stats-bar'>
                <div className='d-flex gap-3 align-items-center'>
                  <img
                    src={attendanceGreenIcon}
                    className='cursor-pointer'
                    onClick={() => moveToLink('attendance')}
                  />
                  <label className='theme-text-black-1 theme-font-inter-medium'>
                    {t('text_attendance_summary')}
                  </label>
                </div>
                <ScoreList
                  scores={[
                    { label: 'text_total', value: attendanceSummary.employeeCount },
                    { label: 'text_check_in', value: attendanceSummary.checkedInCount },
                    { label: 'text_on_leave', value: attendanceSummary.onLeaveCount },
                  ]}
                />
              </div>
            )}
            {statsBarConfiguration.formStatistics && (
              <div className='stats-bar'>
                <div className='d-flex gap-3 align-items-center'>
                  <img
                    src={formGreenIcon}
                    className='cursor-pointer'
                    onClick={() => moveToLink('dashboard')}
                  />
                  <label className='theme-text-black-1 theme-font-inter-medium'>
                    {t('text_forms_statistics')}
                  </label>
                </div>
                <ScoreList scores={[{ label: 'text_submitted', value: submissionCount }]} />
              </div>
            )}
          </div>
        </div>
      )}
      {openWidgetNameModal && (
        <WidgetNameModal
          onUpdate={updateWidgetName}
          closeModal={() => setOpenWidgetNameModal(false)}
          title={widgetDetails.name}
        />
      )}
    </div>
  );
};

TodayStatistics.propTypes = {
  widgetDetails: PropTypes.object.isRequired,
  deleteWidget: PropTypes.func.isRequired,
  groupListing: PropTypes.array.isRequired,
};

const ScoreList = ({ scores }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex gap-3 align-items-center'>
      {scores.map((score, index) => (
        <Fragment key={index}>
          <div className='d-flex gap-1 flex-column align-items-center'>
            <label className='theme-font-inter-light theme-size-0_9'>{t(score.label)}</label>
            <label className='theme-text-black-1'>{score.value}</label>
          </div>
          {index < scores.length - 1 && <div className='vertical-line'></div>}
        </Fragment>
      ))}
    </div>
  );
};

ScoreList.propTypes = {
  scores: PropTypes.array.isRequired,
};

export default TodayStatistics;
