import React from 'react';
import PropTypes from 'prop-types';
import arrowLeftIcon from '../../assets/images/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';

const BackNavigation = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className='back-navigation' onClick={(e) => onClick(e)}>
      <img src={arrowLeftIcon} />
      <span className='them'>{t('text_back')}</span>
    </div>
  );
};

BackNavigation.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackNavigation;
