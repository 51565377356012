import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import plusIcon from 'assets/images/icons/plus.svg';
import AlertModal from 'components/common-components/AlertModal';
import EmployeeTracker from './EmployeeTracker';
import FormButton from 'components/form-components/FormButton';
import Loader from 'components/common-components/Loader';
import TodayStatistics from './TodayStatistics';
import { CallAPI } from 'actions/General';
import ConfigureWidgetContent from './ConfigureWidgetContent';
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const { t } = useTranslation();
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [deletedId, setDeletedId] = useState('');

  const [personalSetting, setPersonalSetting] = useState([]);
  const closeModal = () => setShowConfigureModal(false);

  const getPersonalSettings = async () => {
    const result = await CallAPI('GET_PERSONAL_SETTINGS', {}, null, setLoading, null, null);
    if (result.status) {
      if (result.data?.length) {
        let personalSettingList = [];
        if (result.data.length > 0)
          result.data.map((singleWidget) => {
            personalSettingList.push({
              id: singleWidget._id,
              name: singleWidget.title,
              type: singleWidget.widgetType,
              detail:
                singleWidget.widgetType === 'statistics' ? singleWidget.homeStatisticsValue : null,
              group_id: singleWidget.groupId ? singleWidget.groupId : null,
            });
          });
        setPersonalSetting(personalSettingList);
      } else {
        addPersonalSettings({ todayStats: true, employeeTracker: true });
      }
    }
  };

  const addPersonalSettings = async (addedTypesObject) => {
    let payloadData = { widgets: [] };
    if (addedTypesObject.todayStats) {
      payloadData.widgets.push({
        title: 'Statistics',
        widgetType: 'statistics',
        homeStatisticsValue: {
          pendingRequests: false,
          attendanceSummary: true,
          formStatistics: true,
        },
      });
    }
    if (addedTypesObject.employeeTracker) {
      payloadData.widgets.push({
        title: 'Employee Tracker',
        widgetType: 'employee_tracker',
        homeStatisticsValue: null,
      });
    }
    if (payloadData && payloadData.widgets && payloadData.widgets.length > 0) {
      const result = await CallAPI('ADD_PERSONAL_SETTINGS', payloadData, null, setLoading, null);
      if (result.status) {
        if (result.status) {
          getPersonalSettings();
        }
      }
    }
  };

  const getGroupListing = async () => {
    const result = await CallAPI('GET_ALL_GROUPS', {}, null, null, null, null);
    if (result.status) {
      setGroupListing(result.data);
    }
  };

  const deleteWidgetConfiguration = async (id) => {
    setDeletedId(id);
  };
  const deleteWidget = async () => {
    setLoading(true);
    const result = await CallAPI('DELETE_PERSONAL_SETTINGS', null, deletedId, setLoading, null);
    if (result.status) {
      if (result.status) {
        toast.success('Widget configuration deleted');
        getPersonalSettings();
        setDeletedId('');
      }
    }
  };
  useEffect(() => {
    getPersonalSettings();
    getGroupListing();
  }, []);

  return (
    <div className='home-main position-relative'>
      {loading ? (
        <div className='d-flex justify-content-center py-4'>
          <Loader color='green-1' type='modal-spinner' />
        </div>
      ) : (
        <Fragment>
          {personalSetting.length > 0 && (
            <div className='d-flex flex-column gap-4'>
              {personalSetting.map((singleWidget, index) => {
                return singleWidget.type === 'statistics' ? (
                  <TodayStatistics
                    key={index}
                    widgetDetails={singleWidget}
                    deleteWidget={deleteWidgetConfiguration}
                    groupListing={groupListing}
                  />
                ) : (
                  <EmployeeTracker
                    key={index}
                    widgetDetails={singleWidget}
                    deleteWidget={deleteWidgetConfiguration}
                    groupListing={groupListing}
                  />
                );
              })}
            </div>
          )}
          <div className='w-100 d-flex justify-content-center align-items-center mt-4'>
            <FormButton
              text='button_add_new_widget'
              variant='green-1'
              icon={plusIcon}
              iconClasses='svg-white'
              classes='theme-size-1_2 large-button'
              onClick={() => setShowConfigureModal(true)}
            />
          </div>
          <AlertModal
            show={showConfigureModal}
            closeModal={closeModal}
            title='alert_add_widget'
            actionButtons={[]}
          >
            {showConfigureModal ? (
              <ConfigureWidgetContent
                widgetConfiguration={personalSetting.homeStatisticsValue}
                addPersonalSettings={addPersonalSettings}
                closeModal={closeModal}
              />
            ) : (
              <></>
            )}
          </AlertModal>
          <AlertModal
            show={deletedId !== ''}
            closeModal={() => setDeletedId('')}
            title='alert_delete_widget'
            message='alert_delete_widget_text'
            actionButtons={[
              {
                text: t('button_confirm'),
                variant: 'red-1',
                loading: loading,
                onClick: deleteWidget,
              },
              {
                text: t('button_cancel'),
                variant: 'white-1',
                onClick: () => setDeletedId(''),
              },
            ]}
          ></AlertModal>
        </Fragment>
      )}
    </div>
  );
};

export default MainPage;
