import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import AlertModal from './AlertModal';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import TextField from 'components/form-components/TextField';

const EmailInputModal = ({ onSubmit, showModal, setShowModal, loading }) => {
  const [values, setValues] = useState({ email: '' });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const closeModal = () => setShowModal(false);
  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const emailSchema = yup.object({ email: yup.string().required().email() });

  const validate = async () => {
    try {
      setTouched({});
      await emailSchema.validate(values, { abortEarly: false });
      await onSubmit(values);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  return (
    <AlertModal show={showModal} showCloseButton closeModal={closeModal} actionButtons={[]}>
      <div className='w-100 d-flex flex-column gap-4 align-items-center'>
        <TextField
          name='email'
          displayName='field_email'
          placeholder='field_your_email'
          value={values.email}
          error={errors.email}
          touched={touched.email}
          handleChange={handleChange}
        />
        <FormLoadingButton
          text='button_confirm'
          variant='green-1'
          loading={loading}
          onClick={validate}
        />
      </div>
    </AlertModal>
  );
};

EmailInputModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EmailInputModal;
