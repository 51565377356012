import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DataSourceSelection from './DataSourceSelection';
import BindingComponent from '../BindingComponent';

function DataSourceBasicProperties({ properties, dataSourcesList, handleChange, otherDetails }) {
  const [columnValueList, setColumValueList] = useState([]);

  useEffect(() => {
    if (properties?.dataSource !== '') {
      const selectedDataSource = dataSourcesList.find(
        (dataSource) => dataSource.value === properties.dataSource,
      );
      if (selectedDataSource) {
        const list = selectedDataSource.columns.map((column) => ({ ...column }));
        setColumValueList([...list]);
      }
    }
  }, [properties.dataSource]);
  return (
    <>
      <DataSourceSelection
        properties={properties}
        dataSourcesList={dataSourcesList}
        handleChange={handleChange}
        columnValueList={columnValueList}
      />
      <BindingComponent
        properties={properties}
        handleChange={handleChange}
        otherDetails={otherDetails}
        columnValueList={columnValueList}
      />
    </>
  );
}
DataSourceBasicProperties.propTypes = {
  properties: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default DataSourceBasicProperties;
