import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import arrowLeftIcon from 'assets/images/icons/arrow-left.svg';
import eyeOffIcon from 'assets/images/icons/eye-off.svg';
import lockGreyIcon from 'assets/images/icons/lock-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import Error from 'components/Error';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectionComponent from 'components/common-components/SelectionComponent';
import TextField from 'components/form-components/TextField';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import { addFolderSchema } from './utils';
import { CallAPI } from 'actions/General';

function AddFolder() {
  const { t } = useTranslation();
  const location = useLocation();
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [values, setValues] = useState({
    folderName: '',
    users: [],
    groups: [],
    folderId: '',
    hide: false,
    lock: false,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const submit = async () => {
    setLoadingType('save');
    setTouched({});
    try {
      await addFolderSchema.validate({ ...values }, { abortEarly: false });
      let APICallName = 'ADD_FOLDER';
      let folderId = null;
      let userList = [];
      let groupsList = [];
      values.users.map((item) => userList.push(item.id));
      values.groups.map((item) => groupsList.push(item.id));
      let APIData = {
        name: values.folderName,
        appUser: { users: userList, groups: groupsList },
        isLocked: values.lock,
        isHidden: values.hide,
      };
      if (location?.state?.type === 'edit') {
        APICallName = 'UPDATE_FOLDER';
        folderId = values.folderId;
      }
      let result = await CallAPI(APICallName, APIData, folderId, setLoading, setApiError, null);
      if (result.status) {
        navigate('/forms');
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  const close = async () => {
    navigate(-1);
  };

  const getFolderDetails = async (folderId) => {
    let result = await CallAPI('GET_FOLDER_DETAILS', null, folderId, setLoading, setApiError, null);
    if (result.status) {
      let selectedUsers = [];
      result.data.appUser.users.map((singleSelectedUser) =>
        selectedUsers.push({
          id: singleSelectedUser._id,
          name: singleSelectedUser.lastName + ' ' + singleSelectedUser.firstName,
        }),
      );
      let selectedGroups = [];
      result.data.appUser.groups.map((singleSelectedGroup) =>
        selectedGroups.push({
          id: singleSelectedGroup._id,
          name: singleSelectedGroup.groupName,
        }),
      );
      setValues({
        folderName: result.data.name,
        users: selectedUsers,
        groups: selectedGroups,
        folderId: result.data._id,
        hide: result.data.isHidden,
        lock: result.data.isLocked,
      });
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.type === 'edit') {
      getFolderDetails(location.state.folderId);
    }
  }, [location]);

  const deleteFolder = async () => {
    setLoadingType('delete');
    let result = await CallAPI(
      'DELETE_FOLDER',
      {
        folders: [{ id: values.folderId, title: values.folderName }],
      },
      null,
      setLoading,
      setApiError,
    );
    if (result.status) {
      navigate(-1);
    }
  };
  return (
    <div className='add-group theme-background-white-1 p-4'>
      <div className='d-flex align-items-center cursor-pointer' onClick={close}>
        <img src={arrowLeftIcon} className='back-arrow me-2' alt='Loading...' />
        <label>{t('text_back')}</label>
      </div>
      {apiError ? <Error msg={apiError} /> : <></>}
      <div className='m-4 '>
        <div className='d-flex align-items-center gap-2'>
          <TextField
            name='folderName'
            placeholder='field_folder_name'
            displayName='field_folder_name'
            value={values.folderName}
            handleChange={handleChange}
            classes='w-100'
            shrink={false}
            error={errors.folderName}
            touched={touched.folderName}
            isHorizontal={true}
            horizontalLabelClass={'theme-width-150 text-black'}
          />
        </div>
        <div className='d-flex gap-5 my-4'>
          <div className='d-flex align-items-center gap-5'>
            <div className='d-flex align-items-center gap-2'>
              <img src={lockGreyIcon} className='svg-black' />
              <label className='theme-text-black-1'>{t('text_lock')}</label>
            </div>
            <ToggleSwitch name='hide' value={values.hide} handleChange={handleChange} />
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div className='d-flex align-items-center gap-5'>
            <div className='d-flex align-items-center gap-2'>
              <img src={eyeOffIcon} className='svg-black' />
              <label className='theme-text-black-1'>{t('text_hide')}</label>
            </div>
            <ToggleSwitch name='lock' value={values.lock} handleChange={handleChange} />
          </div>
        </div>

        <SelectionComponent
          title='text_groups'
          type='groups'
          name='groups'
          selectedDataList={values.groups}
          setSelectedList={handleChange}
          showButtons={false}
          loading={loading}
          error={errors.users}
        />
        <SelectionComponent
          title='text_users'
          name='users'
          selectedDataList={values.users}
          setSelectedList={handleChange}
          error={errors.users}
          showButtons={false}
          classes='mt-4'
        />

        <div className={`d-flex justify-content-end gap-3 mt-5`}>
          <FormLoadingButton
            loading={false}
            text='button_cancel'
            variant='white-1'
            onClick={close}
          />
          {values.folderId !== '' && (
            <FormLoadingButton
              loading={loading && loadingType === 'delete'}
              text='button_delete'
              variant='red-1'
              onClick={() => setShowDeleteConfirm(true)}
            />
          )}
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            onClick={submit}
            loading={loading && loadingType === 'save'}
          />
        </div>
        <AlertModal
          show={showDeleteConfirm}
          showCloseButton={true}
          closeModal={() => setShowDeleteConfirm(false)}
          variant='delete'
          message={'alert_delete_folder'}
          actionButtons={[
            { text: t('button_confirm'), variant: 'red-1', onClick: () => deleteFolder() },
            {
              text: t('button_cancel'),
              variant: 'white-1',
              onClick: () => setShowDeleteConfirm(false),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default AddFolder;
