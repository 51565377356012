import axios from 'axios';
import APIRoutes from './APIRoutes.json';
import APIMessages from './APIMessages.json';
import * as ReduxActions from './ReduxActions';
import NodeRSA from 'node-rsa';

const CallAPI = async (type, data, id, setLoader, setApiError, history = null) => {
  let APISingleObject = APIRoutes[type];
  if (setLoader) setLoader(true);
  if (setApiError) setApiError('');
  let result = await SendCallToServer(data, id, APISingleObject);
  if (result.error) {
    if (setApiError)
      setApiError(APIMessages[result.error] ? APIMessages[result.error] : result.error);
    if (setLoader) setLoader(false);
    return {
      status: 0,
      error: APIMessages[result.error] ? APIMessages[result.error] : result.error,
    };
  } else {
    let returnedData = result.data;
    if (APISingleObject.callback)
      returnedData = await ReduxActions[APISingleObject.callback](result.data, history);
    if (setLoader) setLoader(false);
    return { status: 1, data: returnedData };
  }
};
const SendCallToServer = (data, id, APISingleObject) => {
  let url = APISingleObject.url;
  if (id) url += id;
  let method = APISingleObject.method;
  axios.defaults.headers.common['token_type'] = APISingleObject.token_type
    ? APISingleObject.token_type
    : '';

  return axios[method](url, data);
};
const GetOtpForOperation = async (data, setApiError, setLoading = undefined) => {
  setLoading(true);
  let rsaKeys = GenerateRSAKeys();
  let res = await CallAPI(
    'GET_OTP',
    { ...data, privateKey: rsaKeys.privateKey },
    null,
    setLoading,
    setApiError,
    null,
  );
  console.log('GET_OTP', res);
  return { status: res.status, res, publicKey: rsaKeys.publicKey };
};
const GenerateRSAKeys = () => {
  const starter_key = new NodeRSA({ b: 2048 });

  const publicKey = starter_key.exportKey('pkcs1-public');
  const privateKey = starter_key.exportKey('pkcs1-private');
  return { publicKey, privateKey };
};
export { CallAPI, GetOtpForOperation, GenerateRSAKeys };
