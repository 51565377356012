import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableData from './TableData';
import Pagination from 'components/common-components/Pagination';
import MoreFieldsDropdown from 'components/common-components/MoreFieldsDropdown';
import FolderFilterToggler from 'components/common-components/FolderFilterToggler';
import TableFolderListFilter from 'pages/folders/TableFolderListFilter';
import TableHeaderSubmission from './TableHeaderSubmission';

const TableComponent = ({
  header,
  filterEnabled,
  data,
  totalRecords,
  loader,
  props,
  isFieldSelectionEnabled,
  isPaginationEnabled,
  isActionsEnabled,
  isSelectionEnabled,
  selectionKey,
  maintainTableHeight = true,
  maintainTableMaxHeight,
  paginationFunction,
  onSelection,
  selectedRows,
  selectedColumns,
  onColumnSelection,
  debounceFunc,
  filteringData,
  setFilteringData,
  setScrollTop,
}) => {
  let {
    type,
    updateFilteringData,
    addRow,
    setTableHeaderChange,
    filteringHeader,
    actionsCallBack,
    editedRowData,
    actionsType,
    resetHeader,
    mediaEnabled,
    isAllRecordsSelected,
  } = { ...props };
  const [tableHeaderTesting, setTableHeaderTesting] = useState([]);
  const [displayEntries, setDisplayEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [folderFilterOpen, setFolderFilterOpen] = useState(false);
  const [headerWidthMap, setHeaderWidthMap] = useState({});

  useEffect(() => {
    setTableHeaderTesting(header);
  }, [header]);

  const changeDisplayEntries = (name, val) => {
    setDisplayEntries(parseInt(val));
    paginationFunction(1, parseInt(val));
  };

  return (
    <div className='d-flex'>
      <div
        className={`${type !== 'submissions' ? 'flex-grow-1 ' : ''}overflow-auto position-relative`}
      >
        {isFieldSelectionEnabled && (
          <div className={`position-absolute ${filterEnabled ? 'top-filter' : 'top-0'} end-0`}>
            <MoreFieldsDropdown
              fieldList={filteringHeader ? filteringHeader : tableHeaderTesting}
              setFieldListSelection={
                setTableHeaderChange ? setTableHeaderChange : setTableHeaderTesting
              }
              actionsType={actionsType}
              resetHeader={resetHeader}
            />
          </div>
        )}
        {type === 'form_list' && !folderFilterOpen && (
          <div className='position-absolute top-0 end-0'>
            <FolderFilterToggler onClick={() => setFolderFilterOpen(true)} />
          </div>
        )}
        <div
          className={`table-responsive${maintainTableHeight ? ' maintain-height' : ''}${
            maintainTableMaxHeight ? ' maintain-max-height' : ''
          }${type === 'submissions' ? ' submission-table' : ''}`}
          {...(type === 'submissions' &&
            setScrollTop && {
              onScroll: (e) => setScrollTop(e.target.scrollTop),
            })}
        >
          <table
            className={`table-custom ${
              type === 'form_permissions'
                ? 'inverse'
                : type === 'userAccessedForms'
                ? 'bordered'
                : type === 'form_version'
                ? 'version'
                : type === 'data_sources_entries'
                ? 'entries'
                : type === 'profiles_list'
                ? 'profiles'
                : type === 'data_source_filter_list'
                ? 'table-bordered'
                : ''
            } `}
          >
            {type === 'submissions' ? (
              <TableHeaderSubmission
                filterEnabled={filterEnabled}
                mediaEnabled={mediaEnabled ?? false}
                tableFields={tableHeaderTesting}
                onSelection={onSelection}
                isSelectionEnabled={isSelectionEnabled}
                isActionsEnabled={isActionsEnabled}
                selectionKey={selectionKey}
                selectionValue={
                  selectedRows && selectedRows.length > 0
                    ? selectedRows.length === data.length
                    : false
                }
                debounceFunc={debounceFunc}
                filteringData={filteringData}
                setFilteringData={setFilteringData}
                setHeaderWidthMap={setHeaderWidthMap}
              />
            ) : (
              <TableHeader
                filterEnabled={filterEnabled}
                mediaEnabled={mediaEnabled ?? false}
                tableFields={tableHeaderTesting}
                onSelection={onSelection}
                selectedColumns={selectedColumns}
                onColumnSelection={onColumnSelection}
                isSelectionEnabled={isSelectionEnabled}
                isActionsEnabled={isActionsEnabled}
                selectionKey={selectionKey}
                selectionValue={
                  isAllRecordsSelected === true || isAllRecordsSelected === false
                    ? isAllRecordsSelected
                    : selectedRows && selectedRows.length > 0
                    ? selectedRows.length === data.length
                    : false
                }
                debounceFunc={debounceFunc}
                filteringData={filteringData}
                setFilteringData={setFilteringData}
                addRow={addRow}
                closeRow={
                  actionsCallBack && typeof actionsCallBack === 'function' ? actionsCallBack : null
                }
                editedRowData={editedRowData}
                type={type}
              />
            )}
            <TableData
              onSelection={onSelection}
              data={data}
              tableFields={tableHeaderTesting}
              loader={loader}
              props={props}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionKey={selectionKey}
              selectedRows={selectedRows}
              headerWidthMap={headerWidthMap}
            />
          </table>
        </div>
        {isPaginationEnabled && (
          <Pagination
            totalRecords={totalRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            displayEntries={displayEntries}
            setDisplayEntries={changeDisplayEntries}
            currentPageRecords={data ? data.length : 0}
            paginationFunction={paginationFunction}
          />
        )}
      </div>
      {type === 'submissions' && (
        <div
          className='flex-grow-1'
          style={{
            height: '3rem',
            backgroundColor: '#f8f8f8',
            borderBottom: '1px solid #cccccc',
          }}
        ></div>
      )}
      {type === 'form_list' && folderFilterOpen && (
        <TableFolderListFilter
          closeFilter={() => setFolderFilterOpen(false)}
          updateFolderList={updateFilteringData}
        />
      )}
    </div>
  );
};
TableComponent.propTypes = {
  selectedRows: PropTypes.array,
  selectedColumns: PropTypes.array,
  onColumnSelection: PropTypes.func,
  header: PropTypes.array.isRequired,
  filterEnabled: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  loader: PropTypes.bool.isRequired,
  props: PropTypes.object.isRequired,
  isFieldSelectionEnabled: PropTypes.bool.isRequired,
  isPaginationEnabled: PropTypes.bool.isRequired,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  maintainTableHeight: PropTypes.bool,
  maintainTableMaxHeight: PropTypes.bool,
  paginationFunction: PropTypes.func,
  onSelection: PropTypes.func,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  setScrollTop: PropTypes.func,
};
export default TableComponent;
