import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { allPermissionSet, rolePermissions } from 'config/config';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';

function Permission({ permissionsList, setPermissionsList }) {
  const [permissions, setPermissions] = useState({});
  const systemModules = useSelector((state) => state.auth.systemModules);
  useEffect(() => {
    let permissionsObject = cloneDeep(permissions);
    Object.keys(rolePermissions).map((singleModuleKey) => {
      if (
        rolePermissions[singleModuleKey].isNested &&
        rolePermissions[singleModuleKey].displayName
      ) {
        let module = systemModules.filter((module) => module.module === singleModuleKey);
        if (module.length > 0) {
          permissionsObject = {
            ...permissionsObject,
            ...{
              [singleModuleKey]: {
                adminModuleId: module[0]._id,
                adminModuleName: singleModuleKey,
                moduleLevels: permissionsList[singleModuleKey]
                  ? permissionsList[singleModuleKey].moduleLevels
                  : {},
              },
            },
          };
          Object.values(rolePermissions[singleModuleKey].modules).map((singleSubModule) => {
            let singlePermissionsObject = permissionsObject[singleModuleKey].moduleLevels;
            singlePermissionsObject[singleSubModule.value] = {
              levelName: singleSubModule.value,
              operations:
                singlePermissionsObject &&
                singlePermissionsObject[singleSubModule.value] &&
                singlePermissionsObject[singleSubModule.value].operations
                  ? singlePermissionsObject[singleSubModule.value].operations
                  : [],
            };
          });
        }
      }
    });
    setPermissions(permissionsObject);
    setPermissionsList(permissionsObject);
  }, []);
  const { t } = useTranslation();
  const onChangeModulePermissions = (module, level, values) => {
    let permissionsObject = cloneDeep(permissions);
    permissionsObject[module].moduleLevels[level].operations = values;
    setPermissions(permissionsObject);
    setPermissionsList(permissionsObject);
  };
  return (
    <div className='w-100 permission-list'>
      {Object.keys(permissions).length > 0 &&
        Object.keys(rolePermissions).map((singleModuleKey, index) => {
          let singleModuleList = rolePermissions[singleModuleKey];
          if (singleModuleList.isNested && singleModuleList.displayName) {
            return (
              <div className='single-module' key={singleModuleList.displayName + index}>
                <div className='single-row'>
                  <label className='theme-text-green-1 text-uppercase theme-font-inter-medium'>
                    {t(singleModuleList.displayName)}
                  </label>
                  <PermissionObjectHeader />
                </div>
                {Object.values(singleModuleList.modules)
                  .filter((singleSubModule) => singleSubModule.value !== 'company_setup')
                  .map((singleModule, index1) => {
                    return (
                      <div key={'module_' + index + '_' + index1} className='single-row'>
                        <label className='theme-font-inter-medium'>
                          {t(singleModule.displayName)}
                        </label>
                        <PermissionObject
                          moduleLevel={singleModule.value}
                          selectedValues={
                            permissions &&
                            permissions[singleModuleKey] &&
                            permissions[singleModuleKey].moduleLevels &&
                            permissions[singleModuleKey].moduleLevels[singleModule.value]
                              ? permissions[singleModuleKey].moduleLevels[singleModule.value]
                                  .operations
                              : []
                          }
                          onChange={(values) =>
                            onChangeModulePermissions(singleModuleKey, singleModule.value, values)
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            );
          }
        })}
    </div>
  );
}
Permission.propTypes = {
  permissionsList: PropTypes.object.isRequired,
  setPermissionsList: PropTypes.func.isRequired,
};
export default Permission;

const PermissionObjectHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <label className='theme-font-lato-regular text-center'>{t('ViewPermission')}</label>
      <label className='theme-font-lato-regular text-center'>{t('CreateEditPermission')}</label>
      <label className='theme-font-lato-regular text-center'>{t('DeletePermission')}</label>
    </>
  );
};
const PermissionObject = ({ selectedValues, moduleLevel, onChange }) => {
  const [values, setValues] = useState({
    view_only: selectedValues.indexOf('view_only') > -1,
    edit: selectedValues.indexOf('edit') > -1,
    delete: selectedValues.indexOf('delete') > -1,
  });
  const handleChange = (field, value) => {
    let updatedValue = {
      ...values,
      [field]: value,
      ...(field === 'edit' && value ? { view_only: true } : {}),
      ...(field === 'delete' && value ? { edit: true, view_only: true } : {}),
    };

    setValues(updatedValue);
    let allowedPermissions = [];
    Object.keys(updatedValue).map((singleKey) => {
      if (updatedValue[singleKey]) {
        allowedPermissions.push(singleKey);
      }
    });
    onChange(allowedPermissions);
  };
  return (
    <>
      {Object.keys(values).map((singlePermission, index) => {
        const disabled =
          (singlePermission === 'view_only' && (values.edit || values.delete)) ||
          (singlePermission === 'edit' && values.delete);

        return (
          <SingleCheckBoxField
            key={index}
            name={singlePermission}
            value={values[singlePermission]}
            handleChange={handleChange}
            shrink
            disabled={
              (allPermissionSet[moduleLevel].indexOf(singlePermission) > -1 ? false : true) ||
              disabled
            }
          />
        );
      })}
    </>
  );
};
PermissionObject.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  moduleLevel: PropTypes.string.isRequired,
};
