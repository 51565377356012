import { AuthReducer } from '../redux/auth';
import store from '../store/store';

import { getUserPermissionSet, updatePermissionSet } from '../config/permissionUtils';
import { allFeaturesList } from 'config/config';

const AuthAction = AuthReducer.actions;
const dispatch = store.dispatch;

const updateTotalCompanies = (totalCompanies) => {
  dispatch(
    AuthAction.updateTotalCompanies({
      totalCompanies,
    }),
  );
};

const UpdateLoginToken = (data) => {
  dispatch(
    AuthAction.updateAuthState({
      loginToken: data.access_token,
      loginRefreshToken: data.refresh_token,
    }),
  );
};

const RefreshToken = (data, navigate) => {
  console.log(navigate);
  updateAuthTokenAndPermissions(data);
};

const SystemModulesFetched = (data, navigate) => {
  console.log(navigate);
  dispatch(
    AuthAction.updateSystemModules({
      systemModules: data,
    }),
  );
};

const CompanySelected = (data, navigate) => {
  updateAuthTokenAndPermissions(data);
  navigate('/home');
};

const updateAuthTokenAndPermissions = (data) => {
  let profile = data.company;
  profile.firstName = '';
  profile.lastName = '';
  profile.role = '';
  let permissions = null;
  let authTokenInitializeDate = null;
  if (data.access_token) {
    var base64Url = data.access_token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    let userDetailInCompany = JSON.parse(jsonPayload);
    profile.firstName = userDetailInCompany.firstName;
    profile.lastName = userDetailInCompany.lastName;
    profile.countryCode = userDetailInCompany.countryCode;
    profile.mobileNumber = userDetailInCompany.mobileNumber;
    profile.role = userDetailInCompany.userRole;
    profile.userCompanyId = userDetailInCompany.companyUserId;
    permissions = userDetailInCompany.rolePermission;
    authTokenInitializeDate = userDetailInCompany.iat;
  }

  let features = data && data.company && data.company.dashboardFeatures ? [] : null;
  // if (features !== null) {
  // JSON.parse(data.company.dashboardFeatures).map((singleFeature) => {
  //   console.log(singleFeature);
  //   features.push(singleFeature);
  // });
  allFeaturesList.map((singleFeature) => {
    features.push(singleFeature);
    // console.log(singleFeature);
  });
  // }

  // get only those permission that set for company
  let companyPermissions = updatePermissionSet(features);

  if (profile.role === 'owner' || profile.role === 'super_admin') {
    // if user is owner or supper admin then all options will be shon
    companyPermissions = getUserPermissionSet(companyPermissions, [], true);
  } else {
    let userPemissionList = permissions ? permissions : [];
    // for other users only given options will be shown
    companyPermissions = getUserPermissionSet(companyPermissions, userPemissionList, false);
  }

  dispatch(
    AuthAction.updateAuthState({
      authToken: data.access_token,
      refreshToken: data.refresh_token,
      authTokenInitializeDate,
      companyPermissions,
      profile: profile,
    }),
  );
};

const UserExist = (data, history) => {
  console.log(history);
  if (data.status) {
    return { status: 1 };
  } else {
    return { status: 0, msg: 'user already exist' };
  }
};

const BusinessEmailExist = (data, history) => {
  console.log(history);
  if (data.status) {
    return { status: 1 };
  } else {
    return { status: 0, msg: 'business email already exist' };
  }
};
const setUserCustomizedTableHeader = (type, data) => {
  console.log(data, type);

  dispatch(
    AuthAction.updateCustomizedTableHeader({
      customizedTableHeader: data,
      type,
    }),
  );
};

export {
  UserExist,
  BusinessEmailExist,
  CompanySelected,
  RefreshToken,
  UpdateLoginToken,
  SystemModulesFetched,
  updateTotalCompanies,
  setUserCustomizedTableHeader,
};
