import React, { useState } from 'react';
import AddUserByForm from './AddUserByForm';
import AddUserByQR from './AddUserByQR';
import TabBody from '../../components/tab-components/TabBody';
import TabHeader from '../../components/tab-components/TabHeader';
import TabLayout from '../../components/tab-components/TabLayout';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import AuthorizeError from 'pages/AuthorizeError';
import { addUserFieldList, addUserTabList } from './utils';
const AddUser = () => {
  const tabList = addUserTabList;

  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.employees_users_create),
  );
  const [selectedTab, setSelectedTab] = useState(tabList[0]);

  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <TabLayout>
          <TabHeader tabList={tabList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <TabBody>
            {selectedTab.id === tabList[0].id && <AddUserByForm fieldList={addUserFieldList} />}
            {selectedTab.id === tabList[1].id && <AddUserByQR fieldList={addUserFieldList} />}
          </TabBody>
        </TabLayout>
      )}
    </>
  );
};

export default AddUser;
