import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import editIcon from 'assets/images/icons/edit-black.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import UpdateUserDetail from './UpdateUserDetail';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';
import AddTags from './AddTags';
import { Tag } from './Tag';
import { CallAPI } from 'actions/General';
import UpdateUserGroups from './UpdateUserGroups';

const Item = ({ children, title, classes }) => {
  const { t } = useTranslation();
  return (
    <div className={`${classes}`}>
      <div className='tab-heading text-center py-2 theme-background-white-2 theme-font-inter-semi-bold theme-background-white-2 theme-size-1_1 theme-text-black-1'>
        {t(title)}
      </div>
      <div className={`  px-5 py-4`}>{children}</div>
    </div>
  );
};

const Details = ({ onUpdate, title, info }) => {
  const { t } = useTranslation();
  return (
    <>
      {title && (
        <div className='d-flex justify-content-between align-items-end'>
          <div className='theme-font-inter-medium theme-text-green-1 theme-size-0_9'>
            {t(title)}
          </div>
          <img
            className='cursor-pointer'
            height={21}
            onClick={onUpdate}
            src={editIcon}
            alt='loading...'
          />
        </div>
      )}

      {Object.entries(info).map(([key, value]) => (
        <div key={key} className='mt-3'>
          <div className='theme-font-inter-regular theme-size-0_9'>{t(key)}:</div>
          <div className='theme-font-inter-semi-bold theme-size-0_9 theme-text-black-1'>
            {value}
          </div>
        </div>
      ))}
    </>
  );
};

const UserDetails = ({ userDetail, getUserDetail }) => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(null); // contact, company
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogType, setOpenDialogType] = useState('');
  const [tags, setTags] = useState([]);
  const [userTagsDetail, setUsetTagsDetail] = useState({});

  useEffect(() => {
    setTags(userDetail.tags);
    setUsetTagsDetail({
      id: userDetail.user_company_id,
      title: userDetail.contact.firstName + ' ' + userDetail.contact.lastName,
    });
  }, [userDetail]);

  const removeTag = async (tag) => {
    let filterTagList = tags.filter((tempTag) => tempTag != tag);
    let AssignObject = {
      ...userTagsDetail,
      tags: filterTagList,
    };

    let result = await CallAPI('ASSIGN_TAG', { users: [AssignObject] }, null, null, null, null);

    if (result.status) setTags(filterTagList);
  };

  useEffect(() => {
    if (!openDialog) {
      setOpenDialogType('');
      getUserDetail();
    }
  }, [openDialog]);

  return (
    <div className='user-details theme-background-grey-5'>
      {openDialog && openDialogType === 'group' ? (
        <UpdateUserGroups
          userDetail={userDetail}
          close={() => {
            setOpenDialog(false);
          }}
        />
      ) : (
        <>
          <div className='theme-font-inter-regular theme-text-black-1 theme-background-white-1 py-2 px-4'>
            {t('Here you can see all the deep details of the user')}.
          </div>
          <div className='d-flex gap-2'>
            <Item
              title='Personal'
              classes='d-flex flex-column w-100 theme-background-white-1 tab-body'
            >
              <Details
                title='ContactInformation'
                onUpdate={() => {
                  if (isPermissionAvailable(permissionKeys.employees_users_create)) {
                    setUpdate('contact');
                    setOpenDialog(true);
                  }
                }}
                info={{
                  FirstName: userDetail.contact.firstName,
                  LastName: userDetail.contact.lastName,
                  EmailAddress: userDetail.contact.email,
                  Phone: userDetail.contact.phone,
                }}
              />
              <br />

              <Details
                title='CompanyInformation'
                onUpdate={() => {
                  if (isPermissionAvailable(permissionKeys.employees_users_create)) {
                    setOpenDialog(true);
                    setUpdate('company');
                  }
                }}
                info={{
                  Title: userDetail.company.title,
                  Department: userDetail.company.department,
                  Branch: userDetail.company.branch,
                  EmploymentStartDate: new Date(
                    userDetail.company.employmentStartDate,
                  ).toLocaleDateString('en-US'),
                }}
              />
            </Item>
            <div className='d-flex flex-column w-100 theme-background-white-1 gap-3'>
              <Item title='Groups' classes='tab-body h-100'>
                <div className='d-flex justify-content-end'>
                  {isPermissionAvailable(permissionKeys.employees_groups_create) && (
                    <button
                      onClick={() => {
                        setOpenDialog(true);
                        setOpenDialogType('group');
                      }}
                      className='theme-text-green-1 d-flex gap-2 align-items-center border-0 theme-background-white-1'
                    >
                      <img src={plusIcon} alt='Loading...' />
                      {t('AddGroups')}
                    </button>
                  )}
                </div>

                <div className='d-flex flex-wrap gap-2 my-2'>
                  {userDetail.groups.map((group, index) => (
                    <Tag key={index} title={group.groupName} icon={false} onDelete={() => {}} />
                  ))}
                </div>
              </Item>
              <Item title='Tags' classes='tab-body h-100'>
                <div className='d-flex justify-content-end'>
                  {isPermissionAvailable(permissionKeys.employees_users_create) && (
                    <button
                      onClick={() => {
                        setOpenDialog(true);
                        setOpenDialogType('tag');
                      }}
                      className='theme-text-green-1 d-flex gap-2 align-items-center border-0 theme-background-white-1'
                    >
                      <img src={plusIcon} alt='Loading...' />
                      {t('AddTags')}
                    </button>
                  )}
                </div>

                <div className='d-flex flex-wrap gap-2 my-2'>
                  {userDetail.tags.map((tag, index) => (
                    <Tag
                      key={index}
                      title={tag}
                      icon={false}
                      onDelete={() => {
                        removeTag(tag);
                      }}
                    />
                  ))}
                </div>
              </Item>
            </div>
            <Item
              title='App Info'
              classes='d-flex flex-column w-100 theme-background-white-1 tab-body'
            >
              <Details
                info={{
                  Totalnoofsessions: userDetail.appInfo.sessionsCount,
                  Lastlogged_in: new Date(userDetail.appInfo.lastLoginDate).toLocaleDateString(
                    'en-US',
                  ),
                  // Appversion: 'Address, Full text number etc.',
                  // Kioskcode: '12/2/2023',
                }}
              />
            </Item>
          </div>

          {update && (
            <UpdateUserDetail
              type={update}
              userDetails={userDetail}
              handleClose={() => {
                setUpdate(null);
                setOpenDialog(false);
              }}
            />
          )}

          {openDialog && openDialogType === 'tag' && (
            <AddTags
              close={() => {
                setOpenDialog(false);
              }}
              userDetail={userTagsDetail}
              userTags={tags}
              onTagListUpate={(tagList) => setTags(tagList)}
            />
          )}
        </>
      )}
    </div>
  );
};

Item.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

Details.propTypes = {
  title: PropTypes.string,
  info: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

UserDetails.propTypes = {
  userDetail: PropTypes.object,
  getUserDetail: PropTypes.func.isRequired,
};

export default UserDetails;
