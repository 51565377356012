import React, { useState, useEffect } from 'react';
import { DataSourceTableFileds } from '../utils';
import TableComponent from 'components/table-component/Table';
import { cloneDeep } from 'lodash';
import DatabaseHeader from './DatabaseHeader';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import AuthorizeError from 'pages/AuthorizeError';

const DatabaseList = () => {
  const [editRecord, setEditRecord] = useState(null);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [totalDataSources, setTotalDataSources] = useState(0);
  const [loader, setLoader] = useState(false);
  const [ApiError, setApiError] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.field_work_datasource_read),
  );

  const getAllDataSources = async (page = 1, limit = 10) => {
    let result = await CallAPI(
      'GET_ALL_DATA_SOURCE',
      { page, limit },
      null,
      setLoader,
      setApiError,
      null,
    );
    if (result.status) {
      setDataSourceList(result.data.dataSources);
      setTotalDataSources(result.data.totalCount);
    }
  };

  useEffect(() => {
    getAllDataSources();
  }, []);

  const onRowsSelection = (name, value) => {
    let tempSelectedRows = cloneDeep(selectedRows);
    if (name === 'all') {
      dataSourceList.map((single_datasource) => {
        if (value && tempSelectedRows.indexOf(single_datasource._id) === -1)
          tempSelectedRows.push({ id: single_datasource._id, title: single_datasource.schemaName });
        if (!value) tempSelectedRows = [];
        setSelectedRows(tempSelectedRows);
      });
    } else {
      if (value) {
        const selectedDataSource = dataSourceList.find(
          (single_dataSource) => single_dataSource._id === name,
        );
        const title = selectedDataSource ? selectedDataSource.schemaName : '';
        tempSelectedRows.push({ id: name, title });
      } else tempSelectedRows = tempSelectedRows.filter((row) => row.id !== name);

      setSelectedRows(tempSelectedRows);
    }
  };
  const actionsCallBack = (type, id) => {
    if (type === 'delete') {
      getAllDataSources();
    } else if (type === 'edit') {
      dataSourceList.map((singleDataSource) => {
        if (singleDataSource._id === id) {
          setEditRecord(singleDataSource);
        }
      });
    }
  };
  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <div className=''>
          <div className='px-0'>
            <DatabaseHeader
              editRecord={editRecord}
              getAllDataSources={getAllDataSources}
              selectedRows={selectedRows}
              clearEditRecord={() => setEditRecord(null)}
            />
            {ApiError && <Error msg={ApiError} />}
            <div className='position-relative'>
              <TableComponent
                header={DataSourceTableFileds}
                data={dataSourceList}
                props={{
                  actionsType: 'data_sources',
                  type: 'data_sources',
                  actionsCallBack: actionsCallBack,
                }}
                totalRecords={totalDataSources}
                filterEnabled={false}
                loader={loader}
                isFieldSelectionEnabled={false}
                isPaginationEnabled={true}
                isActionsEnabled={true}
                isSelectionEnabled={true}
                selectionKey={'_id'}
                paginationFunction={getAllDataSources}
                onSelection={onRowsSelection}
                selectedRows={selectedRows}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DatabaseList;
