import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import personIcon from 'assets/images/icons/person.svg';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { CallAPI } from 'actions/General';
import selectArrow from 'assets/images/select-arrow.png';
import SelectField from 'components/form-components/SelectField';
import Loader from 'components/common-components/Loader';
import toast from 'react-hot-toast';

const AddAdmin = ({ setPromoteAdmin, data, adminProfile, userRole }) => {
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState(adminProfile ? adminProfile._id : '');
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState('');
  const [APIError, setAPIError] = useState('');
  const [profilesList, setProfilesList] = useState([]);

  const getAllProfilesList = async () => {
    setLoaderType('load');
    let result = await CallAPI(
      'FETCH_ALL_ADMIN_PROFILES',
      null,
      null,
      setLoader,
      setAPIError,
      null,
    );

    if (result.status) {
      let profileListing = [];
      result.data.map((singleProfile) => {
        if (!singleProfile.isOwnerProfile)
          profileListing.push({
            value: singleProfile._id,
            displayValue: singleProfile.profileName,
            isNested: false,
          });
      });
      setProfilesList(profileListing);
    }
    setLoaderType('');
  };
  useEffect(() => {
    getAllProfilesList();
  }, []);
  const updatePermissions = async () => {
    if (selectedProfile) {
      setLoaderType('save');
      const result = await CallAPI(
        'ASSIGN_PROFILE_TO_USER',
        {
          userId: data[0].id,
          profile: {
            id: selectedProfile,
            title: profilesList.filter(
              (singleProfile) => singleProfile.value === selectedProfile,
            )[0].displayValue,
          },
          userRole: 'admin',
        },
        null,
        setLoader,
        setAPIError,
        null,
      );

      setPromoteAdmin(false);
      setLoaderType('');
      if (result.status) {
        toast.success(t('alert_permission_updated'));
      } else {
        toast.error(t('alert_not_authorized_access_error'));
      }
    }
  };

  const handleChange = (name, value) => {
    setSelectedProfile(value);
  };

  return (
    <Modal
      show={true}
      contentClassName='alert-modal'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      keyboard={false}
    >
      <div className='alert-content p-4 d-flex flex-column gap-4 justify-content-center align-items-center'>
        {APIError && <Error msg={APIError} />}
        {loader && loaderType === 'load' ? (
          <Loader color='green-1' />
        ) : (
          <>
            <CloseButton onClick={() => setPromoteAdmin(false)} />
            <img src={personIcon} className='svg-black' style={{ height: '4rem' }} />
            {data.length > 0 && (
              <Fragment>
                {data.length > 1 ? (
                  <div className='d-flex flex-column gap-2 w-100 align-items-center'>
                    <label className='theme-text-black-1'>
                      {t('text_updating_permission_for')} ({data.length}) {t('text_users')}
                    </label>
                    <label className='theme-text-black-1 theme-size-1_2'>
                      {data
                        .slice(0, 2)
                        .map((user) => user.title)
                        .join(', ')}
                      {data.length > 2 ? ',...' : ''}
                    </label>
                  </div>
                ) : (
                  <div className='d-flex flex-column gap-2 w-100 align-items-center'>
                    <label className='theme-text-black-1'>
                      {t('text_updating_permission_for')} {t('user')}
                    </label>
                    <label className='theme-text-black-1 theme-size-1_2'>
                      {data[0].title} ({userRole || data[0].userRole})
                    </label>
                  </div>
                )}
              </Fragment>
            )}
            <SelectField
              label={
                selectedProfile && profilesList.length > 0
                  ? profilesList.filter(
                      (singleProfile) => singleProfile.value === selectedProfile,
                    )?.[0].displayValue
                  : 'select_select'
              }
              handleChange={handleChange}
              listValues={profilesList}
              icon={selectArrow}
              positionReversed
              isAutoClose={true}
            />
            <div className='d-flex gap-3'>
              <FormButton
                text='button_cancel'
                variant={'white-1'}
                onClick={() => setPromoteAdmin(false)}
              />
              <FormLoadingButton
                loading={loader}
                text='button_save'
                variant={'green-1'}
                onClick={updatePermissions}
                disabled={selectedProfile === '' || profilesList.length === 0}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
AddAdmin.propTypes = {
  setPromoteAdmin: PropTypes.func,
  adminProfile: PropTypes.object,
  userRole: PropTypes.string,
  data: PropTypes.array,
};
export default AddAdmin;
