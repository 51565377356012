import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'components/form-components/SelectField';
import DropDownArrowGreen from 'assets/images/icons/dropdown-arrow-green.svg';
import { CallAPI } from 'actions/General';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { notificationKey } from 'config/config';
import { useSelector } from 'react-redux';

function Export({ type, screenTab, additionalDetail = {} }) {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.language);

  let optionsList = [
    {
      value: 'csv',
      displayValue: 'select_csv',
    },
  ];
  const performAction = async (name, value) => {
    let API =
      type === 'user'
        ? 'EXPORT_USERS'
        : type === 'forms'
        ? 'EXPORT_FORMS'
        : type === 'form_design'
        ? 'EXPORT_FORM_DESIGN'
        : type === 'data_source'
        ? 'EXPORT_DATA_SOURCE'
        : type === 'submission'
        ? 'EXPORT_SUBMISSIONS'
        : '';

    let APIData = {
      screenTab: screenTab,
      exportTo: value,
      locale: lang,
      ...additionalDetail,
    };
    toast(t(notificationKey[type + '_' + screenTab + '_export_process_started']), {
      style: { borderRadius: '8px', background: '#000', color: '#fff' },
    });
    if (API !== '') {
      await CallAPI(API, APIData);
    }
  };
  return (
    <SelectField
      label={'text_export'}
      handleChange={performAction}
      listValues={optionsList}
      icon={DropDownArrowGreen}
      positionReversed
      isAutoClose={true}
      variant='green-2'
    />
  );
}
Export.propTypes = {
  type: PropTypes.string.isRequired,
  screenTab: PropTypes.string.isRequired,
  additionalDetail: PropTypes.object,
};
export default Export;
