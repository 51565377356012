import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

const ConnectionExportForm = ({ settings, errors, touched, handleChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <div>
      <TextField
        name='externalDbTableName'
        value={settings.externalDbTableName}
        displayName={'field_external_db_table_name'}
        placeholder={'field_external_db_table_name'}
        error={errors['settings.externalDbTableName']}
        touched={touched['settings.externalDbTableName']}
        handleChange={handleChange}
        classes='mb-3'
        isHorizontal={true}
        shrinkFieldOnly={true}
        horizontalLabelClass='min-width-176'
        disabled={disabled}
      />
      <div className='d-flex'>
        <label className='min-width-176'>{t('field_enable_for_export')}</label>
        <div>
          <SingleCheckBoxField
            name='enabledForExport'
            value={settings.enabledForExport}
            handleChange={handleChange}
            text={<span></span>}
          />
        </div>
      </div>
    </div>
  );
};

ConnectionExportForm.propTypes = {
  settings: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ConnectionExportForm;
