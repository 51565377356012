import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AlertModal from 'components/common-components/AlertModal';
import TextField from 'components/form-components/TextField';
import { useTranslation } from 'react-i18next';

function WidgetNameModal({ onUpdate, closeModal, title }) {
  const { t } = useTranslation();

  const [name, setName] = useState(title);
  return (
    <AlertModal
      show={true}
      closeModal={closeModal}
      title='alert_update_widget_name'
      actionButtons={[]}
    >
      <div className='w-100 d-flex gap-4 flex-column align-items-center'>
        <div className='w-50'>
          <TextField name='name' value={name} handleChange={(_, value) => setName(value)} />
        </div>
        <div className='d-flex w-100'>
          <div className='flex-grow-1 text-center'>
            <label
              className='theme-font-inter-medium theme-text-black-1 cursor-pointer theme-size-1_2'
              onClick={() => {
                closeModal();
              }}
            >
              {t('button_cancel')}
            </label>
          </div>
          <div className='flex-grow-1 text-center'>
            <label
              className='theme-font-inter-medium theme-text-green-1 cursor-pointer theme-size-1_2'
              onClick={() => {
                onUpdate(name);
                closeModal();
              }}
            >
              {t('button_update')}
            </label>
          </div>
        </div>
      </div>
    </AlertModal>
  );
}
WidgetNameModal.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
export default WidgetNameModal;
