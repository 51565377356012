import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorChangeModal from 'components/common-components/ColorChangeModal';

const ColorField = ({ name, displayName, value, shrink, handleChange, classes }) => {
  const shrinkField = shrink ? ' shrink-field' : '';
  const shrinkLabel = shrink ? ' shrink-label' : '';
  const [fontStyleModalOpen, setFontStyleModalOpen] = useState(false);
  const fieldFocused = fontStyleModalOpen ? ' focused' : '';

  return (
    <div className={`color-field-box${fieldFocused}${classes ? ' ' + classes : ''}`}>
      <label htmlFor={name} className={shrinkLabel}>
        {displayName}
      </label>
      <div className='position-relative'>
        <div className={'color-field' + shrinkField}>
          <div className='color-value'>{value}</div>
          <div
            className='color-box'
            onClick={() => setFontStyleModalOpen(!fontStyleModalOpen)}
            style={{ backgroundColor: value }}
          ></div>
        </div>

        {fontStyleModalOpen && (
          <ColorChangeModal
            name={name}
            closeModal={() => setFontStyleModalOpen(false)}
            styleProperties={{ [`${name}Color`]: value }}
            handleChange={handleChange}
            hideFontSizeOption
          />
        )}
      </div>
    </div>
  );
};

ColorField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

export default ColorField;
