import React, { useEffect, useState } from 'react';
import AuthorizeError from 'pages/AuthorizeError';
import TabLayout from 'components/tab-components/TabLayout';
import TabHeader from 'components/tab-components/TabHeader';
import TabBody from 'components/tab-components/TabBody';
import Loader from 'components/common-components/Loader';
import Error from 'components/Error';
import TableComponent from 'components/table-component/Table';
import { TrashFormTableFields, TrashSubmissionsTableFields, trashTabsList } from './utils';
import { cloneDeep } from 'lodash';
import { CallAPI } from 'actions/General';
import TrashListHeader from './TrashListHeader';
import { useSelector } from 'react-redux';

function Trash() {
  const auth = useSelector((state) => state.auth);
  const [hasPermissionToAccess, setHasPermissionToAccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const [selectedTab, setSelectedTab] = useState(trashTabsList[0]);
  const [tableHeader, setTableHeader] = useState([]);
  const [trashedData, setTrashedData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (auth.profile && auth.profile.role && auth.profile.role === 'owner') {
      setHasPermissionToAccess(true);
    } else {
      setHasPermissionToAccess(false);
    }
  }, [auth]);

  useEffect(() => {
    let selectedTabHeader =
      selectedTab.value === 'trash_forms'
        ? TrashFormTableFields
        : selectedTab.value === 'trash_submissions'
        ? TrashSubmissionsTableFields
        : [];

    setTableHeader(selectedTabHeader);
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      setSelectedRows([]);
      getAllTrashData();
    }
  }, [selectedTab, debouncedSearchText, hasPermissionToAccess]);

  const getAllTrashData = async (page = 1, limit = 10) => {
    if (hasPermissionToAccess) {
      setTrashedData([]);
      let callData = { page: page, limit: limit };

      if (debouncedSearchText) {
        callData.searchText = debouncedSearchText;
      }
      let APICall = 'GET_FORMS_TRASH';
      if (selectedTab && selectedTab.value === 'trash_submissions')
        APICall = 'GET_SUBMISSIONS_TRASH';
      let result = await CallAPI(APICall, callData, null, setLoader, setApiError, null);
      if (result.status) {
        let allTrashedData = [];
        if (selectedTab.value === 'trash_forms') {
          result.data.forms.map((singleTrashItem) => {
            allTrashedData.push({
              ...singleTrashItem,
              PublishedBy: singleTrashItem.publishedVersion
                ? singleTrashItem.publishedVersion.publishedBy.firstName +
                  ' ' +
                  singleTrashItem.publishedVersion.publishedBy.lastName
                : '',
              PublishedDate: singleTrashItem.publishedVersion
                ? singleTrashItem.publishedVersion.publishedDate
                : '',
            });
          });
        } else if (selectedTab.value === 'trash_submissions') {
          allTrashedData = result.data.submissions;
        }
        setTrashedData(allTrashedData);
        if (result.data.totalCount) setTotalRecords(result.data.totalCount);
      }
    }
  };
  const onRowsSelection = (name, value) => {
    let tempSelectedRows = cloneDeep(selectedRows);
    if (name === 'all') {
      trashedData.map((single_trash_item) => {
        if (value && tempSelectedRows.map((e) => e.id).indexOf(single_trash_item._id) === -1) {
          let selectedObj = null;
          if (selectedTab.value === 'trash_forms')
            selectedObj = {
              id: single_trash_item._id,
              title: single_trash_item.title,
            };
          else
            selectedObj = {
              id: single_trash_item._id,
              title: single_trash_item.formTitle,
              form_id: single_trash_item.form._id,
              form_status: single_trash_item.form.formStatus,
            };

          tempSelectedRows.push(selectedObj);
        }
      });
      if (!value) tempSelectedRows = [];
      setSelectedRows(tempSelectedRows);
    } else {
      if (value) {
        const selectedtrashItem = trashedData.find(
          (single_trash_item) => single_trash_item._id === name,
        );
        let selectedObj = null;
        if (selectedTab.value === 'trash_forms')
          selectedObj = {
            id: selectedtrashItem._id,
            title: selectedtrashItem.title,
          };
        else
          selectedObj = {
            id: selectedtrashItem._id,
            title: selectedtrashItem.formTitle,
            form_id: selectedtrashItem.form._id,
            form_status: selectedtrashItem.form.formStatus,
          };
        tempSelectedRows.push(selectedObj);
      } else tempSelectedRows = tempSelectedRows.filter((row) => row.id !== name);

      setSelectedRows(tempSelectedRows);
    }
  };
  const searchChangedViaDebounce = (name, value) => {
    setDebouncedSearchText(value);
  };
  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <>
          <TabLayout>
            <TabHeader
              tabList={trashTabsList}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <TabBody>
              {loader && <Loader color='green-1' type='modal-spinner' />}
              {apiError ? <Error msg={apiError} /> : <></>}
              <TrashListHeader
                // filterEnabled={filterEnabled}
                // setFilterEnabled={setFilterEnabled}
                // actionFunction={
                //   selectedTab.value === 'user'
                //     ? navigateToAddUser
                //     : selectedTab.value === 'admin'
                //     ? promoteUser
                //     : selectedTab.value === 'pending'
                //     ? getSortedUser
                //     : null
                // }
                selectedTab={selectedTab.value}
                // sorting={sorting}
                selectedRows={selectedRows}
                getAllTrashData={getAllTrashData}
                debounceFunc={searchChangedViaDebounce}
                searchText={searchText}
                setSearchText={setSearchText}
                // filteringData={filteringDataState}
                // setFilteringData={updateFilteringData}
                // actionAllowedForSelectedRows={actionAllowedForSelectedRows}
              />
              <TableComponent
                debounceFunc={searchChangedViaDebounce}
                header={tableHeader}
                data={trashedData}
                props={{
                  actionsCallBack: getAllTrashData,
                  actionsType: selectedTab.value,
                }}
                totalRecords={totalRecords}
                filterEnabled={false}
                loader={loader}
                isFieldSelectionEnabled={false}
                isPaginationEnabled={true}
                isActionsEnabled={true}
                isSelectionEnabled={true}
                selectionKey={'_id'}
                paginationFunction={getAllTrashData}
                onSelection={onRowsSelection}
                selectedRows={selectedRows}
              />
            </TabBody>
          </TabLayout>
        </>
      )}
    </>
  );
}

export default Trash;
