import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/form-components/TextField';
import SelectField from 'components/form-components/SelectField';
import { APIMethod, ApiParamsTypes, AuthType } from '../utils';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import { set } from 'lodash';

function ExternalApiFields({ properties, handleChange, errors, touched, isHorizontal, type }) {
  const updateAuthBody = (key, name, value) => {
    let allKeyLevels = key.split('.');
    set(properties, key + '.' + name, value);
    handleChange(allKeyLevels[0], properties[allKeyLevels[0]]);
  };

  const APIMethodMap = Object.fromEntries(APIMethod.map((v) => [v.value, v.displayValue]));
  const ApiParamsTypesMap = Object.fromEntries(
    ApiParamsTypes.map((v) => [v.value, v.displayValue]),
  );
  const AuthTypeMap = Object.fromEntries(AuthType.map((v) => [v.value, v.displayValue]));

  return (
    <Fragment>
      <TextField
        name={'apiUrl'}
        value={properties['apiUrl']}
        displayName={'form_field_api_url'}
        handleChange={handleChange}
        error={errors.apiUrl}
        touched={touched.apiUrl}
        classes='mb-2'
        {...(isHorizontal
          ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
          : { shrink: true })}
      />
      <SelectField
        placeholder={'form_field_api_method'}
        label={APIMethodMap[properties.apiMethod] ?? 'select_select'}
        listValues={APIMethod}
        icon={arrowDownIcon}
        handleChange={handleChange}
        selectedValues={[]}
        name={'apiMethod'}
        isAutoClose={true}
        variant='white-1'
        menuClasses={'w-100'}
        filedClasses='w-100 mb-2'
        {...(isHorizontal ? { isHorizontal: true } : { shrink: true })}
      />
      {type === 'field-external-api' && (
        <Fragment>
          <SelectField
            placeholder={'form_field_api_param_type'}
            label={ApiParamsTypesMap[properties.apiParamType] ?? 'select_select'}
            listValues={ApiParamsTypes}
            icon={arrowDownIcon}
            handleChange={handleChange}
            selectedValues={[]}
            name={'apiParamType'}
            isAutoClose={true}
            variant='white-1'
            menuClasses={'w-100'}
            filedClasses='w-100 mb-2'
            {...(isHorizontal ? { isHorizontal: true } : { shrink: true })}
          />
          <TextField
            name={'apiParamValue'}
            value={properties['apiParamValue']}
            displayName={'form_field_api_param_value'}
            handleChange={handleChange}
            error={errors.apiParamValue}
            touched={touched.apiParamValue}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
          <TextField
            name={'statusFieldBinding'}
            value={properties['statusFieldBinding']}
            displayName={'form_field_status_field_binding'}
            handleChange={handleChange}
            error={errors.statusFieldBinding}
            touched={touched.statusFieldBinding}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
        </Fragment>
      )}

      <SelectField
        placeholder={'form_field_api_auth_type'}
        label={AuthTypeMap[properties.auth.authType] ?? 'select_select'}
        listValues={AuthType}
        icon={arrowDownIcon}
        handleChange={(name, value) => {
          updateAuthBody('auth', name, value);
        }}
        selectedValues={[]}
        name={'authType'}
        isAutoClose={true}
        variant='white-1'
        menuClasses={'w-100'}
        filedClasses='w-100 mb-2'
        {...(isHorizontal ? { isHorizontal: true } : { shrink: true })}
      />
      {properties.auth.authType === 'bearer' ? (
        <TextField
          name={'authValue'}
          value={properties['auth']['authValue']}
          displayName={'form_field_api_auth_token'}
          handleChange={(name, value) => updateAuthBody('auth', name, value)}
          error={errors['auth.authValue']}
          classes='mb-2'
          {...(isHorizontal
            ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
            : { shrink: true })}
        />
      ) : properties.auth.authType === 'api_auth' ? (
        <Fragment>
          <TextField
            name={'authApiUrl'}
            value={properties['auth']['authApiUrl']}
            displayName={'form_field_api_auth_url'}
            handleChange={(name, value) => updateAuthBody('auth', name, value)}
            error={errors['auth.authApiUrl']}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
          <SelectField
            placeholder={'form_field_api_auth_method'}
            label={APIMethodMap[properties.auth.authApiMethod] ?? 'select_select'}
            listValues={APIMethod}
            icon={arrowDownIcon}
            handleChange={(name, value) => {
              updateAuthBody('auth', name, value);
            }}
            selectedValues={[]}
            name={'authApiMethod'}
            isAutoClose={true}
            variant='white-1'
            menuClasses={'w-100'}
            filedClasses='w-100 mb-2'
            {...(isHorizontal ? { isHorizontal: true } : { shrink: true })}
          />
          <TextField
            name={'client_name'}
            value={properties['auth']['parameters']['client_name']}
            displayName={'form_field_api_auth_client_name'}
            handleChange={(name, value) => updateAuthBody('auth.parameters', name, value)}
            error={errors['auth.parameters.client_name']}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
          <TextField
            name={'client_id'}
            value={properties['auth']['parameters']['client_id']}
            displayName={'form_field_api_auth_client_id'}
            handleChange={(name, value) => updateAuthBody('auth.parameters', name, value)}
            error={errors['auth.parameters.client_id']}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
          <TextField
            name={'client_secret'}
            value={properties['auth']['parameters']['client_secret']}
            displayName={'form_field_api_auth_client_secret'}
            handleChange={(name, value) => updateAuthBody('auth.parameters', name, value)}
            error={errors['auth.parameters.client_secret']}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
          <TextField
            name={'responseAuthKeyName'}
            value={properties['auth']['responseAuthKeyName']}
            displayName={'form_field_api_auth_response_auth_key_name'}
            handleChange={(name, value) => updateAuthBody('auth', name, value)}
            error={errors['auth.responseAuthKeyName']}
            classes='mb-2'
            {...(isHorizontal
              ? { isHorizontal: true, shrinkFieldOnly: true, horizontalLabelClass: 'min-width-176' }
              : { shrink: true })}
          />
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
}
ExternalApiFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  isHorizontal: PropTypes.bool,
  type: PropTypes.string.isRequired,
};
export default ExternalApiFields;
