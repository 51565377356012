import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import crossIcon from '../../assets/images/icons/cross.svg';
import checkIcon from '../../assets/images/icons/check.svg';
import checkGreyIcon from '../../assets/images/icons/check-grey.svg';

const ToggleSwitch = ({ empty, name, value, disabled, handleChange }) => {
  return (
    <Fragment>
      {!empty ? (
        <div
          className={`toggle-switch toggle-switch-${disabled ? 'disabled' : value ? 'on' : 'off'}`}
          {...(!disabled && handleChange && { onClick: () => handleChange(name, !value) })}
        >
          {!empty && (
            <div className='toggler'>
              <img
                className={!disabled && !value ? 'svg-white' : ''}
                src={disabled ? checkGreyIcon : value ? checkIcon : crossIcon}
              />
            </div>
          )}
        </div>
      ) : (
        <div className='toggle-switch'></div>
      )}
    </Fragment>
  );
};

ToggleSwitch.propTypes = {
  empty: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default ToggleSwitch;
