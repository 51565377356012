import React from 'react';

function AuthorizeError() {
  return <div style={style}>You are not authorize to access this page</div>;
}
const style = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: '3rem',
  color: '#38cb89',
};
export default AuthorizeError;
