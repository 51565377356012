import React from 'react';
import PropTypes from 'prop-types';

const DataSourceFieldPreview = ({ field, dataSourcesList }) => {
  const selectedDataSource = dataSourcesList.find(
    (ds) => ds.value === field.properties?.basicProperties?.dataSource ?? '',
  );
  const selectedDisplayColumn =
    selectedDataSource?.columns.find(
      (c) => c.value === (field.properties?.basicProperties?.displayColumn ?? ''),
    ) ?? null;

  const selectedValueColumn =
    selectedDataSource?.columns.find(
      (c) => c.value === (field.properties?.basicProperties?.valueColumn ?? ''),
    ) ?? null;

  return (
    <div className='d-flex flex-column gap-2'>
      <div className='border-grey-1-h-1 rounded-sm d-flex justify-content-center align-items-center p-2'>
        <label>{selectedDataSource?.displayValue ?? 'Data Source'}</label>
      </div>
      <div className='d-flex gap-3'>
        <div className='border-grey-1-h-1 rounded-sm d-flex justify-content-center align-items-center p-2 w-50'>
          <label>{selectedDisplayColumn?.displayValue ?? 'Display Column'}</label>
        </div>
        <div className='border-grey-1-h-1 rounded-sm d-flex justify-content-center align-items-center p-2 w-50'>
          <label>{selectedValueColumn?.displayValue ?? 'Value Column'}</label>
        </div>
      </div>
    </div>
  );
};

DataSourceFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
};

export default DataSourceFieldPreview;
