import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { toast } from 'react-hot-toast';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import copyIcon from 'assets/images/icons/copy.svg';
import linkBlackIcon from 'assets/images/icons/link-black.svg';
import linkGreenIcon from 'assets/images/icons/link-green.svg';
import qrCodeIcon from 'assets/images/icons/qr-code.svg';
import shareMobileIcon from 'assets/images/icons/share-on-mobile.svg';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectField from 'components/form-components/SelectField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import { CallAPI } from 'actions/General';
import { QRCodeCanvas } from 'qrcode.react';

const AddUserByQR = ({ fieldList }) => {
  const { t } = useTranslation();

  const defaultValues = { firstName: true, lastName: true, mobileNumber: true };

  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [inviteLink, setInviteLink] = useState('');
  const [modified, setModified] = useState(false);
  const [notifyChecked, setNotifyChecked] = useState(false);
  const [groupListing, setGroupListing] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleChange = (name, value) => {
    setModified(true);
    setValues({ ...values, [name]: value });
  };

  const handleNotifyCheck = (_, value) => {
    setModified(true);
    setNotifyChecked(value);
  };

  const handleCheckFieldChange = (name, value) => {
    setModified(true);
    if (value) {
      setValues({ ...values, [name]: false });
    } else {
      const currentValues = { ...values };
      delete currentValues[name];
      setValues(currentValues);
      if (name === 'assign_groups') setSelectedGroups([]);
    }
  };
  const handleGroupChange = (_, value) => {
    setModified(true);
    setSelectedGroups([value]);
  };

  const GetInviteLink = async () => {
    let result = await CallAPI('GET_INVITE_LINK', {}, null, setLoader, null, null);
    if (result.status) {
      let selectedValues = cloneDeep(values);
      result.data.requiredFields.map((singleRequiredField) => {
        selectedValues[singleRequiredField.fieldName] = singleRequiredField.required;
        if (singleRequiredField.fieldName === 'assign_groups')
          setSelectedGroups(singleRequiredField.groups);
      });
      setInviteLink(result.data.link);
      setValues(selectedValues);
      setNotifyChecked(
        result.data.notifyMeOnJoinCompany ? result.data.notifyMeOnJoinCompany : false,
      );
    }
  };
  const UpdateInviteLink = async () => {
    let requiredFields = [];
    let requiredKeys = Object.keys(values);
    let requiredValues = Object.values(values);
    requiredKeys.map((singleKey, index) => {
      requiredFields.push({
        fieldName: singleKey,
        required: requiredValues[index],
        ...(singleKey === 'assign_groups' && { groups: selectedGroups }),
      });
    });
    let result = await CallAPI(
      'UPDATE_INVITE_LINK',
      { notifyMeOnJoinCompany: notifyChecked, requiredFields },
      null,
      setLoader,
      null,
      null,
    );
    if (result.status) {
      GetInviteLink();
    }
  };

  const shortenLink = (link) => {
    if (link.length <= 40) return link;
    return `${link.slice(0, 40)}...`;
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    toast(t('Copied link to clipboard'), {
      style: { borderRadius: '8px', background: '#000', color: '#fff' },
    });
  };

  const sendLink = async () => {
    await CallAPI('SEND_INVITE_LINK_TO_MOBILE', null, null, null, null, null);
  };

  const sendLinkToMobile = () => {
    toast.promise(sendLink(), {
      loading: 'Sending...',
      success: <span>{t('Link sent to your mobile')}</span>,
      error: <span>{t('Could not send link')}</span>,
    });
  };

  useEffect(() => {
    GetInviteLink();
  }, []);

  const downloadQRCode = () => {
    let qrCodeURL = document
      .getElementById('qrCodeElement')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.getElementById('qrCodeElementDownload');
    downloadLink.href = qrCodeURL;
    downloadLink.download = 'QRCode.png';
    downloadLink.click();
  };

  const getGroupListing = async () => {
    const result = await CallAPI('GET_ALL_GROUPS', {}, null, null, null, null);
    if (result.status) {
      setGroupListing(result.data);
    }
  };

  useEffect(() => {
    getGroupListing();
  }, []);

  const groupValues = groupListing.map((group) => ({
    value: group._id,
    displayValue: group.groupName,
  }));
  const groupValuesMap = Object.fromEntries(groupValues.map((v) => [v.value, v.displayValue]));

  return (
    <div className='add-user-qr-section'>
      <label className='p-3'>{t('text_invite_user_info')}</label>
      <div className='d-flex'>
        <div className='flex-grow-1'>
          <div className='header-bar'>
            <img src={linkBlackIcon} />
            <label>{t('text_invite_link')}</label>
          </div>
          <div className='d-flex flex-column gap-2 p-3'>
            <div className='d-flex gap-2 flex-wrap mb-3'>
              <FormButton text={shortenLink(inviteLink)} variant='white-1' icon={linkGreenIcon} />
              <FormButton
                text='button_copy'
                variant='white-1'
                icon={copyIcon}
                onClick={copyLinkToClipboard}
              />
              <FormButton
                text='button_send_link_mobile'
                variant='white-1'
                icon={shareMobileIcon}
                onClick={sendLinkToMobile}
              />
            </div>
            <SingleCheckBoxField
              name='check'
              value={notifyChecked}
              handleChange={handleNotifyCheck}
              text={<span>{t('field_notify_user_request')}</span>}
              classes='w-100'
            />
          </div>
          <div className='p-3 text-center'>
            <label>{t('text_select_user_fields')}</label>
          </div>
          <div className='theme-background-white-2 d-flex px-5 py-2 justify-content-center align-items-center'>
            <div className='col-9 col-xl-7'>
              <label className='theme-size-1'>{t('text_table_of_content')}</label>
            </div>
            <div className='col-3 col-xl-5'>
              <label className='theme-size-1'>{t('text_required')}</label>
            </div>
          </div>

          <div className='field-check-list px-5 py-3'>
            {fieldList.map((field, index) => (
              <div key={index} className='d-flex'>
                <div className='col-9 col-xl-7'>
                  <SingleCheckBoxField
                    name={field.name}
                    value={Object.keys(values).includes(field.name)}
                    disabled={field.mandatory}
                    text={<span>{t(field.displayName)}</span>}
                    handleChange={handleCheckFieldChange}
                    classes='mb-3'
                  />
                </div>
                <div className='col-3 col-xl-5'>
                  <ToggleSwitch
                    name={field.name}
                    value={values[field.name] ?? false}
                    disabled={field.mandatory}
                    empty={!Object.keys(values).includes(field.name)}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            ))}
            {Object.keys(values).includes('assign_groups') && (
              <SelectField
                label={groupValuesMap[selectedGroups[0]] ?? 'text_select_groups'}
                listValues={groupValues}
                icon={arrowDownIcon}
                handleChange={handleGroupChange}
                selectedValues={[]}
                name='groups'
                isAutoClose={true}
                variant='white-1'
                classes='width-max-content'
                menuClasses={'width-max-content'}
              />
            )}
          </div>

          <div className='d-flex justify-content-end'>
            <FormLoadingButton
              text='button_save_changes'
              variant='green-1'
              loading={loader}
              disabled={!modified}
              classes='mx-5 my-3'
              onClick={UpdateInviteLink}
            />
          </div>
        </div>
        <div className='qr-scan-section'>
          <div className='header-bar'>
            <img src={qrCodeIcon} />
            <label>{t('text_qr_code')}</label>
          </div>
          <div className='d-flex gap-4 flex-column align-items-center section'>
            <label className='theme-size-1_8 theme-font-inter-medium theme-text-black-1 text-center'>
              {t('text_company_app')}
            </label>
            <label className='theme-text-black-1 text-center'>{t('text_scan_info')}</label>

            <div className='HpQrcode'>
              <QRCodeCanvas
                id='qrCodeElement'
                size={256}
                style={{ height: 'auto', width: '100%', minWidth: '150px', maxWidth: '250px' }}
                value={inviteLink}
              />
            </div>
            <a download className='d-none' id='qrCodeElementDownload'></a>
            <FormButton onClick={downloadQRCode} text='button_download' variant='green-1' />
          </div>
        </div>
      </div>
    </div>
  );
};

AddUserByQR.propTypes = {
  fieldList: PropTypes.array.isRequired,
};

export default AddUserByQR;
