import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StatsCard = ({ icon, statNumber, title }) => {
  const { t } = useTranslation();

  return (
    <div className='attendance-stat-card'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='grey-circle'>
          <img src={icon} />
        </div>
        <label className='theme-size-1_5 theme-font-inter-semi-bold theme-text-black-1'>
          {statNumber}
        </label>
      </div>

      <label className='theme-size-1 theme-font-inter-regular theme-text-black-1'>{t(title)}</label>
    </div>
  );
};

StatsCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  statNumber: PropTypes.number.isRequired,
};
export default StatsCard;
