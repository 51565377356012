export const submissionsHeader = [
  {
    name: '',
    isSelected: true,
    displayName: 'No',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'indexed',
  },
  {
    name: 'userName',
    isSelected: true,
    displayName: 'User Name',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    populateFunc: 'databaseUpdatedBy',
  },
  {
    name: 'submitted',
    isSelected: true,
    displayName: 'Date Submitted',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'createdAtTime',
    populateFunc: 'populateDate',
  },
  {
    name: 'time',
    isSelected: true,
    displayName: 'Time',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'createdAtTime',
    populateFunc: 'populateTime',
  },
  {
    name: 'referenceNumber',
    isSelected: true,
    displayName: 'Reference Number',
    isFilterAble: false,
    type: 'text',
    tableType: 'user',
    associatedKey: 'referenceNumber',
  },
];
export const stringOperators = [
  { value: 'equal', displayValue: 'Equal' },
  { value: 'not_equal', displayValue: 'Not Equal' },
  { value: 'contains', displayValue: 'Contains' },
  // { value: 'starts_with', displayValue: 'StartsWith' },
  // { value: 'ends_with', displayValue: 'EndsWith' },
];
export const numberOperators = [
  { value: 'equal', displayValue: 'Equals' },
  { value: 'not_equal', displayValue: 'NotEquals' },
  { value: 'greater_than', displayValue: 'Greater Than' },
  { value: 'greater_than_equal_to', displayValue: 'GreaterThanEqualTo' },
  { value: 'less_than', displayValue: 'LessThan' },
  { value: 'less_than_equal_to', displayValue: 'LessThanEqualTo' },
];
export const singleChoiceOperators = [
  { value: 'equal', displayValue: 'Equals' },
  { value: 'not_equal', displayValue: 'NotEquals' },
];
export const multiChoiceOperators = [
  { value: 'in_array', displayValue: 'In Array' },
  { value: 'not_in_array', displayValue: 'Not In Array' },
];
export const conditionOperators = [
  { value: 'op_and', displayValue: 'AND' },
  { value: 'op_or', displayValue: 'OR' },
];

export const getApprovalStatusVariant = (approvalStatus) =>
  approvalStatus === 'ready'
    ? 'green-1'
    : approvalStatus === 'not_ready'
    ? 'red-1'
    : approvalStatus === 'incomplete'
    ? 'orange-2'
    : 'white-1';
