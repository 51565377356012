import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { uniq } from 'lodash';
import FormButton from 'components/form-components/FormButton';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';
import editSquareGreyIcon from 'assets/images/icons/edit-square-grey.svg';
import { CallAPI } from 'actions/General';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import { isPermissionAvailable } from 'config/permissionUtils';
import { isRTL, permissionKeys } from 'config/config';

function GroupActions({ row, ids, isActionButton, actionsCallBack, actionData }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [allSegments] = useState(actionData.allSegmentsList ? actionData.allSegmentsList : []);
  const [isOpen, setIsOpen] = useState('');
  const [loader, setLoader] = useState(false);

  const moveToSegment = async (targetSegment) => {
    let sourceSegmentId = actionData.selectedRowsSegmentsList[0];
    let sourceSegment = { id: sourceSegmentId, title: 'Unassigned Groups' };
    if (targetSegment)
      actionData.allSegmentsList.map((singleSegment) => {
        if (sourceSegmentId === singleSegment.id) {
          sourceSegment = singleSegment;
        }
      });
    else {
      let sourceSegmentObj = actionData.allSegmentsList.filter(
        (singleSegment) => singleSegment.id === sourceSegmentId,
      );
      sourceSegment = {
        id: sourceSegmentId,
        title: sourceSegmentObj.length > 0 ? sourceSegmentObj[0].title : 'Unassigned Groups',
      };
      targetSegment = { id: actionData.defaultSegmentId, title: 'Unassigned Groups' };
    }
    await CallAPI(
      'MOVE_TO_SEGMENT',
      {
        sourceSegment,
        targetSegment,
        groups: ids,
      },
      null,
      null,
      null,
      null,
    );
    setShowDeleteConfirm(false);
    actionsCallBack();
  };

  const confirmDeletion = () => setShowDeleteConfirm(true);

  const deleteGroups = async () => {
    await CallAPI(
      'DELETE_GROUP',
      {
        groupSegmentId: actionData.selectedRowsSegmentsList[0],
        groups: ids,
      },
      null,
      setLoader,
      null,
      null,
    );
    setShowDeleteConfirm(false);
    actionsCallBack();
  };

  useEffect(() => {
    const formActionsList = [
      {
        name: 'action_move_to_segment',
        permission: isPermissionAvailable(permissionKeys.employees_groups_create),
        hasChild: allSegments,
        type: 'add_folder',
      },
      {
        name: 'action_move_to_default_segment',
        permission: isPermissionAvailable(permissionKeys.employees_groups_create),
        action: () => moveToSegment(),
      },
      {
        permission: isPermissionAvailable(permissionKeys.employees_groups_delete),
        name: 'action_delete',
        action: confirmDeletion,
      },
    ];

    if (
      actionData.selectedRowsSegmentsList &&
      uniq(actionData.selectedRowsSegmentsList).length < 2
    ) {
      let formActionListUpdated = formActionsList.filter((item) => item.permission === true);
      setDisplayedMenu(formActionListUpdated);
    } else {
      setDisplayedMenu([]);
    }
  }, [ids]);

  const updateGroup = () => {
    let selectedUsers = [];
    row.users.map((singleSelectedUser) => {
      selectedUsers.push({
        id: singleSelectedUser._id,
        name: singleSelectedUser.lastName + ' ' + singleSelectedUser.firstName,
      });
    });
    navigate(`/groups/update-group`, {
      relative: 'path',
      state: {
        type: 'update',
        groupID: row._id,
        groupName: row.groupName,
        selectedDataList: selectedUsers,
        segmentId: actionData.groupSegmentId,
        allowJoinByLink: row.allowJoinByLink,
      },
    });
  };

  return (
    <div className='dropdown-actions'>
      {isActionButton ? (
        isPermissionAvailable(permissionKeys.employees_groups_create) && (
          <FormButton
            text='button_manage'
            variant='green-1'
            onClick={updateGroup}
            icon={editSquareGreyIcon}
            iconClasses='svg-white'
          />
        )
      ) : (
        <DropdownButton
          disabled={uniq(actionData.selectedRowsSegmentsList).length === 1 ? false : true}
          as={ButtonGroup}
          drop='bottom'
          align={isRTL() ? 'end' : 'start'}
          variant='secondary'
          title={
            <>
              <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
            </>
          }
        >
          {displayedMenu.map((button, index) =>
            button.name ? (
              <div key={index}>
                {button.hasChild ? (
                  <DropdownButton
                    onMouseEnter={() => setIsOpen(button.name)}
                    onMouseLeave={() => setIsOpen(false)}
                    show={isOpen === button.name ? true : false}
                    drop='end'
                    variant='secondary'
                    className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                    title={<div className='theme-text-grey-1 child-dropdown'>{t(button.name)}</div>}
                  >
                    {button.hasChild.map((childBtn, childIndex) => (
                      <Dropdown.Item
                        key={childIndex}
                        onClick={() => {
                          button.type && button.type === 'add_folder'
                            ? moveToSegment(childBtn)
                            : null;
                        }}
                        className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                      >
                        {t(childBtn.title)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                ) : (
                  <Dropdown.Item
                    onClick={button.action}
                    key={index}
                    className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                  >
                    {t(button.name)}
                  </Dropdown.Item>
                )}
              </div>
            ) : (
              <Dropdown.Divider key={index} />
            ),
          )}
        </DropdownButton>
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          show={showDeleteConfirm}
          title={t('alert_group_deletion_title')}
          message={t('alert_group_deletion_message')}
          actionList={[
            {
              color: 'black-1',
              text: t('button_cancel'),
              onClick: () => setShowDeleteConfirm(false),
            },
            {
              color: 'red-1',
              text: t('button_delete'),
              loading: loader,
              onClick: () => deleteGroups(),
            },
          ]}
        />
      )}
    </div>
  );
}
GroupActions.propTypes = {
  actionData: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  ids: PropTypes.array.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
};
export default GroupActions;
