import React from 'react';
import PropTypes from 'prop-types';
import RangeField from '../../components/form-components/RangeField';
import { employees, businessesList } from './utils';
import IndustryField from '../../components/form-components/IndustryField';
import { useTranslation } from 'react-i18next';
const AddCompanyStep2 = ({ handleChange, touched, errors, values }) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex flex-column w-100'>
      <p
        className='text-left theme-font-inter-semi-bold theme-size-1_2 theme-text-black-1 mb-2'
        onClick={handleChange}
      >
        {t('text_range_of_employees')} <span className='theme-text-red-1'>*</span>
      </p>
      <div className='d-flex gap-3 flex-wrap'>
        {employees.map((singleEmployee, i) => {
          return (
            <RangeField
              key={i}
              classes={`theme-font-inter-semi-bold theme-size-1 cursor-pointer  ${
                values.employees === singleEmployee.value
                  ? 'text-white theme-background-green-1 border-0'
                  : 'theme-text-grey-1'
              }`}
              name='employees'
              value={singleEmployee.value}
              displayName={singleEmployee.displayName}
              handleChange={handleChange}
            />
          );
        })}
      </div>
      {!touched.employees && errors.employees && (
        <p className='form-field-error'>{t(errors.employees)}</p>
      )}
      <p
        className='text-left theme-font-inter-semi-bold theme-size-1_2 theme-text-black-1 my-4'
        onClick={handleChange}
      >
        {t('text_business_industry')} <span className='theme-text-red-1'>*</span>
      </p>
      <div className='d-flex gap-3 flex-wrap mb-3'>
        {businessesList.map((singleBsinesses, i) => {
          return (
            <IndustryField
              key={i}
              classes={`theme-font-inter-semi-bold theme-size-1 cursor-pointer`}
              name='businesses'
              value={singleBsinesses.value}
              selectedValue={values.businesses}
              isSelected={values.businesses.indexOf(singleBsinesses.value) > -1}
              icon={singleBsinesses.icon}
              displayName={singleBsinesses.displayName}
              handleChange={handleChange}
            />
          );
        })}
      </div>
      {!touched.businesses && errors.businesses && (
        <p className='form-field-error'>{t(errors.businesses)}</p>
      )}
    </div>
  );
};
AddCompanyStep2.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
};
export default AddCompanyStep2;
