import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import { useNavigate } from 'react-router-dom';

function VersionActions({ ids }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [displayedMenu, setDisplayedMenu] = useState([]);

  useEffect(() => {
    const formActionsList = [
      {
        name: 'action_view_inspections',
        action: () =>
          navigate('/submissions', {
            relative: 'path',
            state: { type: 'edit', formId: ids[0].formId, status: 'live', version: ids[0].id },
          }),
      },
    ];
    setDisplayedMenu(formActionsList);
  }, [ids]);

  return (
    <div className='dropdown-actions'>
      {displayedMenu.length > 0 && (
        <DropdownButton
          disabled={ids.length > 0 ? false : true}
          as={ButtonGroup}
          drop='bottom'
          variant='secondary'
          title={<img src={dotsIcon} alt='Loading...' />}
        >
          {displayedMenu.map((button, index) => (
            <div key={index}>
              <Dropdown.Item
                onClick={button.action}
                key={index}
                className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
              >
                {t(button.name)}
              </Dropdown.Item>
            </div>
          ))}
        </DropdownButton>
      )}
    </div>
  );
}
VersionActions.propTypes = {
  ids: PropTypes.array.isRequired,
};

export default VersionActions;
