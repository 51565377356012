import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import closeIcon from 'assets/images/icons/cross-grey.svg';
import TextField from 'components/form-components/TextField';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Error from 'components/Error';
import DateField from 'components/form-components/DateField';
import { CallAPI } from 'actions/General';
import { updateContactSchema } from '../utils';

const UpdateUserDetail = ({ userDetails, type, handleClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    company: {
      title: '',
      department: '',
      branch: '',
      employmentStartDate: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setValues({
      contact: userDetails.contact,
      company: {
        ...userDetails.company,
        employmentStartDate: new Date(userDetails.company.employmentStartDate)
          .toISOString()
          .slice(0, 10),
      },
    });
  }, [userDetails]);

  const handleCompanyChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, company: { ...values.company, [field]: value } });
  };

  const handleContactChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, contact: { ...values.contact, [field]: value } });
  };

  const submit = async () => {
    try {
      if (type === 'contact')
        await updateContactSchema.validate(values.contact, { abortEarly: false });
      let result = await CallAPI(
        'UPDATE_USER_DETAILS',
        type === 'contact'
          ? { ...values.contact, userId: userDetails.user_id }
          : { ...values.company, userId: userDetails.user_id },
        null,
        setLoading,
        setApiError,
        null,
      );
      if (result.status) {
        handleClose();
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  return (
    <div className='modal custom-modal'>
      <div className='theme-width-633 mx-3 position-relative'>
        <div
          className='position-absolute top-0 end-0 mx-3 my-2 cursor-pointer'
          onClick={handleClose}
        >
          <img src={closeIcon} />
        </div>
        <div className='head'>
          <h1 className='theme-text-black-1 theme-font-inter-semi-bold theme-size-1_5 text-capitalize'>
            {t(`Update ${type}`)}
          </h1>
        </div>
        <hr />
        <div className='body mb-2'>
          {apiError ? <Error msg={apiError} /> : <></>}
          {type === 'contact' ? (
            <>
              <TextField
                name='firstName'
                displayName='FirstName'
                placeholder='FirstName'
                value={values?.contact.firstName}
                error={errors.firstName}
                touched={touched.firstName}
                handleChange={handleContactChange}
                classes='mt-1'
              />

              <TextField
                name='lastName'
                displayName='LastName'
                placeholder='LastName'
                value={values?.contact.lastName}
                error={errors.lastName}
                touched={touched.lastName}
                handleChange={handleContactChange}
                classes='mt-3'
              />

              <TextField
                name='email'
                displayName='Email'
                placeholder='Email'
                value={values?.contact.email}
                error={errors.email}
                touched={touched.email}
                handleChange={handleContactChange}
                classes='mt-3'
              />

              <TextField
                disabled
                name='phone'
                displayName='Phone number'
                placeholder='Phone number'
                value={values?.contact.phone}
                handleChange={handleContactChange}
                classes='mt-3'
              />
            </>
          ) : (
            <>
              <TextField
                name='title'
                displayName='Title'
                placeholder='Title'
                value={values?.company.title}
                handleChange={handleCompanyChange}
                classes='mt-1'
              />
              <TextField
                name='department'
                displayName='Department'
                placeholder='Department'
                value={values?.company.department}
                handleChange={handleCompanyChange}
                classes='mt-3'
              />
              <TextField
                name='branch'
                displayName='Branch'
                placeholder='Branch'
                value={values?.company.branch}
                handleChange={handleCompanyChange}
                classes='mt-3'
              />
              <DateField
                disabled
                name='employmentStartDate'
                displayName='EmployementStartDate'
                placeholder='EmployementStartDate'
                value={values.company.employmentStartDate ?? ''}
                handleChange={handleCompanyChange}
                classes='mt-3'
              />
            </>
          )}

          <FormLoadingButton
            loading={loading}
            onClick={submit}
            text='Submit'
            variant='green-1'
            classes='w-auto rounded-full theme-size-1_1 theme-font-jakartaSans-medium mt-4'
          />
        </div>
      </div>
    </div>
  );
};

UpdateUserDetail.propTypes = {
  userDetails: PropTypes.any,
  handleClose: PropTypes.func,
  type: PropTypes.string.isRequired,
};

export default UpdateUserDetail;
