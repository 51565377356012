import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabBody from '../../components/tab-components/TabBody';
import TabHeader from '../../components/tab-components/TabHeader';
import TabLayout from '../../components/tab-components/TabLayout';
import UserListHeader from './UserListHeader';
import { AdminTableFields, UserTableFields, PendingUserTableFields } from './utils';
import AddAdmin from './AddAdmin';
import TableComponent from 'components/table-component/Table';
import { CallAPI } from 'actions/General';
import Loader from 'components/common-components/Loader';
import Error from 'components/Error';
import { cloneDeep, omitBy } from 'lodash';
import { UserTabsList } from './utils';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import AuthorizeError from 'pages/AuthorizeError';
import { useSelector } from 'react-redux';
import AlertModal from 'components/common-components/AlertModal';
import { useTranslation } from 'react-i18next';
import { setUserCustomizedTableHeader } from 'actions/ReduxActions';

const UserList = () => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [sorting, setSorting] = useState('all');
  const [promoteAdmin, setPromoteAdmin] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(UserTabsList[0]);
  const [tableHeader, setTableHeader] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionAllowedForSelectedRows, setActionAllowedForSelectedRows] = useState(true);
  const [filteringDataState, setFilteringDataState] = useState({});
  const [filteringData, setFilteringData] = useState({});
  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.employees_users_read),
  );
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    let selectedTabHeader =
      selectedTab.value === 'admin'
        ? AdminTableFields
        : selectedTab.value === 'user' || selectedTab.value === 'archived'
        ? UserTableFields
        : selectedTab.value === 'pending'
        ? PendingUserTableFields
        : [];

    let selectedTabVal = selectedTab.value === 'archived' ? 'user_archived' : selectedTab.value;
    if (auth.customizedTableHeader && auth.customizedTableHeader[selectedTabVal]) {
      selectedTabHeader = auth.customizedTableHeader[selectedTabVal];
    }
    setTableHeader(selectedTabHeader);
    let filterAbleRows = {};
    selectedTabHeader.map((singleColumn) => {
      if (singleColumn.isFilterAble) {
        filterAbleRows[singleColumn.name] = '';
      }
    });
    setFilteringDataState({ search: '', ...filterAbleRows });
    setFilteringData({ search: '', ...filterAbleRows });
  }, [selectedTab]);

  const getSortedUser = (name, val) => {
    setSorting(val);
  };

  const navigateToAddUser = () => navigate('/users/new');
  const promoteUser = () => {
    setPromoteAdmin(true);
  };
  // const ChangeUserAccess = async (rowData, role) => {
  //   let isActionAllowed = true;
  //   if (auth.profile.role === 'super_admin' && rowData.userRole === 'owner')
  //     isActionAllowed = false;
  //   else if (
  //     auth.profile.role === 'admin' &&
  //     (rowData.userRole === 'owner' || rowData.userRole === 'super_admin')
  //   )
  //     isActionAllowed = false;
  //   if (isActionAllowed) await ChangeUserAccessLevel(role, rowData._id, cloneDeep(users), setUsers);
  //   else setShowAlert(true);
  // };

  const getAllUsers = async (page = 1, limit = 10) => {
    if (hasPermissionToAccess) {
      setUsers([]);
      let callData = { screenTab: selectedTab.value, page: page, limit: limit };
      if (sorting !== 'all') {
        callData.pendingFilter = sorting;
      }
      let filteringObj = cloneDeep(filteringData);
      filteringObj = omitBy(filteringObj, (v) => v === '');
      if (filteringObj.search) {
        callData.searchText = filteringObj.search;
        delete filteringObj.search;
      }
      if (filteringObj.filterByUserGroups === 'unassigned') {
        callData.filterByUserGroups = filteringObj.filterByUserGroups;
        delete filteringObj.filterByUserGroups;
      }

      if (filterEnabled) {
        if (Object.keys(filteringObj).length > 0) {
          let searchFilterArray = [];
          for (const key in filteringObj) {
            searchFilterArray.push({ [key]: filteringObj[key] });
          }
          callData.searchFilterArray = searchFilterArray;
        }
      }
      let result = await CallAPI('GET_USERS', callData, null, setLoader, setApiError, null);
      if (result.status) {
        let allUsers = [];
        result.data.users.map((singleUser) => {
          allUsers.push({ ...singleUser, user_id: singleUser.user._id });
        });
        setUsers(allUsers);
        if (result.data.totalCount) setTotalUsers(result.data.totalCount);
      }
    }
  };
  useEffect(() => {
    if (selectedTab) {
      setSelectedRows([]);
      getAllUsers();
    }
  }, [selectedTab, sorting, filteringData]);

  const onRowsSelection = (name, value) => {
    let tempSelectedRows = cloneDeep(selectedRows);
    if (name === 'all') {
      users.map((single_user) => {
        if (value && tempSelectedRows.indexOf((row) => row.id === single_user._id) === -1)
          tempSelectedRows.push({
            id: single_user._id,
            title: single_user.firstName + ' ' + single_user.lastName,
            userRole: single_user.userRole,
            userStatus: single_user.userStatus,
          });

        if (!value) tempSelectedRows = [];
        setSelectedRows(tempSelectedRows);
      });
    } else {
      if (value) {
        const selectedUser = users.find((single_user) => single_user._id === name);
        const title = selectedUser ? selectedUser.firstName + ' ' + selectedUser.lastName : '';
        const userRole = selectedUser ? selectedUser.userRole : '';
        tempSelectedRows.push({ id: name, title, userRole, userStatus: selectedUser.userStatus });
      } else tempSelectedRows = tempSelectedRows.filter((row) => row.id !== name);

      setSelectedRows(tempSelectedRows);
    }
    let isActionAllowed = true;
    users.map((singleUser) => {
      let isUserSelected = tempSelectedRows.filter((row) => row.id === singleUser._id).length;
      if (isUserSelected && isActionAllowed) {
        if (singleUser._id === auth.profile.userCompanyId) isActionAllowed = false;
        else if (singleUser.userRole === 'owner') isActionAllowed = false;
      }
    });
    setActionAllowedForSelectedRows(isActionAllowed);
  };
  const searchChangedViaDebounce = (name, value) => {
    setFilteringData(cloneDeep({ ...filteringData, [name]: value }));
  };
  const updateFilteringData = (name, value) => {
    setFilteringDataState({ ...filteringDataState, [name]: value });
  };

  const resetHeader = () => {
    let selectedTabHeader =
      selectedTab.value === 'admin'
        ? AdminTableFields
        : selectedTab.value === 'user' || selectedTab.value === 'archived'
        ? UserTableFields
        : selectedTab.value === 'pending'
        ? PendingUserTableFields
        : [];

    let selectedTabVal = selectedTab.value === 'archived' ? 'user_archived' : selectedTab.value;

    setTableHeader(cloneDeep(selectedTabHeader));
    setUserCustomizedTableHeader(selectedTabVal, cloneDeep(selectedTabHeader));
  };
  const translateValueFunc = (val) => {
    return t(val);
  };
  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <>
          <TabLayout>
            <TabHeader
              tabList={UserTabsList}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <TabBody>
              {loader && <Loader color='green-1' type='modal-spinner' />}
              {apiError ? <Error msg={apiError} /> : <></>}
              <UserListHeader
                filterEnabled={filterEnabled}
                setFilterEnabled={setFilterEnabled}
                actionFunction={
                  selectedTab.value === 'user'
                    ? navigateToAddUser
                    : selectedTab.value === 'admin'
                    ? promoteUser
                    : selectedTab.value === 'pending'
                    ? getSortedUser
                    : null
                }
                selectedTab={selectedTab.value}
                sorting={sorting}
                filterByUserGroups={filteringData.filterByUserGroups ?? 'all'}
                selectedRows={selectedRows}
                getAllUsers={getAllUsers}
                debounceFunc={searchChangedViaDebounce}
                filteringData={filteringDataState}
                setFilteringData={updateFilteringData}
                actionAllowedForSelectedRows={actionAllowedForSelectedRows}
              />
              <TableComponent
                debounceFunc={searchChangedViaDebounce}
                filteringData={filteringDataState}
                setFilteringData={updateFilteringData}
                header={tableHeader}
                data={users}
                props={{
                  translateValueFunc: translateValueFunc,
                  // ChangeUserAccess,
                  actionsCallBack: getAllUsers,
                  resetHeader: resetHeader,
                  actionsType:
                    selectedTab.value === 'archived' ? 'user_archived' : selectedTab.value,
                }}
                totalRecords={totalUsers}
                filterEnabled={filterEnabled}
                loader={loader}
                isFieldSelectionEnabled={true}
                isPaginationEnabled={true}
                isActionsEnabled={true}
                isSelectionEnabled={true}
                selectionKey={'_id'}
                paginationFunction={getAllUsers}
                onSelection={onRowsSelection}
                selectedRows={selectedRows}
              />
            </TabBody>
          </TabLayout>
          {promoteAdmin && <AddAdmin setPromoteAdmin={setPromoteAdmin} />}
          <AlertModal
            show={showAlert}
            showCloseButton={true}
            closeModal={() => setShowAlert(false)}
            variant='delete'
            message={'alert_authorize_access_level'}
            actionButtons={[
              {
                text: t('button_ok'),
                variant: 'red-1',
                onClick: () => setShowAlert(false),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default UserList;
