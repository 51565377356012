import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
import menuIcon from '../../assets/images/icons/menu-grey.svg';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { setUserCustomizedTableHeader } from 'actions/ReduxActions';
import FormButton from 'components/form-components/FormButton';

const MoreFieldsDropdown = ({ fieldList, setFieldListSelection, actionsType, resetHeader }) => {
  const { t } = useTranslation();

  const handleSelected = (field, value) => {
    const currentFieldsSelection = cloneDeep(fieldList);
    const foundIndex = currentFieldsSelection.findIndex((f) => f.name === field);
    currentFieldsSelection[foundIndex].isSelected = value;
    setFieldListSelection(currentFieldsSelection);
    setUserCustomizedTableHeader(actionsType, currentFieldsSelection);
  };

  return (
    <div className='fields-header-more'>
      <Dropdown className='d-inline' autoClose='outside'>
        <DropdownToggle as='div' id='dropdown-autoclose-outside'>
          <div className='d-flex align-content-center gap-1 cursor-pointer'>
            <img src={menuIcon} />
            <span>&#x25bc;</span>
          </div>
        </DropdownToggle>

        <DropdownMenu as='div' className='width-max-content dropdown-area'>
          {fieldList.map(
            (field, index) =>
              field.name !== '' &&
              field.name.length > 0 && (
                <DropdownMenuItem
                  key={index}
                  label={t(field.displayName)}
                  name={field.name}
                  isSelected={field.isSelected}
                  disabled={field.mandatory ?? false}
                  handleSelected={handleSelected}
                />
              ),
          )}
          <FormButton
            text='button_reset_table_header'
            variant='white-1'
            classes='my-2 mx-auto rounded-pill'
            minWidth
            onClick={resetHeader}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

MoreFieldsDropdown.propTypes = {
  actionsType: PropTypes.string.isRequired,
  fieldList: PropTypes.array.isRequired,
  setFieldListSelection: PropTypes.func.isRequired,
  resetHeader: PropTypes.func.isRequired,
};

export default MoreFieldsDropdown;

const DropdownMenuItem = ({ label, name, isSelected, disabled, handleSelected }) => {
  return (
    <div className='menu-option-item cursor-pointer'>
      <input
        type='checkbox'
        name={name}
        id={name}
        disabled={disabled}
        checked={isSelected}
        onChange={(e) => handleSelected(e.target.name, e.target.checked)}
        className='form-check-input m-0'
      />
      <label className='cursor-pointer' htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

DropdownMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelected: PropTypes.func.isRequired,
};
