import dataSourceIcon from 'assets/images/icons/data-source.svg';

export const dataSourceEntriesTabsList = [
  {
    id: 1,
    title: 'tabs_data_source',
    value: 'data_source',
    breadcrumbValue: {
      icon: dataSourceIcon,
      items: ['Data Source', 'Database', 'Data Source'],
    },
  },
  {
    id: 2,
    title: 'tabs_data_filter',
    value: 'data_filter',
    breadcrumbValue: {
      icon: dataSourceIcon,
      items: ['Data Source', 'Database', 'Data Filter'],
    },
  },
];
