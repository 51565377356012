import React, { useEffect, useState } from 'react';
import { CallAPI } from 'actions/General';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/common-components/Loader';
import SelectField from 'components/form-components/SelectField';
import selectArrow from 'assets/images/icons/dropdown-arrow-green.svg';
import AlertModal from 'components/common-components/AlertModal';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import EmailInputModal from 'components/common-components/EmailInputModal';

function Report() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [displayedHtml, setDisplayedHtml] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showEmailInputModal, setShowEmailInputModal] = useState(false);
  const [actionType, setActionType] = useState('');

  const getViewDetail = async () => {
    let result = await CallAPI(
      'GET_PREVIEW_DETAIL',
      { previewId: params.previewId, dataOf: params.dataType, action: params.actionType },
      null,
      setLoader,
      null,
      null,
    );
    if (result.status) {
      result = await CallAPI(
        'EXTERNAL_POST_API',
        { ...result.data.data },
        result.data.url,
        setLoader,
        null,
        null,
      );
      if (params.actionType === 'web_link') setDisplayedHtml(result.data.html);
      else if (params.actionType === 'pdf_preview') getPdfLink(result.data.url, result.data.data);
    } else {
      setErrorMessage(result.error);
    }
  };
  const getPdfLink = async (url, data) => {
    console.log('data, url,', data, url);
    let result = await CallAPI('EXTERNAL_POST_API', data, url, setLoader, null, null);
    console.log('result', result);
    if (result.status) {
      window.open(result.data.url, '_self');
    } else {
      setErrorMessage(result.error);
    }
  };

  const actions = (name, value) => {
    let urlActionType =
      value === 'share_link'
        ? 'web_link'
        : value === 'open_pdf'
        ? 'pdf_preview'
        : value === 'share_pdf'
        ? 'pdf_share_link'
        : 'pdf_send';
    setActionType(urlActionType);
    let url =
      process.env.REACT_APP_ADMIN_PANEL_URL +
      `/report/${urlActionType}/${params.dataType}/${params.previewId}`;
    if (value === 'share_link') {
      toast('Copied report link to clipboard.', {
        style: { borderRadius: '8px', background: '#000', color: '#fff' },
      });
      navigator.clipboard.writeText(url);
    } else if (value === 'open_pdf') {
      window.open(url, '_blank');
    } else {
      setShowEmailInputModal(true);
    }
  };

  const sendPDFCopyToUser = async (email) => {
    await CallAPI(
      'GET_PREVIEW_DETAIL',
      {
        previewId: params.previewId,
        dataOf: params.dataType,
        action: actionType,
        email: email.email,
      },
      null,
      setLoader,
      null,
      null,
    );
    setShowEmailInputModal(false);
  };

  const sendPDFCopy = async (email) => {
    toast.promise(
      sendPDFCopyToUser(email),
      {
        loading: 'Sending Email...',
        success: <span className='theme-text-white-1'>PDF Copy sent successfully.</span>,
        error: <span className='theme-text-white-1'>Something went wrong.</span>,
      },
      { style: { borderRadius: '8px', background: '#000', color: '#fff' } },
    );
  };

  useEffect(() => {
    if (!loader) getViewDetail();
  }, [params]);
  useEffect(() => {
    if (displayedHtml) {
      const allSectionElements = document.getElementsByClassName('section');
      for (let i = 0; i < allSectionElements.length; i++) {
        const element = allSectionElements[i];
        element.addEventListener('click', () => {
          if (element.classList.contains('closed')) {
            element.classList.remove('closed');

            let nextSibling = element.nextElementSibling;
            while (nextSibling && nextSibling.classList.contains('section-element')) {
              nextSibling.style.display = 'block';
              nextSibling = nextSibling.nextElementSibling;
            }
          } else {
            element.classList.add('closed');
            let nextSibling = element.nextElementSibling;
            while (nextSibling && nextSibling.classList.contains('section-element')) {
              nextSibling.style.display = 'none';
              nextSibling = nextSibling.nextElementSibling;
            }
          }
        });
      }
    }
  }, [displayedHtml]);

  return (
    <div>
      {loader && <Loader color='green-1' type='modal-spinner' />}
      <div className='d-none' id='supportiveElement'></div>
      {displayedHtml && (
        <>
          <div dangerouslySetInnerHTML={{ __html: displayedHtml }}></div>
          <SelectField
            handleChange={actions}
            name='share'
            position='end'
            listValues={[
              { value: 'share_link', displayValue: 'Copy Web Link', isNested: false },
              { value: 'share_pdf', displayValue: 'Share PDF Link', isNested: false },
              { value: 'send_pdf', displayValue: 'Send PDF Copy', isNested: false },
              { value: 'open_pdf', displayValue: 'Open PDF', isNested: false },
            ]}
            label={'Share'}
            icon={selectArrow}
            isAutoClose={true}
            classes='report-share'
          />
        </>
      )}
      {errorMessage && (
        <AlertModal
          show={true}
          title='Invalid Link'
          message="Link Doesn't Exist"
          actionButtons={[
            {
              text: t('button_ok'),
              variant: 'green-1',
              loading: loader,
              onClick: () => navigate('/login'),
            },
          ]}
        />
      )}

      {showEmailInputModal && (
        <EmailInputModal
          showModal={showEmailInputModal}
          setShowModal={setShowEmailInputModal}
          onSubmit={sendPDFCopy}
          loading={loader}
        />
      )}
    </div>
  );
}

export default Report;
