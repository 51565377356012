import PropTypes from 'prop-types';
import React from 'react';
import selectedGreenIcon from '../../assets/images/industry/selected-green.svg';
import { useTranslation } from 'react-i18next';

const IndustryField = ({
  name,
  classes,
  value,
  selectedValue,
  icon,
  isSelected,
  displayName,
  handleChange,
}) => {
  const {t}= useTranslation()
  const industryClicked = (chosenValue) => {
    let alreadySelectedValue = selectedValue;
    if (selectedValue.indexOf(chosenValue) > -1)
      alreadySelectedValue.splice(alreadySelectedValue.indexOf(chosenValue), 1);
    else alreadySelectedValue = [chosenValue];
    handleChange(name, alreadySelectedValue);
  };
  return (
    <div
      className={`custom-industry-field ${classes}`}
      name={name}
      value={value}
      onClick={() => industryClicked(value)}
    >
      {isSelected ? <img className='selected' src={selectedGreenIcon} /> : <></>}
      <img src={icon} />
      <span>{t(displayName)}</span>
    </div>
  );
};

IndustryField.propTypes = {
  classes: PropTypes.string,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selectedValue: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

export default IndustryField;
