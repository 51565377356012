import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import Error from 'components/Error';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import Loader from 'components/common-components/Loader';
import Permission from './Permission';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';

function AddEditProfiles() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const systemModules = useSelector((state) => state.auth.systemModules);

  const [values, setValues] = useState({
    profileId: '',
    profileName: '',
    profileDescription: '',
  });
  const [permissionsList, setPermissionsList] = useState({});
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [APIError, setAPIError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState('');
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const getAdminProfileDetails = async (profile_id) => {
    setLoading(true);
    let result = await CallAPI(
      'SINGLE_ADMIN_PROFILE_DETAIL',
      null,
      profile_id,
      null,
      setAPIError,
      null,
    );
    console.log('result', result);
    if (result.status) {
      setValues({
        profileId: result.data._id,
        profileName: result.data.profileName,
        profileDescription: result.data.profileDescription,
      });

      console.log(result.data.rolePermission);
      let permissionObj = {};
      result.data.rolePermission.modules.map((singleModule) => {
        let module = systemModules.filter((module) => module.module === singleModule.module);
        permissionObj[singleModule.module] = {
          adminModuleId: module[0]._id,
          adminModuleName: singleModule.module,
          moduleLevels: {},
        };
        singleModule.moduleLevels.map((singleModuleLevel) => {
          permissionObj[singleModule.module].moduleLevels[singleModuleLevel.levelName] = {
            levelName: singleModuleLevel.levelName,
            operations: singleModuleLevel.operations,
          };
        });
      });
      setPermissionsList(permissionObj);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (location.state.type === 'edit') {
      let profile_id = location.state.profile_id;
      getAdminProfileDetails(profile_id);
    }
  }, [location]);

  const CreateProfile = async () => {
    if (!isPermissionAvailable(permissionKeys.system_profiles_update)) {
      toast.error(t('alert_not_authorized_access_error'));
    } else if (!isAnyModuleSelected()) {
      toast.error('At least select one permission');
    } else {
      let modules = Object.values(permissionsList);
      modules.map((singleModule) => {
        singleModule.moduleLevels = Object.values(singleModule.moduleLevels);
      });
      let apiObj = {
        profileId: values.profileId === '' ? null : values.profileId,
        profileName: values.profileName,
        profileDescription: values.profileDescription,
        modules,
      };
      setLoadingType('create');
      let result = await CallAPI(
        'CREATE_ADMIN_PROFILE',
        apiObj,
        null,
        setLoading,
        setAPIError,
        null,
      );
      if (result.status) {
        navigate('/profiles');
      }
    }
  };

  const deleteBtnClick = () => {
    if (isPermissionAvailable(permissionKeys.system_profiles_delete)) {
      setDeleteConfirmationModal(true);
    } else {
      toast.error(t('alert_not_authorized_access_error'));
    }
  };

  const DeleteProfile = async () => {
    setLoadingType('delete');
    let result = await CallAPI(
      'DELETE_ADMIN_PROFILE',
      null,
      values.profileId,
      setLoading,
      setAPIError,
      null,
    );
    if (result.status) {
      navigate('/profiles');
    }
  };
  const CancelProcess = () => {
    navigate('/profiles');
  };

  const isAnyModuleSelected = () => {
    return Object.values(permissionsList).some((module) => {
      return Object.values(module.moduleLevels).some((level) => level.operations.length > 0);
    });
  };

  return (
    <div className='theme-background-white-1 custom-shadow rounded-sm p-3'>
      {APIError && <Error msg={APIError} />}
      {loading ? (
        <Loader color='green-1' />
      ) : (
        <>
          <div className='d-flex align-items-center flex-column gap-2'>
            <TextField
              name='profileName'
              displayName='field_profile_name'
              placeholder='field_profile_placeholder_name'
              value={values.profileName}
              handleChange={handleChange}
              classes='w-100'
              shrink={false}
              error={errors.profileName}
              touched={touched.profileName}
              isHorizontal={true}
              horizontalLabelClass='theme-width-230'
            />
            <TextArea
              name='profileDescription'
              rows={4}
              displayName='field_profile_description'
              placeholder='field_profile_placeholder_description'
              value={values.profileDescription}
              error={errors.profileDescription}
              touched={touched.profileDescription}
              handleChange={handleChange}
              classes='mt-3'
              isHorizontal={true}
              horizontalLabelClass='theme-width-230'
            />
            <div className='d-flex w-100'>
              <label className='theme-width-230'>{t('label_profile_access')}</label>
              <Permission
                permissionsList={permissionsList}
                setPermissionsList={setPermissionsList}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end gap-3 mt-4'>
            <FormLoadingButton
              text='button_cancel'
              variant='white-1'
              loading={false}
              onClick={CancelProcess}
            />
            {values.profileId && (
              <FormLoadingButton
                text='button_delete'
                variant='red-1'
                loading={loading && loadingType === 'delete'}
                onClick={deleteBtnClick}
              />
            )}
            <FormLoadingButton
              text={values.profileId ? 'button_update' : 'button_create'}
              variant='green-1'
              loading={loading && loadingType === 'create'}
              disabled={values.profileName === ''}
              onClick={CreateProfile}
            />
          </div>
          <ConfirmationModal
            show={deleteConfirmationModal}
            title={t('alert_profile_deletion_title')}
            message={t('alert_profile_deletion_message')}
            actionList={[
              {
                color: 'black-1',
                text: t('button_cancel'),
                onClick: () => setDeleteConfirmationModal(false),
              },
              {
                color: 'red-1',
                text: t('button_delete'),
                onClick: () => {
                  DeleteProfile();
                  setDeleteConfirmationModal(false);
                },
              },
            ]}
          />
        </>
      )}
    </div>
  );
}

export default AddEditProfiles;
