import * as yup from 'yup';

export const addGroupSchema = yup.object({
  groupName: yup.string().required('Group name is required'),
  users: yup.array().min(1, 'Select at least one user').required('Select at least one user'),
});

export const addSegmentSchema = yup.object({
  segmentName: yup.string().required('Segment name is required'),
});
export const GroupTableFields = [
  {
    name: 'group_name',
    isSelected: true,
    displayName: 'table_header_group_name',
    isRequired: false,
    associatedKey: 'groupName',
  },
  {
    name: 'users',
    isSelected: true,
    displayName: 'table_header_users',
    isRequired: false,
    populateFunc: 'countUsers',
    associatedKey: 'users',
  },
  {
    name: 'created_by',
    isSelected: true,
    displayName: 'table_header_created_by',
    isRequired: false,
    populateFunc: 'groupCreatedBy',
    associatedKey: 'createdBy',
  },
  // {
  //   name: 'administrated_by',
  //   isSelected: true,
  //   displayName: 'Administrated By',
  //   isRequired: false,
  //   populateFunc: 'administrativeInitials',
  //   associatedKey: 'users',
  // },
  // {
  //   name: 'action',
  //   isSelected: true,
  //   displayName: '',
  //   isRequired: false,
  //   populateFunc: 'manageGroup',
  //   callbackFunc: 'udpateGroup',
  // },
];
