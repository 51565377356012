import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import React from 'react';

const ButtonSamples = () => {
  return (
    <div className='theme-background-white-1'>
      <h1>Buttons</h1>
      <div className='d-flex flex-column gap-3 p-4'>
        {[
          'green-1',
          'green-2',
          'green-3',
          'white-1',
          'white-2',
          'grey-1',
          'grey-2',
          'red-1',
          'red-2',
          'blue-1',
          'blue-2',
          'blue-3',
          'orange-1',
          'orange-2',
        ].map((variant, index) => (
          <div className='w-50 d-flex gap-2' key={index}>
            <h5 style={{ width: 150 }}>{variant}</h5>
            <FormButton text='Text' variant={variant} />
            <FormButton text='Text' disabled={true} variant={variant} />
            <FormLoadingButton text='Text' loading={true} variant={variant} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ButtonSamples;
