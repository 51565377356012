import React from 'react';
import PropTypes from 'prop-types';

const CheckListFieldPreview = ({ field }) => {
  return (
    <div className='d-flex flex-column gap-2'>
      {(field.properties?.basicProperties?.optionList ?? []).map((option, index) => (
        <div
          key={index}
          className='choice-list-option-box'
          style={{ backgroundColor: option.itemColor }}
        >
          <label>{option.responseText}</label>
        </div>
      ))}
    </div>
  );
};

CheckListFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
};

export default CheckListFieldPreview;
