import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { FormConnectionExportSchema, validateSqlColumnName } from '../utils';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import AlertModal from 'components/common-components/AlertModal';
import ConnectionExportForm from './ConnectionExportForm';
import ConfirmationModal from 'components/common-components/ConfirmationModal';

const ExternalDBConnectionExport = ({ formDetails, updateTab, getFormDetail }) => {
  const { t } = useTranslation();

  const defaultSetting = { enabledForExport: false, externalDbTableName: '' };
  const [values, setValues] = useState({
    requestType: 'sql_db',
    connectionTitle: 'SQL Export',
    existing: false,
    settings: { ...defaultSetting },
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [apiError, setApiError] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const handleSettingsChange = (field, value) => {
    if (field === 'externalDbTableName') {
      if (validateSqlColumnName(value)) {
        setTouched({ ...touched, [`settings.${field}`]: true });
        setErrors({ ...errors, [`settings.${field}`]: undefined });
        setValues({ ...values, settings: { ...values.settings, [field]: value } });
      }
    } else {
      setTouched({ ...touched, [`settings.${field}`]: true });
      setErrors({ ...errors, [`settings.${field}`]: undefined });
      setValues({ ...values, settings: { ...values.settings, [field]: value } });
    }
  };

  const submit = async () => {
    setSaveLoading(true);
    setTouched({});
    setApiError('');
    try {
      await FormConnectionExportSchema.validate(values, { abortEarly: false });
      const payload = {
        formId: formDetails._id,
        externalOtherExportSettings: [
          { requestType: values.requestType, settings: { ...values.settings } },
        ],
      };

      let tableExist = false;
      if (!values.existing) {
        const exist = await checkIfTableNameExist(values.settings.externalDbTableName);

        if (exist) {
          setApiError(`Table: ${values.settings.externalDbTableName} already exists`);
          tableExist = true;
        }
      }

      console.log({ tableExist, payload });

      if (!tableExist) {
        console.log('Calling Save');
        const result = await CallAPI('SAVE_FORM', payload, null, setSaveLoading, setApiError, null);

        if (result.status) {
          setShowSuccessAlert(true);
          getFormDetail(formDetails._id);
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message.split('.').at(-1);
      });

      setErrors({ ...schemaErrors });
      setSaveLoading(false);
    }
  };

  const deleteExternalSetting = async () => {
    const payload = { formId: formDetails._id, externalOtherExportSettings: [] };
    const result = await CallAPI('SAVE_FORM', payload, null, setDeleteLoading, setApiError, null);

    if (result.status) {
      setShowSuccessAlert(true);
      getFormDetail(formDetails._id);
    }
  };

  const checkIfTableNameExist = async (tableName) => {
    const result = await CallAPI(
      'CHECK_EXTERNAL_DB_TABLE_NAME',
      { tableName },
      null,
      setSaveLoading,
      null,
      null,
    );

    console.log('checkIfTableNameExist', result);

    if (result.status && result.data.tableExist) return true;
    else return false;
  };

  useEffect(() => {
    if (formDetails.externalOtherExportSettings?.length) {
      const otherExportSetting = formDetails.externalOtherExportSettings[0];

      setValues({
        ...values,
        requestType: otherExportSetting.requestType,
        existing: true,
        settings: { ...cloneDeep(otherExportSetting.settings) },
      });
    } else {
      setValues({
        requestType: 'sql_db',
        connectionTitle: 'SQL Export',
        existing: false,
        settings: { ...defaultSetting },
      });
    }
  }, [formDetails]);

  return (
    <div className='p-3'>
      {apiError && <Error msg={apiError} />}
      <AlertModal
        show={showSuccessAlert}
        showCloseButton={true}
        closeModal={() => setShowSuccessAlert(false)}
        title='alert_success'
        message={'alert_form_export_setting_updated'}
        variant='success'
        actionButtons={[
          { text: t('button_ok'), variant: 'green-1', onClick: () => setShowSuccessAlert(false) },
        ]}
      />
      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_common_title')}
        message={t('alert_data_source_column_deletion_message')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setDeleteConfirmationModal(false),
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              deleteExternalSetting();
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
      <label className='theme-size-1_2 theme-text-black-1 mb-2'>{values.connectionTitle}</label>
      <ConnectionExportForm
        settings={values.settings}
        errors={errors}
        touched={touched}
        handleChange={handleSettingsChange}
        disabled={values.existing}
      />

      <div className='w-100 d-flex justify-content-end gap-3 mt-3'>
        <FormButton text='button_cancel' variant='white-1' onClick={() => updateTab(0)} />
        {values.existing && (
          <FormLoadingButton
            text='button_delete'
            variant='red-1'
            loading={deleteLoading}
            onClick={() => setDeleteConfirmationModal(true)}
          />
        )}
        <FormLoadingButton
          text='button_save'
          variant='green-1'
          loading={saveLoading}
          onClick={submit}
        />
      </div>
    </div>
  );
};

ExternalDBConnectionExport.propTypes = {
  updateTab: PropTypes.func,
  formDetails: PropTypes.object.isRequired,
  getFormDetail: PropTypes.func,
};

export default ExternalDBConnectionExport;
