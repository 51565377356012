import React from 'react';
import PropTypes from 'prop-types';
import directoryIcon from 'assets/images/icons/directory.svg';

const FolderFilterToggler = ({ onClick }) => {
  return (
    <div className='fields-header-more' onClick={() => onClick()}>
      <img src={directoryIcon} className='cursor-pointer' />
    </div>
  );
};

FolderFilterToggler.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FolderFilterToggler;
