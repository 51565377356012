import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { hexToRgba, rgbaToHex } from 'hex-and-rgba';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import boldIcon from 'assets/images/icons/bold.svg';
import crossIcon from 'assets/images/icons/cross.svg';
import italicIcon from 'assets/images/icons/italic.svg';
import strikethroughIcon from 'assets/images/icons/strikethrough.svg';
import underlineIcon from 'assets/images/icons/underline.svg';
import ClickOutside from './ClickOutside';
import SelectField from 'components/form-components/SelectField';
import { useTranslation } from 'react-i18next';
import CustomColorPicker from './CustomColorPicker';

const ColorChangeModal = ({
  name,
  closeModal,
  styleProperties,
  handleChange,
  hideFontSizeOption,
  hideOpacityOption,
}) => {
  const { t } = useTranslation();
  const modalRef = useRef();

  const [pickerColor, setPickerColor] = useState({ r: 0, g: 0, b: 0, a: 1 });

  const values = [
    { value: '12px', displayValue: t('ExtraSmall12px'), isNested: false },
    { value: '14px', displayValue: t('Small14px'), isNested: false },
    { value: '16px', displayValue: t('Medium16px'), isNested: false },
    { value: '18px', displayValue: t('Large18px'), isNested: false },
    { value: '20px', displayValue: t('ExtraLarge20px'), isNested: false },
  ];

  const valuesMap = Object.fromEntries(values.map((v) => [v.value, v.displayValue]));

  const handleFontColorChange = (r, g, b, a) => {
    const hexColor = rgbaToHex(r, g, b, !hideOpacityOption ? a : 1);
    handleChange(`${name}Color`, hexColor);
  };

  useEffect(() => {
    const [r, g, b, a] = hexToRgba(styleProperties[`${name}Color`]);
    setPickerColor({ r, g, b, a });
  }, [styleProperties[`${name}Color`]]);

  useEffect(() => {
    const propertiesSection = document.getElementById('properties-section');
    if (modalRef.current && propertiesSection) {
      const clientReact = modalRef.current.getBoundingClientRect();

      const contentHeight = clientReact.top + clientReact.height + 20 + 36;
      if (window.innerHeight < contentHeight) {
        propertiesSection.style.height = `${
          propertiesSection.clientHeight + (contentHeight - window.innerHeight)
        }px`;
      }
    }

    return () => {
      if (propertiesSection) propertiesSection.style.height = `fit-content`;
    };
  }, []);

  return (
    <ClickOutside onClick={() => closeModal()}>
      <div className='font-style-modal' ref={modalRef} role='button'>
        <div className='close-btn' onClick={() => closeModal()}>
          <img src={crossIcon} />
        </div>
        {!hideFontSizeOption && (
          <div className='d-flex gap-3 align-items-end'>
            <div className='w-50'>
              <SelectField
                placeholder='form_field_font_size'
                label={
                  styleProperties[`${name}Size`]
                    ? valuesMap[styleProperties[`${name}Size`]]
                    : 'select_select'
                }
                listValues={values}
                icon={arrowDownIcon}
                handleChange={handleChange}
                selectedValues={[]}
                name={`${name}Size`}
                isAutoClose={true}
                variant='white-1'
                menuClasses={'w-100'}
                shrink
              />
            </div>
            <FontTypeField
              name={name}
              styleProperties={styleProperties}
              handleChange={handleChange}
            />
          </div>
        )}

        <CustomColorPicker pickerColor={pickerColor} handleChange={handleFontColorChange} />
      </div>
    </ClickOutside>
  );
};

ColorChangeModal.propTypes = {
  name: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  styleProperties: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  hideFontSizeOption: PropTypes.bool,
  hideOpacityOption: PropTypes.bool,
};

export default ColorChangeModal;

const FontTypeField = ({ name, styleProperties, handleChange }) => {
  const { t } = useTranslation();
  const fontTypes = [
    { value: 'italic', icon: italicIcon },
    { value: 'bold', icon: boldIcon },
    { value: 'underline', icon: underlineIcon },
    { value: 'strikethrough', icon: strikethroughIcon },
  ];

  const handleFontStyleChange = (field, value) => {
    let tempFontTypes = styleProperties[`${name}FontType`]
      ? [...styleProperties[`${name}FontType`]]
      : [];
    if (tempFontTypes.includes(value)) {
      tempFontTypes = tempFontTypes.filter((v) => v !== value);
    } else {
      tempFontTypes.push(value);
    }

    handleChange(field, tempFontTypes);
  };

  return (
    <div className='w-100'>
      <label className='theme-size-0_8'>{t('form_field_font_type')}</label>
      <div className='font-type-field'>
        {fontTypes.map((fontType, index) => (
          <div
            key={index}
            className={`d-flex align-items-center${
              (styleProperties[`${name}FontType`] ?? []).includes(fontType.value)
                ? ' theme-background-green-1 active'
                : ''
            }`}
            onClick={() => handleFontStyleChange([`${name}FontType`], fontType.value)}
          >
            <img
              className={
                (styleProperties[`${name}FontType`] ?? []).includes(fontType.value)
                  ? 'svg-white'
                  : 'svg-black'
              }
              src={fontType.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

FontTypeField.propTypes = {
  name: PropTypes.string.isRequired,
  styleProperties: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
