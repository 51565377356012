import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import plusGreyIcon from 'assets/images/icons/plus-grey.svg';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { isPermissionAvailable } from 'config/permissionUtils';
import { CallAPI } from 'actions/General';
import { permissionKeys } from 'config/config';
import { brandingColorsList } from './utils';

const ColorScheme = ({ companyDetails, getCompanyDetail }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const colorBoxRef = useRef(null);

  const [selectedColor, setSelectedColor] = useState(
    companyDetails?.brandColorScheme || brandingColorsList[0],
  );

  const submit = async () => {
    const result = await CallAPI(
      'UPDATE_COLOR_SCHEME',
      { brandColorScheme: selectedColor },
      null,
      setLoading,
      null,
      null,
    );

    if (result.status) {
      toast.success('Company details updated successfully');
      getCompanyDetail();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorBoxRef.current && !colorBoxRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorBoxRef]);

  return (
    <div className='branding'>
      <div className='color-scheme'>
        <div className='d-flex flex-column'>
          <label className='theme-font-jakartaSans-medium theme-size-1_3'>
            {t('text_brand_color')}
          </label>
          <label className='theme-font-jakartaSans-medium theme-size-0_9'>
            {t('text_choose_primary_color')}
          </label>
          <div className='color-picker w-100 mt-2'>
            {brandingColorsList.map((color, index) => (
              <div
                key={index}
                className='color-box cursor-pointer'
                onClick={() => setSelectedColor(color)}
                style={{ backgroundColor: color }}
              ></div>
            ))}
            <div className='color-box cursor-pointer' ref={colorBoxRef}>
              <img src={plusGreyIcon} alt='Loading...' onClick={() => setOpen(!open)} />
              <SketchPicker
                className={`position-absolute right-0 top-100 ${open ? 'd-block' : 'd-none'}`}
                color={selectedColor}
                onChangeComplete={(color) => {
                  setSelectedColor(color.hex);
                }}
              />
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-grow-1 w-100'>
          <label className='theme-font-jakartaSans-medium theme-size-1_3'>{t('text_color')}</label>
          <label className='theme-font-jakartaSans-medium theme-size-0_9'>
            {t('text_how_it_works')}
          </label>
          <div
            className='w-100 border-grey-1-h-1 rounded-sm overflow-hidden mt-2'
            style={{ borderColor: selectedColor }}
          >
            <div
              className='w-100 py-1 theme-text-white-1 text-center'
              style={{ backgroundColor: selectedColor }}
            >
              {t('text_primary_tabs')}
            </div>
            <div className='py-4 text-center'>
              <button
                className='preview-button theme-text-white-1 border-0 px-5 py-2 rounded-sm'
                style={{ backgroundColor: selectedColor }}
              >
                {t('text_primary_buttons')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
        <div className='d-flex justify-content-end gap-3 mx-5 my-3'>
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            onClick={submit}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};
ColorScheme.propTypes = {
  companyDetails: PropTypes.object,
  getCompanyDetail: PropTypes.func.isRequired,
};
export default ColorScheme;
