import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { arrayMove, List } from 'react-movable';
import crossIcon from 'assets/images/icons/cross.svg';
import editIcon from 'assets/images/icons/edit.svg';
import gripVerticalIcon from 'assets/images/icons/grip-vertical.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import trashIcon from 'assets/images/icons/trash.svg';
import ColorChangeModal from 'components/common-components/ColorChangeModal';
import ResponseSetListModal from './ResponseSetListModal';
import TextField from 'components/form-components/TextField';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';

const ChoiceListOptionList = ({ setName, optionList, onChangeBasicProperties }) => {
  const { t } = useTranslation();
  const initialState = { responseText: '', value: '', itemColor: '#38CB89FF' };

  const [editIndex, setEditIndex] = useState(-1);
  const [showAddOption, setShowAddOption] = useState(false);
  const [showSelectResponseSet, setShowSelectResponseSet] = useState(false);
  const [newOption, setNewOption] = useState({ ...initialState });
  const [fontStyleModalOpen, setFontStyleModalOpen] = useState(false);
  const handleNewOptionChange = (field, value) => setNewOption({ ...newOption, [field]: value });

  const addOptionToList = () => {
    const newOptionList = [...optionList, { ...newOption }];
    onChangeBasicProperties('optionList', newOptionList);
    setNewOption({ ...initialState });
    setShowAddOption(false);

    setFontStyleModalOpen(false);
  };

  const removeOption = (index) => {
    const tempOptionList = [...optionList];
    tempOptionList.splice(index, 1);
    onChangeBasicProperties('optionList', [...tempOptionList]);

    setFontStyleModalOpen(false);
  };

  const editOptionToList = (index) => {
    const newOptionList = [...optionList];
    newOptionList[index] = { ...newOption };
    onChangeBasicProperties('optionList', newOptionList);
    setNewOption({ ...initialState });
    setEditIndex(-1);
  };

  const cancelOption = () => {
    setNewOption({ ...initialState });
    setShowAddOption(false);
    setEditIndex(-1);
  };

  const selectResponseSet = (responseSet, setName) => {
    const newOptionList = [...responseSet];
    onChangeBasicProperties('optionList', { setName, optionList: newOptionList });

    setFontStyleModalOpen(false);
    setShowSelectResponseSet(false);
  };

  const onItemDoubleClick = (index) => {
    closeAllModalActions();
    setEditIndex(index);
    setNewOption({ ...optionList[index] });
  };

  const closeAllModalActions = () => {
    setShowAddOption(false);
    setEditIndex(-1);
    setShowSelectResponseSet(false);
  };

  const openAddOption = () => {
    setNewOption({ ...initialState });
    closeAllModalActions();
    setShowAddOption(true);
  };

  const clearAllOptions = () => {
    closeAllModalActions();
    onChangeBasicProperties('optionList', []);
  };

  return (
    <div
      className={`choice-list-view${optionList.length > 0 ? ' border-grey-1-h-1' : ''}${
        setName ? ' border-top-0' : ''
      }`}
    >
      {setName && (
        <div className='d-flex justify-content-between theme-background-white-3 mb-2 p-2'>
          <label>{setName}</label>
          <label
            className='theme-text-green-1 cursor-pointer'
            onClick={() => {
              closeAllModalActions();
              setShowSelectResponseSet(!showSelectResponseSet);
            }}
          >
            {t('text_change')}
          </label>
        </div>
      )}
      <div className='choice-list-table-view'>
        {optionList.length > 0 && (
          <div className='table-view-header'>
            <div className='table-view-row'>
              {isPermissionAvailable(permissionKeys.field_work_response_set_create) && (
                <div className='table-view-cell px-0'></div>
              )}
              <div className='table-view-cell text-col'>{t('form_response')}</div>
              <div className='table-view-cell text-col'>{t('form_value')}</div>
              <div className='table-view-cell px-2'>{t('form_color')}</div>
              {isPermissionAvailable(permissionKeys.field_work_response_set_delete) && (
                <div className='table-view-cell'></div>
              )}
            </div>
          </div>
        )}

        <List
          values={optionList}
          onChange={({ oldIndex, newIndex }) =>
            onChangeBasicProperties('optionList', arrayMove(optionList, oldIndex, newIndex))
          }
          renderList={({ children, props, isDragged }) => (
            <div
              {...props}
              style={{ padding: 0, cursor: isDragged ? 'grabbing' : undefined }}
              className='table-view-body'
            >
              {children}
            </div>
          )}
          renderItem={({ value: listItem, props, index, isDragged }) => {
            return (
              <div
                {...props}
                // eslint-disable-next-line react/prop-types
                key={props.key}
                className={`table-view-row${isDragged ? ' d-flex gap-3' : ''}`}
                style={{
                  // eslint-disable-next-line react/prop-types
                  ...props.style,
                  zIndex: isDragged ? 99999 : undefined,
                }}
              >
                {isPermissionAvailable(permissionKeys.field_work_response_set_create) && (
                  <div className='table-view-cell px-0'>
                    <img src={gripVerticalIcon} className='cursor-pointer' />
                  </div>
                )}

                <div className='table-view-cell text-col'>{listItem.responseText}</div>
                <div className='table-view-cell text-col'>{listItem.value}</div>
                <div className='table-view-cell px-2'>
                  <div
                    role='button'
                    className='color-box'
                    style={{ backgroundColor: listItem.itemColor }}
                  >
                    {isPermissionAvailable(permissionKeys.field_work_response_set_create) && (
                      <img src={editIcon} onClick={() => onItemDoubleClick(index)} />
                    )}
                  </div>
                </div>
                {isPermissionAvailable(permissionKeys.field_work_response_set_delete) && (
                  <div className='table-view-cell'>
                    <img
                      src={trashIcon}
                      role='button'
                      className='cursor-pointer'
                      onClick={() => removeOption(index)}
                    />
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
      {(showAddOption || editIndex > -1) && (
        <Fragment>
          <div className='d-flex gap-2 align-items-end m-2'>
            <TextField
              name='responseText'
              value={newOption.responseText}
              placeholder='field_response'
              handleChange={handleNewOptionChange}
              shrink
            />
            <TextField
              name='value'
              value={newOption.value}
              placeholder='field_value'
              handleChange={handleNewOptionChange}
              shrink
            />
            <div className='position-relative'>
              <div
                className='p-2 rounded-circle cursor-pointer'
                style={{ backgroundColor: newOption.itemColor }}
                onClick={() => setFontStyleModalOpen(true)}
              >
                <img src={editIcon} />
              </div>
              {fontStyleModalOpen && (
                <ColorChangeModal
                  name='item'
                  closeModal={() => setFontStyleModalOpen(false)}
                  styleProperties={newOption}
                  handleChange={handleNewOptionChange}
                  hideFontSizeOption
                  hideOpacityOption
                />
              )}
            </div>
          </div>
          <div className='d-flex gap-3 m-2'>
            <label className='theme-text-red-1 cursor-pointer' onClick={() => cancelOption()}>
              {t('text_cancel')}
            </label>
            <label
              className='theme-text-green-1'
              style={{
                cursor:
                  newOption.responseText === '' || newOption.value === ''
                    ? 'not-allowed'
                    : 'pointer',
              }}
              onClick={() => {
                if (newOption.responseText !== '' && newOption.value !== '') {
                  if (editIndex > -1) editOptionToList(editIndex);
                  else addOptionToList();
                }
              }}
            >
              {t('text_save')}
            </label>
          </div>
        </Fragment>
      )}
      {!showAddOption && editIndex < 0 && (
        <div className='d-flex gap-3 align-items-center flex-wrap p-2'>
          {isPermissionAvailable(permissionKeys.field_work_response_set_create) && (
            <div>
              <label className='action-label' onClick={() => openAddOption()}>
                <img src={plusIcon} />
                <span className='theme-size-0_9 theme-text-green-1'>{t('form_add_option')}</span>
              </label>
            </div>
          )}
          {isPermissionAvailable(permissionKeys.field_work_response_set_delete) && (
            <div>
              <label className='action-label' onClick={() => clearAllOptions()}>
                <img src={crossIcon} className='svg-grey' />
                <span className='theme-size-0_9 theme-text-grey-1'>{t('form_clear')}</span>
              </label>
            </div>
          )}
          {!setName && (
            <div>
              <label
                className='action-label'
                onClick={() => {
                  closeAllModalActions();
                  setShowSelectResponseSet(!showSelectResponseSet);
                }}
              >
                <img src={plusIcon} />
                <span className='theme-size-0_9 theme-text-green-1'>
                  {t('form_select_response_set')}
                </span>
              </label>
            </div>
          )}
        </div>
      )}
      {showSelectResponseSet && (
        <div className='position-relative'>
          <ResponseSetListModal
            selectResponseSet={selectResponseSet}
            closeModal={() => setShowSelectResponseSet(false)}
          />
        </div>
      )}
    </div>
  );
};

ChoiceListOptionList.propTypes = {
  setName: PropTypes.string,
  optionList: PropTypes.array.isRequired,
  onChangeBasicProperties: PropTypes.func.isRequired,
};

export default ChoiceListOptionList;
