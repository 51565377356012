import React from 'react';
import PropTypes from 'prop-types';
import starFilledIcon from 'assets/images/static-icons/star-filled.svg';
import starHollowIcon from 'assets/images/static-icons/star-hollow.svg';

const RatingFieldPreview = ({ field }) => {
  return (
    <div className='d-flex gap-1 justify-content-center align-items-center'>
      <label className='theme-text-black-1 px-2'>
        {field.properties?.basicProperties?.lowLabel ?? ''}
      </label>
      {Array.from(Array(field.properties?.basicProperties?.ratingPointers ?? 0).keys()).map(
        (_, index) => (
          <img
            key={index}
            src={
              index < Math.ceil((field.properties?.basicProperties?.ratingPointers ?? 0) / 2)
                ? starFilledIcon
                : starHollowIcon
            }
            style={{ height: '2rem' }}
          />
        ),
      )}
      <label className='theme-text-black-1 px-2'>
        {field.properties?.basicProperties?.highLabel ?? ''}
      </label>
    </div>
  );
};

RatingFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
};

export default RatingFieldPreview;
