import PropTypes from 'prop-types';
import React from 'react';
import selectedGreenIcon from '../../assets/images/industry/selected-green.svg';
import selectedGreyIcon from '../../assets/images/industry/selected-grey.svg';
import { useTranslation } from 'react-i18next';

const FeatureField = ({
  name,
  classes,
  value,
  selectedValue,
  icon,
  isSelected,
  isDisabled,
  isCategory,
  displayName,
  handleChange,
}) => {
  const { t } = useTranslation()
  const industryClicked = (choosedValue) => {
    if (!isDisabled) {
      let alreadySelectedValue = selectedValue;
      if (selectedValue.indexOf(choosedValue) > -1)
        alreadySelectedValue.splice(alreadySelectedValue.indexOf(choosedValue), 1);
      else alreadySelectedValue = [...alreadySelectedValue, choosedValue];
      handleChange(name, alreadySelectedValue);
    }
  };
  return (
    <div
      className={`custom-feature-field ${classes} ${isDisabled ? 'disabled' : ''} ${
        isCategory ? 'category' : ''
      }`}
      name={name}
      value={value}
      onClick={() => handleChange && industryClicked(value)}
    >
      {isSelected && !isDisabled ? <img className='selected' src={selectedGreenIcon} /> : <></>}
      {isDisabled ? <img className='selected' src={selectedGreyIcon} /> : <></>}
      {icon ? <img src={icon} /> : <></>}
      <span>{t(displayName)}</span>
    </div>
  );
};

FeatureField.propTypes = {
  classes: PropTypes.string,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selectedValue: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isCategory: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

export default FeatureField;
