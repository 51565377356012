import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import exportGreyIcon from 'assets/images/icons/export-grey.svg';
import filterGreyIcon from 'assets/images/icons/filter-grey.svg';
import searchIcon from 'assets/images/icons/search.svg';
import sortingIcon from '../../assets/images/icons/sorting-icon.svg';
import DateField from 'components/form-components/DateField';
import FormButton from 'components/form-components/FormButton';
import SelectField from 'components/form-components/SelectField';
import TextField from 'components/form-components/TextField';
import { clockInFilterList, clockInStatuses } from './utils';
import { isRTL, notificationKey } from 'config/config';
import { CallAPI } from 'actions/General';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const AttendanceTableHeader = ({ filterData, setFilterData, groupListing, type, selectedRows }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const { lang } = useSelector((state) => state.language);

  const handleFilterChange = (field, value) => {
    setFilterData({ ...filterData, [field]: value });
  };

  const handleDateFilterChange = (field, value) => {
    if (field === 'toDate' && filterData.fromDate) {
      setFilterData({ ...filterData, [field]: value });
    } else if (field === 'fromDate') {
      setFilterData({ ...filterData, [field]: value });
    }
  };

  const values = groupListing.map((group) => ({ value: group._id, displayValue: group.groupName }));
  values.unshift({ value: '', displayValue: 'select_show_all' });
  const valuesMap = Object.fromEntries(values.map((v) => [v.value, v.displayValue]));

  const clockInStatusesMap = Object.fromEntries(
    clockInStatuses.map((v) => [v.value, v.displayValue]),
  );

  const exportTimeSheet = async () => {
    toast(t(notificationKey['clock-in_attendance_time_sheet_export_process_started']), {
      style: { borderRadius: '8px', background: '#000', color: '#fff' },
    });
    let dataObject = {
      exportTo: 'pdf',
      exportMode: 'all',
      locale: lang,
    };
    if (selectedRows.length > 0) {
      dataObject.timeSheetIds = selectedRows;
      dataObject.exportMode = 'multiple';
    }
    if (filterData.fromDate) {
      dataObject.fromDate = filterData.fromDate;

      if (filterData.toDate) {
        dataObject.toDate = filterData.toDate;
      }
    }
    await CallAPI('EXPORT_TIME_SHEET', dataObject);
  };
  return (
    <div className='d-flex gap-3 align-items-center justify-content-between'>
      <div className='d-flex gap-3 align-items-center'>
        <TextField
          value={searchText}
          name='searchText'
          handleChange={(_, value) => setSearchText(value)}
          debounceFunc={handleFilterChange}
          placeholder={t('field_search')}
          icon={searchIcon}
          shrink
        />
        {['employeeTrackerHeader', 'map', 'attendance'].includes(type) && (
          <div className='dropdown-actions'>
            <DropdownButton
              align={isRTL() ? 'end' : 'start'}
              variant='secondary'
              title={<FormButton text='text_filter' variant='white-1' icon={filterGreyIcon} />}
            >
              {clockInFilterList.map((c, index) =>
                filterData.filterByDate !== c.value ? (
                  <Dropdown.Item
                    key={index}
                    className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
                    onClick={() => handleFilterChange('filterByDate', c.value)}
                  >
                    {t(c.displayValue)}
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    key={index}
                    className='items theme-size-1 theme-background-green-1 theme-text-white-1 px-3 py-2 cursor-pointer'
                  >
                    {t(c.displayValue)}
                  </Dropdown.Item>
                ),
              )}
            </DropdownButton>
          </div>
        )}
        {['employeeTrackerHeader', 'map', 'attendance'].includes(type) && (
          <SelectField
            label={valuesMap[filterData.groupId] ?? 'text_select_groups'}
            listValues={values}
            icon={arrowDownIcon}
            handleChange={handleFilterChange}
            selectedValues={[]}
            name='groupId'
            isAutoClose={true}
            variant='white-1'
            menuClasses={'width-max-content'}
          />
        )}
        {type === 'timeSheet' && (
          <>
            <label className='text-nowrap'>{t('text_from_date')}</label>
            <DateField
              name='fromDate'
              value={filterData.fromDate ?? ''}
              handleChange={handleDateFilterChange}
              shrink
            />
            <label className='text-nowrap'>{t('text_to_date')}</label>
            <DateField
              name='toDate'
              value={filterData.toDate ?? ''}
              handleChange={handleDateFilterChange}
              shrink
            />
          </>
        )}

        {type === 'timeSheet' && (
          <FormButton
            text='text_export'
            variant='white-1'
            icon={exportGreyIcon}
            onClick={exportTimeSheet}
          />
        )}
      </div>

      {['map', 'attendance'].includes(type) && (
        <SelectField
          label={clockInStatusesMap[filterData.attendanceState] ?? ''}
          variant={clockInStatusesMap[filterData.attendanceState] ? 'green-1' : 'grey-1'}
          name='attendanceState'
          handleChange={handleFilterChange}
          listValues={clockInStatuses}
          icon={sortingIcon}
          positionReversed
          isAutoClose={true}
        />
      )}
      {type === 'employeeTrackerHeader' && <label>{t('text_track_employee_daily')}</label>}
    </div>
  );
};

AttendanceTableHeader.propTypes = {
  filterData: PropTypes.object.isRequired,
  setFilterData: PropTypes.func.isRequired,
  groupListing: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  selectedRows: PropTypes.array,
};

export default AttendanceTableHeader;
