import dashboardIcon from 'assets/images/icons/dashboard.svg';

export const tabList = [
  {
    id: 1,
    title: 'tabs_submitted_forms',
    value: 'submittedForms',
    breadcrumbValue: { icon: dashboardIcon, items: ['sidebar_dashboard', 'tabs_submitted_forms'] },
  },
  {
    id: 2,
    title: 'tabs_bookmarks',
    value: 'bookmarkedForms',
    breadcrumbValue: { icon: dashboardIcon, items: ['sidebar_dashboard', 'tabs_bookmarks'] },
  },
];

export const formsTableHeader = [
  {
    name: 'name',
    displayName: 'table_header_form_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    // associatedKey: 'title',
    populateFunc: 'populateFormTitle',
    callbackFunc: 'formNameClickCallBack',
  },
  {
    name: 'published',
    displayName: 'table_header_published',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'publishedDate',
    populateFunc: 'populateDate',
  },
  {
    name: 'last_entry',
    displayName: 'table_header_last_entry',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'lastEntryDate',
  },
  {
    name: 'entries',
    displayName: 'table_header_entries',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'numberOfEntries',
  },
];
