import React, { useState } from 'react';
import PropTypes from 'prop-types';
import backArrow from 'assets/images/icons/arrow-left.svg';
import SelectionComponent from '../../../components/common-components/SelectionComponent';
import { useTranslation } from 'react-i18next';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import AlertModal from 'components/common-components/AlertModal';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import FormButton from 'components/form-components/FormButton';

const UpdatePermissions = ({ formId, selectedPermission, goBack, getFormDetail }) => {
  const { t } = useTranslation();
  const [groupBtnLoading, setGroupBtnLoading] = useState(false);
  const [userBtnLoading, setUserBtnLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    (selectedPermission.users ?? []).map((user) => ({
      id: user._id,
      name: user.firstName + ', ' + user.lastName,
    })),
  );
  const [selectedGroups, setSelectedGroups] = useState(
    (selectedPermission.groups ?? []).map((group) => ({ id: group._id, name: group.groupName })),
  );
  const [selectedFolders] = useState(
    (selectedPermission.folders ?? []).map((folder) => ({ id: folder._id, name: folder.name })),
  );
  const [showAlert, setShowAlert] = useState(false);

  const [errors, setErrors] = useState({ users: null, groups: null });
  const [APIError, setAPIError] = useState('');

  const submit = async (ids, type) => {
    if (ids.length <= 0) {
      setErrors({ ...errors, [type]: 'Selected list can not be empty' });
      return;
    }
    let values = {
      formId,
      [selectedPermission.key]: {
        users: selectedUsers.map((item) => item.id),
        groups: selectedGroups.map((item) => item.id),
        folders: selectedFolders.map((item) => item.id),
      },
    };

    let result = await CallAPI(
      'SAVE_FORM',
      values,
      null,
      type === 'users' ? setUserBtnLoading : setGroupBtnLoading,
      setAPIError,
      null,
    );

    if (result.status) {
      setShowAlert(true);
      getFormDetail(formId);

      setErrors({
        users: null,
        groups: null,
      });
    }
  };

  return (
    <div className=''>
      {showAlert && (
        <AlertModal
          show={showAlert}
          showCloseButton={true}
          closeModal={() => setShowAlert(false)}
          title='alert_success'
          message='alert_permission_updated'
          variant='success'
          actionButtons={[{ text: t('button_ok'), variant: 'green-1', onClick: () => goBack() }]}
        />
      )}
      <div className='row head'>
        <div className='col-4 d-flex align-items-center cursor-pointer' onClick={goBack}>
          <img src={backArrow} className='me-2' />
          <label className='theme-text-black-1'>{t('text_back')}</label>
        </div>
        <div className='col-4 title text-center'>
          {t(
            selectedPermission.key === 'appUser'
              ? 'text_app_users_are_allowed_to_use_this_form_on_mobile_app'
              : selectedPermission.key === 'supervisor'
              ? 'text_supervisors_are_allowed_to_view_data_on_web'
              : 'text_designers_are_allowed_to_make_changes_on_web',
          )}
        </div>
      </div>
      {APIError && <Error msg={APIError} />}
      <SelectionComponent
        title='text_groups'
        type='groups'
        submit={(ids) => {
          submit(ids, 'groups');
        }}
        selectedDataList={selectedGroups}
        setSelectedList={setSelectedGroups}
        loading={groupBtnLoading}
        error={errors.groups}
        showButtons={false}
      />
      <SelectionComponent
        classes='mt-4'
        title='text_users'
        // onCancel={cancelUser}
        submit={(ids) => {
          submit(ids, 'users');
        }}
        selectedDataList={selectedUsers}
        setSelectedList={setSelectedUsers}
        loading={userBtnLoading}
        error={errors.users}
        // buttonsPosition='bottom'
        showButtons={false}
      />
      {/* <SelectionComponent
        classes='mt-4'
        title='Folders'
        type='folders'
        onCancel={cancelFolder}
        submit={(ids) => {
          submit(ids, 'folders');
        }}
        selectedDataList={selectedFolders}
        setSelectedList={setSelectedFolders}
        loading={groupBtnLoading}
        error={errors.users}
        buttonsPosition='bottom'
      /> */}
      <div className='w-100 d-flex justify-content-end gap-3 mt-4'>
        <FormButton text='button_cancel' variant='white-1' onClick={goBack} />
        <FormLoadingButton
          text='button_save'
          variant='green-1'
          onClick={submit}
          loading={userBtnLoading}
        />
      </div>
    </div>
  );
};

UpdatePermissions.propTypes = {
  formId: PropTypes.string.isRequired,
  selectedPermission: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  getFormDetail: PropTypes.func.isRequired,
};

export default UpdatePermissions;
