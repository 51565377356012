import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import submissionDivider from 'assets/images/icons/submission-divider.svg';

function Divider({ name, text, handleDividerChange }) {
  const { t } = useTranslation();
  return (
    <div className='divider cursor-pointer' onClick={() => handleDividerChange(name)}>
      {t(text)} <img src={submissionDivider} />
    </div>
  );
}
Divider.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleDividerChange: PropTypes.func.isRequired,
};
export default Divider;
