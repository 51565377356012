import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import starFilledIcon from 'assets/images/static-icons/star-filled.svg';
import starHollowIcon from 'assets/images/static-icons/star-hollow.svg';
import { useTranslation } from 'react-i18next';

const RatingStarField = ({ value, numOfStars, displayName, classes, handleChange }) => {
  const { t } = useTranslation();
  const [fillStarCount, setFillStarCount] = useState(0)

  useEffect(() => {
    setFillStarCount(value)
 }, [numOfStars]);


  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      {displayName && (
        <label
         
        >
          {t(displayName)}
        </label>
      )}
       <div className='custom-input-field'>
        {Array.from(Array(numOfStars ?? 0).keys()).map(
        (_, index) => (
          <img
            key={index}
            src={
              index < fillStarCount
                ? starFilledIcon
                : starHollowIcon
            }
            onClick={()=>{
              setFillStarCount(index + 1)
              handleChange(index + 1)}
            } 
            style={{ height: '2rem' }}
          />
        ),
      )}
      </div>
    </div>
  );
};

RatingStarField.propTypes = {
  displayName: PropTypes.string,
  value: PropTypes.number,
  numOfStars: PropTypes.number,
  handleChange: PropTypes.func,
  classes: PropTypes.string
};

export default RatingStarField;
