import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RangeField = ({ name, classes, value, displayName, handleChange }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`custom-range-field ${classes}`}
      name={name}
      value={value}
      onClick={() => handleChange(name, value)}
    >
      {t(displayName)}
    </div>
  );
};

RangeField.propTypes = {
  classes: PropTypes.string,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
};

export default RangeField;
