import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const MainToggleBoxBody = ({ show, children, classes, style }) => {
  return (
    <Fragment>
      {show && (
        <div
          className={`main-toggler-body${classes ? ` ${classes}` : ''}`}
          {...(style && { style })}
        >
          {children}
        </div>
      )}
    </Fragment>
  );
};

MainToggleBoxBody.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  classes: PropTypes.string,
  style: PropTypes.object,
};

export default MainToggleBoxBody;
