import React from 'react';
import PropTypes from 'prop-types';
import mapIcon from 'assets/images/icons/map.svg';
import DataSourceFieldPreview from '../common-components/DataSourceFieldPreview';
import DateTimeFieldPreview from '../common-components/DateTimeFieldPreview';
import FormButton from 'components/form-components/FormButton';
import ImageFieldPreview from '../common-components/ImageFieldPreview';
import NumberRangeFieldPreview from '../common-components/NumberRangeFieldPreview';
import RatingFieldPreview from '../common-components/RatingFieldPreview';
import SignatureFieldPreview from '../common-components/SignatureFieldPreview';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';
import CheckListFieldPreview from '../common-components/CheckListFieldPreview';

const FieldDetailView = ({ element, dataSourcesList }) => {
  return (
    <div className='field-detail-view'>
      {['text', 'number', 'supervisor_number'].includes(element.elementType) ? (
        <div>
          <TextField
            name='fieldTitle'
            value=''
            classes='w-100'
            placeholder={
              element.properties?.basicProperties?.fieldPlaceholder
                ? element.properties?.basicProperties?.fieldPlaceholder
                : 'form_placeholder_text'
            }
            handleChange={() => {}}
            disabled
          />
        </div>
      ) : element.elementType === 'supervisor_note' ? (
        <div>
          <TextArea
            name='fieldTitle'
            value='Add note...'
            classes='w-100'
            handleChange={() => {}}
            rows={3}
            disabled
          />
        </div>
      ) : ['checkList', 'supervisor_status','apiStatus'].includes(element.elementType) ? (
        <CheckListFieldPreview field={element} />
      ) : element.elementType === 'location' ? (
        <div className='d-flex gap-2'>
          <TextField
            name='location'
            value=''
            placeholder='Address'
            classes='w-100'
            handleChange={() => {}}
            disabled
            shrink
          />
          <FormButton text='Map' variant='green-1' icon={mapIcon} onClick={() => {}} />
        </div>
      ) : element.elementType === 'dateTime' || element.elementType === 'supervisor_dateTime' ? (
        <DateTimeFieldPreview field={element} />
      ) : element.elementType === 'image' ? (
        <ImageFieldPreview field={element} />
      ) : element.elementType === 'signature' || element.elementType === 'supervisor_signature' ? (
        <SignatureFieldPreview field={element} />
      ) : element.elementType === 'ratingSlider' ||
        element.elementType === 'supervisor_ratingSlider' ? (
        <RatingFieldPreview field={element} />
      ) : element.elementType === 'numberSlider' ? (
        <NumberRangeFieldPreview field={element} />
      ) : element.elementType === 'dataSource' ? (
        <DataSourceFieldPreview field={element} dataSourcesList={dataSourcesList} />
      ) : (
        <></>
      )}
    </div>
  );
};

FieldDetailView.propTypes = {
  element: PropTypes.object.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
};

export default FieldDetailView;
