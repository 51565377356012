import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

const ConfigureWidgetContent = ({ addPersonalSettings, closeModal }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({ todayStats: false, employeeTracker: false });
  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const confirmConfiguration = () => addPersonalSettings({ ...values });

  return (
    <div className='w-100 d-flex gap-4 flex-column align-items-center'>
      <div className='width-max-content'>
        <SingleCheckBoxField
          name='todayStats'
          value={values.todayStats}
          handleChange={handleChange}
          text={<span>{t('text_today_statistics')}</span>}
          classes='mb-3'
        />
        <SingleCheckBoxField
          name='employeeTracker'
          value={values.employeeTracker}
          handleChange={handleChange}
          text={<span>{t('text_employee_tracker')}</span>}
        />
      </div>
      <div className='d-flex w-100'>
        <div className='flex-grow-1 text-center'>
          <label
            className='theme-font-inter-medium theme-text-black-1 cursor-pointer theme-size-1_2'
            onClick={() => closeModal()}
          >
            {t('button_cancel')}
          </label>
        </div>
        <div className='flex-grow-1 text-center'>
          <label
            className='theme-font-inter-medium theme-text-green-1 cursor-pointer theme-size-1_2'
            onClick={() => {
              confirmConfiguration();
              closeModal();
            }}
          >
            {t('button_confirm')}
          </label>
        </div>
      </div>
    </div>
  );
};

ConfigureWidgetContent.propTypes = {
  addPersonalSettings: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ConfigureWidgetContent;
