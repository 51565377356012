export const clockInFilterList = [
  { displayValue: 'select_all', value: 'all' },
  { displayValue: 'select_today', value: 'today' },
  { displayValue: 'select_yesterday', value: 'yesterday' },
  { displayValue: 'select_last_3_days', value: 'last_three_days' },
  { displayValue: 'select_last_7_days', value: 'last_seven_days' },
  { displayValue: 'select_last_30_days', value: 'last_thirty_days' },
];
export const clockInStatuses = [
  { displayValue: 'select_show_all', value: undefined },
  { displayValue: 'text_working_now', value: 'started' },
  { displayValue: 'text_check_out', value: 'ended' },
  { displayValue: 'text_on_break', value: 'paused' },
];

import attendanceIcon from 'assets/images/icons/attendance-green.svg';
export const tabList = [
  {
    id: 1,
    title: 'table_header_map',
    value: 'map',
    breadcrumbValue: { icon: attendanceIcon, items: ['sidebar_attendance', 'table_header_map'] },
  },
  {
    id: 2,
    title: 'table_header_track_attendance',
    value: 'attendance',
    breadcrumbValue: { icon: attendanceIcon, items: ['sidebar_attendance', 'table_header_track'] },
  },
  // {
  //   id: 3,
  //   title: 'TrackRequest',
  //   value: 'request',
  //   breadcrumbValue: { icon: attendanceIcon, items: ['Attendance', 'Requests'] },
  // },
  {
    id: 4,
    title: 'table_header_time_sheet',
    value: 'timeSheet',
    breadcrumbValue: {
      icon: attendanceIcon,
      items: ['sidebar_attendance', 'table_header_time_sheet'],
    },
  },
  {
    id: 5,
    title: 'table_header_setup',
    value: 'setup',
    breadcrumbValue: { icon: attendanceIcon, items: ['sidebar_attendance', 'table_header_setup'] },
  },
];
export const employeeTrackerTableHeader = [
  {
    name: '',
    displayName: '#',
    type: 'number',
    isRequired: true,
    isSelected: true,
    associatedKey: 'sr',
  },
  {
    name: 'name',
    displayName: 'table_header_user_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    populateFunc: 'populateCreatorName',
  },
  {
    name: 'state',
    displayName: 'table_header_employee_status',
    type: 'string',
    isRequired: false,
    isSelected: true,
    associatedKey: 'state',
    translateVal: true,
    populateFunc: 'populateStatus',
  },
  {
    name: 'startDate',
    displayName: 'table_header_check_in',
    type: 'string',
    isRequired: false,
    isSelected: true,
    associatedKey: 'startDate',
    populateFunc: 'populateTime',
  },
  {
    name: 'lastFilledForm',
    displayName: 'table_header_last_filled_form',
    type: 'string',
    isRequired: false,
    isSelected: true,
    associatedKey: 'lastFilledForm',
  },
  {
    name: 'lastSeen',
    displayName: 'table_header_last_seen',
    type: 'string',
    isRequired: false,
    isSelected: true,
    populateFunc: 'populateTimeAgo',
  },
  {
    name: 'lastLocation',
    displayName: 'table_header_last_location',
    type: 'string',
    isRequired: false,
    isSelected: true,
    translateVal: true,
    populateFunc: 'populateLastLocation',
  },
];
export const attendanceTableHeader = [
  {
    name: 'name',
    displayName: 'table_header_user_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    populateFunc: 'populateCreatorName',
  },
  {
    name: 'last_seen',
    displayName: 'table_header_last_seen',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    populateFunc: 'populateTimeAgo',
  },
  {
    name: 'status',
    displayName: 'table_header_status',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    translateVal: true,
    associatedKey: 'state',
    populateFunc: 'populateStatus',
  },
  {
    name: 'check_in',
    displayName: 'table_header_check_in',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'startDate',
    populateFunc: 'populateDateTime',
  },
  {
    name: 'check_out',
    displayName: 'table_header_check_out',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'endDate',
    populateFunc: 'populateDateTime',
  },
  {
    name: 'total_hours',
    displayName: 'table_header_total_hours',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'totalClockTimeSecs',
    populateFunc: 'populateTotalHours',
  },
  {
    name: 'on_a_break',
    displayName: 'table_header_on_a_break',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'state',
    populateFunc: 'populateOnBreak',
  },
];
export const timeSheetTableHeader = [
  {
    name: 'name',
    displayName: 'table_header_user_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    populateFunc: 'populateCreatorName',
  },
  {
    name: 'check_in',
    displayName: 'table_header_check_in',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'startDate',
    populateFunc: 'populateDateTime',
  },
  {
    name: 'check_out',
    displayName: 'table_header_check_out',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'endDate',
    populateFunc: 'populateDateTime',
  },
  {
    name: 'total_hours',
    displayName: 'table_header_total_hours',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: false,
    mandatory: false,
    associatedKey: 'totalClockTimeSecs',
    populateFunc: 'populateTotalHours',
  },
];
