import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import backgroundIcon from 'assets/images/icons/brand-logo-black.svg';
import uploadIcon from 'assets/images/icons/upload-white.svg';
import Loader from './Loader';

const UploadSectionImage = ({
  selectedImage,
  redirectUrl,
  handleFileChange,
  shrink,
  loader,
  label,
  maxFileSize,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className='upload-image-box d-flex gap-2 align-items-center justify-content-between p-3'>
      <div
        className={`position-relative theme-background-white-3${!loader ? ' cursor-pointer' : ''}${
          shrink ? ' shrink-field' : ''
        }`}
        onClick={!loader ? handleUploadClick : undefined}
      >
        <input
          className='d-none'
          type='file'
          accept='image/jpg,image/jpeg,image/png'
          ref={fileInputRef}
          onChange={handleFileChange}
        />

        {loader ? (
          <Loader color='green-1' />
        ) : (
          <Fragment>
            <img
              className={selectedImage || redirectUrl ? 'preview-image' : 'background-icon'}
              src={
                selectedImage ? URL.createObjectURL(selectedImage) : redirectUrl ?? backgroundIcon
              }
              alt='Loading...'
            />
            <div
              className={`upload-icon-circle theme-background-green-1 d-flex justify-content-center align-items-center${
                shrink ? ' shrink-field' : ''
              }`}
            >
              <img className='upload-icon' src={uploadIcon} alt='Loading...' />
            </div>
          </Fragment>
        )}
      </div>
      <div className='d-flex flex-column align-items-center text-center'>
        <label className={`${!shrink ? 'theme-size-1_1 ' : ''}theme-text-green-1 mb-1`}>
          {t(label ?? 'field_upload_image')}
        </label>
        <label className={!shrink ? 'theme-size-0_9' : 'theme-size-0_8'}>
          {t('text_recommended_minimum_size')}
        </label>
        <label className={!shrink ? 'theme-size-0_9' : 'theme-size-0_8'}>
          {t(`320 x 75Px / ${maxFileSize ?? '5 MB'} max`)}
        </label>
      </div>
    </div>
  );
};

UploadSectionImage.propTypes = {
  redirectUrl: PropTypes.string,
  selectedImage: PropTypes.object,
  handleFileChange: PropTypes.func.isRequired,
  shrink: PropTypes.bool,
  loader: PropTypes.bool,
  label: PropTypes.string,
  maxFileSize: PropTypes.string,
};

export default UploadSectionImage;
