import React, { Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import AuthenticatedLayout from './layout/AuthenticatedLayout';
import FirebaseProtectedRoute from './layout/FirebaseProtectedRoute';
import JWTProtectedRoute from './layout/JWTProtectedRoute';
import NonAuthenticatedLayout from './layout/NonAuthenticatedLayout';
import About from './pages/About';
import ActivityLog from 'pages/activity-log/ActivityLog';
import AddCompany from './pages/company/AddCompany';
import AddFolder from 'pages/folders/AddFolder';
import AddForm from './pages/forms/AddForm';
import AddGroup from 'pages/group/AddGroup';
import AddUser from './pages/users/AddUser';
// import AnnouncementsList from 'pages/announcements/AnnouncementsList';
// import Announcements from 'pages/announcements/Announcements';
import Attendance from 'pages/attendance/Attendance';
import ButtonSamples from 'pages/ButtonSamples';
import CommingSoon from './pages/CommingSoon';
import CompanySetup from './pages/company-setup/CompanySetup';
import Dashboard from 'pages/dashboard/Dashboard';
import DataSource from './pages/datasource/DataSource';
import DataSourceListView from 'pages/datasource/datasourceList/DataSourceListView';
import DownloadPage from 'pages/DownloadPage';
import FormList from './pages/forms/FormList';
import HomePage from 'pages/home/HomePage';
import Icons from './pages/Icons';
import ListCompanies from './pages/company/ListCompanies';
import ListGroups from 'pages/group/ListGroups';
import Login from './pages/auth-pages/Login';
import Register from './pages/auth-pages/Register';
import Report from 'pages/report/Report';
import ResetPassword from './pages/auth-pages/ResetPassword';
import Submissions from 'pages/submissions/Submissions';
import Trash from 'pages/trash/Trash';
import UserList from './pages/users/UserList';
import ToastNotification from 'components/common-components/ToastNotification';
import i18n from './i18n/config';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import Profiles from 'pages/profiles/Profiles';
import AddEditProfiles from 'pages/profiles/AddEditProfiles';
import ResponseSets from 'pages/response-sets/ResponseSets';

function App() {
  document.dir = i18n.dir();
  return (
    <Fragment>
      <ToastNotification />
      <Routes>
        <Route element={<NonAuthenticatedLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/reset_password' element={<ResetPassword />} />
          <Route path='/download' element={<DownloadPage />} />
        </Route>
        <Route path='/terms-conditions' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/report/:actionType/:dataType/:previewId' element={<Report />} />
        <Route path='/about' element={<About />} />
        <Route path='/icons' element={<Icons />} />
        <Route path='/buttons' element={<ButtonSamples />} />

        <Route element={<FirebaseProtectedRoute />}>
          <Route element={<NonAuthenticatedLayout />}>
            <Route path='/all_companies' element={<ListCompanies />} />
          </Route>

          <Route element={<AuthenticatedLayout />}>
            <Route path='/add_company' element={<AddCompany />} />
          </Route>
        </Route>

        <Route element={<JWTProtectedRoute />}>
          <Route path='/' element={<AuthenticatedLayout />}>
            <Route path='/profiles'>
              <Route index element={<Profiles />} />
              <Route path='edit' element={<AddEditProfiles />} />
              <Route path='add' element={<AddEditProfiles />} />
            </Route>

            <Route path='/home' element={<HomePage />} />
            <Route path='/issues' element={<CommingSoon />} />
            <Route path='/tasks' element={<CommingSoon />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/response_set' element={<ResponseSets />} />
            <Route path='/submissions' element={<Submissions />} />
            <Route path='/reports' element={<CommingSoon />} />
            <Route path='/attendance' element={<Attendance />} />
            <Route path='/attendance/:tab' element={<Attendance />} />
            <Route path='/guests' element={<CommingSoon />} />
            <Route path='/billing' element={<CommingSoon />} />
            <Route path='/support' element={<CommingSoon />} />
            <Route path='/help' element={<CommingSoon />} />
            <Route path='/profile' element={<CommingSoon />} />
            <Route path='/guests' element={<CommingSoon />} />
            <Route path='/trash' element={<Trash />} />
            {/* <Route path='/announcement' element={<AnnouncementsList />} />
            <Route path='/announcement/add' element={<Announcements />} /> */}
            <Route path='/announcement' element={<CommingSoon />} />
            <Route path='/announcement/add' element={<CommingSoon />} />
            <Route path='/directory' element={<CommingSoon />} />
            <Route path='/activity_log' element={<ActivityLog />} />

            <Route index element={<Navigate to='/users' />} />
            <Route path='/users'>
              <Route index element={<UserList />} />
              <Route path='new' element={<AddUser />} />
            </Route>
            <Route path='/folders'>
              <Route index element={<AddFolder />} />
            </Route>
            <Route path='/forms'>
              <Route index element={<FormList />} />
              <Route path='new' element={<AddForm />} />
              <Route path='edit' element={<AddForm />} />
              <Route path='submissions' element={<CommingSoon />} />
            </Route>
            <Route path='/company'>
              <Route index element={<CompanySetup />} />
            </Route>
            <Route path='/datasource'>
              <Route index element={<DataSource />} />
              <Route path='entries' element={<DataSourceListView />} />
            </Route>
            <Route path='/groups'>
              <Route index element={<ListGroups />} />
              <Route path='add-group' element={<AddGroup />} />
              <Route path='update-group' element={<AddGroup />} />
            </Route>

            <Route path='*' element={<h1>Not Found</h1>} />
          </Route>
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
