import React from 'react';
import PropTypes from 'prop-types';
import filterGreyIcon from 'assets/images/icons/filter-grey.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import FormButton from 'components/form-components/FormButton';

const Filter = ({ filterEnabled, setFilterEnabled }) => {
  return (
    <FormButton
      text='text_filter'
      variant={`${filterEnabled ? 'green-1' : 'white-1'}`}
      onClick={() => setFilterEnabled(!filterEnabled)}
      icon={filterEnabled ? filterIcon : filterGreyIcon}
      iconClasses={filterEnabled ? 'svg-white' : ''}
    />
  );
};

Filter.propTypes = {
  filterEnabled: PropTypes.bool.isRequired,
  setFilterEnabled: PropTypes.func.isRequired,
};

export default Filter;
