import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Modal } from 'react-bootstrap';
import deleteIcon from 'assets/images/icons/warning-red.svg';
import successIcon from 'assets/images/icons/check-with-circle.svg';
import { useTranslation } from 'react-i18next';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import FormButton from 'components/form-components/FormButton';

const AlertModal = ({
  show,
  closeModal,
  showCloseButton,
  size,
  headerIcon,
  title,
  message,
  data,
  children,
  actionButtons,
  variant,
  noPadding,
}) => {
  const { t } = useTranslation();
  const headIcon =
    variant === undefined && headerIcon
      ? headerIcon
      : variant === 'delete'
      ? deleteIcon
      : variant === 'success'
      ? successIcon
      : undefined;

  return (
    <Modal
      show={show}
      contentClassName='alert-modal'
      aria-labelledby='contained-modal-title-vcenter'
      size={size}
      centered
      backdrop='static'
      keyboard={false}
    >
      <div
        className={`alert-content d-flex flex-column gap-3 justify-content-center align-items-center ${
          noPadding ? 'p-0' : 'p-4'
        }`}
      >
        {showCloseButton && <CloseButton onClick={() => closeModal()} />}
        {headIcon && <img className='header-icon' src={headIcon} />}
        {title && <label className='alert-title'>{t(title)}</label>}
        {message && <p className='alert-message'>{t(message)}</p>}
        {data && <Fragment>{data}</Fragment>}
        {children && <Fragment>{children}</Fragment>}
        {actionButtons.length > 0 && (
          <div className='d-flex gap-3'>
            {actionButtons.map((buttonProps, index) =>
              buttonProps['loading'] ? (
                <FormLoadingButton key={index} {...buttonProps} />
              ) : (
                <FormButton key={index} {...buttonProps} />
              ),
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  showCloseButton: PropTypes.bool,
  size: PropTypes.string,
  headerIcon: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.object,
  actionButtons: PropTypes.array.isRequired,
  noPadding: PropTypes.bool,
};

export default AlertModal;
