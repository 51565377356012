import React, { useEffect, useState } from 'react';
import FormButton from 'components/form-components/FormButton';
import plusIcon from 'assets/images/icons/plus.svg';
import searchIcon from 'assets/images/icons/search.svg';
import TextField from 'components/form-components/TextField';
import PropTypes from 'prop-types';
import arrowLeft from 'assets/images/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import AddColumnDropdown from './AddColumnDropdown';
import EntriesActions from './EntriesActions';
import Export from 'components/common-components/Export';
import ColumnActions from './ColumnActions';
import { CallAPI } from 'actions/General';

const EditDataSourceHeader = ({
  refreshLists,
  setAddRow,
  title,
  datasource_id,
  dataSourceName,
  onBack,
  fieldsList,
  selectedRows,
  selectedColumns,
  actionsCallBack,
}) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const searchChanged = (_, value) => setSearchText(value);

  const [allowRemoveKeyFilter, setAllowRemoveKeyFilter] = useState(false);

  const getDataSourceFilterData = async () => {
    const payload = { dataSourceId: datasource_id, page: 1, limit: 10 };
    const result = await CallAPI('GET_DATA_SOURCE_FILTER_DATA', payload, null, null, null, null);
    if (result.status) setAllowRemoveKeyFilter(result.data.totalCount === 0);
  };

  useEffect(() => {
    if (datasource_id) getDataSourceFilterData();
  }, [datasource_id]);

  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className='d-flex align-items-center gap-3'>
        <div className='d-flex align-items-center gap-3' onClick={() => onBack()}>
          <img src={arrowLeft} className='icon back-arrow' />
          <label className='theme-size-1_3 theme-font-inter-semi-bold'>{t(title)}</label>
        </div>
        {selectedColumns.length > 0 ? (
          <ColumnActions
            columns={selectedColumns}
            dataSourceId={datasource_id}
            dataSourceName={dataSourceName}
            fieldsList={fieldsList}
            refreshLists={refreshLists}
            allowRemoveKeyFilter={allowRemoveKeyFilter}
          />
        ) : (
          <EntriesActions
            ids={selectedRows}
            isActionButton={false}
            actionsCallBack={actionsCallBack}
            actionData={{
              datasourceName: title,
              row_datasource_id: datasource_id,
            }}
            type={'data_sources_entries'}
          />
        )}
      </div>
      <div className='d-flex align-items-center gap-3'>
        {/* <FormButton
          text={t('ImportSCV')}
          variant='blue-1'
          icon={importIcon}
          classes='text-nowrap'
        /> */}

        <TextField
          value={searchText}
          name='search'
          handleChange={searchChanged}
          placeholder={t('field_search')}
          icon={searchIcon}
          classes='mb-0'
          shrink
        />

        {/* <img src={filterIcon} className='icon' /> */}

        <AddColumnDropdown
          datasource_id={datasource_id}
          refreshLists={refreshLists}
          fieldsList={fieldsList}
        />

        <Export
          type={'data_source'}
          screenTab={'database'}
          additionalDetail={{
            dataSourceId: datasource_id,
            dataSourceName: title,
            exportMode: 'all',
          }}
        />

        <FormButton
          text='button_create_row'
          onClick={() => setAddRow(true)}
          variant='green-2'
          icon={plusIcon}
        />
      </div>
    </div>
  );
};
EditDataSourceHeader.propTypes = {
  title: PropTypes.string.isRequired,
  datasource_id: PropTypes.string.isRequired,
  dataSourceName: PropTypes.string.isRequired,
  setAddRow: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  refreshLists: PropTypes.func.isRequired,
  fieldsList: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
};
export default EditDataSourceHeader;
