import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormLoadingButton from '../../../components/form-components/FormLoadingButton';
import TextField from '../../../components/form-components/TextField';
import { addDataSourceSchema } from '../utils';
import FormButton from '../../../components/form-components/FormButton';
import { OverlayTrigger, Popover } from 'react-bootstrap';
//import { CallAPI } from 'actions/General';
import { useTranslation } from 'react-i18next';
import Error from 'components/Error';
import plusIcon from '../../../assets/images/icons/plus.svg';
import { CallAPI } from 'actions/General';

const AddDataSource = ({ onSave, editRecord, clearEditRecord }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const [errors, setErrors] = useState({});
  const initialState = { schemaName: '', dataSourceType: '', externalId: '' };
  const [values, setValues] = useState({ ...initialState });

  const setUpDataSource = async () => {
    let APIName = 'ADD_DATA_SOURCE';
    let recordId = null;
    if (editRecord && editRecord._id) {
      APIName = 'UPDATE_DATA_SOURCE';
      recordId = editRecord._id;
    }
    const result = await CallAPI(APIName, { ...values }, recordId, setLoader, setApiError, null);
    if (result.status) {
      setShow(false);
      onSave();
    }
  };

  useEffect(() => {
    if (editRecord != null) {
      setValues({
        ...values,
        schemaName: editRecord.schemaName,
        dataSourceType: editRecord.dataSourceType,
        externalId: editRecord.externalId,
      });
      setShow(true);
    }
  }, [editRecord]);

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const submit = async () => {
    try {
      await addDataSourceSchema.validate(values, { abortEarly: false });
      setUpDataSource();
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  const onCancel = () => {
    if (editRecord) clearEditRecord();
    setValues({ ...initialState });
    setShow(false);
  };

  const popover = (
    <Popover className='min-width-176' id='popover-basic' style={{ margin: '0 -1.5rem' }}>
      <Popover.Header className='theme-background-white-2 theme-text-black-1' as='h3'>
        {t('button_create')}
      </Popover.Header>
      <Popover.Body>
        {apiError ? <Error msg={apiError} /> : <></>}
        <div className='d-flex w-100 gap-3 '>
          <div className='d-flex flex-column gap-3 w-100 '>
            <TextField
              name='schemaName'
              displayName=''
              placeholder='field_data_source_name'
              handleChange={handleChange}
              value={values.schemaName}
              error={errors.schemaName}
              classes='mb-3'
            />
            <TextField
              name='dataSourceType'
              displayName=''
              placeholder='field_data_source_type'
              handleChange={handleChange}
              value={values.dataSourceType}
              error={errors.dataSourceType}
              classes='mb-3'
            />
            <TextField
              name='externalId'
              displayName=''
              placeholder='field_external_id'
              handleChange={handleChange}
              value={values.externalId}
              error={errors.externalId}
              classes='mb-3'
            />
            <div className='d-flex w-100 gap-2 '>
              <FormButton text='button_cancel' variant='white-1' onClick={() => onCancel()} />
              <FormLoadingButton
                text={editRecord && editRecord._id ? 'button_update' : 'button_save'}
                variant='green-1'
                loading={loader}
                onClick={() => submit()}
              />
            </div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className=''>
      <OverlayTrigger
        trigger='click'
        onToggle={() => setShow(!show)}
        show={show}
        placement='bottom'
        overlay={popover}
      >
        <div className='custom-form-button width-max-content theme-button-green-1'>
          <img className='svg-white' src={plusIcon} />
          {t('button_create')}
        </div>
      </OverlayTrigger>
    </div>
  );
};

AddDataSource.propTypes = {
  editRecord: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  clearEditRecord: PropTypes.func.isRequired,
};

export default AddDataSource;
