import React from 'react';
import PropTypes from 'prop-types';
import rectangleIcon from 'assets/images/icons/rectangle.svg';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import { useTranslation } from 'react-i18next';

const ThumbnailSelectionField = ({ name, value, handleChange, classes }) => {
  const { t } = useTranslation();
  const thumbnailOptionList = [
    { value: 'small', displayValue: 'select_small', imagesPerRow: 6 },
    { value: 'medium', displayValue: 'select_medium', imagesPerRow: 4 },
    { value: 'large', displayValue: 'select_large', imagesPerRow: 3 },
  ];

  return (
    <div className={`thumbnail-selection-field${classes ? ` ${classes}` : ''}`}>
      {thumbnailOptionList.map((option, index) => (
        <div key={index}>
          <div
            className={`option${value === option.value ? ' active' : ''}`}
            onClick={() => handleChange(name, option.value)}
          >
            <div className='check'>
              <SingleCheckBoxField
                name='check'
                value={value === option.value}
                handleChange={() => {}}
                text={<></>}
                shrink
              />
            </div>

            <div className='w-100 d-flex gap-2'>
              {Array.from(Array(option.imagesPerRow).keys()).map((_, index) => (
                <div key={index} className='flex-grow-1'>
                  <img src={rectangleIcon} className='w-100' />
                </div>
              ))}
            </div>
          </div>
          <div className='d-flex flex-column gap-1 mt-2'>
            <label className='theme-font-inter-medium theme-text-black-1'>
              {t(option.displayValue)}
            </label>
            <label className='theme-text-black-1 theme-size-0_9'>{`${option.imagesPerRow} `+ t('text_images_per_row')}</label>
          </div>
        </div>
      ))}
    </div>
  );
};

ThumbnailSelectionField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

export default ThumbnailSelectionField;
