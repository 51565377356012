import React from 'react';
import PropTypes from 'prop-types';

const SingleRadioBoxField = ({
  name,
  text,
  value,
  disabled,
  error,
  touched,
  shrink,
  handleChange,
  classes,
}) => {
  const shrinkCheckOption = shrink ? ' shrink-check-option' : '';
  const shrinkCheckbox = shrink ? ' shrink-checkbox' : '';
  const shrinkLabel = shrink ? ' shrink-label' : '';
  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      <div className={`custom-check-option${shrinkCheckOption}`}>
        <input
          type='radio'
          id={name}
          name={name}
          disabled={disabled ?? false}
          checked={value}
          onChange={(e) => (handleChange ? handleChange(e.target.name, e.target.checked) : null)}
          className={`form-check-input${shrinkCheckbox}`}
        />
        <label htmlFor={name} className={`mb-0 cursor-pointer${shrinkLabel}`}>
          {text}
        </label>
      </div>
      {!touched && error && <p className='form-field-error'>{error}</p>}
    </div>
  );
};

SingleRadioBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

export default SingleRadioBoxField;
