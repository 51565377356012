import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import arrowIcon from 'assets/images/icons/double-down-arrow.svg';
import directoryIcon from 'assets/images/icons/directory.svg';
import filterGreyIcon from 'assets/images/icons/filter-grey.svg';
import folderHiddenIcon from 'assets/images/icons/folder-hidden.svg';
import folderLockedIcon from 'assets/images/icons/folder-locked.svg';
import plusGreyIcon from 'assets/images/icons/plus-grey.svg';
import settingGreyIcon from 'assets/images/icons/setting-grey.svg';
import SingleRadioBoxField from 'components/form-components/SingleRadioBoxField';
import { CallAPI } from 'actions/General';
import { useTranslation } from 'react-i18next';

const TableFolderListFilter = ({ closeFilter, updateFolderList }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [folderList, setFolderList] = useState([]);
  const [filteredFolderList, setFilteredFolderList] = useState([]);
  const [folderFilterOpen, setFolderFilterOpen] = useState(false);
  const [folderFilter, setFolderFilter] = useState('');
  const [formFilter, setFormFilter] = useState('');

  const GetAllFolders = async () => {
    let result = await CallAPI('GET_ALL_FOLDERS', {}, null, null, null, null);
    if (result.status) {
      setFolderList(result.data);
      setFilteredFolderList(result.data);
    }
  };

  const toggleFormFilter = (value) => {
    updateFolderList(
      value === 'all-folder' ? 'all' : value === 'no-folder' ? 'no_folder' : 'custom',
      formFilter !== value ? value : '',
    );
    setFormFilter(formFilter !== value ? value : '');
  };
  useEffect(() => {
    GetAllFolders();
  }, []);

  useEffect(() => {
    if (folderFilter === 'hidden') {
      const tempFolderList = folderList.filter((f) => f.isHidden);
      setFilteredFolderList(tempFolderList);
    } else if (folderFilter === 'locked') {
      const tempFolderList = folderList.filter((f) => f.isLocked);
      setFilteredFolderList(tempFolderList);
    } else {
      const tempFolderList = [...folderList];
      setFilteredFolderList(tempFolderList);
    }
  }, [folderFilter]);

  return (
    <div className='table-folder-filter-list'>
      <div className='folder-filter-list-header '>
        <label className='theme-font-jakartaSans-medium'>{t('text_folders')}</label>
        <div className='d-flex gap-4'>
          <img
            src={plusGreyIcon}
            className='cursor-pointer'
            onClick={() => navigate('/folders', { relative: 'path' })}
          />
          <img
            className='cursor-pointer rotate-270'
            src={arrowIcon}
            onClick={() => closeFilter()}
          />
        </div>
      </div>
      <div className='filters'>
        <label
          className={`rounded-pill${formFilter === 'all-folder' ? ' active' : ''}`}
          onClick={() => toggleFormFilter('all-folder')}
        >
          {t('text_all')}
        </label>
        <label
          className={`rounded-pill${formFilter === 'no-folder' ? ' active' : ''}`}
          onClick={() => toggleFormFilter('no-folder')}
        >
          {t('text_unassigned_forms')}
        </label>
        <img
          src={filterGreyIcon}
          className='cursor-pointer'
          onClick={() => setFolderFilterOpen(!folderFilterOpen)}
        />
        {folderFilterOpen && (
          <FolderFilter
            folderFilter={folderFilter}
            setFolderFilter={setFolderFilter}
            setFolderFilterOpen={setFolderFilterOpen}
          />
        )}
      </div>

      <div className='folder-list'>
        {filteredFolderList.map((folder) => (
          <div
            key={folder._id}
            className={`d-flex gap-4 align-items-center text-left mb-1${
              formFilter === folder._id ? ' selected' : ''
            }`}
          >
            <img
              src={
                folder.isLocked
                  ? folderLockedIcon
                  : folder.isHidden
                  ? folderHiddenIcon
                  : directoryIcon
              }
              className='cursor-pointer'
              onClick={() => toggleFormFilter(folder._id)}
            />
            <label
              className='theme-font-jakartaSans-medium flex-grow-1 cursor-pointer'
              onClick={() => toggleFormFilter(folder._id)}
            >
              {folder.name}
            </label>
            <img
              src={settingGreyIcon}
              className='cursor-pointer'
              onClick={() =>
                navigate('/folders', {
                  relative: 'path',
                  state: { type: 'edit', folderId: folder._id },
                })
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

TableFolderListFilter.propTypes = {
  closeFilter: PropTypes.func.isRequired,
  updateFolderList: PropTypes.func.isRequired,
};

export default TableFolderListFilter;

const FolderFilter = ({ folderFilter, setFolderFilter, setFolderFilterOpen }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(folderFilter);
  }, []);

  const filterClick = () => {
    setFolderFilter(value);
  };

  return (
    <div className='filter-modal'>
      <div className='theme-background-white-3 px-3 py-1'>
        <label className='theme-size-1_2 theme-text-black-1 theme-font-jakartaSans-medium'>
          {t('text_folders')}
        </label>
      </div>
      <div className='py-2 px-3'>
        <SingleRadioBoxField
          name='all-folders'
          value={value === 'all-folders'}
          handleChange={() => setValue('all-folders')}
          text={<span>{t('text_all')}</span>}
          classes='mb-2'
          shrink
        />
        <SingleRadioBoxField
          name='hidden'
          value={value === 'hidden'}
          handleChange={() => setValue('hidden')}
          text={<span>{t('text_hidden')}</span>}
          classes='mb-2'
          shrink
        />
        <SingleRadioBoxField
          name='locked'
          value={value === 'locked'}
          handleChange={() => setValue('locked')}
          text={<span>{t('text_locked')}</span>}
          classes='mb-3'
          shrink
        />
        <div className='d-flex gap-2 justify-content-around'>
          <label
            className='theme-text-red-1 cursor-pointer'
            onClick={() => setFolderFilterOpen(false)}
          >
            {t('text_cancel')}
          </label>

          <label className='theme-text-green-1 cursor-pointer' onClick={() => filterClick()}>
            {t('text_filter')}
          </label>
        </div>
      </div>
    </div>
  );
};

FolderFilter.propTypes = {
  folderFilter: PropTypes.string.isRequired,
  setFolderFilter: PropTypes.func.isRequired,
  setFolderFilterOpen: PropTypes.func.isRequired,
};
