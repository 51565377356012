import React from 'react';
import PropTypes from 'prop-types';

const ToggleBox = ({ children, classes, style }) => {
  return (
    <div className={`div-toggler${classes ? ` ${classes}` : ''}`} {...(style && { style })}>
      {children}
    </div>
  );
};

ToggleBox.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.string,
  style: PropTypes.object,
};

export default ToggleBox;
