import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import addLogo from '../../assets/images/add-logo.svg';
import { useTranslation } from 'react-i18next';

const AddCompanyStep4 = ({ file, setFile, touched, errors }) => {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const newFile = event.target.files[0];
    setFile(newFile);
  };
  return (
    <div className='d-flex flex-column'>
      <div>
        <p className='text-left theme-font-inter-bold theme-size-1_5 theme-text-black-1 mb-0'>
          {t('text_brand_logo')}
        </p>
        <p className='text-left theme-font-inter-regular theme-size-1 theme-text-grey-1'>
          {t('text_replace_your_logo')}
        </p>
      </div>
      <div
        className='d-flex gap-3 mb-3 justify-content-evenly logo-container theme-width-453'
        onClick={() => {
          inputFile.current.click();
        }}
      >
        <img src={file ? URL.createObjectURL(file) : addLogo} />
        <div className='d-flex flex-column justify-content-center'>
          <span className='theme-font-jakartaSans-semi-bold theme-size-1_1 theme-text-green-1'>
            {t('field_upload_your_logo')}
          </span>
          <span className='theme-font-jakartaSans-medium theme-size-0_9 theme-text-grey-1'>
            {t('text_recommended_size')}
          </span>
          <span className='theme-font-jakartaSans-medium theme-size-0_9 theme-text-grey-1'>
            {t("180 x 180 Px / 5 MB max")}
          </span>
        </div>
      </div>
      {!touched.features && errors.features && (
        <p className='form-field-error'>{t(errors.features)}</p>
      )}
      <input
        type='file'
        id='file'
        ref={inputFile}
        onChange={onChangeFile}
        style={{ display: 'none' }}
      />
    </div>
  );
};
AddCompanyStep4.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
};
export default AddCompanyStep4;
