import React, { useEffect, useState } from 'react';
import GeneralHeader from './GeneralHeader';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AuthReducer } from '../../../redux/auth';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import CompanyDetails from './CompanyDetails';
import CompanySettings from './CompanySettings';
import AdditionalDetails from './AdditionalDetails';
import { CallAPI } from '../../../actions/General';
import Loader from '../../../components/common-components/Loader';
import BrandLogo from '../company-branding/BrandLogo';
import ColorScheme from '../company-branding/ColorScheme';
import AppWelcomeScreen from '../company-branding/AppWelcomeScreen';
import SuperAdminDetails from './SuperAdminDetails';
import TabDivider from 'components/common-components/TabDivider';
const AuthAction = AuthReducer.actions;

const CompanyGeneral = ({ selectedTab }) => {
  const dispatch = useDispatch();
  const [subTabsList, setSubTabsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  useEffect(() => {
    setSubTabsList(selectedTab.subTabs);
  }, [selectedTab.subTabs]);
  const { t } = useTranslation();

  const onHeaderClick = (tab) => {
    let selectedSubTab = null;
    subTabsList.map((singleSubTab) => {
      if (singleSubTab.id === tab.id) {
        if (singleSubTab.isSelected) {
          singleSubTab.isSelected = false;
        } else {
          selectedSubTab = singleSubTab;
          singleSubTab.isSelected = true;
        }
      } else {
        singleSubTab.isSelected = false;
      }
    });
    if (selectedSubTab) {
      dispatch(
        AuthAction.updateBreadCrumb({
          breadcrumbs: selectedSubTab.breadcrumbValue,
        }),
      );
    } else {
      dispatch(
        AuthAction.updateBreadCrumb({
          breadcrumbs: selectedTab.breadcrumbValue,
        }),
      );
    }
    setSubTabsList(cloneDeep(subTabsList));
  };

  const getCompanyDetail = async () => {
    let result = await CallAPI('GET_COMPANY_DETAIL', null, null, setLoader, null, null);
    if (result.status) {
      setCompanyDetails(result.data);
    }
  };

  useEffect(() => {
    getCompanyDetail();
  }, []);

  return (
    <div className='p-3 '>
      {loader && <Loader color='green-1' type='modal-spinner' />}
      {subTabsList.map((singleTab, index) => {
        return (
          <div key={index}>
            <TabDivider />
            <GeneralHeader
              title={t(singleTab.title)}
              icon={singleTab.isSelected ? singleTab.selectedIcon : singleTab.icon}
              onClick={() => onHeaderClick(singleTab)}
              isSelected={singleTab.isSelected}
            />
            {singleTab.isSelected ? (
              singleTab.name === 'CompanyDetails' ? (
                companyDetails && (
                  <CompanyDetails
                    companyDetails={companyDetails}
                    getCompanyDetail={getCompanyDetail}
                  />
                )
              ) : singleTab.name === 'CompanySettings' ? (
                <CompanySettings
                  companyDetails={companyDetails}
                  getCompanyDetail={getCompanyDetail}
                />
              ) : singleTab.name === 'SuperAdminDetails' ? (
                <SuperAdminDetails
                  companyDetails={companyDetails}
                  getCompanyDetail={getCompanyDetail}
                />
              ) : singleTab.name === 'AdditionalDetails' ? (
                <AdditionalDetails
                  companyDetails={companyDetails}
                  getCompanyDetail={getCompanyDetail}
                />
              ) : singleTab.name === 'BrandLogo' ? (
                <BrandLogo companyDetails={companyDetails} getCompanyDetail={getCompanyDetail} />
              ) : singleTab.name === 'ColorScheme' ? (
                <ColorScheme companyDetails={companyDetails} getCompanyDetail={getCompanyDetail} />
              ) : singleTab.name === 'AppWelcomeScreen' ? (
                <AppWelcomeScreen
                  companyDetails={companyDetails}
                  getCompanyDetail={getCompanyDetail}
                />
              ) : null
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
CompanyGeneral.propTypes = {
  selectedTab: PropTypes.object.isRequired,
};
export default CompanyGeneral;
