import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function PrepareErrorList({ errorMessages }) {
  const { t } = useTranslation();
  return (
    <div className='form-error-messages'>
      {errorMessages.map((error, index) => (
        <div key={index}>
          <label className='theme-size-1_1 theme-text-black-1'>
            {error.errorsClassification.map((partText) => t(partText)).join('')}
          </label>
          <ul>
            {error.errorList.map((err, index) => (
              <li key={index}>{err.map((partText) => t(partText)).join('')}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
PrepareErrorList.propTypes = {
  errorMessages: PropTypes.array.isRequired,
};
export default PrepareErrorList;
