import React, { useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import { CallAPI } from 'actions/General';
import ActivityList from './ActivityList';
import { useLocation } from 'react-router-dom';
import { ActivityFiltersList, UserActivityTabs } from './utils';
import TabLayout from 'components/tab-components/TabLayout';
import TabHeader from 'components/tab-components/TabHeader';
import TabBody from 'components/tab-components/TabBody';
import UserDetails from 'pages/users/user-details/UserDetails';
import UserAccess from 'pages/users/user-details/UserAccess';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';

const ActivityLog = () => {
  const location = useLocation();
  const [type, setType] = useState('groups'); // users || groups
  const [selectedFilterTab, setSelectedFilterTab] = useState(ActivityFiltersList[0]); // all || forms || tasks || issues || attendance
  const [selectedTab, setSelectedTab] = useState(UserActivityTabs[0]);
  const [activities, setActivities] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [userName, setUserName] = useState('');
  const [isCompanyActivity, setIsCompanyActivity] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const getAllActivities = async (differentSearch = false) => {
    let APIData = {
      page: pageNumber,
      limit: 25,
      logType: selectedFilterTab.value,
    };
    let APICall = 'COMPANY_ACTIVITIES';
    if (selectedId) {
      if (type === 'groups') {
        APIData.groupId = selectedId.toString();
        APICall = 'GROUP_ACTIVITIES';
      } else {
        APIData.userIds = [selectedId.toString()];
        APICall = 'USER_ACTIVITIES';
      }
    }
    let result = await CallAPI(APICall, APIData, null, null, null);
    if (result.status) {
      const tempActivities = pageNumber === 1 || differentSearch ? [] : [...activities];
      setActivities([...tempActivities, ...result.data.activityLogs]);
      if (result.data.totalCount > 0) setTotalRecords(result.data.totalCount);
    }
  };

  useEffect(() => {
    if (selectedId !== '' && isPermissionAvailable(permissionKeys.view_data_activity_log_read)) {
      if (pageNumber === 1) getAllActivities(true);
      else setPageNumber(1);
    }
  }, [selectedId]);
  useEffect(() => {
    if (isPermissionAvailable(permissionKeys.view_data_activity_log_read)) getAllActivities();
  }, [pageNumber]);
  useEffect(() => {
    if (isPermissionAvailable(permissionKeys.view_data_activity_log_read))
      if (pageNumber === 1) getAllActivities(true);
      else setPageNumber(1);
  }, [selectedFilterTab]);

  const getUserDetail = async (user_id) => {
    let userDetailResult = await CallAPI('GET_USER_PROFILE', {
      userId: user_id,
    });
    if (userDetailResult.status) {
      let user = userDetailResult.data.users[0];
      setUserDetail({
        user_id: user.user._id,
        user_company_id: user._id,
        contact: {
          firstName: user.user.firstName,
          lastName: user.user.lastName,
          email: user.email ? user.email : '',
          phone: user.user.countryCode + '' + user.user.mobileNumber,
        },
        company: {
          title: user.title ? user.title : '',
          department: user.department ? user.department : '',
          branch: user.branch ? user.branch : '',
          employmentStartDate: user.startDate ? user.startDate : '',
        },
        appInfo: {
          lastLoginDate: user.lastLoginDate,
          sessionsCount: user.sessionsCount ? user.sessionsCount : user.lastLoginDate ? 1 : 0,
        },
        groups: user.userGroups,
        tags: user.tags,
      });
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.userId) {
      getUserDetail(location.state.userId);
      setSelectedId(
        location && location.state && location.state.userId ? location.state.userId : '',
      );
      setType('users');
      setSelectedFilterTab({ ...ActivityFiltersList[0], breadcrumbValue: null });
      setUserName(
        location && location.state && location.state.userName ? location.state.userName : '',
      );
      setIsCompanyActivity(false);
    } else {
      setIsCompanyActivity(true);
      setSelectedFilterTab(ActivityFiltersList[0]);
    }
  }, [location]);

  const activityTabChanged = (filter) => {
    delete filter.breadcrumbValue;
    setSelectedFilterTab(filter);
  };

  return (
    <>
      {isCompanyActivity ? (
        <div className='activity-log theme-background-white-1 row gx-0'>
          <div className='col-3 border border-top-0'>
            <SideMenu
              type={type}
              setType={setType}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
            />
          </div>
          <div className='col-9'>
            {activities && (
              <ActivityList
                activitiesList={activities}
                selectedTab={selectedFilterTab}
                setSelectedTab={activityTabChanged}
                isCompanyActivity={isCompanyActivity}
                totalRecords={totalRecords}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </div>
        </div>
      ) : (
        activities && (
          <TabLayout>
            <TabHeader
              tabList={UserActivityTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabUserName={userName}
            />
            <TabBody variant='nominheight'>
              {selectedTab.value === 'activity' ? (
                <ActivityList
                  activitiesList={activities}
                  selectedTab={selectedFilterTab}
                  setSelectedTab={activityTabChanged}
                  isCompanyActivity={isCompanyActivity}
                  totalRecords={totalRecords}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              ) : selectedTab.value === 'detail' ? (
                userDetail ? (
                  <UserDetails
                    userDetail={userDetail}
                    getUserDetail={() => getUserDetail(location.state.userId)}
                  />
                ) : (
                  <></>
                )
              ) : (
                <UserAccess userId={selectedId} />
              )}
            </TabBody>
          </TabLayout>
        )
      )}
    </>
  );
};

export default ActivityLog;
