import React, { useState } from 'react';
import AddCompanyHeader from './AddCompanyHeader';
import AddCompanyStep1 from './AddCompanyStep1';
import AddCompanyStep2 from './AddCompanyStep2';
import { step1Schema, step2Schema, step3Schema, step4Schema } from './utils';
import AddCompanyStep3 from './AddCompanyStep3';
import AddCompanyStep4 from './AddCompanyStep4';
import { CallAPI } from '../../actions/General';
import FormLoadingButton from '../../components/form-components/FormLoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Error from '../../components/Error';
import { v4 as uuidv4 } from 'uuid';
import { uploadImage } from './utils';
import { useTranslation } from 'react-i18next';
import { allFeaturesList } from 'config/config';

const AddCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isLeavingCompanyCreationProcess, setIsLeavingCompanyCreationProcess] = useState(false);
  const [file, setFile] = useState(null);

  const [values, setValues] = useState({
    name: '',
    role: '',
    email: location && location.state && location.state.email ? location.state.email : '',
    employees: '',
    businesses: [],
    features: allFeaturesList,
    mediaId: null,
    logo: null,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const setUpCompany = async (mediaId, logo) => {
    if (mediaId) {
      setValues({ ...values, mediaId: mediaId, logo: logo });
    }
    let result = await CallAPI(
      'CREATE_COMPANY',
      {
        companyName: values.name,
        title: values.role,
        businessEmail: values.email,
        userEmail:
          location && location.state && location.state.email ? location.state.email : values.email,
        employeeRange: values.employees,
        businessIndustry: JSON.stringify(values.businesses),
        dashboardFeatures: JSON.stringify(values.features),
        mediaId: mediaId,
      },
      null,
      setLoader,
      setApiError,
    );
    if (result.status) {
      navigate('/all_companies');
    }
  };
  const handleChange = (name, value) => {
    setTouched({ ...touched, [name]: true });
    setErrors({ ...errors, [name]: undefined });
    setValues({ ...values, [name]: value });
  };

  const nextStep = async () => {
    setTouched({});
    try {
      let selectedSchema =
        step === 1
          ? step1Schema
          : step === 2
          ? step2Schema
          : step === 3
          ? step3Schema
          : step4Schema;
      await selectedSchema.validate(values, { abortEarly: false });
      let isBusinessEmailExist = true;
      if (step === 1) {
        const isBusinessEmailExistResponse = await CallAPI(
          'CHECK_BUSINESS_EMAIL_EXIST',
          { email: values.email },
          null,
          setLoader,
          setApiError,
          null,
        );

        if (isBusinessEmailExistResponse.status === 0) isBusinessEmailExist = false;
      }

      if (isBusinessEmailExist) {
        if (step === 4) {
          if (file && file.name) {
            setLoader(true);
            let uuid = uuidv4();
            let response = await CallAPI('GET_COMPANY_LOGO_FIREBASE_URL', {
              customFolderId: uuid,
              mediaType: 'image',
              imageName: uuid + '-' + file.name,
            });
            if (response.status) {
              await uploadImage(file, response.data, setUpCompany);
            }
          } else setUpCompany(null, null);
        } else setStep(step + 1);
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };
  const leaveCompanyCreationProcess = () => {
    setIsLeavingCompanyCreationProcess(true);
  };
  return (
    <div className='modal custom-modal'>
      <div className='theme-width-633 mx-3 position-relative'>
        <AddCompanyHeader
          step={step}
          setStep={step === 1 ? leaveCompanyCreationProcess : setStep}
        />
        {apiError ? <Error msg={apiError} /> : <></>}
        <div className='body'>
          {step === 1 ? (
            <AddCompanyStep1
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
            />
          ) : step === 2 ? (
            <AddCompanyStep2
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
            />
          ) : step === 3 ? (
            <AddCompanyStep3
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
            />
          ) : step === 4 ? (
            <AddCompanyStep4
              errors={errors}
              touched={touched}
              file={file}
              setFile={setFile}
            />
          ) : (
            <></>
          )}
          <FormLoadingButton
            text={step === 4 ? 'button_complete' : 'button_next_step'}
            variant='green-1'
            loading={loader}
            classes='w-auto rounded-full theme-size-1_1 theme-font-jakartaSans-medium mt-4'
            onClick={nextStep}
          />
        </div>
      </div>
      {isLeavingCompanyCreationProcess && (
        <div className='modal custom-company-leave-process'>
          <div className='d-flex flex-column gap-2 align-items-center'>
            <label className='theme-size-1_5 theme-font-inter-medium theme-text-black-1'>
              {t('text_leave_company_setup')}
            </label>
            <label className='theme-font-inter-regular'>{t('text_sure_leave_company_setup')}</label>
            <div className='w-100 d-flex gap-2 justify-content-around mt-3'>
              <label
                className='theme-size-1_3 theme-font-inter-medium theme-text-black-1 cursor-pointer'
                onClick={() => setIsLeavingCompanyCreationProcess(false)}
              >
                {t('button_cancel')}
              </label>
              <label
                className='theme-size-1_3 theme-font-inter-medium theme-text-red-1 cursor-pointer'
                onClick={() => {
                  navigate('/all_companies');
                }}
              >
                {t('button_leave')}
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCompany;
