import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

const TrackingOption = ({ title, isSelected, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column theme-width-175 justify-content-center align-items-center gap-3'>
      <div
        className={`location-tracking-option ${isSelected ? 'active' : ''}`}
        onClick={() => onClick()}
      >
        {t(title)}
      </div>

      <SingleCheckBoxField
        name={title}
        value={isSelected}
        handleChange={() => onClick()}
        classes='w-auto'
        text={<span></span>}
      />
    </div>
  );
};

TrackingOption.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default TrackingOption;
