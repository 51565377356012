import React, { useState } from 'react';
import TabBody from 'components/tab-components/TabBody';
import TabHeader from 'components/tab-components/TabHeader';
import TabLayout from 'components/tab-components/TabLayout';
import DashboardForms from './DashboardForms';
import { tabList } from './utils';

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState(tabList[0]);

  return (
    <TabLayout>
      <TabHeader tabList={tabList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <TabBody>{selectedTab && <DashboardForms type={selectedTab.value} />}</TabBody>
    </TabLayout>
  );
};

export default Dashboard;
