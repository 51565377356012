import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import plusIcon from 'assets/images/icons/plus.svg';
import ClickOutside from 'components/common-components/ClickOutside';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';

const ResponseSetListModal = ({ selectResponseSet, closeModal }) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');

  const getAllResponseSets = async () => {
    const result = await CallAPI('GET_ALL_RESPONSE_SETS', null, null, setLoader, setAPIError, null);
    if (result.status) {
      result.data.forEach((set) => {
        set.choiceValue = JSON.parse(set.choiceValue);
      });
      setList(result.data);
    }
  };

  const goToAddResponseSetPage = () => {
    window.open('/response_set', '_blank');
  };

  useEffect(() => {
    getAllResponseSets();
  }, []);

  return (
    <ClickOutside onClick={() => closeModal()}>
      <div className='response-set-list-modal'>
        <div className='response-set-table-view'>
          <div className='table-view-header'>
            <div className='table-view-row'>
              <div className='table-view-cell'>Names</div>
              <div className='table-view-cell'>Responses</div>
              <div className='table-view-cell'>Values</div>
            </div>
          </div>
          <div className='table-view-body'>
            {list.map((set, index) => (
              <div
                key={index}
                className='table-view-row'
                onDoubleClick={() => selectResponseSet(set.choiceValue, set.choiceName)}
              >
                <div className='table-view-cell text-col'>{set.choiceName}</div>
                <div className='table-view-cell'>
                  <div className='d-flex gap-2 flex-wrap'>
                    {set.choiceValue.map((option, index) => (
                      <label
                        key={index}
                        className='px-3 py-1 rounded-pill theme-text-white-1'
                        style={{ backgroundColor: option.itemColor }}
                      >
                        {option.responseText}
                      </label>
                    ))}
                  </div>
                </div>
                <div className='table-view-cell text-col'>
                  {set.choiceValue.map((option) => option.value).join(', ')}
                </div>
              </div>
            ))}
          </div>
        </div>
        {APIError ? <Error msg={APIError} classList='my-2' /> : <></>}
        {loader ? (
          <div className='text-center my-3'>
            <Spinner className='theme-text-green-1' />
          </div>
        ) : (
          <></>
        )}

        <div className='d-flex justify-content-between align-items-center px-3 py-2'>
          <label className='theme-text-red-1 cursor-pointer' onClick={() => closeModal()}>
            {t('text_close')}
          </label>
          <label
            className='d-flex gap-1 align-items-center cursor-pointer action-label mt-1'
            onClick={() => goToAddResponseSetPage()}
          >
            <img src={plusIcon} />
            <span className='theme-text-green-1'>{t('text_add_response_set')}</span>
          </label>
        </div>
      </div>
    </ClickOutside>
  );
};

ResponseSetListModal.propTypes = {
  selectResponseSet: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ResponseSetListModal;
