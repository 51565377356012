import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import verticalBoxGroupIcon from 'assets/images/icons/vertical-box-group.svg';
import elementPreviewIcon from 'assets/images/icons/field_preview_Icon.svg';
import SwappingComponent from './SwappingComponent';

const SectionComponent = ({
  section,
  selectComponent,
  changeFormElementOrder,
  isSelected,
  elementIndex,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`fields-section ${isSelected ? 'fields-section-selected' : ''}`}>
      <div className='fields-section-header'>
        <div
          className='d-flex flex-grow-1 gap-3 cursor-pointer py-2'
          onClick={() => selectComponent()}
        >
          <img
            src={elementPreviewIcon}
            className={`field-type-icon ${!isSelected ? 'svg-black' : ''}`}
          />
          <p
            className='mb-0 theme-text-black-1 theme-font-jakartaSans-semi-bold'
            style={{
              color: section.properties?.basicProperties?.sectionTitleColor,
              fontSize: section.properties?.basicProperties?.sectionTitleSize,
            }}
          >
            {section.properties?.basicProperties?.sectionTitle
              ? section.properties?.basicProperties?.sectionTitle
              : t('form_untitled_section')}
          </p>
        </div>
        <SwappingComponent
          elementType='section'
          elementIcon={verticalBoxGroupIcon}
          type='section'
          props={{
            position: elementIndex,
          }}
          changeFormElementOrder={changeFormElementOrder}
        />
      </div>
      <div className='fields-section-description' onClick={() => selectComponent()}>
        <p
          style={{
            color: section.properties?.basicProperties?.sectionHintTextColor,
            fontSize: section.properties?.basicProperties?.sectionHintTextSize,
          }}
        >
          {section.properties?.basicProperties?.sectionHintText
            ? section.properties?.basicProperties?.sectionHintText
            : t('form_description')}
        </p>
      </div>
    </div>
  );
};

SectionComponent.propTypes = {
  section: PropTypes.object.isRequired,
  selectComponent: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  elementIndex: PropTypes.number.isRequired,
};

export default SectionComponent;
