import React from 'react';
import PropTypes from 'prop-types';
import { featuresCategory, featuresList } from './utils';
import FeatureField from '../../components/form-components/FeatureField';

const AddCompanyStep3 = ({ handleChange, touched, errors, values }) => {
  return (
    <div className='d-flex flex-column w-100'>
      <div className='d-flex gap-3 flex-wrap mb-3'>
        {featuresCategory.map((singlefeatureCategory, i) => {
          return (
            <FeatureField
              key={i}
              classes={`theme-font-inter-semi-bold theme-size-1`}
              displayName={singlefeatureCategory.displayName}
              name={''}
              value={''}
              selectedValue={[]}
              isDisabled={false}
              icon={''}
              isCategory={true}
              isSelected={false}
            />
          );
        })}
      </div>
      <div className='d-flex gap-3 flex-wrap mb-3'>
        {featuresList.map((singlefeature, i) => {
          return (
            <FeatureField
              key={i}
              classes={`theme-font-inter-semi-bold theme-size-1 cursor-pointer`}
              name='features'
              value={singlefeature.value}
              selectedValue={values.features}
              isSelected={values.features.indexOf(singlefeature.value) > -1}
              isDisabled={singlefeature.isDisabled}
              icon={singlefeature.icon}
              displayName={singlefeature.displayName}
              handleChange={handleChange}
              isCategory={false}
            />
          );
        })}
      </div>
      {!touched.features && errors.features && (
        <p className='form-field-error'>{errors.features}</p>
      )}
    </div>
  );
};
AddCompanyStep3.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
};
export default AddCompanyStep3;
