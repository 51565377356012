import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import bellIcon from 'assets/images/icons/bell-icon.svg';
import userEditIcon from 'assets/images/icons/user-edit.svg';
import AlertModal from './common-components/AlertModal';
import ClickOutside from './common-components/ClickOutside';
import FormButton from './form-components/FormButton';
import Loader from './common-components/Loader';
import { CallAPI } from 'actions/General';
import { DEV_ONLY, notificationKey } from 'config/config';

const Notification = ({ authTokenInitializeDate }) => {
  const [unReadNotification, setUnReadNotification] = useState(false);
  const [showNotificationsList, setShowNotificationsList] = useState(false);
  const [showPageRefreshModal, setShowPageRefreshModal] = useState(false);

  const checkIfNewNotification = async () => {
    const result = await CallAPI('GET_USER_UPDATE');
    if (result.status) {
      setUnReadNotification(result.data.hasNewNotification);

      if (authTokenInitializeDate * 1000 < new Date(result.data.updateJWTTokenDate).getTime()) {
        setShowPageRefreshModal(true);
      }
    }
  };

  const refreshToken = async () => {
    setShowPageRefreshModal(false);
    const response = await CallAPI('REFRESH_TOKEN', {}, null, null, null, null);
    if (response.status) {
      window.location.reload();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkIfNewNotification();
    }, 3000);
    const durationInSeconds = DEV_ONLY ? 3600 : 30;
    const interval = setInterval(() => {
      checkIfNewNotification();
    }, durationInSeconds * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <div className='notification'>
        <div className='notification-icon'>
          <ClickOutside onClick={() => setShowNotificationsList(false)}>
            <img
              onClick={() => setShowNotificationsList(true)}
              className={'theme-width-35 cursor-pointer'}
              src={bellIcon}
            />
            <span className={`${unReadNotification ? 'has-unread-count' : ''}`}></span>
          </ClickOutside>
        </div>
        {showNotificationsList && (
          <NotificationList
            unReadNotification={unReadNotification}
            showNotificationsList={showNotificationsList}
          />
        )}
      </div>
      <AlertModal
        show={showPageRefreshModal}
        actionButtons={[]}
        showCloseButton
        closeModal={() => setShowPageRefreshModal(false)}
        size='sm'
      >
        <div className='text-center'>
          <div className='d-flex gap-2 justify-content-center align-items-center mb-3'>
            <img src={userEditIcon} />
            <label className='theme-text-black-1 theme-size-1_3'>Profile Update!</label>
          </div>
          <label className='text-center mb-3'>
            Your profile access has been updated! Please refresh the page to see the changes.
          </label>
          <label
            className='theme-text-green-1 theme-size-1_3 cursor-pointer'
            onClick={() => refreshToken()}
          >
            Refresh Page
          </label>
        </div>
      </AlertModal>
    </Fragment>
  );
};

Notification.propTypes = {
  authTokenInitializeDate: PropTypes.number.isRequired,
};

export default Notification;

const NotificationList = ({ showNotificationsList, unReadNotification }) => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const getAllNotifications = async (page = 1) => {
    const result = await CallAPI('GET_NOTIFICATION_HISTORY', { page, limit: 20 });
    if (result.status) {
      const unreadNotificationsIds = [];

      result.data.notifications.forEach((singleNotification) => {
        if (!singleNotification.isRead) unreadNotificationsIds.push(singleNotification._id);
      });

      setAllNotifications((currentList) => [...currentList, ...result.data.notifications]);

      if (unreadNotificationsIds.length < 0) markNotificationAsRead(unreadNotificationsIds);
    }
  };

  const markNotificationAsRead = async (notificationIds) => {
    const result = await CallAPI('READ_NOTIFICATION_HISTORY', {
      ...(unReadNotification && { markNewNotificationRead: true }),
      notificationIds,
    });
    if (result.status) {
      // getAllNotifications();
    }
  };

  const onScroll = (e) => {
    const element = e.target;
    let timeout;
    if (Math.round(element.scrollTop) >= Math.round(element.scrollHeight - element.offsetHeight)) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setPageNumber((currentPage) => currentPage + 1);
      }, 500);
    }
  };

  useEffect(() => {
    if (showNotificationsList) {
      getAllNotifications(pageNumber);
    }
  }, [showNotificationsList, pageNumber]);

  return (
    <div className='notifications-list-container'>
      <div className='notifications-list' onScroll={(e) => onScroll(e)}>
        {allNotifications.map((singleNotification, index) => (
          <NotificationElement key={index} singleNotification={singleNotification} />
        ))}
      </div>
    </div>
  );
};

NotificationList.propTypes = {
  showNotificationsList: PropTypes.bool.isRequired,
  unReadNotification: PropTypes.bool.isRequired,
};

const NotificationElement = ({ singleNotification }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [exportId, setExportId] = useState('');

  const singleNotificationDetail = JSON.parse(singleNotification.details);

  const singleNotificationNotifyDetail = singleNotificationDetail.exportNotify
    ? singleNotificationDetail.exportNotify
    : singleNotificationDetail.companyNotify
    ? singleNotificationDetail.companyNotify
    : singleNotificationDetail.formNotify
    ? singleNotificationDetail.formNotify
    : singleNotificationDetail.supervisorFieldNotify
    ? singleNotificationDetail.supervisorFieldNotify
    : singleNotificationDetail.importNotify;

  const downloadExportFile = async (notification, action) => {
    setExportId(notification._id);
    let exportDetails = JSON.parse(notification.details);
    let result = await CallAPI(
      'GET_EXPORT_DETAIL',
      null,
      exportDetails.exportNotify._id,
      setLoader,
      null,
    );
    if (result.status) {
      if (result.data && result.data.exportUrl) {
        if (action === 'download') {
          window.open(result.data.exportUrl, '_blank');
        } else {
          setTimeout(() => {
            navigator.clipboard.writeText(result.data.exportUrl);
          }, 0);

          toast(t('Copied link to clipboard'), {
            style: { borderRadius: '8px', background: '#000', color: '#fff' },
          });
        }
      }
    }
    setExportId('');
  };

  return (
    <div className='notifications-item'>
      <span>
        {t(
          singleNotification.notificationActions === 'export_failed'
            ? notificationKey['export_process_failed']
            : singleNotification.notificationActions.includes('import_failed')
            ? notificationKey['import_process_failed']
            : singleNotification.notificationType === 'submission' &&
              singleNotification.notificationActions === 'supervisor_field_updated'
            ? notificationKey[
                singleNotification.notificationType + '_' + singleNotification.notificationActions
              ]
            : singleNotification.notificationType === 'export' ||
              singleNotification.notificationActions === 'export_completed' ||
              singleNotification.notificationType === 'submission'
            ? notificationKey[
                singleNotificationNotifyDetail.exportModuleScreen
                  ? singleNotificationNotifyDetail.exportModule +
                    '_' +
                    singleNotificationNotifyDetail.exportModuleScreen +
                    '_' +
                    singleNotification.notificationActions
                  : singleNotificationNotifyDetail.exportModule +
                    '_' +
                    singleNotification.notificationActions
              ]
            : singleNotification.notificationType === 'users' ||
              singleNotification.notificationType === 'forms'
            ? notificationKey[
                singleNotification.notificationType + '_' + singleNotification.notificationActions
              ]
            : singleNotification.notificationType === 'import'
            ? notificationKey[singleNotification.notificationActions]
            : '',
        )}
      </span>
      {(singleNotification.notificationActions === 'export_completed' ||
        singleNotification.notificationActions === 'download_attachment_completed') && (
        <div className='d-flex justify-content-between gap-3'>
          {loader && singleNotification._id === exportId ? (
            <Loader color='green-1 theme-width-20 theme-height-20' />
          ) : (
            <>
              <FormButton
                onClick={() => downloadExportFile(singleNotification, 'copy')}
                text='button_copy_link_to_clipboard'
                variant='green-1'
              />
              <FormButton
                onClick={() => downloadExportFile(singleNotification, 'download')}
                text='button_download'
                variant='green-1'
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

NotificationElement.propTypes = {
  singleNotification: PropTypes.object.isRequired,
};
