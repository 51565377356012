import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UploadSectionImage from 'components/common-components/UploadSectionImage';
import { CallAPI } from 'actions/General';
import toast from 'react-hot-toast';

function ImageFilePicker({ properties, handleChange }) {
  const [fetchImageLoader, setFetchImageLoader] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 500) {
        toast.error('Max file size 500KB');
      } else {
        handleChange('fieldImageFile', file);
      }
    }
  };

  const getImageUrl = async (url) => {
    let result = await CallAPI('GET_FORM_MEDIA_REDIRECT_URL', null, url, setFetchImageLoader, null);
    if (result.status) {
      return result.data?.url ?? null;
    } else return null;
  };

  useEffect(async () => {
    if (properties?.fieldImage?.id) {
      const url = await getImageUrl(properties.fieldImage.id);
      if (url) setRedirectUrl(url);
    }
  }, []);
  return (
    <UploadSectionImage
      selectedImage={properties?.fieldImageFile ?? null}
      redirectUrl={redirectUrl}
      handleFileChange={handleFileChange}
      loader={fetchImageLoader}
      maxFileSize='500 KB'
      shrink
    />
  );
}
ImageFilePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  properties: PropTypes.object.isRequired,
};
export default ImageFilePicker;
