import React from 'react';
import PropTypes from 'prop-types';
import signatureIcon from 'assets/images/icons/signature.svg';
import FormButton from 'components/form-components/FormButton';

const SignatureFieldPreview = () => {
  return (
    <div className='border-grey-1-h-1 rounded-md d-flex justify-content-between align-items-center overflow-hidden'>
      <div className='px-2'>
        <label>Signature</label>
      </div>
      <FormButton
        text=''
        variant='green-1'
        icon={signatureIcon}
        onClick={() => {}}
        classes='less-rounded'
        iconClasses='svg-white'
        minWidth
      />
    </div>
  );
};

SignatureFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
};

export default SignatureFieldPreview;
