import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import FormButton from './FormButton';
import { useTranslation } from 'react-i18next';

const ImageInput = ({
  name,
  value,
  handleChange,
  shrink,
  shrinkFieldOnly,
  displayName,
  classes,
  isHorizontal,
}) => {
  const { t } = useTranslation();
  const shrinkField = shrink || shrinkFieldOnly ? true : false;
  const shrinkLabel = shrink ? ' shrink-label' : '';
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleChange(name, file);
  };

  return (
    <div className={`custom-input-box ${classes}`}>
      {displayName && (
        <label htmlFor={name} className={`${shrinkLabel}`}>
          {t(displayName)}
        </label>
      )}
      <div onClick={handleUploadClick} className={`${isHorizontal ? 'd-flex gap-2' : ''}`}>
        <input
          className='d-none'
          type='file'
          accept='image/*'
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <FormButton
          text='Upload Image'
          variant='green-1'
          shrink={shrinkField}
          classes='text-nowrap'
        />
        {value && <div className='theme-width-150 text-truncate mt-1'>{value.name}</div>}
      </div>
    </div>
  );
};

ImageInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  displayName: PropTypes.string,
  classes: PropTypes.string,
  shrink: PropTypes.bool,
  shrinkFieldOnly: PropTypes.bool,
  isHorizontal: PropTypes.bool,
};

export default ImageInput;
