import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AlertModal from 'components/common-components/AlertModal';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import { CallAPI } from 'actions/General';
import { isRTL, notificationKey, permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';
import FormButton from 'components/form-components/FormButton';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

function EntriesActions({ ids, actionsCallBack, actionData, isActionButton, type }) {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.language);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [displayedMenu, setDisplayedMenu] = useState([]);

  const deleteDataSourceEntry = async () => {
    let data = {
      dataSource: { id: actionData.row_datasource_id, title: actionData.datasourceName },
      dataEntryIds: ids,
    };
    await CallAPI('DELETE_DATASOURCE_ENTRY', data, null, null, null, null);
    setShowDeleteConfirm(false);
    actionsCallBack('delete');
  };
  const confirmDeletion = () => setShowDeleteConfirm(true);
  const editRecord = () => {
    actionsCallBack('edit', ids[0]);
  };

  const exportCSV = async () => {
    let API = 'EXPORT_DATA_SOURCE';
    let APIData = {
      dataSourceId: actionData.row_datasource_id,
      dataSourceName: actionData.datasourceName,
      entryIds: ids,
      exportMode: 'multiple',
      exportTo: 'csv',
      locale: lang,
    };

    toast(t(notificationKey['data_source_export_process_started']), {
      style: { borderRadius: '8px', background: '#000', color: '#fff' },
    });
    await CallAPI(API, APIData);
  };

  useEffect(() => {
    let userActionsList = [
      {
        allowedFrom: ['row_data_sources_entries'],
        permission: isPermissionAvailable(permissionKeys.field_work_datasource_create),
        name: 'action_edit',
        action: editRecord,
      },
      {
        allowedFrom: ['row_data_sources_entries', 'data_sources_entries'],
        permission: isPermissionAvailable(permissionKeys.field_work_datasource_read),
        name: 'action_export_csv',
        action: exportCSV,
      },
      {
        allowedFrom: ['row_data_sources_entries', 'data_sources_entries'],
        permission: isPermissionAvailable(permissionKeys.field_work_datasource_delete),
        name: 'action_delete',
        action: confirmDeletion,
      },
    ];

    setDisplayedMenu(
      userActionsList.filter(
        (item) => item.allowedFrom.indexOf(type) > -1 && item.permission === true,
      ),
    );
  }, [type, ids]);
  return (
    <div className='dropdown-actions'>
      {displayedMenu.length > 0 && (
        <DropdownButton
          disabled={ids.length > 0 ? false : true}
          as={ButtonGroup}
          drop='bottom'
          align={isRTL() ? 'end' : 'start'}
          variant='secondary'
          title={
            <>
              {isActionButton ? (
                <img src={dotsIcon} alt='Loading...' />
              ) : (
                <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
              )}
            </>
          }
        >
          {displayedMenu.map((button, index) => (
            <Dropdown.Item
              onClick={button.action}
              key={index}
              className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
            >
              {t(button.name)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
      <AlertModal
        show={showDeleteConfirm}
        showCloseButton={true}
        closeModal={() => setShowDeleteConfirm(false)}
        variant='delete'
        message={'alert_delete_data_source_entry'}
        actionButtons={[
          {
            text: t('button_confirm'),
            variant: 'red-1',
            onClick: () => deleteDataSourceEntry('delete'),
          },
          {
            text: t('button_cancel'),
            variant: 'white-1',
            onClick: () => setShowDeleteConfirm(false),
          },
        ]}
      />
    </div>
  );
}
EntriesActions.propTypes = {
  ids: PropTypes.array.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
  actionData: PropTypes.object.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};
export default EntriesActions;
