import trashIcon from 'assets/images/icons/trash-breadcrumb.svg';
export const trashTabsList = [
  {
    id: 1,
    title: 'tabs_trashed_forms',
    value: 'trash_forms',
    breadcrumbValue: { icon: trashIcon, items: ['sidebar_trash', 'sidebar_forms'] },
  },
  {
    id: 2,
    title: 'tabs_trashed_submission_data',
    value: 'trash_submissions',
    breadcrumbValue: { icon: trashIcon, items: ['sidebar_trash', 'tabs_trashed_submission_data'] },
  },
];
export const TrashSubmissionsTableFields = [
  {
    name: 'formName',
    displayName: 'table_header_form_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    associatedKey: 'formTitle',
  },
  {
    name: 'submittedBy',
    displayName: 'table_header_submitted_by',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    associatedKey: 'user',
    populateFunc: 'populateCreatorName',
  },
  {
    name: 'submittedDate',
    displayName: 'table_header_submitted_date',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    populateFunc: 'populateDate',
    associatedKey: 'completeDate',
  },
];
export const TrashFormTableFields = [
  {
    name: 'formName',
    displayName: 'table_header_form_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    associatedKey: 'title',
  },
  {
    name: 'publishedBy',
    displayName: 'table_header_published_by',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    associatedKey: 'PublishedBy',
  },
  {
    name: 'publishedDate',
    displayName: 'table_header_published_date',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    populateFunc: 'populateDate',
    associatedKey: 'PublishedDate',
  },
  {
    name: 'lastEntry',
    displayName: 'table_header_last_entry',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    populateFunc: 'populateDate',
    associatedKey: 'lastEntryDate',
  },
  {
    name: 'Entries',
    displayName: 'table_header_entries',
    type: 'string',
    isRequired: true,
    isSelected: true,
    isFilterAble: true,
    mandatory: true,
    associatedKey: 'numberOfEntries',
  },
];
