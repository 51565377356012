import React from 'react';
import PropTypes from 'prop-types';
import triangleIcon from 'assets/images/icons/triangle.svg';

const TabDividerVertical = ({ variant }) => {
  return (
    <div className='tab-divider-vertical'>
      <img src={triangleIcon} className='rotate-270' />
      <div className={variant ? variant : ''} />
      <img src={triangleIcon} className='rotate-90' />
    </div>
  );
};

TabDividerVertical.propTypes = {
  variant: PropTypes.string,
};
export default TabDividerVertical;
