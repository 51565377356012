import React, { useState } from 'react';
import homeIcon from 'assets/images/icons/home.svg';
import MainPage from './MainPage';
import TabBody from 'components/tab-components/TabBody';
import TabHeader from 'components/tab-components/TabHeader';
import TabLayout from 'components/tab-components/TabLayout';

const HomePage = () => {
  const HomeTabsList = [
    {
      id: 1,
      title: 'tabs_main',
      value: 'main',
      breadcrumbValue: { icon: homeIcon, items: ['sidebar_home', 'tabs_main'] },
    },
  ];
  const [selectedTab, setSelectedTab] = useState(HomeTabsList[0]);

  return (
    <div>
      <TabLayout>
        <TabHeader
          tabList={HomeTabsList}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          fixedWidth
        />
        <TabBody transparent>{selectedTab.id === HomeTabsList[0].id && <MainPage />}</TabBody>
      </TabLayout>
    </div>
  );
};

export default HomePage;
