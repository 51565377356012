import React, { useState } from 'react';
import { tabList } from './utils';
import CompanyGeneral from './company-general/CompanyGeneral';
import TabLayout from '../../components/tab-components/TabLayout';
import TabHeader from '../../components/tab-components/TabHeader';
import TabBody from '../../components/tab-components/TabBody';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import AuthorizeError from 'pages/AuthorizeError';

const CompanySetup = () => {
  const [selectedTab, setSelectedTab] = useState(tabList[0]);
  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.system_company_setup_read),
  );
  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <TabLayout>
          <TabHeader tabList={tabList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <TabBody>
            <CompanyGeneral selectedTab={selectedTab} />
          </TabBody>
        </TabLayout>
      )}
    </>
  );
};

export default CompanySetup;
