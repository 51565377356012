import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import pageEditIcon from 'assets/images/icons/page_edit_circle.svg';
import SwappingComponent from './SwappingComponent';

const PageComponent = ({
  page,
  selectComponent,
  changeFormElementOrder,
  currentPage,
  totalPages,
}) => {
  const { t } = useTranslation();

  return (
    <div className='fields-page'>
      <div className='page-header'>
        <div
          className='d-flex flex-grow-1 gap-3 cursor-pointer py-3'
          onClick={() => selectComponent()}
        >
          <p
            className='mb-0 theme-text-black-1 theme-font-jakartaSans-semi-bold'
            style={{
              color: page.properties?.basicProperties?.pageTitleColor,
              fontSize: page.properties?.basicProperties?.pageTitleSize,
            }}
          >
            {page.properties?.basicProperties?.pageTitle
              ? page.properties?.basicProperties?.pageTitle
              : t('form_untitled_page')}
          </p>
          <img src={pageEditIcon} />
        </div>
        <SwappingComponent
          elementType='page'
          elementIcon='page'
          type='page'
          props={{ position: currentPage, totalPages: totalPages, pageNumber: currentPage }}
          changeFormElementOrder={changeFormElementOrder}
        />
      </div>
    </div>
  );
};

PageComponent.propTypes = {
  page: PropTypes.object.isRequired,
  selectComponent: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PageComponent;
