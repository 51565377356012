import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import Loader from 'components/common-components/Loader';
import TableComponent from 'components/table-component/Table';
import { formsTableHeader } from './utils';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import DashboardHeader from './DashboardHeader';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';

const DashboardForms = ({ type }) => {
  const navigate = useNavigate();
  const [allForms, setAllForms] = useState([]);
  const [totalForms, setTotalForms] = useState(0);
  const [loader, setLoader] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchTextData, setSearchTextData] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const onRowsSelection = (name, value) => {
    let selectedIds = cloneDeep(selectedRows);
    if (name === 'all') {
      allForms.map((single_form) => {
        if (value && selectedIds.map((e) => e.id).indexOf(single_form._id) === -1)
          selectedIds.push({ id: single_form._id, title: single_form.title });
        if (!value) selectedIds = [];
        setSelectedRows(selectedIds);
      });
    } else {
      if (value) {
        let title = '';
        allForms.map((single_form) => {
          if (single_form._id === name) title = single_form.title;
        });
        selectedIds.push({ id: name, title: title });
      } else selectedIds.splice(selectedIds.map((e) => e.id).indexOf(name), 1);
      setSelectedRows(selectedIds);
    }
  };
  const getAllForms = async (page = 1, limit = 10) => {
    // let API = type === 'submittedForms' ? 'FETCH_SUPERVISOR_FORMS' : 'FETCH_FORMS';
    let API = 'FETCH_SUPERVISOR_FORMS';
    let result = await CallAPI(
      API,
      {
        screenTab: type === 'submittedForms' ? 'forms' : 'bookmarks',
        page: page,
        limit: limit,
        searchText: searchTextData,
      },
      null,
      setLoader,
      setAPIError,
      null,
    );
    if (result.status) {
      let formsList = [];
      result.data.forms.map((singleForm) => {
        formsList.push({
          ...singleForm,
          currentVersion: singleForm.currentVersion ? singleForm.currentVersion : {},
          publishedDate:
            singleForm.publishedVersion && singleForm.publishedVersion.publishedDate
              ? singleForm.publishedVersion.publishedDate
              : '',
        });
      });
      setAllForms(formsList);
      if (result.data.totalCount) setTotalForms(result.data.totalCount);
    }
  };

  useEffect(() => {
    if (isPermissionAvailable(permissionKeys.view_data_dashboard_read)) {
      setSelectedRows([]);
      getAllForms();
    }
  }, [searchTextData, type]);

  const debouncedFunction = (name, value) => {
    setSearchTextData(value);
  };
  const searchTextChanged = (name, value) => {
    setSearchText(value);
  };
  const formNameClickCallBack = (row) => {
    navigate('/submissions', {
      relative: 'path',
      state: {
        type: 'edit',
        formId: row._id,
        version: '',
        status: 'live',
        versionsList: row.versions,
      },
    });
  };

  return (
    <div>
      {loader && <Loader color='green-1' type='modal-spinner' />}
      {APIError ? <Error msg={APIError} /> : <></>}
      <DashboardHeader
        searchTextChanged={searchTextChanged}
        searchText={searchText}
        debouncedFunction={debouncedFunction}
        type={type}
        actionsCallBack={getAllForms}
        selectedRows={selectedRows}
      />
      <TableComponent
        data={allForms}
        header={formsTableHeader}
        props={{
          type: 'dashboardForms',
          actionsType: 'dashboardForms',
          actionsCallBack: getAllForms,
          formNameClickCallBack,
        }}
        totalRecords={totalForms}
        filterEnabled={false}
        loader={loader}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={true}
        isActionsEnabled={true}
        isSelectionEnabled={true}
        selectionKey={'_id'}
        paginationFunction={getAllForms}
        onSelection={onRowsSelection}
        selectedRows={selectedRows}
      />
    </div>
  );
};
DashboardForms.propTypes = {
  type: PropTypes.string.isRequired,
};
export default DashboardForms;
