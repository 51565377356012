import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import AlertModal from 'components/common-components/AlertModal';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectField from 'components/form-components/SelectField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import TextArea from 'components/form-components/TextArea';
import TextField from 'components/form-components/TextField';
import { formReportSchema } from '../utils';
import { useTranslation } from 'react-i18next';
import { CallAPI } from 'actions/General';

const ReportEmailSetting = ({ updateTab, formDetails, getFormDetail }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    toAddress: '',
    toFromFieldValue: '',
    sentFrom: '',
    toSubmittingUser: false,
    subject: '',
    body: '',
    attachmentName: '',
    disableRandomCode: false,
    anonymisePersonalData: false,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTouched({});
    try {
      await formReportSchema.validate(values, { abortEarly: false });
      const { toAddress, ...payload } = values;
      const result = await CallAPI(
        'SAVE_FORM',
        {
          formId: formDetails?._id ? formDetails._id : '',
          report: { ...payload, toAddresses: toAddress.split(',') },
        },
        null,
        setLoading,
        null,
        null,
      );

      if (result.status) {
        setShowSuccessAlert(true);
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValues({
      toAddress: (formDetails?.report?.toAddresses ?? []).join(','),
      toFromFieldValue: formDetails?.report?.toFromFieldValue ?? '',
      sentFrom: formDetails?.report?.sentFrom ?? '',
      toSubmittingUser: formDetails?.report?.toSubmittingUser ?? false,
      subject: formDetails?.report?.subject ?? '',
      body: formDetails?.report?.body ?? '',
      attachmentName: formDetails?.report?.attachmentName ?? '',
      disableRandomCode: formDetails?.report?.disableRandomCode ?? false,
      anonymisePersonalData: formDetails?.report?.anonymisePersonalData ?? false,
    });
  }, [formDetails]);

  const fromFieldValues = formDetails?.currentVersion
    ? formDetails.currentVersion.elements
        .filter((element) => element.elementType === 'text')
        .map((element) => {
          const properties = element.properties ? JSON.parse(element.properties) : {};
          return {
            value: element._id ?? null,
            displayValue: properties.basicProperties?.fieldTitle ?? '',
          };
        })
    : [];

  const fromFieldValuesMap = Object.fromEntries(
    fromFieldValues.map((v) => [v.value, v.displayValue]),
  );

  const sentFromValues = [{ value: 'no-reply@midani.co', displayValue: 'no-reply@midani.co' }];

  const sentFromValuesMap = Object.fromEntries(
    sentFromValues.map((v) => [v.value, v.displayValue]),
  );

  return (
    <div className='px-4 py-3'>
      <AlertModal
        show={showSuccessAlert}
        showCloseButton={true}
        closeModal={() => setShowSuccessAlert(false)}
        title='alert_success'
        message={'alert_report_updated'}
        variant='success'
        actionButtons={[
          {
            text: t('button_ok'),
            variant: 'green-1',
            onClick: () => {
              setShowSuccessAlert(false);
              getFormDetail(formDetails._id);
            },
          },
        ]}
      />
      <div className='mb-3'>
        <label className='theme-size-1_2 theme-text-black-1 mb-3'>
          {t('text_email_addresses')}
        </label>

        <TextField
          name='toAddress'
          displayName='field_to_address'
          hintText='field_to_address_hint'
          placeholder='ToAddress'
          value={values.toAddress}
          error={errors.toAddress}
          touched={touched.toAddress}
          handleChange={handleChange}
          classes='mb-3'
          horizontalLabelClass='min-width-176'
          shrinkFieldOnly
          isHorizontal
        />
        <SelectField
          name='toFromFieldValue'
          handleChange={handleChange}
          listValues={fromFieldValues}
          placeholder='field_to_from_field_value'
          label={fromFieldValuesMap[values.toFromFieldValue] ?? 'select_select'}
          icon={arrowDownIcon}
          variant='white-1'
          menuClasses='w-100'
          isAutoClose={true}
          isHorizontal={true}
        />
        <div className='space-4'></div>
        <SelectField
          name='sentFrom'
          handleChange={handleChange}
          listValues={sentFromValues}
          placeholder='field_email_sent_from'
          label={sentFromValuesMap[values.sentFrom] ?? 'select_select'}
          icon={arrowDownIcon}
          variant='white-1'
          menuClasses='w-100'
          isAutoClose={true}
          isHorizontal={true}
        />
        <div className='d-flex mt-3'>
          <div className='min-width-176'></div>
          <div>
            <SingleCheckBoxField
              name='toSubmittingUser'
              value={values.toSubmittingUser}
              handleChange={handleChange}
              text={<span>{t('field_to_submitting_user')}</span>}
              classes='mb-3'
            />
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <label className='theme-size-1_2 theme-text-black-1 mb-3'>
          {t('text_body_attachments')}
        </label>

        <TextField
          name='subject'
          displayName='field_subject'
          placeholder='field_subject'
          value={values.subject}
          error={errors.subject}
          touched={touched.subject}
          handleChange={handleChange}
          classes='mb-3'
          horizontalLabelClass='min-width-176'
          shrinkFieldOnly
          isHorizontal
        />
        <TextArea
          name='body'
          displayName='field_body'
          placeholder='field_body'
          value={values.body}
          error={errors.body}
          touched={touched.body}
          handleChange={handleChange}
          rows={3}
          classes='mb-3'
          horizontalLabelClass='min-width-176'
          isHorizontal
        />
        <TextField
          name='attachmentName'
          displayName='field_attachment_name'
          placeholder='field_attachment_name'
          value={values.attachmentName}
          error={errors.attachmentName}
          touched={touched.attachmentName}
          handleChange={handleChange}
          classes='mb-3'
          horizontalLabelClass='min-width-176'
          shrinkFieldOnly
          isHorizontal
        />
        <div className='d-flex'>
          <div className='min-width-176'></div>
          <div>
            <SingleCheckBoxField
              name='disableRandomCode'
              value={values.disableRandomCode}
              handleChange={handleChange}
              text={<span>{t('field_disable_random_code')}</span>}
              classes='mb-3'
            />
            <SingleCheckBoxField
              name='anonymisePersonalData'
              value={values.anonymisePersonalData}
              handleChange={handleChange}
              text={<span>{t('field_anonymise_personal_data')}</span>}
            />
          </div>
        </div>
        <div className='w-100 d-flex justify-content-end gap-3'>
          <FormButton text='button_cancel' variant='white-1' onClick={() => updateTab(0)} />
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

ReportEmailSetting.propTypes = {
  getFormDetail: PropTypes.func.isRequired,
  updateTab: PropTypes.func.isRequired,
  formDetails: PropTypes.object,
};

export default ReportEmailSetting;
