import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loader from 'components/common-components/Loader';
import TextArea from 'components/form-components/TextArea';
import { CallAPI } from 'actions/General';

const AttachmentsOverviewContent = ({ mediaAndNotes }) => {
  const { t } = useTranslation();

  const imagesMedia = mediaAndNotes.media.filter(
    (mediaAndNote) => mediaAndNote.mediaType === 'image',
  );
  const videosMedia = mediaAndNotes.media.filter(
    (mediaAndNote) => mediaAndNote.mediaType === 'video',
  );
  const mediaTimeStampStringMap = mediaAndNotes.mediaTimeStampString;

  const [loader, setLoader] = useState(false);
  const [imageRedirectUrls, setImageRedirectUrls] = useState([]);
  const [videoRedirectUrls, setVideoRedirectUrls] = useState([]);

  const getImageUrl = async (apiType, url) => {
    let result = null;
    if (apiType === 'submission')
      result = await CallAPI('GET_SUBMISSION_MEDIA_REDIRECT_URL', null, url, null, null);
    else if (apiType === 'attendance')
      result = await CallAPI('ANY_MEDIA_URL', url, null, null, null);

    if (result.status) {
      return result.data.url;
    } else return '';
  };

  const fetchMediaUrls = async (mediaList) => {
    return Promise.all(
      mediaList.map(async (media) => {
        return {
          timestamp: mediaTimeStampStringMap[media._id]
            ? parseInt(mediaTimeStampStringMap[media._id])
            : null,
          url: await getImageUrl(
            mediaAndNotes.dialogType,
            mediaAndNotes.dialogType === 'submission'
              ? media.formId +
                  '/' +
                  media.versionId +
                  '/' +
                  media.customFolderId +
                  '/' +
                  media.imageName
              : media,
          ),
        };
      }),
    );
  };

  useEffect(async () => {
    setLoader(true);
    const [imageUrls, videoUrls] = await Promise.all([
      fetchMediaUrls(imagesMedia),
      fetchMediaUrls(videosMedia),
    ]);

    setImageRedirectUrls(imageUrls.filter((singleUrl) => singleUrl.url !== ''));
    setVideoRedirectUrls(videoUrls.filter((singleUrl) => singleUrl.url !== ''));
    setLoader(false);
  }, [mediaAndNotes]);

  return (
    <div className='attachment-overview'>
      <div className='theme-background-white-2 mt-2 py-3 text-center'>
        <label className='theme-text-black-1'>{t('text_attachments_overview')}</label>
      </div>
      <div className='content'>
        {mediaAndNotes.userName && mediaAndNotes.userName !== '' && (
          <div className='d-flex gap-2 justify-content-start align-items-center mb-2'>
            <div className='label-initial' />
            <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_2'>
              {t('text_user_name')}:
            </label>
            <span className='theme-text-green-1'>{mediaAndNotes.userName}</span>
          </div>
        )}
        {imagesMedia.length > 0 && (
          <div>
            <div className='d-flex gap-2 justify-content-start align-items-center mb-2'>
              <div className='label-initial' />
              <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_2'>
                {t('text_images')}
              </label>
            </div>
            {loader && <Loader color='green-1' />}
            <div className='d-flex gap-3 align-items-start py-2' style={{ overflow: 'auto' }}>
              {imageRedirectUrls.map((redirectUrl, index) => (
                <div key={index} style={{ height: 'auto', width: '200px' }}>
                  <img style={{ height: 'auto', width: '100%' }} src={redirectUrl.url} />
                  {mediaAndNotes.showTimeStamp && redirectUrl.timestamp && (
                    <label>{new Date(redirectUrl.timestamp).toLocaleString('en-US')}</label>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {videosMedia.length > 0 && (
          <div>
            <div className='d-flex gap-2 justify-content-start align-items-center mb-2'>
              <div className='label-initial' />
              <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_2'>
                {t('text_videos')}
              </label>
            </div>
            {loader && <Loader color='green-1' />}
            <div
              className='d-flex flex-column gap-3 align-items-start'
              style={{ overflow: 'auto' }}
            >
              {videoRedirectUrls.map((redirectUrl, index) => (
                <div key={index} style={{ width: '100%' }}>
                  <video
                    controls
                    controlsList='nodownload'
                    disablePictureInPicture
                    disableRemotePlayback
                    style={{ width: '100%', height: 'auto', maxHeight: '300px' }}
                  >
                    <source src={redirectUrl.url} />
                  </video>
                  {mediaAndNotes.showTimeStamp && redirectUrl.timestamp && (
                    <label>{new Date(redirectUrl.timestamp).toLocaleString('en-US')}</label>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {mediaAndNotes.note !== '' && (
          <div>
            <div className='d-flex gap-2 justify-content-start align-items-center mb-2'>
              <div className='label-initial' />
              <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_2'>
                {t('text_notes')}
              </label>
            </div>
            <TextArea
              name='note'
              value={mediaAndNotes.note}
              classes='w-100'
              handleChange={() => {}}
              rows={5}
              disabled
            />
          </div>
        )}
      </div>
    </div>
  );
};

AttachmentsOverviewContent.propTypes = {
  mediaAndNotes: PropTypes.object.isRequired,
};

export default AttachmentsOverviewContent;
