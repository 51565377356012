import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import arrowDownIcon from 'assets/images/icons/dropdown-arrow-grey.svg';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectField from 'components/form-components/SelectField';
import { CallAPI } from 'actions/General';
import { isPermissionAvailable } from 'config/permissionUtils';
import { permissionKeys } from 'config/config';

const CompanySettings = ({ companyDetails, getCompanyDetail }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [values, setValues] = useState({
    timeFormat: '',
    dateFormat: '',
    timeZone: '',
    startOfWeek: '',
    mainLanguage: '',
    textDirection: '',
    measurement: '',
  });

  useEffect(() => {
    setValues({
      timeFormat: companyDetails?.settings?.timeFormat,
      dateFormat: companyDetails?.settings?.dateFormat,
      timeZone: companyDetails?.settings?.timeZone,
      startOfWeek: companyDetails?.settings?.startOfWeek,
      mainLanguage: companyDetails?.settings?.mainLanguage,
      textDirection: companyDetails?.settings?.textDirection ?? 'left_to_right',
      measurement: companyDetails?.settings?.measurement,
    });
  }, [companyDetails]);

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const submit = async () => {
    setLoading(true);
    try {
      let payload = { settings: { ...values } };
      const result = await CallAPI('UPDATE_COMPANY_DETAIL', payload, null, setLoading, setApiError);

      if (result.status) {
        toast.success('Company details updated successfully');
        getCompanyDetail();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const timeFormatValues = [
    { value: '12 hrs', displayValue: 'select_12hours', isNested: false },
    { value: '24 hrs', displayValue: 'select_24hours', isNested: false },
  ];
  const timeFormatValuesMap = Object.fromEntries(
    timeFormatValues.map((v) => [v.value, v.displayValue]),
  );

  const dateFormatValues = [
    { value: 'dd/mm/yyyy', displayValue: 'DD-MM-YYYY', isNested: false },
    { value: 'mm/dd/yyyy', displayValue: 'MM-DD-YYYY', isNested: false },
  ];
  const dateFormatValuesMap = Object.fromEntries(
    dateFormatValues.map((v) => [v.value, v.displayValue]),
  );

  const startOfWeekValues = [
    { value: 'sunday', displayValue: 'select_sunday' },
    { value: 'monday', displayValue: 'select_monday' },
    { value: 'tuesday', displayValue: 'select_tuesday' },
    { value: 'wednesday', displayValue: 'select_wednesday' },
    { value: 'thursday', displayValue: 'select_thursday' },
    { value: 'friday', displayValue: 'select_friday' },
    { value: 'saturday', displayValue: 'select_saturDay' },
  ];
  const startOfWeekValuesMap = Object.fromEntries(
    startOfWeekValues.map((v) => [v.value, v.displayValue]),
  );

  const languageValues = [
    { value: 'english', displayValue: 'select_english' },
    { value: 'arabic', displayValue: 'select_arabic' },
  ];
  const languageValuesMap = Object.fromEntries(
    languageValues.map((v) => [v.value, v.displayValue]),
  );

  const textDirectionValues = [
    { value: 'right_to_left', displayValue: 'select_right_to_left' },
    { value: 'left_to_right', displayValue: 'select_left_to_right' },
  ];
  const textDirectionValuesMap = Object.fromEntries(
    textDirectionValues.map((v) => [v.value, v.displayValue]),
  );

  return (
    <div className='px-4 py-3'>
      {apiError && (
        <div className='mb-2'>
          <Error msg={apiError} />
        </div>
      )}
      <div className='company-settings-style'>
        <div className='responsive-box'>
          <div>
            <SelectField
              placeholder='field_time_format'
              label={timeFormatValuesMap[values.timeFormat] ?? 'field_time_format'}
              listValues={timeFormatValues}
              icon={arrowDownIcon}
              handleChange={handleChange}
              name='timeFormat'
              isAutoClose={true}
              classes='border-grey-1-h-1 rounded-md'
              menuClasses='w-100'
              isHorizontal
              example='(e.g. 5:30 PM)'
            />
          </div>
          <div>
            <SelectField
              placeholder='field_date_format'
              label={dateFormatValuesMap[values.dateFormat] ?? 'field_date_format'}
              listValues={dateFormatValues}
              icon={arrowDownIcon}
              handleChange={handleChange}
              name='dateFormat'
              isAutoClose={true}
              classes='border-grey-1-h-1 rounded-md'
              menuClasses='w-100'
              isHorizontal
              example='(e.g. 01/12/2023)'
            />
          </div>
        </div>
        <div className='mt-3'>
          <SelectField
            placeholder='field_timezone'
            label={values.timeZone ? 'KuwaitRiyadhTime' : 'field_timezone'}
            listValues={[{ value: '(UTC+03:00) Kuwait, Riyadh', displayValue: 'KuwaitRiyadhTime' }]}
            icon={arrowDownIcon}
            handleChange={handleChange}
            name='timeZone'
            isAutoClose={true}
            variant='white-1'
            menuClasses='w-100'
            isHorizontal
          />
        </div>
        <div className='responsive-box mt-3'>
          <div>
            <SelectField
              placeholder='field_start_of_week'
              label={startOfWeekValuesMap[values.startOfWeek] ?? 'field_start_of_week'}
              listValues={startOfWeekValues}
              icon={arrowDownIcon}
              handleChange={handleChange}
              name='startOfWeek'
              isAutoClose={true}
              variant='white-1'
              menuClasses='w-100'
              isHorizontal
            />
          </div>
          <div>
            <SelectField
              placeholder='field_main_language'
              label={languageValuesMap[values.mainLanguage] ?? 'field_main_language'}
              listValues={languageValues}
              icon={arrowDownIcon}
              handleChange={handleChange}
              name='mainLanguage'
              isAutoClose={true}
              variant='white-1'
              menuClasses='w-100'
              isHorizontal
            />
          </div>
        </div>
        <div className='responsive-box mt-3'>
          <div>
            <SelectField
              placeholder='field_text_direction'
              label={textDirectionValuesMap[values.textDirection] ?? 'field_text_direction'}
              listValues={textDirectionValues}
              icon={arrowDownIcon}
              handleChange={handleChange}
              name='textDirection'
              isAutoClose={true}
              variant='white-1'
              menuClasses='w-100'
              isHorizontal
            />
          </div>
          <div>
            <SelectField
              placeholder='field_measurement'
              label={values.measurement === 'metric' ? 'select_metric' : 'field_measurement'}
              listValues={[{ value: 'metric', displayValue: 'select_metric' }]}
              icon={arrowDownIcon}
              handleChange={handleChange}
              name='measurement'
              isAutoClose={true}
              variant='white-1'
              menuClasses='w-100'
              isHorizontal
            />
          </div>
        </div>
      </div>

      {isPermissionAvailable(permissionKeys.system_company_setup_update) && (
        <div className='mt-3 w-100 d-flex justify-content-end gap-3'>
          <FormButton text='button_cancel' variant='white-1' />
          <FormLoadingButton
            onClick={submit}
            loading={loading}
            text='button_save'
            variant='green-1'
          />
        </div>
      )}
    </div>
  );
};

CompanySettings.propTypes = {
  companyDetails: PropTypes.object,
  getCompanyDetail: PropTypes.func.isRequired,
};

export default CompanySettings;
