import React, { Fragment, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import eyeIcon from 'assets/images/icons/eye-off.svg';
import phoneIcon from 'assets/images/icons/phone.svg';
import BackNavigation from './BackNavigation';
import Error from 'components/Error';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import MobileNumberField from 'components/form-components/MobileNumberField';
import OTPField from 'components/form-components/OTPField';
import PasswordField from 'components/form-components/PasswordField';
import {
  checkUserExist,
  encryptPassword,
  newPasswordSchema,
  otpSchema,
  resetPasswordSchema,
  verifyOTP,
} from './utils';
import { CallAPI, GetOtpForOperation } from 'actions/General';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState({
    countryCode: location.state?.countryCode ?? '+966',
    mobileNumber: location.state?.mobileNumber ?? '',
    otp: '',
    password: '',
    rePassword: '',
  });

  const [apiError, setApiError] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [publicKey, setPublicKey] = useState('');

  const onBackClick = () => {
    setStep(step - 1);
  };

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const onSubmitMobileNumber = async (e) => {
    e.preventDefault();
    setTouched({});
    try {
      await resetPasswordSchema.validate(values, { abortEarly: false });

      const userExist = await checkUserExist(
        { countryCode: values.countryCode, mobileNumber: values.mobileNumber },
        setApiError,
        setLoading,
      );

      if (userExist) {
        const result = await GetOtpForOperation(
          {
            otpType: 'reset_password',
            countryCode: values.countryCode,
            mobileNumber: values.mobileNumber,
          },
          setApiError,
          setLoading,
        );
        if (result.status) {
          setPublicKey(result.publicKey);
          setStep(2);
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  const onResendOTP = async () => {
    setResendOTPLoading(true);
    let result = await GetOtpForOperation(
      {
        otpType: 'reset_password',
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
      },
      setApiError,
      setResendOTPLoading,
    );
    setResendOTPLoading(false);
    if (result.status) {
      setPublicKey(result.publicKey);
      setResendOTP(true);
    }
  };

  const onSubmitOTP = async (e) => {
    e.preventDefault();
    setTouched({});
    try {
      await otpSchema.validate(values, { abortEarly: false });

      const payload = {
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
        otp: values.otp,
        publicKey,
      };
      await verifyOTP(payload, setApiError, setLoading, null, setStep);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  const onConfirmNewPassword = async (e) => {
    e.preventDefault();
    setTouched({});
    try {
      await newPasswordSchema.validate(values, { abortEarly: false });

      let result = await CallAPI(
        'UPDATE_PASSWORD',
        {
          countryCode: values.countryCode,
          mobileNumber: values.mobileNumber,
          password: await encryptPassword(values.password),
        },
        null,
        setLoading,
        setApiError,
        null,
      );
      if (result.status) {
        toast.success('Password updated successfully');
        navigate('/login');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  return (
    <Fragment>
      {step > 1 && <BackNavigation onClick={onBackClick} />}
      <div className='auth-form-container'>
        {step === 1 && (
          <Fragment>
            <h3 className='main-heading'>{t('text_otp_verification')}</h3>
            <p className='sub-heading'>{t('text_otp_verification_text')}</p>
            {apiError ? <Error msg={apiError} /> : <></>}
            <MobileNumberField
              fieldProps={{ name: 'mobileNumber', placeholder: '123123123' }}
              displayName='field_phone_number'
              icon={phoneIcon}
              value={{ countryCode: values.countryCode, mobileNumber: values.mobileNumber }}
              error={errors.mobileNumber}
              touched={touched.mobileNumber}
              handleChange={handleChange}
            />
            <div className='space-20'></div>
            <FormLoadingButton
              text='button_get_otp'
              variant='green-1'
              loading={loading}
              onClick={onSubmitMobileNumber}
            />
            <div className='space-20'></div>
            <p className='helper-text text-left'>
              <NavLink to='/login'>
                <span className='theme-text-green-1'>{t('text_go_to_login')}</span>
              </NavLink>
            </p>
          </Fragment>
        )}
        {step === 2 && (
          <Fragment>
            <h3 className='main-heading'>{t('text_otp_verification')}</h3>
            <p className='sub-heading'>
              {t('text_sent_otp_on_this_number')}: {values.countryCode} {values.mobileNumber}
            </p>
            {apiError ? <Error msg={apiError} /> : <></>}
            <OTPField
              name='otp'
              handleChange={handleChange}
              error={errors.otp}
              touched={touched.otp}
            />
            <div className='space-20'></div>
            <FormLoadingButton
              text='button_verify'
              variant='green-1'
              loading={loading}
              onClick={onSubmitOTP}
            />
            {!resendOTP && (
              <Fragment>
                <div className='space-20'></div>
                <div>
                  <span className='helper-text'>{t('text_did_not_receive_otp')} </span>
                  <span
                    {...(!resendOTPLoading && { onClick: () => onResendOTP() })}
                    className='theme-text-green-1 cursor-pointer'
                  >
                    {t('text_resend_otp')}
                  </span>
                  {resendOTPLoading && (
                    <span>
                      <Spinner size='sm' className='theme-text-green-1 ms-2' />
                    </span>
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
        {step === 3 && (
          <Fragment>
            <h3 className='main-heading'>{t('text_reset_your_password')}</h3>
            <p className='sub-heading'>{t('text_login_to_company')}</p>
            <PasswordField
              name='password'
              displayName='field_enter_new_password'
              placeholder='field_enter_password'
              icon={eyeIcon}
              value={values.password}
              error={errors.password}
              touched={touched.password}
              handleChange={handleChange}
              classes='mb-3'
            />
            <PasswordField
              name='rePassword'
              displayName='field_re_enter_new_password'
              placeholder='field_enter_password'
              icon={eyeIcon}
              value={values.rePassword}
              error={errors.rePassword}
              touched={touched.rePassword}
              handleChange={handleChange}
              classes='mb-3'
            />
            <div className='space-10'></div>
            <FormLoadingButton
              text='button_confirm'
              variant='green-1'
              loading={loading}
              onClick={onConfirmNewPassword}
            />
            <div className='space-20'></div>
            <p className='helper-text text-left'>
              <NavLink to='/login'>
                <span className='theme-text-green-1'>{t('text_go_to_login')}</span>
              </NavLink>
            </p>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ResetPassword;
