import React from 'react';
import TextField from '../../components/form-components/TextField';
import PropTypes from 'prop-types';

const AddCompanyStep1 = ({ handleChange, values, errors, touched }) => {
  return (
    <div className='d-flex flex-column align-items-center w-100'>
      <TextField
        name='name'
        displayName='field_name_title'
        placeholder='field_name_title'
        icon={null}
        handleChange={handleChange}
        value={values.name}
        error={errors.name}
        touched={touched.name}
        classes='mb-3'
      />
      <TextField
        name='role'
        displayName='field_your_role'
        placeholder='field_role'
        icon={null}
        handleChange={handleChange}
        value={values.role}
        error={errors.role}
        touched={touched.role}
        classes='mb-3'
      />
      <TextField
        name='email'
        displayName='field_business_email'
        placeholder='field_business_email'
        icon={null}
        handleChange={handleChange}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        classes='mb-3'
      />
    </div>
  );
};
AddCompanyStep1.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
};
export default AddCompanyStep1;
