import React from 'react';
import PropTypes from 'prop-types';
import doubleUDownArrowIcon from '../../assets/images/icons/double-down-arrow.svg';
import { useTranslation } from 'react-i18next';

const MainToggleBoxHeader = ({ show, toggleBoxOpen, title, titleIcon, infoIcon }) => {
  const { t } = useTranslation();
  return (
    <div className={`main-toggler-header${show ? ' active' : ''}`} onClick={() => toggleBoxOpen()}>
      <div className='d-flex gap-3'>
        {titleIcon && <img src={titleIcon} />}
        <label>{t(title)}</label>
        {infoIcon && <img src={infoIcon} />}
      </div>
      <img className={show ? 'svg-white rotate-180' : ''} src={doubleUDownArrowIcon} />
    </div>
  );
};

MainToggleBoxHeader.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleBoxOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  infoIcon: PropTypes.string,
};

export default MainToggleBoxHeader;
