import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import TableComponent from 'components/table-component/Table';
import { cloneDeep } from 'lodash';
import { CallAPI } from 'actions/General';
import Error from 'components/Error';
import { permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import AuthorizeError from 'pages/AuthorizeError';
import dataSourceIcon from 'assets/images/icons/data-source.svg';
import { useDispatch } from 'react-redux';
import { AuthReducer } from '../../../redux/auth';
import EditDataSourceHeader from './EditDataSourceHeader';
import Loader from 'components/common-components/Loader';
const AuthAction = AuthReducer.actions;

const DatasourceList = ({ setEnabledFilterTab }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editedRowData, setEditedRowData] = useState({});
  const [addRow, setAddRow] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [dataSourceEntriesList, setDataSourceEntriesList] = useState([]);
  const [dataSourceEntriesTableFields, setDataSourceEntriesTableFields] = useState([]);
  const [headerKeyDataRelation, setHeaderKeyDataRelation] = useState({});
  const [totalDataSources, setTotalDataSources] = useState(0);
  const [loader, setLoader] = useState(false);
  const [ApiError, setApiError] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [hasPermissionToAccess] = useState(
    isPermissionAvailable(permissionKeys.field_work_datasource_read),
  );

  const getAllDataSources = async (page = 1, limit = 10) => {
    let result = await CallAPI(
      'GET_DATASOURCE_ENTRIES',
      { dataSourceId: dataSource._id, page: page, limit: limit },
      null,
      setLoader,
      setApiError,
      null,
    );
    if (result.status) {
      let entriesList = [];
      result.data.dataEntries.map((singleEntry) => {
        let singleObject = {};
        singleEntry.entryValues.map((singleEntryValue) => {
          singleObject[headerKeyDataRelation[singleEntryValue.columnId]] =
            singleEntryValue.keyValue;
        });
        singleObject.row_datasource_entry_id = singleEntry._id;
        singleObject.row_datasource_name = dataSource.schemaName;
        singleObject.row_datasource_id = dataSource._id;
        entriesList.push(singleObject);
      });

      setDataSourceEntriesList(entriesList);
      setTotalDataSources(result.data.totalCount);
    }
  };
  const getAllDataSourceColumns = async () => {
    let result = await CallAPI(
      'GET_ALL_DATASOURCE_COLUMN',
      null,
      dataSource._id,
      setLoader,
      setApiError,
      null,
    );
    let hasAnyFilter = false;
    if (result.status && result.data) {
      let tableHeader = [];
      let relations = {};
      result.data.map((singleKey) => {
        relations[singleKey.columnId] = singleKey.columnName;
        tableHeader.push({
          name: singleKey.columnName,
          isSelected: true,
          displayName: singleKey.columnName,
          type: singleKey.dataType,
          associatedKey: singleKey.columnName,
          columnId: singleKey.columnId,
          isKeyFilter: !!singleKey.isKeyFilter,
          isFilter: !!singleKey.isFilter,
        });
        if (!hasAnyFilter && singleKey.isKeyFilter) hasAnyFilter = true;
      });

      setEnabledFilterTab(hasAnyFilter);

      if (hasAnyFilter)
        tableHeader.sort((a, b) => Number(!!b.isKeyFilter) - Number(!!a.isKeyFilter));

      setHeaderKeyDataRelation(relations);
      setDataSourceEntriesTableFields(tableHeader);
    }
  };
  const refreshLists = () => {
    getAllDataSourceColumns();
    setSelectedColumns([]);
  };
  useEffect(() => {
    if (dataSource) {
      getAllDataSourceColumns();
    }
  }, [dataSource]);
  useEffect(() => {
    if (Object.keys(headerKeyDataRelation).length > 0) {
      getAllDataSources();
    }
  }, [headerKeyDataRelation]);
  useEffect(() => {
    if (location.state.dataSource != undefined) {
      dispatch(
        AuthAction.updateBreadCrumb({
          breadcrumbs: {
            icon: dataSourceIcon,
            items: ['Data Source', 'Database', 'Data Source', location.state.dataSource.schemaName],
          },
        }),
      );
      setDataSource(location.state.dataSource);
    }
  }, [location]);

  const onRowsSelection = (name, value) => {
    setSelectedColumns([]);
    let selectedIds = cloneDeep(selectedRows);
    if (name === 'all') {
      dataSourceEntriesList.map((single_datasource) => {
        if (value && selectedIds.indexOf(single_datasource.row_datasource_entry_id) === -1)
          selectedIds.push(single_datasource.row_datasource_entry_id);
        if (!value) selectedIds = [];
        setSelectedRows(selectedIds);
      });
    } else {
      if (value) selectedIds.push(name);
      else selectedIds.splice(selectedIds.indexOf(name), 1);
      setSelectedRows(selectedIds);
    }
  };
  const onColumnSelection = (field) => {
    setSelectedRows([]);
    const foundIndex = selectedColumns.findIndex((c) => c.columnId === field.columnId);
    const tempSelection = [...selectedColumns];

    if (foundIndex > -1) tempSelection.splice(foundIndex, 1);
    else tempSelection.push(field);

    setSelectedColumns(tempSelection);
  };

  const actionsCallBack = (type, id) => {
    if (type === 'cancel') {
      setAddRow(false);
    } else if (type === 'add') {
      getAllDataSources();
      setAddRow(false);
    } else if (type === 'edit') {
      setAddRow(false);
      let editedRecord = dataSourceEntriesList.filter(
        (singleRow) => singleRow.row_datasource_entry_id === id,
      )[0];
      let object = { row_datasource_entry_id: editedRecord.row_datasource_entry_id };
      Object.keys(editedRecord).map((singleValue) => {
        dataSourceEntriesTableFields.map((singleHeader) => {
          if (singleHeader.name === singleValue) {
            object[singleHeader.columnId] = editedRecord[singleValue];
          }
        });
      });
      setEditedRowData(object);
      setAddRow(true);
    } else if (type === 'edit_delete_column') {
      refreshLists();
    } else if (type === 'delete') {
      getAllDataSources();
    }
  };
  return (
    <>
      {!hasPermissionToAccess ? (
        <AuthorizeError />
      ) : (
        <div>
          {loader && <Loader color='green-1' type='modal-spinner' />}
          {ApiError ? <Error msg={ApiError} /> : <></>}
          <div className='px-0'>
            <EditDataSourceHeader
              refreshLists={refreshLists}
              setAddRow={(value) => {
                setEditedRowData({});
                setAddRow(value);
              }}
              title={dataSource ? dataSource.schemaName : ''}
              datasource_id={dataSource ? dataSource._id : ''}
              dataSourceName={dataSource?.schemaName ?? ''}
              onBack={() => {
                navigate(-1);
              }}
              fieldsList={dataSourceEntriesTableFields}
              selectedRows={selectedRows}
              selectedColumns={selectedColumns}
              actionsCallBack={actionsCallBack}
            />
            <div className='position-relative'>
              <TableComponent
                header={dataSourceEntriesTableFields}
                data={dataSourceEntriesList}
                props={{
                  addRow,
                  type: 'data_sources_entries',
                  actionsType: 'data_sources_entries',
                  actionsCallBack: actionsCallBack,
                  editedRowData,
                }}
                totalRecords={totalDataSources}
                filterEnabled={false}
                loader={loader}
                isFieldSelectionEnabled={false}
                isPaginationEnabled={true}
                isActionsEnabled={true}
                isSelectionEnabled={true}
                selectionKey={'row_datasource_entry_id'}
                paginationFunction={getAllDataSources}
                onSelection={onRowsSelection}
                selectedRows={selectedRows}
                selectedColumns={selectedColumns}
                onColumnSelection={onColumnSelection}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

DatasourceList.propTypes = {
  setEnabledFilterTab: PropTypes.func.isRequired,
};

export default DatasourceList;
