import React from 'react';
import PropTypes from 'prop-types';
import triangleIcon from 'assets/images/icons/triangle.svg';
import triangleGreenIcon from 'assets/images/icons/triangle-green.svg';

const TabDivider = ({ variant }) => {
  return (
    <div className='tab-divider'>
      <img src={variant ? triangleGreenIcon : triangleIcon} />
      <div className={variant ? variant : ''} />
      <img src={variant ? triangleGreenIcon : triangleIcon} className='rotate-180' />
    </div>
  );
};

TabDivider.propTypes = {
  variant: PropTypes.string,
};
export default TabDivider;
