import React from 'react';
import PropTypes from 'prop-types';
import ListCompanies from './ListCompanies';
import { CloseButton, Modal } from 'react-bootstrap';

const SwitchCompany = ({ show, showCloseButton, closeModal }) => {
  return (
    <Modal
      show={show}
      contentClassName='alert-modal'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      keyboard={false}
    >
      <div className='alert-content p-4 d-flex flex-column gap-4 justify-content-center align-items-center'>
        {showCloseButton && <CloseButton onClick={() => closeModal()} />}
        <ListCompanies isSwitchCompany={true} closeModal={closeModal} />
      </div>
    </Modal>
  );
};
SwitchCompany.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  showCloseButton: PropTypes.bool,
};
export default SwitchCompany;
