import constructionIcon from '../../assets/images/industry/construction.svg';
import cleaningIcon from '../../assets/images/industry/cleaning.svg';
import foodItemsIcon from '../../assets/images/industry/foodItems.svg';
import healthIcon from '../../assets/images/industry/health.svg';
import manufacturingIcon from '../../assets/images/industry/manufacturing.svg';
import retailIcon from '../../assets/images/industry/retail.svg';
import securityIcon from '../../assets/images/industry/security.svg';
import workersIcon from '../../assets/images/industry/workers.svg';
import formIcon from '../../assets/images/features/forms.svg';
import dashboardIcon from '../../assets/images/features/dashboard.svg';
import usersIcon from '../../assets/images/features/users.svg';
import companySetupIcon from '../../assets/images/features/company_setup.svg';
import issuesIcon from '../../assets/images/features/issues.svg';
import reportsIcon from '../../assets/images/features/reports.svg';
import guestsIcon from '../../assets/images/features/guests.svg';
import billingIcon from '../../assets/images/features/billing.svg';
import tasksIcon from '../../assets/images/features/tasks.svg';
import attendanceIcon from '../../assets/images/features/attendance.svg';
import groupsIcon from '../../assets/images/features/groups.svg';
import supportIcon from '../../assets/images/features/support.svg';

import * as yup from 'yup';

export const steps = {
  1: 'text_customize_your_app',
  2: 'text_customize_your_app',
  3: 'text_add_features_to_your_app',
  4: 'text_add_your_logo',
};

export const employees = [
  {
    displayName: 'select_1_to_10',
    value: 'from_1_to_10',
  },
  {
    displayName: 'select_10_to_100',
    value: 'from_10_to_100',
  },
  {
    displayName: 'select_100_to_1000',
    value: 'from_100_to_1000',
  },
  {
    displayName: 'select_1000+',
    value: 'above_1000',
  },
];
export const businessesList = [
  {
    displayName: 'select_construction',
    value: 'construction',
    icon: constructionIcon,
  },
  {
    displayName: 'select_security',
    value: 'security',
    icon: securityIcon,
  },
  {
    displayName: 'select_health',
    value: 'health',
    icon: healthIcon,
  },
  {
    displayName: 'select_cleaning',
    value: 'cleaning',
    icon: cleaningIcon,
  },
  {
    displayName: 'select_retail',
    value: 'retail',
    icon: retailIcon,
  },
  {
    displayName: 'select_food_items',
    value: 'foodItems',
    icon: foodItemsIcon,
  },
  {
    displayName: 'select_workers',
    value: 'workers',
    icon: workersIcon,
  },
  {
    displayName: 'select_manufacturing',
    value: 'manufacturing',
    icon: manufacturingIcon,
  },
];
export const featuresList = [
  {
    displayName: 'feature_forms',
    value: 'forms',
    icon: formIcon,
    isDisabled: true,
  },
  {
    displayName: 'feature_dashboard',
    value: 'dashboard',
    icon: dashboardIcon,
    isDisabled: true,
  },
  {
    displayName: 'feature_users',
    value: 'users',
    icon: usersIcon,
    isDisabled: true,
  },
  {
    displayName: 'feature_company_setup',
    value: 'company_setup',
    icon: companySetupIcon,
    isDisabled: true,
  },
  {
    displayName: 'feature_issues',
    value: 'issues',
    icon: issuesIcon,
    isDisabled: false,
  },
  {
    displayName: 'feature_reports',
    value: 'reports',
    icon: reportsIcon,
    isDisabled: false,
  },
  {
    displayName: 'feature_guests',
    value: 'guests',
    icon: guestsIcon,
    isDisabled: false,
  },
  {
    displayName: 'feature_billing',
    value: 'billing',
    icon: billingIcon,
    isDisabled: true,
  },
  {
    displayName: 'feature_tasks',
    value: 'tasks',
    icon: tasksIcon,
    isDisabled: false,
  },
  {
    displayName: 'feature_attendance',
    value: 'attendance',
    icon: attendanceIcon,
    isDisabled: false,
  },
  {
    displayName: 'feature_groups',
    value: 'groups',
    icon: groupsIcon,
    isDisabled: false,
  },
  {
    displayName: 'feature_support',
    value: 'support',
    icon: supportIcon,
    isDisabled: true,
  },
];
export const featuresCategory = [
  {
    displayName: 'sidebar_fieldwork',
  },
  {
    displayName: 'sidebar_view_data',
  },
  {
    displayName: 'sidebar_employees',
  },
  {
    displayName: 'sidebar_system',
  },
];
export const step1Schema = yup.object({
  name: yup.string().required(),
  role: yup.string().required(),
  email: yup.string().required(),
});
export const step2Schema = yup.object({
  employees: yup
    .string()
    .oneOf(
      ['from_1_to_10', 'from_10_to_100', 'from_100_to_1000', 'above_1000'],
      'error select at least one option',
    ),
  businesses: yup.array().min(1, 'error select at least one option'),
});
export const step3Schema = yup.object({
  features: yup.array().min(1, 'error select at least one option'),
});
export const step4Schema = yup.object({});
export const uploadImage = async (file, uploadDetail, setState) => {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'image/jpeg');
  myHeaders.append('x-ms-blob-type', 'BlockBlob');

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: file,
    redirect: 'follow',
  };

  await fetch(uploadDetail.url, requestOptions)
    .then(() => {
      if (setState) setState(uploadDetail.id, uploadDetail.url);
    })
    .catch((error) => console.log('error', error));
  return '';
};
