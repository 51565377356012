import React from 'react';
import PropTypes from 'prop-types';
import calendarGreyIcon from 'assets/images/icons/calendar-grey.svg';
import clockGreyIcon from 'assets/images/icons/clock-grey.svg';

const DateTimeFieldPreview = ({ field }) => {
  return (
    <div className='d-flex flex-column gap-3'>
      {['dateAndTime', 'date'].includes(field.properties?.basicProperties?.subType ?? '') && (
        <div className='border-grey-1-h-1 rounded-md d-flex align-items-center gap-2 p-2'>
          <img src={calendarGreyIcon} />
          <label>{new Date().toLocaleDateString()}</label>
        </div>
      )}
      {['dateAndTime', 'time'].includes(field.properties?.basicProperties?.subType ?? '') && (
        <div className='border-grey-1-h-1 rounded-md d-flex align-items-center gap-2 p-2'>
          <img src={clockGreyIcon} className='px-1' />
          <label>{new Date().toLocaleTimeString()}</label>
        </div>
      )}
    </div>
  );
};

DateTimeFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
};

export default DateTimeFieldPreview;
