import React, { Fragment, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import eyeIcon from 'assets/images/icons/eye-off.svg';
import phoneIcon from 'assets/images/icons/phone.svg';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import MobileNumberField from 'components/form-components/MobileNumberField';
import OTPField from 'components/form-components/OTPField';
import PasswordField from 'components/form-components/PasswordField';
import { loginSchema, otpSchema, userLogin, verifyOTP } from './utils';
import { GetOtpForOperation } from 'actions/General';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    countryCode: '+966',
    mobileNumber: '',
    password: '',
    otp: '',
  });

  const [apiError, setApiError] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);

  const [publicKey, setPublicKey] = useState('');

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTouched({});
    try {
      await loginSchema.validate(values, { abortEarly: false });

      await userLogin(
        { ...values },
        setApiError,
        setLoading,
        setOtpRequired,
        setPublicKey,
        navigate,
      );
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  const onSubmitOTP = async (e) => {
    e.preventDefault();
    setTouched({});
    try {
      await otpSchema.validate(values, { abortEarly: false });

      const payload = {
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
        otp: values.otp,
        publicKey,
      };
      const verified = await verifyOTP(payload, setApiError, setLoading, 'register');
      if (verified) {
        toast.success('OTP verified successfully');
        navigate('/login');
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
  };

  const onResendOTP = async () => {
    setResendOTPLoading(true);
    let result = await GetOtpForOperation(
      { otpType: 'register', countryCode: values.countryCode, mobileNumber: values.mobileNumber },
      setApiError,
      setResendOTPLoading,
    );
    setResendOTPLoading(false);
    if (result.status) {
      setPublicKey(result.publicKey);
      setResendOTP(true);
    }
  };

  return (
    <div className='auth-form-container'>
      {!otpRequired ? (
        <Fragment>
          <h3 className='main-heading'>{t('text_welcome_to_midani')}</h3>
          <p className='sub-heading'>{t('text_login_to_company')}</p>
          {apiError ? <Error msg={apiError} /> : <></>}{' '}
          <MobileNumberField
            fieldProps={{ name: 'mobileNumber', placeholder: '123123123' }}
            displayName='field_phone_number'
            icon={phoneIcon}
            value={{ countryCode: values.countryCode, mobileNumber: values.mobileNumber }}
            error={errors.mobileNumber}
            touched={touched.mobileNumber}
            handleChange={handleChange}
            classes='mb-3'
          />
          <PasswordField
            name='password'
            displayName='field_password'
            placeholder='field_enter_password'
            icon={eyeIcon}
            value={values.password}
            error={errors.password}
            touched={touched.password}
            handleChange={handleChange}
            onEnter={onSubmit}
            classes='mb-3'
          />
          <div className='space-10'></div>
          <FormLoadingButton
            text='button_login'
            variant='green-1'
            loading={loading}
            onClick={onSubmit}
          />
          <div className='space-20'></div>
          <p className='helper-text text-left'>
            {t('text_forgot_password')}{' '}
            <NavLink to='/reset_password'>
              <span className='theme-text-green-1'>{t('text_reset_password')}</span>
            </NavLink>
          </p>
          <div className='space-20'></div>
          <FormButton
            text='button_create_account'
            variant='white-1'
            onClick={() => navigate('/register', { relative: 'path' })}
          />
        </Fragment>
      ) : (
        <Fragment>
          <h3 className='main-heading'>{t('text_otp_verification')}</h3>
          <p className='sub-heading'>
            {t('text_sent_otp_on_this_number')} {values.countryCode} {values.mobileNumber}
          </p>
          {apiError ? <Error msg={apiError} /> : <></>}
          <OTPField
            name='otp'
            handleChange={handleChange}
            error={errors.otp}
            touched={touched.otp}
          />
          <div className='space-20'></div>
          <FormLoadingButton
            text='button_verify'
            variant='green-1'
            loading={loading}
            onClick={onSubmitOTP}
          />
          {!resendOTP && (
            <Fragment>
              <div className='space-20'></div>
              <div>
                <span className='helper-text'>{t('text_did_not_receive_otp')} </span>
                <span
                  {...(!resendOTPLoading && { onClick: (e) => onResendOTP(e) })}
                  className='theme-text-green-1 cursor-pointer'
                >
                  {t('text_resend_otp')}
                </span>
                {resendOTPLoading && (
                  <span>
                    <Spinner size='sm' className='theme-text-green-1 ms-2' />
                  </span>
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Login;
