import { createSlice } from '@reduxjs/toolkit';

export const ColorReducer = createSlice({
  name: 'colors',
  initialState: {
    recentColors: [],
  },
  reducers: {
    updateRecentColors: (state, action) => {
      state.recentColors = action.payload.recentColors;
    },
  },
});
