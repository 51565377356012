import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';
import plusIcon from 'assets/images/icons/plus.svg';
import searchIcon from 'assets/images/icons/search.svg';
import AlertModal from 'components/common-components/AlertModal';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectionComponent from 'components/common-components/SelectionComponent';
import TableComponent from 'components/table-component/Table';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';

const DataSourceFilter = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useState({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);
  const [filterRowIds, setFilterRowIds] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const updateUserList = async (users) => {
    const existingUserIds = filterRows.map((f) => f.userId);
    const updatedUserIds = users.map((u) => u.id);

    const unionArr = [...new Set([...existingUserIds, ...updatedUserIds])];

    const newUserIds = unionArr.filter((u) => !existingUserIds.includes(u));
    const removedUserIds = unionArr.filter((u) => !updatedUserIds.includes(u));

    const newUsers = users.filter((u) => newUserIds.includes(u.id));

    const removedFilters = filterRows.filter((f) => removedUserIds.includes(f.userId));

    const payload = {
      dataSource: {
        id: location.state.dataSource._id,
        title: location.state.dataSource.schemaName,
      },
      addFilters: newUsers.map((user) => ({
        dataSourceId: location.state.dataSource._id,
        _id: null,
        userId: user.id,
        userName: user.name,
        filterValues: filterRowIds.map((id) => ({ dataEntryRowId: id, isChecked: false })),
      })),
      deleteFilters: removedFilters.map((f) => f._id),
    };

    const result = await CallAPI(
      'BULK_UPSERT_DATA_SOURCE_FILTER_ROWS',
      payload,
      null,
      setAddLoading,
      null,
      null,
    );
    if (result.status) {
      toast.success('Filter users updated successfully');
      getDataSourceFilterData();
    }
  };

  const getDataSourceFilterSchema = async () => {
    const payload = { dataSourceId: location.state.dataSource._id };
    const result = await CallAPI('GET_DATA_SOURCE_FILTER_SCHEMA', payload, null, null, null, null);
    if (result.status) {
      const headerList = [
        { name: '', displayName: '#', type: 'number', isSelected: true, associatedKey: 'sr' },
      ];
      let valueColumnList = [];
      let tempFilterRowIds = [];
      result.data.filterColumns.forEach((column) => {
        if (column.isKeyColumn) {
          headerList.push({
            name: 'userName',
            displayName: column.headerValue,
            type: 'string',
            isSelected: true,
            populateFunc: 'populateUserNameInDataSourceFilter',
            callbackFunc: 'handleDeleteRow',
          });
        } else {
          valueColumnList.push({
            name: 'filter_check_field',
            displayName: column.headerValue,
            isSelected: true,
            type: 'text',
            filterKey: column.dataEntryRowId,
            populateFunc: 'populateCheckField',
            callbackFunc: 'handleCheckChange',
          });

          tempFilterRowIds.push(column.dataEntryRowId);
        }
      });

      setFilterRowIds([...tempFilterRowIds]);
      if (valueColumnList.length) {
        headerList.push({
          name: 'filter_check_field',
          displayName: 'All',
          isSelected: true,
          type: 'text',
          filterKey: 'all',
          populateFunc: 'populateCheckField',
          callbackFunc: 'handleCheckChange',
        });

        headerList.push(...valueColumnList);
      }

      setTableHeader([...headerList]);
    }
  };

  const getDataSourceFilterData = async () => {
    const payload = {
      ...filterData,
      dataSourceId: location.state.dataSource._id,
      page: 1,
      limit: 10,
    };
    const result = await CallAPI('GET_DATA_SOURCE_FILTER_DATA', payload, null, null, null, null);
    if (result.status) {
      const tempFilterRows = result.data.dataFilters.map((filter, index) => ({
        sr: index + 1,
        _id: filter._id,
        userId: filter.userId,
        userName: filter.userName,
        filterValuesObj: Object.fromEntries(
          filter.filterValues.map((v) => [v.dataEntryRowId, v.isChecked]),
        ),
      }));

      setFilterRows(tempFilterRows);
    }
  };

  const deleteFilterRow = async (dataFilterIds) => {
    const payload = {
      dataSource: {
        id: location.state.dataSource._id,
        title: location.state.dataSource.schemaName,
      },
      dataFilterIds,
    };
    const result = await CallAPI('DELETE_DATA_SOURCE_FILTER_ROW', payload, null, null, null, null);
    if (result.status) {
      toast.success('Filter users updated successfully');
      getDataSourceFilterData();
      setDeleteConfirmationId(null);
    }
  };

  const saveFilterData = async () => {
    const filtersToUpdate = filterRows.map((filter) => ({
      dataSourceId: location.state.dataSource._id,
      _id: filter._id,
      userId: filter.userId,
      userName: filter.userName,
      filterValues: Object.entries(filter.filterValuesObj).map(([dataEntryRowId, isChecked]) => ({
        dataEntryRowId,
        isChecked,
      })),
    }));

    const payload = {
      dataSource: {
        id: location.state.dataSource._id,
        title: location.state.dataSource.schemaName,
      },
      addFilters: filtersToUpdate,
      deleteFilters: [],
    };

    const result = await CallAPI(
      'BULK_UPSERT_DATA_SOURCE_FILTER_ROWS',
      payload,
      null,
      setSaveLoading,
      null,
      null,
    );
    if (result.status) {
      toast.success('Filter permissions updated successfully');

      getDataSourceFilterData();
    }
  };

  const handleCheckChange = (userId, valueObj) => {
    const tempFilterRows = cloneDeep(filterRows);
    tempFilterRows.forEach((f) => {
      if (f.userId === userId) f.filterValuesObj = valueObj;
    });

    setFilterRows(cloneDeep(tempFilterRows));
  };

  const getUserList = () => {
    return filterRows.map((filter) => ({ id: filter.userId, name: filter.userName }));
  };

  useEffect(() => {
    getDataSourceFilterData();
  }, [filterData]);
  useEffect(() => {
    getDataSourceFilterSchema();
  }, []);

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center p-3'>
        <label className='theme-size-1_2'>
          {location.state.dataSource?.schemaName ?? 'Data Source Name'}
        </label>
        <div className='d-flex gap-3'>
          <TextField
            value={searchText}
            name='searchText'
            handleChange={(_, value) => setSearchText(value)}
            debounceFunc={(field, value) => setFilterData({ [field]: value })}
            placeholder={t('field_search_user')}
            icon={searchIcon}
            shrink
          />
          <FormLoadingButton
            text='button_add_user'
            onClick={() => setShowAddUserModal(true)}
            loading={addLoading}
            variant='green-1'
            icon={plusIcon}
            iconClasses='svg-white'
          />
          <FormLoadingButton
            text='button_save'
            onClick={() => saveFilterData()}
            loading={saveLoading}
            variant='green-1'
          />
        </div>
      </div>
      <TableComponent
        data={filterRows}
        header={tableHeader}
        props={{
          type: 'data_source_filter_list',
          handleCheckChange,
          handleDeleteRow: (filterId) => setDeleteConfirmationId(filterId),
        }}
        totalRecords={1}
        filterEnabled={false}
        loader={false}
        isFieldSelectionEnabled={false}
        isPaginationEnabled={false}
        isActionsEnabled={false}
      />
      <AlertModal
        show={showAddUserModal}
        closeModal={() => setShowAddUserModal(false)}
        showCloseButton
        size='lg'
        actionButtons={[]}
        message='Select the users you want to add in data filter'
      >
        {showAddUserModal ? (
          <UserSelection
            setShowAddUserModal={setShowAddUserModal}
            updateUserList={updateUserList}
            userList={getUserList()}
          />
        ) : (
          <></>
        )}
      </AlertModal>
      <ConfirmationModal
        show={!!deleteConfirmationId}
        title={t('alert_common_title')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setDeleteConfirmationId(null),
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => deleteFilterRow([deleteConfirmationId]),
          },
        ]}
      />
    </div>
  );
};

export default DataSourceFilter;

const UserSelection = ({ setShowAddUserModal, userList, updateUserList }) => {
  const [selectedUsers, setSelectedUsers] = useState([...userList]);

  const handleChange = (_, value) => {
    setSelectedUsers(value);
  };

  return (
    <div className='w-100 px-4'>
      <SelectionComponent
        title='text_users'
        name='users'
        type='data_source_filter_users'
        selectedDataList={selectedUsers}
        setSelectedList={handleChange}
        showButtons={false}
        classes=''
      />
      <div className='d-flex gap-3 justify-content-end mt-4'>
        <FormButton
          text='button_cancel'
          onClick={() => setShowAddUserModal(false)}
          variant='white-1'
        />
        <FormButton
          text='button_save'
          onClick={() => {
            updateUserList(selectedUsers);
            setShowAddUserModal(false);
          }}
          variant='green-1'
        />
      </div>
    </div>
  );
};

UserSelection.propTypes = {
  setShowAddUserModal: PropTypes.func.isRequired,
  updateUserList: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
};
