import announcementIcon from 'assets/images/icons/announcement-green.svg';
import calendarIcon from 'assets/images/icons/calendar-grey.svg';
import calendarIconActive from 'assets/images/icons/calendar.svg';
import checkIcon from 'assets/images/icons/double-check-green.svg';
// import clipIcon from 'assets/images/icons/clip-grey.svg';
// import clipIconActive from 'assets/images/icons/clip-green.svg';
import dashboardIcon from 'assets/images/icons/dashboard.svg';
import dataSourceIcon from 'assets/images/icons/data-source-green.svg';
import formGreyIcon from 'assets/images/icons/form-grey.svg';
import formIconActive from 'assets/images/icons/form-green.svg';
import graphIcon from 'assets/images/icons/graph-grey.svg';
import graphIconActive from 'assets/images/icons/graph-green.svg';
import userIcon from 'assets/images/icons/users-green.svg';
import usersIcon from 'assets/images/icons/group-users-green.svg';
// import warningIcon from 'assets/images/icons/issues-grey.svg';
import warningIconActive from 'assets/images/icons/issues-green.svg';
import { defaultSupervisorStatusOptionsList } from 'pages/form/utils';

export const ActivityFiltersList = [
  {
    id: 1,
    title: 'tabs_all',
    value: 'all',
    breadcrumbValue: { icon: userIcon, items: ['Users', 'Activity'] },
    icon: graphIcon,
    activeIcon: graphIconActive,
  },
  {
    id: 2,
    title: 'tabs_forms',
    value: 'forms',
    breadcrumbValue: { icon: userIcon, items: ['Users', 'Activity'] },
    icon: formGreyIcon,
    activeIcon: formIconActive,
  },
  // {
  //   id: 3,
  //   title: 'tabs_groups',
  //   value: 'groups',
  //   breadcrumbValue: { icon: userIcon, items: ['Users', 'Activity'] },
  //   icon: usersIcon,
  //   activeIcon: usersIcon,
  // },
  {
    id: 4,
    title: 'tabs_attendance',
    value: 'attendance',
    breadcrumbValue: { icon: userIcon, items: ['Users', 'Activity'] },
    icon: calendarIcon,
    activeIcon: calendarIconActive,
  },
];
export const UserActivityTabs = [
  {
    id: 1,
    title: 'Detail',
    value: 'detail',
    breadcrumbValue: {
      icon: userIcon,
      items: ['Users', 'Users', '%%%User Name%%%', 'User Activity', 'Details'],
    },
  },
  {
    id: 2,
    title: 'Activity',
    value: 'activity',
    breadcrumbValue: {
      icon: userIcon,
      items: ['Users', 'Users', '%%%User Name%%%', 'User Activity', 'Activity'],
    },
  },
  {
    id: 3,
    title: 'Access',
    value: 'access',
    breadcrumbValue: {
      icon: userIcon,
      items: ['Users', 'Users', '%%%User Name%%%', 'User Activity', 'Access'],
    },
  },
];
export const getSupervisorStatusValue = (value) => {
  let supervisorStatus = defaultSupervisorStatusOptionsList.filter(
    (singleStatus) => singleStatus.value === value,
  );
  return supervisorStatus.length > 0 ? supervisorStatus[0].responseText : value;
};
export const activityIcons = {
  forms: formIconActive,
  users: userIcon,
  groups: usersIcon,
  issues: warningIconActive,
  clock: calendarIconActive,
  system_permissions: checkIcon,
  folders: checkIcon,
  group_segment: checkIcon,
  announcement: announcementIcon,
  data_source: dataSourceIcon,
  submission: dashboardIcon,
};
