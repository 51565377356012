import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TextArea = ({
  name,
  placeholder,
  displayName,
  icon,
  value,
  error,
  touched,
  shrink,
  handleChange,
  classes,
  isHorizontal,
  horizontalLabelClass,
  disabled,
  isDisplayAbleOnly,
  rows,
}) => {
  const { t } = useTranslation();
  const shrinkLabel = shrink ? ' shrink-label' : '';
  return (
    <div
      className={`custom-input-box${classes ? ' ' + classes : ''} ${
        isHorizontal ? 'd-flex align-items-center w-100' : ''
      } ${isDisplayAbleOnly ? 'read-only' : ''}`}
    >
      {displayName && (
        <label
          htmlFor={name}
          className={`${!touched && error ? 'label-error' + shrinkLabel : shrinkLabel} ${
            isHorizontal ? horizontalLabelClass : ''
          }`}
        >
          {t(displayName)}
        </label>
      )}
      <div className='d-flex flex-column w-100'>
        <div className={`custom-input-field ${isHorizontal ? 'w-100' : ''}`}>
          <textarea
            disabled={disabled}
            rows={rows}
            type='text'
            style={{ resize: 'none' }}
            id={name}
            name={name}
            className={`${!touched && error ? 'input-error' : ''}`}
            placeholder={t(placeholder)}
            value={value}
            autoComplete='off'
            onChange={(e) => {
              !isDisplayAbleOnly ? handleChange(e.target.name, e.target.value) : null;
            }}
          />
          {icon && <img src={icon} className='icon' />}
        </div>
        {!touched && error && <p className='form-field-error'>{t('error ' + error)}</p>}
      </div>
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  horizontalLabelClass: PropTypes.string,
  isDisplayAbleOnly: PropTypes.bool,
  rows: PropTypes.number,
};

export default TextArea;
