import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/common-components/Loader';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import TrackingOption from './TrackingOption';
import { CallAPI } from 'actions/General';

const AttendanceSetup = () => {
  const { t } = useTranslation();
  const [trackLocation, setTrackLocation] = useState(false);
  const [loader, setLoader] = useState(false);
  const [trackOption, setTrackOption] = useState('15');
  const [settingId, setSettingId] = useState('');

  const getSetupDetail = async () => {
    let result = await CallAPI('FETCH_ATTENDANCE_SETUP', null, null, setLoader, null);
    if (result.status) {
      if (result.data.length === 0)
        updateSetting(true, {
          settingType: 'track_location_period',
          settingValue: '15',
          isEnabled: false,
        });
      else {
        setSettingId(result.data[0]._id);
        setTrackOption(result.data[0].settingValue);
        setTrackLocation(result.data[0].isEnabled);
      }
    }
  };

  const updateSetting = async (isCreate = false, data) => {
    let API = isCreate ? 'CREATE_ATTENDANCE_SETUP' : 'Edit_ATTENDANCE_SETUP';
    await CallAPI(API, data, isCreate ? null : settingId, setLoader, null);
    if (isCreate) getSetupDetail();
  };
  useEffect(() => {
    getSetupDetail();
  }, []);
  const updateLocalSetting = async (type, value) => {
    if (type === 'period') setTrackOption(value);
    else setTrackLocation(value);
    await updateSetting(false, {
      settingType: 'track_location_period',
      settingValue: type === 'period' ? value : '15',
      isEnabled: type === 'location' ? value : trackLocation,
    });
  };
  return (
    <div>
      <div className='p-4 border-bottom-grey-10-h-1'>
        <label className='theme-font-inter-medium theme-size-1_1 text-uppercase'>
          {t('text_attendance_setup_msg')}
        </label>
      </div>
      <div className='p-5 d-flex flex-column gap-4'>
        {loader && <Loader color='green-1' />}
        <div className='d-flex gap-3 align-items-center'>
          <label className='theme-size-2 theme-font-inter-medium theme-text-black-1 '>
            {t('text_track_location')}
          </label>

          <ToggleSwitch
            name='enableChats'
            value={trackLocation}
            handleChange={() => updateLocalSetting('location', !trackLocation)}
          />
        </div>

        <label className='theme-font-inter-regular theme-size-1_1 theme-text-black-1'>
          {t('text_location_tracking_period')}
        </label>

        <div className='d-flex gap-3'>
          <TrackingOption
            title={'select_every_15_minutes'}
            isSelected={trackOption === '15'}
            onClick={() => updateLocalSetting('period', '15')}
          />
          <TrackingOption
            title={'select_every_30_minutes'}
            isSelected={trackOption === '30'}
            onClick={() => updateLocalSetting('period', '30')}
          />
          <TrackingOption
            title={'select_every_60_minutes'}
            isSelected={trackOption === '60'}
            onClick={() => updateLocalSetting('period', '60')}
          />
        </div>
      </div>
    </div>
  );
};
AttendanceSetup.propTypes = {};
export default AttendanceSetup;
