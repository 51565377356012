import React from 'react';
import PropTypes from 'prop-types';
import FieldDetailView from './FieldDetailView';
import SwappingComponent from './SwappingComponent';
import { userFieldList } from '../utils';
import { useTranslation } from 'react-i18next';
import elementPreviewIcon from 'assets/images/icons/field_preview_Icon.svg';

const fieldTypeMap = Object.fromEntries([...userFieldList].map((f) => [f.elementType, f]));

const UserFieldComponent = ({
  field,
  selectComponent,
  changeFormElementOrder,
  isSelected,
  dataSourcesList,
  elementIndex,
}) => {
  const { t } = useTranslation();
  const getDynamicStyle = (fieldName) => {
    const style = {
      color: field.properties?.basicProperties?.[`${fieldName}Color`],
      fontSize: field.properties?.basicProperties?.[`${fieldName}Size`],
    };
    if ((field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('italic')) {
      style['fontStyle'] = 'italic';
    }
    if ((field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('bold')) {
      style['fontWeight'] = 'bolder';
    }
    if ((field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('underline')) {
      style['textDecoration'] = 'underline';
    }
    if (
      (field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('strikethrough')
    ) {
      style['textDecoration'] = 'line-through';
    }
    if (
      (field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('underline') &&
      (field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('strikethrough')
    ) {
      style['textDecoration'] = 'underline line-through';
    }

    return style;
  };

  return (
    <div className={`field-component ${isSelected ? 'field-component-selected' : ''}`}>
      <div className='field-component-header'>
        <div
          className='d-flex gap-3 flex-grow-1 cursor-pointer py-1'
          onClick={() => selectComponent()}
        >
          <img
            src={elementPreviewIcon}
            className={`field-type-icon ${!isSelected ? 'svg-black' : ''}`}
          />
          <p className='mb-0 theme-text-black-1'>
            {field.properties?.basicProperties?.fieldTitle
              ? field.properties?.basicProperties?.fieldTitle
              : t('form_untitled_field')}
          </p>
        </div>
        <SwappingComponent
          elementType={t(fieldTypeMap[field.elementType].displayName)}
          elementIcon={fieldTypeMap[field.elementType].icon}
          type='field'
          props={{ position: elementIndex }}
          changeFormElementOrder={changeFormElementOrder}
        />
      </div>
      <div className='field-component-body'>
        {/* <p
          className='theme-text-black-1 theme-font-jakartaSans-medium'
          style={getDynamicStyle('fieldTitle')}
        >
          
        </p> */}
        <p style={getDynamicStyle('fieldHintText')}>
          {field.properties?.basicProperties?.fieldHintText
            ? field.properties?.basicProperties?.fieldHintText
            : t('form_hint')}
        </p>
        <FieldDetailView element={field} dataSourcesList={dataSourcesList} />
      </div>
    </div>
  );
};

UserFieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  selectComponent: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  elementIndex: PropTypes.number.isRequired,
};

export default UserFieldComponent;
