import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import searchIcon from 'assets/images/icons/search.svg';
import dropIcon from 'assets/images/icons/double-down-arrow-black.svg';
import usersIcon from 'assets/images/icons/group-users-white.svg';
import Initials from 'components/common-components/Initials';
import { CallAPI } from 'actions/General';
const initialCount = 10;

const SideMenu = ({ type, setType, setSelectedId, selectedId }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [dataList, setDataList] = useState([]);
  const [rowCount, setRowCount] = useState(initialCount);

  const GetAllSearchAbleUser = async () => {
    let callName = type === 'groups' ? 'GET_ALL_GROUPS' : 'GET_ALL_SEARCH_ABLE_USERS';
    let result = await CallAPI(callName, {}, null, null, null, null);
    if (result.status) {
      let apiData = type === 'groups' ? result.data : result.data.users;
      let allList = [];
      apiData.map((singleRecord) => {
        allList.push({
          id: singleRecord._id,
          name:
            type === 'groups'
              ? singleRecord.groupName
              : singleRecord.firstName + ' ' + singleRecord.lastName,
          firstName: type === 'users' ? singleRecord.firstName : '',
          lastName: type === 'users' ? singleRecord.lastName : '',
          activities: 10,
        });
      });
      setDataList(allList);
    } else setDataList([]);
  };

  const loadMore = () => {
    setRowCount((prevRowCount) => prevRowCount + initialCount);
  };

  useEffect(() => {
    GetAllSearchAbleUser();
  }, [type]);

  return (
    <div className='py-3'>
      <div className='row g-0 rounded-3 theme-background-white-2 cursor-pointer border mx-3'>
        <div
          className={`col-6 text-center p-2 rounded-3 ${
            type === 'groups'
              ? 'theme-text-white-1 theme-background-green-1 border'
              : 'theme-text-black-1'
          }`}
          onClick={() => {
            setType('groups');
            setSelectedId('');
          }}
        >
          {t('tabs_groups')}
        </div>
        <div
          className={`col-6 text-center p-2 rounded-3 ${
            type === 'users'
              ? 'theme-text-white-1 theme-background-green-1 border'
              : 'theme-text-black-1'
          }`}
          onClick={() => {
            setType('users');
            setSelectedId('');
          }}
        >
          {t('tabs_users')}
        </div>
      </div>

      <div className='px-4'>
        <div className='d-flex justify-content-between pt-4'>
          <span className='theme-text-black-1'>
            <span>{t(type === 'groups' ? 'text_groups' : 'text_users')}</span>{' '}
            <span className='theme-text-grey-1'>{dataList.length}</span>
          </span>
          <span className='theme-text-grey-1'>{t('text_activities')}</span>
        </div>
        <hr />

        <TextField
          value={searchText}
          name='search'
          handleChange={(field, value) => {
            setSearchText(value);
          }}
          placeholder={t('field_search')}
          icon={searchIcon}
          shrink
          classes='w-100 mb-2'
        />
      </div>
      {dataList
        .slice(0, rowCount)
        .filter((x) => x.name?.toLowerCase().includes(searchText?.toLowerCase()))
        .map((item, index) => (
          <div
            key={index}
            className={`d-flex align-items-center justify-content-between py-2 px-4 cursor-pointer text-capitalize single-item ${
              selectedId === item.id ? 'selected' : ''
            }`}
            onClick={() => {
              setSelectedId(item.id);
            }}
          >
            <div className='d-flex align-items-center gap-2'>
              {type === 'users' ? (
                <Initials
                  removeGuard={true}
                  users={[{ firstName: item.firstName, lastName: item.lastName }]}
                />
              ) : (
                <div className='rounded-circle theme-background-grey-13 theme-width-35 theme-height-33 d-flex align-items-center justify-content-center'>
                  <img width={20} height={20} src={usersIcon} alt='Loading...' />
                </div>
              )}
              <span className='theme-font-inter-medium theme-text-black-1'>{item.name}</span>
            </div>
          </div>
        ))}

      <div className='px-4 mt-5'>
        <div className='theme-font-inter-regular theme-size-0_9'>
          {dataList.length === 0 ? (
            <> {t('text_no_records')}</>
          ) : (
            <>
              {t('text_showing')}{' '}
              <span className='theme-font-inter-semi-bold theme-text-black-1'>
                {
                  dataList
                    .slice(0, rowCount)
                    .filter((x) => x.name?.toLowerCase().includes(searchText?.toLowerCase())).length
                }
              </span>{' '}
              {t('text_out_of')}{' '}
              <span className='theme-font-inter-semi-bold theme-text-black-1'>
                {dataList.length}
              </span>
            </>
          )}
        </div>
        <div
          onClick={loadMore}
          className={`d-flex align-items-center justify-content-center gap-3 py-2 border rounded-3 mt-3 ${
            rowCount >= dataList.length ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
        >
          <span className='theme-text-black-1 theme-font-inter-medium theme-size-0_9'>
            {t('text_load_more')}
          </span>
          <img src={dropIcon} alt='Loading...' />
        </div>
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
};
export default SideMenu;
