import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NumberRangeField = ({
  name,
  displayName,
  value,
  minRange,
  maxRange,
  shrink,
  handleChange,
  disabled,
  classes,
  onlyShowValue,
}) => {
  const { t } = useTranslation();
  const shrinkLabel = shrink ? ' shrink-label' : '';

  const newVal = Number(((value - minRange) * 100) / (maxRange - minRange));
  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      {displayName && (
        <label htmlFor={name} className={shrinkLabel}>
          {t(displayName)}
        </label>
      )}
      <div className='custom-number-range-field'>
        {onlyShowValue && value >= minRange && value <= maxRange && (
          <output
            className='bubble'
            style={{ '--styles': `calc(${newVal}% + (${8 - newVal * 0.15}px))` }}
          >
            {value}
          </output>
        )}
        <input
          type='range'
          id={name}
          name={name}
          value={value}
          min={minRange}
          max={maxRange}
          disabled={disabled ?? false}
          onChange={(e) => handleChange(e.target.name, Number(e.target.value))}
        />
        {!onlyShowValue && (
          <div className='d-flex justify-content-between'>
            <span>{minRange}</span>
            <span>{maxRange}</span>
          </div>
        )}
      </div>
    </div>
  );
};

NumberRangeField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  value: PropTypes.number.isRequired,
  minRange: PropTypes.number.isRequired,
  maxRange: PropTypes.number.isRequired,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  onlyShowValue: PropTypes.bool,
};

export default NumberRangeField;
