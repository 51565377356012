import React, { useEffect, useState } from 'react';
import TabDivider from 'components/common-components/TabDivider';
import MainToggleBox from 'components/toggler-components/MainToggleBox';
import MainToggleBoxBody from 'components/toggler-components/MainToggleBoxBody';
import MainToggleBoxHeader from 'components/toggler-components/MainToggleBoxHeader';
import { AuthReducer } from 'redux/auth';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { profileTabs } from './utils';
import infoCircleIcon from 'assets/images/icons/info-circle.svg';
import ProfilesList from './ProfilesList';
const AuthAction = AuthReducer.actions;

function Profiles() {
  const dispatch = useDispatch();

  const [toggleState, setToggleState] = useState(cloneDeep(profileTabs));

  const toggleBoxOpen = (id) => {
    const currentToggleState = [...toggleState];
    currentToggleState.forEach((toggle) => {
      toggle.isSelected = toggle.id === id ? !toggle.isSelected : false;
    });
    setToggleState(currentToggleState);

    dispatch(
      AuthAction.updateBreadCrumb({ breadcrumbs: currentToggleState[id - 1].breadcrumbValue }),
    );
  };

  useEffect(() => {
    dispatch(AuthAction.updateBreadCrumb({ breadcrumbs: toggleState[0].breadcrumbValue }));
  }, [dispatch]);
  return (
    <div className='theme-background-white-1 custom-shadow rounded-sm p-3'>
      {toggleState.map((toggleBox, index) => {
        return (
          <MainToggleBox key={index}>
            <TabDivider variant='green-1' />
            <MainToggleBoxHeader
              show={toggleBox.isSelected}
              toggleBoxOpen={() => toggleBoxOpen(index + 1)}
              title={toggleBox.title}
              titleIcon={toggleBox.titleIcon ? toggleBox.titleIcon : null}
              infoIcon={infoCircleIcon}
            />
            <MainToggleBoxBody show={toggleBox.isSelected}>
              {toggleBox.id === 1 ? <ProfilesList /> : <></>}
            </MainToggleBoxBody>
          </MainToggleBox>
        );
      })}
    </div>
  );
}

export default Profiles;
