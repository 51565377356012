import profileGreenIcon from 'assets/images/icons/company-profile-green.svg';

export const profileTabs = [
  {
    id: 1,
    title: 'tabs_admin_access_profiles',
    isSelected: true,
    titleIcon: '',
    breadcrumbValue: {
      icon: profileGreenIcon,
      items: ['sidebar_view_profiles', 'tabs_admin_access_profiles'],
    },
  },
];
export const profilesTableHeader = [
  {
    name: 'type',
    displayName: 'table_header_profiles_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    populateFunc: 'populateProfileTitle',
    callbackFunc: 'profileNameClickCallBack',
    className: 'ps-4 ms-3',
  },
  {
    name: 'groups',
    displayName: 'table_header_profiles_description',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'profileDescription',
  },
  {
    name: 'user',
    displayName: 'table_header_profiles_users',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'usersCount',
  },
];
