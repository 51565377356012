import React from 'react';
import PropTypes from 'prop-types';

const MainToggleBox = ({ children, classes, style }) => {
  return (
    <div className={`main-toggler${classes ? ` ${classes}` : ''}`} {...(style && { style })}>
      {children}
    </div>
  );
};

MainToggleBox.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.string,
  style: PropTypes.object,
};

export default MainToggleBox;
