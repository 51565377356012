import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NumberField = ({
  name,
  placeholder,
  displayName,
  icon,
  value,
  error,
  touched,
  shrink,
  handleChange,
  classes,
  shrinkFieldOnly,
}) => {
  const { t } = useTranslation();
  const shrinkField = shrink || shrinkFieldOnly ? ' shrink-field' : '';
  const shrinkLabel = shrink ? ' shrink-label' : '';

  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      {displayName && (
        <label
          htmlFor={name}
          className={!touched && error ? 'label-error' + shrinkLabel : shrinkLabel}
        >
          {t(displayName)}
        </label>
      )}
      <div className='custom-input-field'>
        <input
          type='number'
          id={name}
          name={name}
          className={!touched && error ? 'input-error' + shrinkField : shrinkField}
          placeholder={t(placeholder)}
          value={value}
          autoComplete='off'
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        {icon && <img src={icon} className='icon' />}
      </div>
      {!touched && error && <p className='form-field-error'>{t('error ' + error)}</p>}
    </div>
  );
};

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.number,
  error: PropTypes.string,
  touched: PropTypes.bool,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  shrinkFieldOnly: PropTypes.bool,
};

export default NumberField;
