import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { AuthReducer } from 'redux/auth';
import infoCircleIcon from 'assets/images/icons/info-circle.svg';
import ExternalDBConnectionExport from './ExternalDBConnectionExport';
import ExternalDBFormExport from './ExternalDBFormExport';
import MainToggleBox from 'components/toggler-components/MainToggleBox';
import MainToggleBoxBody from 'components/toggler-components/MainToggleBoxBody';
import MainToggleBoxHeader from 'components/toggler-components/MainToggleBoxHeader';
import TabDivider from 'components/common-components/TabDivider';
const AuthAction = AuthReducer.actions;

const ExternalExport = ({ formDetails, subTabs, updateTab, getFormDetail }) => {
  const dispatch = useDispatch();

  const [toggleState, setToggleState] = useState(cloneDeep(subTabs));

  const toggleBoxOpen = (id) => {
    const currentToggleState = [...toggleState];
    currentToggleState.forEach((toggle) => {
      toggle.isSelected = toggle.id === id ? !toggle.isSelected : false;
    });
    setToggleState(currentToggleState);

    dispatch(
      AuthAction.updateBreadCrumb({ breadcrumbs: currentToggleState[id - 1].breadcrumbValue }),
    );
  };

  return (
    <div className='p-3'>
      <label className='d-flex align-items-center theme-font-inter-medium theme-text-black-1 theme-size-1_1 mb-3'>
        {formDetails?.title ?? ''}
      </label>
      {toggleState.map((toggleBox, index) => (
        <MainToggleBox key={index}>
          <TabDivider />
          <MainToggleBoxHeader
            show={toggleBox.isSelected}
            toggleBoxOpen={() => toggleBoxOpen(index + 1)}
            title={toggleBox.title}
            titleIcon={toggleBox.titleIcon}
            infoIcon={infoCircleIcon}
          />
          <MainToggleBoxBody show={toggleBox.isSelected}>
            {toggleBox.id === 1 ? (
              <ExternalDBFormExport
                updateTab={updateTab}
                formDetails={formDetails}
                getFormDetail={getFormDetail}
              />
            ) : toggleBox.id === 2 ? (
              <ExternalDBConnectionExport
                updateTab={updateTab}
                formDetails={formDetails}
                getFormDetail={getFormDetail}
              />
            ) : (
              <></>
            )}
          </MainToggleBoxBody>
        </MainToggleBox>
      ))}
    </div>
  );
};

ExternalExport.propTypes = {
  subTabs: PropTypes.array.isRequired,
  updateTab: PropTypes.func.isRequired,
  getFormDetail: PropTypes.func.isRequired,
  formDetails: PropTypes.object.isRequired,
};

export default ExternalExport;
