import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Permissions from './FormPermission/Permissions';
import Report from './FormReport/Report';
import Setup from './FormSetup/Setup';
import TabBody from 'components/tab-components/TabBody';
import TabHeader from 'components/tab-components/TabHeader';
import TabLayout from 'components/tab-components/TabLayout';
import ExternalExport from './FormExternalExport/ExternalExport';
import { tabList } from './utils';
import { CallAPI } from 'actions/General';
// import FormDesign from './FormCreation/FormDesign';
import DesignForm from 'pages/form/DesignForm';
import Loader from 'components/common-components/Loader';

const AddForm = () => {
  const [formDetails, setFormDetails] = useState(null);
  const [dataSourcesOptionList, setDataSourcesOptionList] = useState([]);
  const [dataSourcesList, setFilterAbleDataSources] = useState([]);
  const [companySettings, setCompanySettings] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabList[4]);
  const [isFormAdded, setIsFormAdded] = useState(true);
  const [formDetailLoading, setFormDetailLoading] = useState(false);
  const [dataSourceLoading, setDataSourceLoading] = useState(false);
  const [companyDetailLoading, setCompanyDetailLoading] = useState(false);

  const location = useLocation();

  const getFormDetail = async (id) => {
    setIsFormAdded(false);
    const result = await CallAPI('GET_FORM_DETAIL', null, id, setFormDetailLoading, null, null);
    setFormDetails(cloneDeep(result.data));
  };

  const getAllDataSources = async () => {
    const result = await CallAPI(
      'GET_ALL_DATA_SOURCES_FOR_FORMS',
      null,
      null,
      setDataSourceLoading,
      null,
    );
    if (result.status) {
      const dataSources = result.data
        .filter((dataSource) => !!dataSource.dataSourceSchema)
        .map((dataSource) => {
          const dataSourceColumns = dataSource.dataSourceSchema.map((column) => ({
            value: column.columnId,
            displayValue: column.columnName,
          }));
          return {
            value: dataSource._id,
            displayValue: dataSource.schemaName,
            columns: dataSourceColumns,
          };
        });

      setDataSourcesOptionList(dataSources);

      const dataSourcesFilters = result.data.filter((dataSource) => !!dataSource.dataSourceSchema);

      setFilterAbleDataSources(dataSourcesFilters);
    }
  };

  const getCompanyDetail = async () => {
    const result = await CallAPI(
      'GET_COMPANY_DETAIL',
      null,
      null,
      setCompanyDetailLoading,
      null,
      null,
    );
    if (result.status) {
      setCompanySettings(result.data?.settings ?? null);
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.type === 'edit') {
      getFormDetail(location.state.formId);

      let selectedTabIndex = location.state.tab ? location.state.tab : 0;
      setSelectedTab(tabList[selectedTabIndex]);
    }
    getAllDataSources();
    getCompanyDetail();
  }, [location]);

  const updateTab = (selectedTabIndex) => {
    setSelectedTab(tabList[selectedTabIndex]);
  };
  return (
    <TabLayout>
      <TabHeader
        tabList={tabList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        disabledId={!isFormAdded ? [] : [1, 2, 3, 4]}
      />
      <TabBody transparent={[tabList[0].id].includes(selectedTab.id)}>
        {(formDetailLoading || dataSourceLoading || companyDetailLoading) && (
          <Loader color='green-1' type='modal-spinner' />
        )}
        {selectedTab.id === tabList[0].id &&
          // <FormDesign
          //   formDetails={formDetails}
          //   getFormDetail={getFormDetail}
          //   dataSourcesList={dataSourcesOptionList}
          // />
          formDetails && (
            <DesignForm
              formDetails={formDetails ? formDetails : {}}
              getFormDetail={getFormDetail}
              dataSourcesList={dataSourcesOptionList}
            />
          )}
        {selectedTab.id === tabList[1].id && (
          <Report
            subTabs={selectedTab.subTabs}
            updateTab={updateTab}
            formDetails={formDetails}
            getFormDetail={getFormDetail}
          />
        )}
        {selectedTab.id === tabList[2].id && formDetails && (
          <Permissions
            formDetails={formDetails}
            updateTab={updateTab}
            getFormDetail={getFormDetail}
            dataSourcesList={dataSourcesList}
          />
        )}
        {selectedTab.id === tabList[3].id && formDetails && (
          <ExternalExport
            subTabs={selectedTab.subTabs}
            updateTab={updateTab}
            formDetails={formDetails}
            getFormDetail={getFormDetail}
          />
        )}
        {selectedTab.id === tabList[4].id && (
          <Setup
            formDetails={formDetails}
            companySettings={companySettings}
            updateTab={updateTab}
            getFormDetail={getFormDetail}
          />
        )}
      </TabBody>
    </TabLayout>
  );
};
export default AddForm;
