import { CallAPI } from 'actions/General';
import { cloneDeep, sortBy } from 'lodash';
import { uploadImage } from 'pages/company/utils';

export const createAPIStructure = (formProperties) => {
  let errorsArray = [];
  let formPageKeys = Object.keys(formProperties);
  let elements = [];
  let supervisorElements = [];
  let statusElements = [];
  let position = 1;
  let page = 1;
  let fieldUniqueIdsList = [];
  formPageKeys.map((singlePageKey) => {
    if (singlePageKey !== 'otherDetails') {
      let pageElementPosition = 0;
      let sectionNumber = 0;
      let singlePageElements = cloneDeep(formProperties[singlePageKey].elements);
      let pageErrors = [];
      singlePageElements.map((singleElement) => {
        if (singleElement.elementType === 'section') sectionNumber++;

        // Create Single Element for API
        if (singlePageKey === 'supervisorFields') {
          let elementType = singleElement.elementType.replace('supervisor_', '');
          if (elementType === 'status')
            statusElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber: 0,
              elementType: elementType,
              properties: singleElement.properties,
            });
          else
            supervisorElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber: 0,
              elementType: elementType,
              properties: singleElement.properties,
            });
        } else {
          if (singleElement.elementType === 'dataSource') {
            let updatedBindingList = singleElement.properties.basicProperties.bindingList.filter(
              (singleBindElement) => {
                if (singleBindElement) {
                  return singleBindElement;
                }
              },
            );
            singleElement.properties.basicProperties.bindingList = updatedBindingList;
          }
          elements.push({
            ...singleElement,
            position,
            pageNumber: page,
            sectionNumber,
            properties: singleElement.properties,
          });
        }
        getValidationError(
          fieldUniqueIdsList,
          cloneDeep(singleElement),
          pageElementPosition,
          pageErrors,
        );
        position++;
        pageElementPosition++;
      });
      if (pageErrors.length) {
        const errorsClassification =
          singlePageKey === 'supervisorFields'
            ? ['form_error.Supervisors fields errors']
            : ['form_error.Page (', `${page}`, 'form_error.) errors'];
        errorsArray.push({ errorsClassification, errorList: pageErrors });
      }
      page++;
    }
  });

  // Check Duplicate Field Unique Key values
  const fieldUniqueIdsDuplicated =
    new Set([...fieldUniqueIdsList]).size !== fieldUniqueIdsList.length;
  if (fieldUniqueIdsDuplicated) {
    const errorsClassification = ['form_error.Duplicate IDs'];
    const errorList = [['form_error.Duplicate field IDs']];

    errorsArray.push({ errorsClassification, errorList });
  }
  return {
    status: !errorsArray.length,
    errors: errorsArray,
    supervisorElements,
    elements,
    statusElements,
  };
};

export const jsonStringifyAllElements = (APIData) => {
  for (let index = 0; index < APIData.elements.length; index++) {
    const element = APIData.elements[index];
    element.properties = JSON.stringify(element.properties);
  }
  for (let index = 0; index < APIData.supervisorElements.length; index++) {
    const element = APIData.supervisorElements[index];
    element.properties = JSON.stringify(element.properties);
  }
  for (let index = 0; index < APIData.statusElements.length; index++) {
    const element = APIData.statusElements[index];
    element.properties = JSON.stringify(element.properties);
  }
  return APIData;
};
export const removeUnbindElementUniqueIds = (elements, formProperties) => {
  elements.map((singleElement) => {
    if (
      singleElement.uniqueIdToReplace &&
      !formProperties.otherDetails.bindingElementsObj[singleElement.uniqueIdToReplace]
    ) {
      delete singleElement.uniqueIdToReplace;
    }
  });
  return elements;
};

const getValidationError = (fieldUniqueIdsList, singleElement, pageElementPosition, pageErrors) => {
  // Check Title and Field Unique Key That these must be added except Image Element
  if (singleElement.elementType !== 'image') {
    let key =
      singleElement.elementType === 'page'
        ? 'pageTitle'
        : singleElement.elementType === 'section'
        ? 'sectionTitle'
        : 'fieldTitle';
    // Check Title is not empty
    if (singleElement.properties.basicProperties[key] === '') {
      if (singleElement.elementType === 'page')
        pageErrors.push(['form_error.Page has missing title']);
      else
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing title',
        ]);
    }
    // Check field Unique Id Exist, Add in array to check unique
    if (
      singleElement.properties.otherProperties &&
      singleElement.properties.otherProperties.fieldUniqueId &&
      singleElement.properties.otherProperties.fieldUniqueId !== ''
    ) {
      fieldUniqueIdsList.push(singleElement.properties.otherProperties.fieldUniqueId);
    }

    // Check Empty Field Unique Key error
    if (
      singleElement.elementType !== 'page' &&
      singleElement.elementType !== 'section' &&
      singleElement.properties.otherProperties.fieldUniqueId === ''
    ) {
      pageErrors.push([
        'form_error.Element (',
        `${pageElementPosition}`,
        'form_error.) has missing field unique id',
      ]);
    }

    // Check Data Source Related Selected keys
    if (singleElement.elementType === 'dataSource') {
      if (singleElement.properties.basicProperties.dataSource === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source options',
        ]);
      }
      if (singleElement.properties.basicProperties.displayColumn === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source display column',
        ]);
      }
      if (singleElement.properties.basicProperties.valueColumn === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source value column',
        ]);
      }
    }

    // CheckList, Status Related Mandatory Options
    if (
      ['checkList', 'status'].includes(singleElement.elementType) &&
      singleElement.properties.basicProperties.optionList.length < 2
    ) {
      pageErrors.push([
        'form_error.Element (',
        `${pageElementPosition}`,
        'form_error.) has missing answer choices',
      ]);
    }
  } else if (
    !singleElement.properties.layoutProperties.fieldImage?.id &&
    !singleElement.properties.layoutProperties.fieldImageFile
  ) {
    pageErrors.push([
      'form_error.Element (',
      `${pageElementPosition}`,
      'form_error.) has missing image',
    ]);
  }

  return pageErrors;
};

export const uploadImageFieldsMedia = async (formElementsList, formDetails) => {
  const imageElements = formElementsList.filter((element) => element.elementType === 'image');

  const formId = formDetails?._id ?? '';

  const formVersionId = (formDetails?.versions ?? []).length
    ? sortBy(formDetails.versions, (e) => parseInt(e.version))[formDetails.versions.length - 1]._id
    : '';

  for (const imageElement of imageElements) {
    let imageProps = imageElement.properties;
    const fieldImageFile = imageProps.layoutProperties.fieldImageFile ?? null;
    const fieldImage = imageProps.layoutProperties.fieldImage ?? null;

    if (fieldImageFile) {
      if (fieldImage) await deleteFieldImageIfExist(fieldImage);
      const uploadResponse = await uploadSingleFieldImage(fieldImageFile, formId, formVersionId);
      delete imageProps.layoutProperties.fieldImageFile;

      imageProps.layoutProperties = {
        ...imageProps.layoutProperties,
        ...(uploadResponse ? { fieldImage: uploadResponse } : undefined),
      };
      imageElement.properties = imageProps;
    }
  }
};

const deleteFieldImageIfExist = async (fieldImage) => {
  if (fieldImage.id) {
    await CallAPI(
      'DELETE_MEDIA',
      {
        folderType: 'forms',
        mediaType: 'image',
        mediaId: fieldImage.id,
        imageName: fieldImage.imageName,
        formId: fieldImage.formId,
        versionId: fieldImage.versionId,
      },
      null,
      null,
      null,
      null,
    );
  }
};
const uploadSingleFieldImage = async (file, formId, formVersionId) => {
  const imageData = { imageName: `${Date.now()}.jpg`, formId, versionId: formVersionId };
  const response = await CallAPI(
    'GET_FORM_MEDIA_UPLOAD_URL',
    { ...imageData, mediaType: 'image' },
    null,
    null,
  );
  if (response.status) {
    await uploadImage(file, response.data, null);
    return { ...imageData, id: response.data.id };
  }
  return null;
};

export const createStructureForExportJson = (elements) => {
  elements.map((singleElement) => {
    delete singleElement.company;
    delete singleElement.elementGlobalId;
    delete singleElement._id;
    delete singleElement.uniqueIdToReplace;
    if (singleElement.elementType === 'dataSource') {
      singleElement.properties.basicProperties = {
        ...singleElement.properties.basicProperties,
        dataSource: '',
        displayColumn: '',
        valueColumn: '',
        bindingList: [],
      };
    } else if (singleElement.elementType === 'externalApi') {
      singleElement.properties.basicProperties = {
        ...singleElement.properties.basicProperties,
        apiMethod: 'post',
        apiParamType: 'url',
        apiParamValue: '',
        statusFieldBinding: '',
        apiUrl: '',
        bindingList: [],
        auth: {
          authType: 'api_auth',
          authValue: '',
          authApiUrl: '',
          authApiMethod: 'post',
          responseAuthKeyName: '',
          parameters: { client_name: '', client_id: '', client_secret: '' },
        },
      };
    } else if (singleElement.elementType === 'image') {
      singleElement.properties.layoutProperties = {
        ...singleElement.properties.layoutProperties,
        fieldImageFile: null,
        fieldImage: null,
      };
    }
  });
  return elements;
};
