import React from 'react';

function Terms() {
  return (
    <div className='terms_privacy bg-white theme-size-1_1 theme-font-inter-regular'>
      <div className='d-flex flex-column justify-content-center align-items-center theme-font-inter-bold theme-height-224 theme-text-green-1 theme-background-green-4 theme-size-3_5 px-4 text-center lh-sm'>
        Terms of Service
      </div>
      <div className='content mx-auto my-5 px-3 text-justify'>
        <p className='theme-font-inter-bold'>Midani Terms of Service</p>
        <p>
          Midani (“Midani“, “us“, “our“, or “we“) provides a platform (the “Platform”) that allows
          participating businesses (each, a “Customer“) to create dedicated communication,
          operations, and training applications (any such application, an “App“) to manage and track
          compliance and performance of Customer’s employees, service providers, or independent
          contractors (“Personnel“). The services available through the Platform and any of the Apps
          shall collectively be referred to as “Services“. “You” means any user of the Services,
          whether on behalf of a Customer or as Personnel of a Customer.
        </p>

        <p>
          These Terms of Service (“Terms“) govern your access to and use of the Services, Platform,
          and any App. Our Privacy Notice, available at https://hajj.midani.co/privacy/(“Privacy
          Notice“) and the DPA (defined below), where applicable, govern our collection, processing,
          and transfer of any Personal Data (as such term is defined in the Privacy Notice).
        </p>

        <p>
          Please read these Terms carefully. By either clicking “I Accept,” (or other similar
          language), checking a checkbox for the acceptance of these Terms, or by signing up,
          registering to, downloading, using, installing, or otherwise accessing or using the
          Services, sites or any of our Apps, you signify your assent to these Terms and to the
          collection and use of your information as set forth in the Privacy Notice. If you do not
          agree to any of these Terms, please do not click the sign-up button and do not access or
          use the Platform and Services. Any new feature released by us shall be governed by these
          Terms and Privacy Notice.
        </p>

        <p>
          Some Services may be subject to additional terms specific to such Services, features, or
          functionality (“Additional Services” and “Additional Terms”), and such Additional Terms
          will be presented to you within the installation or registration process. By accessing,
          enabling, using and/or purchasing any such Additional Services (such as the Business
          Associate Agreement (available at request); our referral program), you agree and
          acknowledge that such Additional Terms are binding and governing your use of such
          Additional Services, which are incorporated herein by reference.
        </p>

        <p>
          We may modify the Terms at any time. If we make a material change to these Terms, we will
          provide you with reasonable notice as appropriate, at our discretion, by either messaging
          you through the Services, or by other communication means (including email), or with the
          publication of these updated Terms on our website. The revised Terms will become effective
          upon the earlier date set forth in our notice, and the posting date of the modified Terms
          on our website. Your continued use of the Services after the changes have been implemented
          will constitute your acceptance of the changes. You can review the current version of the
          Terms at any time on our website.
        </p>

        <p>
          If you are registering a “Customer Account” on behalf of a Customer (as an “Account
          Owner“), you further represent that you are authorized to enter into and bind the Customer
          to these Terms and register the Customer for the Platform and Services. You are solely
          responsible for ensuring that these Terms are in compliance with all laws, rules, and
          regulations applicable to you and such Customer.
        </p>
        <ol>
          <li>
            Use of the Services
            <ol>
              <li>
                Midani allows you to access and use the Platform, Apps, and Services on a
                non-exclusive basis subject to these Terms. Subject to applicable law, Midani may,
                at its sole discretion and at any time, modify or discontinue providing the
                Platform, Services, any App, or any part thereof without notice and shall not be
                liable to you or any third party for any such modification or discontinuance.
              </li>
              <li>
                Use of the Services and access to the Platform and/or Apps is void where prohibited.
                You represent and warrant that: (a) all registration information you submit is
                truthful and accurate; (b) you will maintain the accuracy of such information; (c)
                you are of the age of majority as defined under applicable law or have obtained
                parental or guardian consent where required under applicable law and have the
                ability to form a binding contract; (d) your use of the Platform, Apps, or Services
                does not violate any applicable law, regulation, or any obligation you may have to a
                third party; and (e) you shall comply with applicable laws, regulations, guidelines,
                and these Terms throughout your use of the Platform, Apps and/or Services.
              </li>
              <li>
                If You are an Employee or an Administrator (as defined below), you agree that it is
                solely Customer’s responsibility to (a) inform you of any Customer’s policies and
                any settings related to our processing of the User Content (as defined below); (b)
                obtain any rights, permissions or consents from you and any authorized users that
                are necessary for the lawful use of the User Content, the processing of such User
                Content by us and the operation of our Services; and (c) respond to and resolve any
                dispute between you and the Customer or between you and any other user related to
                your Account, arising out of or related to the use of the Services.
              </li>
              <li>
                If you are registering to use the Platform, Apps, or Services as an Account Owner on
                behalf of a Customer, you further represent and warrant that: (a) when we process
                Personal Data of the Customer’s Personnel on its behalf for the purpose of provision
                of the Services, the Customer will at all times be considered the data Controller
                (as defined in the GDPR, or equivalent term under any applicable law) and shall be
                responsible for compliance with its obligations as data Controller under applicable
                law, including the General Data Protection Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 (“GDPR”) and we shall act solely as a
                data Processor (as defined in the GDPR or equivalent term under any applicable law)
                on its behalf, all in accordance with the Data Processing Addendum, available at
                https://Midani.com/data-processing-agreement-addendum/, (“DPA“) (while Personal Data
                we process as a data Controller is detailed in our Privacy Notice); (b) the Customer
                has and shall maintain throughout the term of these Terms and the DPA all necessary
                legal bases, rights and/or consents required under applicable law in order to
                provide Midani Personal Data and allow us provide the Services and process the
                Personal Data as contemplated by these Terms, the DPA and the Privacy Notice (as
                applicable); (c) the Customer shall ensure that a record of such legal basis is
                maintained, as required under applicable law; and (d) the Customer shall not provide
                Midani with any Special Categories of Data (as defined in the GDPR) and/or any data
                or information subject to special regulatory or statutory protection regimes
                (including but not limited to data regarding children, financial and/or health
                data); provided, however, that with respect to health information subject to the
                Health Insurance Portability and Accountability Act as amended and supplemented
                (“HIPAA”), or any similar legislation in other jurisdiction, such information may be
                provided to the extent that (i) the applicable Services is made commercially
                generally available by Midani for use with HIPAA data; and (ii) Customer has entered
                into a Business Associate Agreement with us available upon request).
              </li>
              <li>
                The Customer is solely liable and responsible for controlling who becomes a user and
                what are the settings and privileges for such user. Any action taken by a user of
                the Customer Account is deemed by us as an authorized action by the Customer, and
                you shall have no claim towards us in this regard.
              </li>
              <li>
                From time to time, we may invite you to try a product or service that has not been
                made generally available to customers (“Beta Services“) at no additional charge
                (unless otherwise provided). You may accept or decline any such Beta Services in
                your sole discretion. Beta Services will be clearly designated as beta, limited
                availability, non-production, evaluation or by a similar description. Beta Services
                are not considered Services under these Terms, are not supported, and may be subject
                to additional terms. We may discontinue Beta Services at any time in our sole
                discretion and may never make such services generally available. Notwithstanding
                anything to the contrary in these Terms, Midani will have no liability for any harm
                or damage arising out of or in connection with a Beta Service.
              </li>
              <li>
                By connecting a third-party service to the Services, as may be permitted through the
                Services, you (a) represent and warrant that you have all required rights, licenses,
                permits, and consents to do so, and (b) grant us permission to access and use your
                information from that service as permitted by that service.
              </li>
            </ol>
          </li>

          <li>
            Customer Account and Personnel Profile Registration{' '}
            <ol>
              <li>
                Customer Account. In order for any Personnel to access the Services, a Customer
                Account must be created by an Account Owner. An Account Owner may create profiles
                for other Customer Personnel within the Customer Account (“Personnel Profiles“).
                Personnel Profiles may be designated either as an additional Account Owner, as an
                “Administrator” or as an “Employee“. Account Owners, Administrators, and Employees
                are granted different permissions within the Customer Account.
              </li>
              <li>
                General. When opening a Customer Account or when creating a Personnel Profile,
                certain registration information must be provided, such as the names and email
                addresses of the applicable Personnel. Personnel may choose to add additional
                optional information to their Personnel Profile, which may assist us in providing
                improved Services. Personnel Profiles may be created manually, by importing a file
                with the required information, or by connecting to the Customer’s systems through an
                API or SFTP. Subject to applicable law, Midani reserves the right to refuse to open
                a Customer Account or a Personnel Profile for any reason, all at its sole
                discretion.
              </li>
              <li>
                Administrator. Account Owners may open Administrator Personnel Profiles.
                Administrators serve as authorized representatives of the Customer and will have
                certain permissions that allow them to set-up and manage the Customer’s dedicated
                App on the Customer’s behalf. Administrators shall be authorized to grant access to
                the App to Employees and to block Employees from using the App, and to set up
                Employee permissions within the App. Administrators may view all of the Employees’
                information and may access all data uploaded by an Employee to the App. The
                Administrator may also receive reports based on Employees’ use of the App.
              </li>
              <li>
                Employees. Administrators may open Employee Personnel Profiles. Following the
                creation of a Personnel Profile for an Employee, the Employee will receive a link
                via email and/or text message (SMS) allowing such an Employee to confirm the
                Personnel Profile and access the Customer’s dedicated App. Alternatively, Employees
                may be sent a link via email and/or text message (SMS) to allow them to request to
                join the App. After filling out a request form, the Administrator may approve the
                request and open a Personnel Profile for the applicable Employee. The Customer
                hereby represents and warrants that it has the Employee’s consent if and as required
                under applicable law for Midani to contact the Employee as set forth in this
                Section.
              </li>
              <li>
                User Verification. We may require you to provide information that may be used to
                confirm your identity and help ensure the security of your Customer Account and/or
                profile. In the event that Account Owner or an Admin loses access to a Customer
                Account or otherwise requests information about such a Customer Account, we reserve
                the right to request from you, from the Account Owner or an Administrator (as the
                case may be) any verification and/or information, including in the form of a
                release, as we deem necessary, before restoring or providing access to or providing
                information about such Customer Account.
              </li>
              <li>
                Unauthorized Access. You will notify us immediately of any unauthorized use of your
                Customer Account, Personnel Profile, or password. You are fully and solely
                responsible for the security of your computer system and/or mobile device and all
                activity on the Customer Account or your Personnel Profile, as applicable, even if
                such activities were not committed by you. To the fullest extent permitted by law,
                Midani will not be liable for any losses or damage arising from unauthorized use of
                your Account or Personnel Profile. You agree to indemnify and hold Midani harmless
                for any such unauthorized, improper, or illegal use, including any charges and/or
                taxes incurred. You may notify us via e-mail to support@Midani.com in case your
                Customer Account or Personnel Profile has been compromised and may request that we
                block access to it, which we will do as soon as reasonably practicable. We do not
                police for and cannot guarantee that we will learn of or prevent any inappropriate
                use of the Services, Platform, or Apps.
              </li>
            </ol>
          </li>

          <li>
            Termination of Personnel Profile{' '}
            <ol>
              <li>
                You agree that Midani may, subject to applicable law, for no reason or for any
                reason, with or without notice, at its sole discretion, suspend or terminate your
                Customer Account or Personnel Profile and may remove any Content (as defined below)
                associated therewith. We may also take any other corrective action we deem
                appropriate in accordance with applicable law, including termination or suspension
                of your Customer Account or any Personnel Profile, including, inter alia, for the
                following reasons: (a) termination, suspension or expiration of the agreement
                between us and the Customer for any reason; (b) extended periods of inactivity; (c)
                violation of the letter or spirit of these Terms; (d) fraudulent, harassing or
                abusive behavior; (e) scheduled maintenance; (f) to address any emergency security
                concerns; or (g) behavior that is illegal or harmful to other users, third parties,
                or the business interests of Midani. Upon termination, you shall not have any
                further access to any Content that may be available through the Services. If your
                Customer Account or Personnel Profile is terminated, you may not rejoin Midani
                without our express permission.
              </li>
              <li>
                If you are an Employee or Administrator whose engagement with the Customer is
                terminated, an Account Owner or an Administrator may either delete or archive your
                Personal Profile. If your Personal Profile is deleted, any data or Content
                (including User Content) associated with it will be deleted and may not be
                recovered. If your Personal Profile is archived, records may be retained at the
                applicable Account Owner or Administrator’s discretion.
              </li>
              <li>
                Account Owner may request termination of your Customer Account at any time and for
                any reason by sending an email to support@Midani.com. Any suspension or termination
                of your Customer Account or these Terms shall not affect your obligations to Midani
                under these Terms (including but not limited to ownership, indemnification,
                representations and warranties made by you and payment obligations), nor will it
                affect any other terms herein, which by their sense and context are intended to
                survive such suspension or termination.
              </li>
            </ol>
          </li>

          <li>
            Fees and Payment
            <ol>
              <li>
                If you subscribed to one of our paid plans, as an Account Owner on behalf of a
                Customer, you agree that the Customer will pay Midani the fees as specified on our
                website in accordance with the plan for which you have registered (“Fees”). We
                reserve the right to change the Fees at any time, upon notice to Customer if such
                change may affect Customer’s existing subscriptions upon renewal. In the event of
                failure to collect the Fees owed by Customer, we may, at our sole discretion (but
                shall not be obligated to) retry to collect at a later time, and/or suspend or
                cancel the Account, without notice.
              </li>
              <li>
                Payments are to be made on a monthly or yearly basis, in accordance with the
                applicable plan, in advance of the applicable month or year. You will be asked to
                provide customary billing information such as name, billing address, and credit card
                information either to Midani or its third-party payment processor(s). You hereby
                authorize the collection of such amounts by charging the credit card provided,
                either directly by Midani or indirectly, via a thirdparty online payment processor
                or by one of the payment methods described in the Services and in accordance with
                the payment schedule of the applicable plan. If you are directed to a third-party
                payment processor, you may be subject to terms and conditions governing the use of
                that third party’s service and that third party’s Personal Data collection
                practices. Please review such party’s terms and conditions and privacy policy before
                using such services. Except as expressly provided in these Terms, Fees are
                non-refundable.
              </li>
              <li>
                Where applicable, taxes may also be charged. It is the Customer’s responsibility to
                determine whether it is required to pay any applicable taxes and to clarify such
                information when making a payment. Midani does not accept any responsibility for the
                calculation or collection of any applicable taxes.
              </li>
              <li>
                All payments should be made in U.S. dollars unless otherwise agreed. Please note
                that Midani may impose or deduct foreign currency processing costs on or from any
                payments or Midani by Customer in currencies other than U.S. dollars. When
                converting currency, prices may be rounded up to the nearest whole number.
              </li>
              <li>
                Subscriptions can be canceled at any time. No refunds will be granted for any
                pre-paid amounts, whether a monthly or yearly subscription. Notwithstanding the
                above, cancellation of a yearly subscription within the first 30 days of the
                subscription will entitle the Customer to a prorated refund of amounts that were
                prepaid with respect to the term following such cancellation.
              </li>
              <li>
                Free Services as detailed on our website/Platform under the “Small Business Plan” or
                “Limited” option under any of our hubs (“Free Services”), are provided to you
                without charge subject to certain usage limits determined solely by us. Usage over
                these limits requires you the purchase of additional resources or Services. Subject
                to applicable law, we, in our sole discretion, may modify or terminate your right to
                access the Free Services or any part thereof with reasonable notice. You hereby
                agree that Midani will not be liable to you or any third party for such termination.
              </li>
              <li>
                In the event you pay for the Services through third-party marketplaces (a) you shall
                also be subject to the terms and conditions set forth by those third parties, and
                (b) you are solely responsible for managing your payments to us through the
                third-party marketplace. We will not be liable for your use of those marketplaces or
                the enforcement of such third parties’ policies.
              </li>
            </ol>
          </li>

          <li>
            Content{' '}
            <ol>
              <li>
                Certain types of content may be made available through the Platform, Apps, and/or
                Services. “Content” means, collectively, all content on or made available on or
                through the Platform, Apps, and/or Services, including any images, photos, pictures,
                videos, reports, or recommendations and any modifications or derivatives of the
                foregoing.
              </li>
              <li>
                Content on the Platform and Apps comes from a variety of sources. You understand
                that Midani is not responsible for the accuracy, usefulness, safety, appropriateness
                of, or infringement of any intellectual property rights of or relating to this
                Content, including but not limited to the User Content (as defined below) of other
                users. Although users (including Customers, Account Owners, Administrators, and
                Employees) must agree to these Terms, it is possible that other users (including
                unauthorized users) may post or transmit offensive or obscene materials and that you
                may be involuntarily exposed to such offensive or obscene materials. You hereby
                waive any legal or equitable rights or remedies you have or may have against us with
                respect thereto.
              </li>
              <li>
                Midani DOES NOT ENDORSE ANY CONTENT (INCLUDING BUT NOT LIMITED TO ANY USER CONTENT)
                OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY
                DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH CONTENT AND/OR USER CONTENT.
              </li>
            </ol>
          </li>

          <li>
            User Content
            <ol>
              <li>
                Midani allows you and other users, whether Account Owners, Employees, or
                Administrators, to upload certain content including but not limited to logos,
                images, photos, textual materials, and/or additional materials and Content. All
                content uploaded by users is referred to as “User Content”.
              </li>
              <li>
                You are and shall at all times remain fully and solely responsible for any User
                Content you upload to the Services and the consequences of posting, publishing,
                sharing it, or otherwise making it available on the Service, and you agree that we
                are only acting as a passive conduit for your online distribution and publication of
                your User Content. You represent and warrant that (a) any User Content you upload:
                (i) complies with applicable law; (ii) does not infringe or violate any third-party
                intellectual property rights, privacy, publicity rights, moral rights, and/or any
                other rights; and (b) You have all necessary rights and authorities to submit such
                User Content.
              </li>
              <li>
                By providing or sharing User Content through the Service, you agree to allow others
                to view, edit, and/or share your User Content in accordance with the settings set by
                You (and, in the case of an Employee, by the Account Owner or Administrator) and
                these Terms. The User Content may be viewed or shared with others in accordance with
                your employer settings and the Privacy Notice.
              </li>
              <li>
                Anyone receiving or viewing User Content may use information you provided through
                such User Content for purposes other than those you intended. We are not responsible
                for the use of any Personal Data that you disclose through the Services by any third
                party. By making any information available through the Platform or an App you
                acknowledge that you understand and have agreed to such risks.
              </li>
              <li>
                Midani has no obligation to accept, display, review, maintain or approve any request
                to publish or maintain any User Content. Moreover, Midani reserves the right to
                refuse to publicize, remove and/or permanently delete any User Content uploaded by
                you, without notice and for any reason and at any time.
              </li>
              <li>
                Without limiting the foregoing, you agree that you will not transmit, submit or
                upload any User Content or act in any way that: (a) restricts or inhibits use of the
                Platform, Apps, and/or Services; (b) violates the legal rights of others, including
                defaming, abusing, stalking or threatening users; (c) infringes (or results in the
                infringement of the intellectual property, moral, publicity, privacy, and/or other
                rights of any third party; (d) is (or you reasonably believe or should reasonably
                believe to be) stolen, illegal, counterfeit, fraudulent, pirated, unauthorized, or
                violent, or in furtherance of any illegal, counterfeiting, fraudulent, pirating,
                unauthorized, or violent activity, or that involves (or you reasonably believe or
                should reasonably believe to involve) any stolen, illegal, counterfeit, fraudulent,
                pirated, or unauthorized material; (e) does not comply with all applicable laws,
                rules and regulations; (f) publishes falsehoods or misrepresentations that may
                damage us or any third party; (g) imposes an unreasonably or disproportionately
                large load on our infrastructure; or (h) posts, stores, transmits, offers, or
                solicits anything that contains the following, or that you know contains links to
                the following or to locations that in turn contain links to the following: (1)
                material that we determine to be offensive, including material promoting or
                glorifying hate, violence, bigotry, or any entity (past or present) principally
                dedicated to such causes or items associated with such an entity, (2) material that
                is racially or ethnically insensitive, material that is defamatory, harassing or
                threatening, (3) pornography or obscene material, (4) any virus, worm, trojan horse,
                or other harmful or disruptive component, or (5) anything that encourages conduct
                that would be considered a criminal offense, give rise to civil liability, violate
                any law or regulation or is otherwise inappropriate or offensive.
              </li>
              <li>
                Conencteam shall not monitor and/or moderate the User Content and there shall be no
                claim against us for not doing such. Notwithstanding the above, if notified by a
                user or a third party that User Content allegedly does not conform to these Terms,
                we may investigate the allegation and determine in our sole discretion whether to
                remove the User Content, which we reserve the right to do at any time and without
                notice; provided, however, that Midani reserves the right to treat User Content as
                content stored at the direction of users for which Midani will not exercise
                editorial control, except when violations are directly brought to Midani’s
                attention, and in such cases Midani will act in accordance with applicable law.
              </li>
            </ol>
          </li>

          <li>
            Use Restrictions{' '}
            <ol>
              <li>
                You may not do or attempt to do or facilitate a third party in doing any of the
                following: (i) decipher, decompile, disassemble, or reverse-engineer any of the
                software and/or code, if and as applicable, used to provide the Platform, Apps, or
                Services without our prior written authorization; (ii) circumvent, disable, or
                otherwise interfere with security-related features or features that prevent or
                restrict use or copying of any Content; (iii) use the Platform, Apps, or Services or
                content thereon in connection with any commercial endeavors in any manner, except
                for the purposes specifically set forth in these Terms; (iv) use any robot, spider,
                site search or retrieval application, or any other manual or automatic device or
                process to retrieve, index, data-mine, or in any way reproduce or circumvent the
                navigational structure or presentation of the Platform, Apps, or Services, including
                framing or mirroring any part of the Platform, Apps, or Services; (v) use or access
                another user’s Personnel Profile or password without permission; and/or (vi) use
                Platform, Apps, or Services or content thereon in any manner not permitted by these
                Terms or applicable law.
              </li>
              <li>
                Midani DISCLAIMS ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR
                OMISSIONS OF ANY AND ALL USERS (INCLUDING UNAUTHORIZED USERS), WHETHER SUCH ACTS OR
                OMISSIONS OCCUR DURING THE USE OF THE PLATFORM, APPS, OR SERVICES OR OTHERWISE.
              </li>
            </ol>
          </li>

          <li>
            Intellectual Property
            <ol>
              <li>
                Midani, its affiliates or its licensors, as the case may be, have all rights, title,
                and interest in the Platform, Apps, Services (including the API) and all Content
                thereon (excluding User Content), including its overall appearance, text, graphics,
                graphics design, videos, demos, interfaces, and underlying source files, and all
                worldwide intellectual property rights, the trademarks, service marks, and logos
                contained therein registered and unregistered. Except as expressly permitted herein,
                you may not copy, further develop, reproduce, republish, modify, alter download,
                post, broadcast, transmit or otherwise use the Platform, Apps, Content or the
                Services. You will not remove, alter or conceal any copyright, trademark, service
                mark or other proprietary rights notices incorporated in the Platform, Apps, Content
                or Services, if any. All trademarks are trademarks or registered trademarks of their
                respective owners. Nothing in these Terms shall be construed as granting you any
                right to use any trademark, service mark, logo, or trade name of Midani or any third
                party. If you provide Midani with any feedback regarding the Platform, Apps,
                Content, and/or Services, Midani may use all such feedback without any consideration
                to you and without restriction and shall not be subject to any non-disclosure or
                non-use obligations in respect of such feedback.
              </li>
              <li>
                By submitting or posting any User Content, you grant Midani and its successors and
                assignees a worldwide, non-exclusive, royalty-free, perpetual, sub-licensable and
                transferable license under any of your intellectual property, moral or privacy
                rights to use, copy, distribute, transmit, modify, prepare derivative works of,
                alter, decompile or publicly perform such User Content on, through or in connection
                with the Services, Platform and Apps in any media format and through any media
                channels for the purposes of (i) provision of Services, including addressing
                technical, fraud or security issues and resolving support requests; (ii)
                investigating when we have a good faith belief, or have received a complaint
                alleging, that such User Content violates these Terms; (iii) complying with a valid
                legal subpoena, request, or other lawful process; (iv) as expressly permitted in
                writing by you, or (v) as otherwise permitted under these Terms.
              </li>
              <li>
                The Services and/or the Site may have certain features that allow you to submit User
                Content, such as comments, information, and other materials publicly (collectively,
                “Public Materials”) and share such Public Materials with the public and/or other
                users. You are and shall remain fully responsible for any Public Materials you
                submit. By submitting Public Materials, you grant us a free license to access, use,
                copy, reproduce, process, adapt, publish, transmit, host, and display that Public
                Materials for any lawful purpose, including promotion of the Services and/or the
                Site in any media format and you hereby waive or assign (subject to applicable law)
                any rights you might have with respect to the public Materials.
              </li>
            </ol>
          </li>

          <li>
            Copyright
            <ol>
              <li>
                The policy of Midani is not to infringe upon or violate the intellectual property
                rights or other rights of any third party, and Midani will refuse to use and shall
                have the right to remove any User Content that infringes the rights of any third
                party. Under the Digital Millennium Copyright Act of 1998 (the “DMCA”), or any
                similar requirement under applicable law, Midani will remove any Content (including
                without limitation any User Content) if properly notified that such material
                infringes third party rights, and may do so at its sole discretion, without prior
                notice to users at any time, subject to the provisions below. The policy of Midani
                is to terminate the Customer Accounts or Personnel Profiles of repeat infringers in
                appropriate circumstances.
              </li>
              <li>
                You are in the best position to judge whether User Content is in violation of
                intellectual property or personal rights of any third-party. You accept full
                responsibility for avoiding infringement of the intellectual property or personal
                rights of others in connection with User Content.
              </li>
              <li>
                If you believe that something appearing on the Platform or Apps infringes your
                copyright, you may send us a notice requesting that it be removed, or access to it
                blocked. If you believe that such a notice has been wrongly filed against you, the
                DMCA lets you send us a counter-notice. Notices and counter-notices must meet the
                DMCA’s requirements. We suggest that you consult your legal advisor before filing a
                notice or counter-notice. Be aware that there can be substantial penalties for false
                claims. Send notices and counter-notices to us by contacting us at
                support@Midani.com.
              </li>
            </ol>
          </li>

          <li>
            Disclaimer of Warranty
            <ol>
              <li>
                Your use of the Platform, Apps, and Services is at your sole discretion and risk.
                The Platform, Apps, and Services are provided on an “AS IS” and “AS AVAILABLE” basis
                without warranties of any kind. We do not represent or warrant that the Services
                will be of good quality or useful for your needs.
              </li>
              <li>
                WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY,
                RELATING TO THE PLATFORM, APPS, AND/OR SERVICES, INCLUDING WITH RESPECT TO ANY
                (THIRD-PARTY) TOOLS USED TO DEVELOP AND/OR MAINTAIN THE SAME, INCLUDING WITHOUT
                LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE AND NON-INFRINGEMENT. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I)
                REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE
                PLATFORM, APPS, AND SERVICES; OR (II) THAT THE PLATFORM, APPS, AND SERVICES WILL BE
                ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE
                OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION
                PROVIDED BY THE PLATFORM, APPS, AND/OR SERVICES.
              </li>
              <li>
                No advice or information, whether oral or written, obtained by you from us, shall
                create any warranty not expressly stated in these Terms. If you choose to rely on
                such information, you do so solely at your own risk. Some states or jurisdictions do
                not allow the exclusion of certain warranties. Accordingly, some of the above
                exclusions may not apply to you. Check your local laws for any restrictions or
                limitations regarding the exclusion of implied warranties.
              </li>
              <li>
                You acknowledge and agree that Midani is not a data retention service. You therefore
                must create backups of your data, and Midani shall have no responsibility or
                liability in respect of any loss of, damage to, or corruption of any such data.
              </li>
            </ol>
          </li>

          <li>
            Limitation of Liability
            <ol>
              <li>
                In addition to the foregoing, we assume no responsibility for any error, omission,
                interruption, deletion, defect, delay in operation or transmission, communications
                line failure, theft or destruction or unauthorized access to, or alteration of, the
                Platform, Apps, or Services. We are not responsible for any problems or technical
                malfunction of any telephone network or lines, computer online systems, servers or
                providers, computer equipment, software, failure of any email due to technical
                problems or traffic congestion on the Internet or on any of the Platform, Apps, or
                Services or combination thereof, including any injury or damage to you or to any
                person’s mobile device or computer related to or resulting from the Platform, Apps,
                or Services or the use thereof. Under no circumstances shall we be responsible for
                any loss or damage, including personal injury or death, resulting from use of the
                Platform, Apps, or Services, from any content posted on or through the Services, or
                from the conduct of any users of the Services, whether online or offline. In
                addition, we assume no responsibility for any incorrect data, including Personal
                Data and/or User Content provided by you or on your behalf and you hereby represent
                and warrant that you are solely responsible for any and all data provided to Midani,
                including any incorrect data and you shall assume any and all liability for any
                consequences of provision of such incorrect data to us.
              </li>
              <li>
                IN NO EVENT SHALL Midani, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE OFFICERS,
                DIRECTORS, EMPLOYEES, ASSIGNEES OR AGENTS BE LIABLE TO YOU, THE CUSTOMER, OR ANY
                THIRD PARTY FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT,
                INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN
                CONNECTION WITH YOUR USE OF THE PLATFORM AND/OR SERVICES, INCLUDING BUT NOT LIMITED
                TO THE QUALITY, ACCURACY, OR UTILITY OF THE SERVICES AND/OR CONTENT PROVIDED AS PART
                OF OR THROUGH THE PLATFORM, APPS, AND/OR THE SERVICES, WHETHER THE DAMAGES ARE
                FORESEEABLE AND WHETHER OR NOT Midani HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
                PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL Midani’S
                MAXIMUM CUMULATIVE LIABILITY TO YOU AND/OR THE CUSTOMER UNDER ANY CAUSE(S) OF ACTION
                (WHETHER IN CONTRACT, TORT OR OTHERWISE) EXCEED, IN THE AGGREGATE, THE AMOUNT YOU OR
                THE CUSTOMER (AS APPLICABLE) HAVE PAID US IN THE TWELVE MONTHS IMMEDIATELY PRECEDING
                THE APPLICABLE CLAIM OR SERIES OF CLAIMS. IF YOU HAVE NOT MADE ANY PAYMENTS TO
                Midani FOR THE USE OF THE SERVICES, Midani SHALL NOT HAVE ANY LIABILITY TOWARD YOU.
              </li>
              <li>
                EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY,
                DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE
                THE RISKS BETWEEN YOU AND US IN CONNECTION WITH THE SERVICES. EACH OF THESE
                PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. BY
                ENTERING INTO THESE TERMS WE BOTH AGREE THAT THOSE LIMITATIONS AND RISK ALLOCATION
                ARE COMMERCIALY REASONABLE.
              </li>
            </ol>
          </li>

          <li>
            Indemnification{' '}
            <span>
              Indemnification You agree, on your own behalf or on behalf of the Customer, as
              applicable, to indemnify, defend, and hold harmless Midani, its affiliates and their
              respective employees, directors, officers, subcontractors and agents, against any and
              all claims, damages, or costs, losses, liabilities, or expenses (including court costs
              and attorneys’ fees) that arise directly or indirectly from: (a) breach of these Terms
              by you or anyone using your computer, mobile device, and/or password (whether
              authorized or unauthorized); (b) any claim, loss or damage experienced from your use,
              attempted use or misuse of the Services, Platform or the App; (c) your violation of
              any law, regulation, or any of your obligations, representations or warranties
              hereunder including but not limited to breach of any privacy and/or data protection
              laws and regulations to which you are subject; (d) your infringement of any right of
              any third party; and (e) any other matter for which you are responsible hereunder or
              under applicable law.
            </span>
          </li>
          <li>
            Third-Parties.
            <ol>
              <li>
                The Services may provide you with third-party links (including, without limitation,
                advertisements) to websites, applications, and services (“Third Party Services”).
                The Services may also enable you engage Third Party Services via our marketplace,
                our integrations offering, or through our API (as describe below). We do not endorse
                any products or services offered by third parties and make no promises regarding any
                content, goods or services provided by such third parties. The use of Third Party
                Services is at your own risk and we shall not be held liable with respect to any use
                of Third Party Services, nor shall we have any liability as a result of listing,
                reviewing, certifying or enabling any Third Party Services, including, without
                limitation any responsibility for (a) payments processed or submitted through
                third-party websites and applications; (b) the terms of use and privacy policies of
                such third parties, and (c) any exchange of User Content or other data between you
                and any such third party. You hereby acknowledge any use of data, including the User
                Content by a Third Party Services, is governed by the terms shared between you and
                the applicable third party; You are solely responsible for any loss, damage or
                liability whatsoever that may arise out of or in connection with the decision to
                permit the use of Third Party Services and enabling such third parties to access or
                use User Content. We urge our users to exercise caution in using third-party
                websites or applications.
              </li>
              <li>
                The Services may contain features designed to integrate with Third Party Services.
                We cannot guarantee the continued availability of such Service features and may
                cease providing them without entitling You to any refund, credit, or other
                compensation. If you choose integrating and/or using the Third Party Services, you
                acknowledge and agree that you are solely responsible for your compliance with
                applicable privacy restrictions, laws and regulations, including your any data
                activities you may conduct or permit third parties to conduct.
              </li>
            </ol>
          </li>

          <li>
            API
            <ol>
              <li>
                Customers may access the Services via an Application Program Interface (“API”). Such
                API is considered a part of the Services, and its use is subject to these Terms. You
                may only access and use our API for Customer’s internal business purposes, to create
                interoperability and integration between the Services and other products, services
                or systems Customer uses internally. You represent and warrant you have and shall
                maintain throughout the term all rights, license and consents required under
                applicable law of contract for such integration. We reserve the right, at any time
                at our sole discretion, to modify or discontinue, temporarily or permanently, your
                access to the API and you are solely responsible to ensure that your use of the API
                is compatible with the current version. Use of the API shall only be made in
                accordance with these Terms and provided further that you shall not (a) modify or
                create a derivative work of any part of the API; (b) process or permit the
                processing of the data of any other party unless in connection with the authorized
                use of the API, or (c) market, sell, license, sublicense, distribute, publish,
                display, reproduce, assign or otherwise transfer to a third party the API, or any
                copy thereof, in whole or in part. You acknowledge and agree that (a) you have no
                rights to any source code for the API, and (b) you shall not cause or permit the
                disassembly, decomplication or reverse engineering of the API or otherwise attempt
                to gain access to the source code to the API (or the underlying algorithms,
                structure or organization of the object code in the API), unless permitted by
                applicable law. Abuse or excessively frequent requests (determined as such in our
                sole discretion) to Midani via the API may result in the temporary or permanent
                suspension of your account’s access to the API.
              </li>
            </ol>
          </li>

          <li>
            Confidential Information
            <ol>
              <li>
                Confidential Information means all information disclosed by a party (“Disclosing
                Party”) to the other party (“Receiving Party”), whether orally or in writing, that
                is designated as confidential at the time of disclosure or that reasonably should be
                understood to be confidential given the nature of the information and the
                circumstances of disclosure. To clarify, Confidential Information of each party
                includes business and marketing plans, code, inventions, technology and technical
                information, product plans and business processes disclosed by such party.
                Confidential Information of Midani includes the Site, the Services (including Beta
                Services and the API) and the technology underlying such Services, as well as
                security procedures and documentation. Confidential Information does not include
                information that (a) is or becomes generally available to the public without breach
                of any obligation owed to the Disclosing Party; (b) was known to the Receiving Party
                prior to its disclosure by the Disclosing Party without breach of any obligation
                owed to the Disclosing Party; (c) is received from a third party without breach of
                any obligation owed to the Disclosing Party; or (d) was independently developed by
                the Receiving Party without any use or reference to the Confidential Information.
              </li>
              <li>
                The Receiving Party will (a) take at least reasonable measures to prevent the
                unauthorized disclosure or use of Confidential Information; (b) not use, disclose,
                copy or reproduce any Confidential Information of the Disclosing Party for any
                purpose outside the scope of this Agreement (c) limit access to those employees,
                affiliates, service providers and agents, on a need to know basis, or in connection
                with a due diligence process that the Receiving Party is undergoing; provided, in
                all cases that such disclosure is subject to confidentiality bligations at least as
                restrictive as those contained herein.
              </li>
              <li>
                Notwithstanding the above, Confidential Information may be disclosed pursuant to the
                order or requirement of a court, administrative agency or other governmental
                authority; provided, however, that to the extent legally permissible, the Receiving
                Party shall provide written notice of such requirement to the Disclosing Party to
                enable the Disclosing Party to seek a protective order or otherwise prevent or
                restrict such disclosure.
              </li>
            </ol>
          </li>

          <li>
            Referral.{' '}
            <span>
              Any user may invite such user’s friends, contacts or employees to try out the Service,
              and may do so in accordance with our referral terms to be published as part of the
              Services, through the Site, or as proposed to such User through email, SMS text
              message or the Platform. We may provide you with the option to send invitations via
              SMS text message. You may not use the Service to send text messages unless you have
              the consent from the recipient to receive text message communications.
            </span>
          </li>
          <li>
            Reference{' '}
            <span>
              Customer acknowledges and accepts that Midani has the right to use Customer’s name and
              logo to identify Customer as a customer of Midani on the Site, marketing materials or
              otherwise. Customer may revoke such right, at any time, by contacting
              support@connetceam.com
            </span>
          </li>
          <li>
            Export Controls; Sanctions.{' '}
            <span>
              The Services may be subject to Israeli, European Union, United Kingdom U.S. or other
              applicable export controls, laws and regulations (“Export Controls”). Customer is
              solely responsible for complying with applicable Export Controls and all economic
              sanctions which may impose additional restrictions or requirements on the use, export,
              re-export or import of the Services and/or the User Content. You hereby represent that
              you are not subject to any United States, UK, European or Israeli economic sanctions.
              Furthermore, you agree to indemnify Midani for any damages, liabilities, costs, or
              expenses incurred as a result of any breach of the foregoing representations. Any
              breach of this provision may result in immediate termination of your Customer Account
              or Personal Profile (as the case may be) and may subject you to legal penalties and
              consequences under the applicable laws.
            </span>
          </li>
          <li>
            Notices.{' '}
            <span>
              Any required notices pursuant to these Terms may be sent by registered mail, email
              transmission (to the addresses provided upon registration), and/or provided through
              the Services, and any such notice shall be deemed to have been received one (1)
              business day after delivery by courier, four (4) business days after delivery by
              registered mail and one (1) day after email transmission or publication through the
              Services. Notices to us shall be provided to Mobilesson Ltd., attn: Legal, at
              legal@Midani.com.
            </span>
          </li>
          <li>
            Miscellaneous.{' '}
            <span>
              These Terms shall be governed exclusively by the laws of the State of Israel exclusive
              of its choice of law rules and without regard to the United Nations Convention on the
              International Sales of Goods and the competent courts in Tel-Aviv Jaffa shall have
              exclusive jurisdiction to hear any disputes arising hereunder. In any action to
              enforce these Terms, the prevailing party will be entitled to costs and attorneys’
              fees. Any cause of action against Midani must be brought within one (1) year of the
              date such cause of action arose. If applicable law prohibits such limitation period
              for asserting claims, any claim must be asserted within the shortest time period
              permitted by applicable Law.
            </span>
          </li>
        </ol>
        <p>
          In the event that any provision of these Terms is held to be unenforceable, such provision
          shall be replaced with an enforceable provision which most closely achieves the effect of
          the original provision, and the remaining terms of these Terms shall remain in full force
          and effect. Nothing in these Terms creates any agency, employment, joint venture, or
          partnership relationship between you and Midani or enables you to act on behalf of Midani.
          Except as may be expressly stated in these Terms, these Terms constitute the entire
          agreement between us and you pertaining to the subject matter hereof, and any and all
          other agreements existing between us relating thereto are hereby canceled. We may assign
          and/or transfer our rights and obligations hereunder to any third party without prior
          notice. You shall not assign and/or transfer any of your rights or obligations hereunder,
          and any assignment in violation of the foregoing shall be void. No waiver of any breach or
          default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or
          default.
        </p>
      </div>
    </div>
  );
}

export default Terms;
