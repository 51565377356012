import React, { useState } from 'react';
import PropTypes from 'prop-types';
import playIcon from 'assets/images/icons/play.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import searchIcon from 'assets/images/icons/search.svg';
import AddSegment from './AddSegment';
import FormButton from 'components/form-components/FormButton';
import TextField from 'components/form-components/TextField';
import { DEV_ONLY, permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';

const GroupListHeader = ({ getAllGroups, searchText, searchChanged }) => {
  const [openAddSegment, setOpenAddSegment] = useState(false);

  return (
    <div className='d-flex justify-content-end flex-wrap-reverse p-4 gap-3'>
      {DEV_ONLY && <FormButton text='button_learn_feature' variant='blue-3' icon={playIcon} />}
      {DEV_ONLY && <FormButton text='button_group_matrix' variant='green-1' />}
      <TextField
        value={searchText}
        name='search'
        classes='w-auto'
        handleChange={searchChanged}
        placeholder='field_search'
        icon={searchIcon}
        shrink
      />
      <div className='parent-container'>
        {isPermissionAvailable(permissionKeys.employees_groups_create) && (
          <FormButton
            text='button_add_segment'
            onClick={() => {
              setOpenAddSegment(!openAddSegment);
            }}
            variant='green-2'
            icon={plusIcon}
          />
        )}
        {openAddSegment && (
          <AddSegment getAllGroups={getAllGroups} close={() => setOpenAddSegment(false)} />
        )}
      </div>
    </div>
  );
};
GroupListHeader.propTypes = {
  getAllGroups: PropTypes.func,
  searchChanged: PropTypes.func,
  searchText: PropTypes.string,
};
export default GroupListHeader;
