import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FormFields from './FormFields';
import FormPreview from './FormPreview';
import FormProperties from './FormProperties';
import { useTranslation } from 'react-i18next';
import dragDropIcon from 'assets/images/icons/drag-drop.svg';
import { DEV_ONLY } from 'config/config';
import FormButton from 'components/form-components/FormButton';
import { formElementsDefaultProperties, rightToggleList } from './utils';
import { cloneDeep, merge } from 'lodash';
import Error from 'components/Error';
import FormDesignHeader from './FormDesignHeader';
import PreviewModal from './PreviewModal';
import { createAPIStructure, jsonStringifyAllElements } from './actions';
import AlertModal from 'components/common-components/AlertModal';
import PrepareErrorList from './PrepareErrorList';

function DesignForm({ formDetails, getFormDetail, dataSourcesList }) {
  const { t } = useTranslation();
  const propertiesSectionRef = useRef();
  const [selectedField, setSelectedField] = useState(null);
  const [formProperties, setFormProperties] = useState(null);
  const [draggedElement, setDraggedElement] = useState(null);
  const [APIError, setAPIError] = useState('');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [formUserElements, setFormUserElements] = useState([]);
  const [formSupervisorElements, setFormSupervisorElements] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorsList, setErrorsList] = useState([]);

  useEffect(async () => {
    let bindingElementsObj = {};
    let allElementsObj = {};
    const pastTimeStamp = Math.ceil(Date.now() / 1000) - 30 * 24 * 60 * 60; // one month earlier
    let formProps = {
      supervisorFields: { elements: [] },
      otherDetails: { bindingElementsObj: {}, allFormElementsObj: {}, hasStatusFieldInForm: false },
    };
    let pageNumber = 0;
    if (formDetails?.currentVersion?.elements) {
      formDetails.currentVersion.elements.map((singleElement, index) => {
        if (singleElement.elementType === 'page') pageNumber++;
        let properties = JSON.parse(singleElement.properties);
        // add all missing properties of a single processed element
        properties = merge(
          cloneDeep(formElementsDefaultProperties[singleElement.elementType]),
          properties,
        );
        // make all elements obj to bind with other elements
        if (
          !['page', 'section', 'image', 'dataSource', 'externalApi'].includes(
            singleElement.elementType,
          )
        ) {
          allElementsObj = {
            ...allElementsObj,
            [singleElement.elementGlobalId]: {
              fieldName: properties.basicProperties.fieldTitle,
              fieldId: singleElement.elementGlobalId,
            },
          };
        }
        // check already created elements those doesn't contain fieldUniqueId key, populate fieldUniqueId Key
        if (!['page', 'section', 'image'].includes(singleElement.elementType)) {
          if (
            !Object.keys(properties.otherProperties).includes('fieldUniqueId') ||
            properties.otherProperties.fieldUniqueId === ''
          ) {
            properties.otherProperties.fieldUniqueId = `field_${pastTimeStamp + index * 5}`;
          }
        }

        // if processed element is data source element then update the binding elements list
        if (
          singleElement.elementType === 'dataSource' ||
          singleElement.elementType === 'externalApi'
        ) {
          const currentElementBinding = properties.basicProperties.bindingList;
          if (currentElementBinding.length) {
            currentElementBinding.map((binding) => {
              if (binding && binding.fieldId)
                bindingElementsObj = {
                  ...bindingElementsObj,
                  [binding.fieldId]: {
                    fieldName: properties.basicProperties.fieldTitle,
                    fieldId: singleElement.elementGlobalId,
                  },
                };
            });
          }
        }

        let addedElement = {
          company: singleElement.company,
          elementGlobalId: singleElement.elementGlobalId,
          elementType: singleElement.elementType,
          _id: singleElement._id,
          properties,
        };
        if (formProps['page' + pageNumber]) {
          formProps['page' + pageNumber].elements.push(addedElement);
        } else {
          formProps['page' + pageNumber] = {
            elements: [addedElement],
          };
        }
      });
      let hasStatusFieldInForm = false;
      formDetails.currentVersion.supervisorElements.map((singleElement) => {
        let addedElement = {
          company: singleElement.company,
          elementGlobalId: singleElement.elementGlobalId,
          elementType: 'supervisor_' + singleElement.elementType,
          _id: singleElement._id,
          properties: JSON.parse(singleElement.properties),
        };
        formProps.supervisorFields.elements.push(addedElement);
        if (addedElement.elementType === 'supervisor_status') {
          hasStatusFieldInForm = true;
        }
      });
      if (formDetails.currentVersion.statusElement) {
        let statusElement = formDetails.currentVersion.statusElement;
        let addedElement = {
          company: statusElement.company,
          elementGlobalId: statusElement.elementGlobalId,
          elementType: 'supervisor_' + statusElement.elementType,
          _id: statusElement._id,
          properties: JSON.parse(statusElement.properties),
        };
        // formProps.supervisorFields.elements.push(addedElement);
        let supervisorElementsList = formProps.supervisorFields.elements;

        if (supervisorElementsList.length !== 0) {
          let userElementsCount = formDetails?.currentVersion?.elements
            ? formDetails.currentVersion.elements.length
            : 0;
          let statusFieldIndex = statusElement.position - userElementsCount - 1;
          console.log(statusElement.position, userElementsCount, statusFieldIndex);
          supervisorElementsList = [
            ...supervisorElementsList.slice(0, statusFieldIndex),
            addedElement,
            ...supervisorElementsList.slice(statusFieldIndex),
          ];
        } else supervisorElementsList = [addedElement];
        formProps.supervisorFields.elements = supervisorElementsList;
        hasStatusFieldInForm = true;
      }
      if (Object.keys(formProps).length < 3) {
        formProps = {
          ...formProps,
          page1: {
            elements: [
              { elementType: 'page', properties: cloneDeep(formElementsDefaultProperties.page) },
            ],
          },
        };
      }
      formProps.otherDetails.bindingElementsObj = bindingElementsObj;
      formProps.otherDetails.allFormElementsObj = allElementsObj;
      formProps.otherDetails.hasStatusFieldInForm = hasStatusFieldInForm;
    } else {
      formProps = {
        ...formProps,
        page1: {
          elements: [
            { elementType: 'page', properties: cloneDeep(formElementsDefaultProperties.page) },
          ],
        },
      };
    }
    const orderedFormProps = Object.keys(formProps)
      .sort()
      .reduce((obj, key) => {
        obj[key] = formProps[key];
        return obj;
      }, {});
    setFormProperties(orderedFormProps);
  }, [formDetails]);

  const validateAndCheckOrder = () => {
    let APIData = createAPIStructure(cloneDeep(formProperties), true);
    if (APIData.status) {
      APIData = jsonStringifyAllElements(APIData);
      setShowOrderModal(!showOrderModal);
      setFormUserElements(APIData.elements);
      setFormSupervisorElements(APIData.supervisorElements);
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };
  const validateAndPreview = () => {
    let APIData = createAPIStructure(cloneDeep(formProperties), true);
    if (APIData.status) {
      setShowPreviewModal(!showPreviewModal);
      setFormUserElements(APIData.elements);
    } else {
      setErrorsList(APIData.errors);
      setShowErrorModal(true);
    }
  };

  return (
    <>
      {formProperties && (
        <div className='form-design'>
          <FormDesignHeader
            setAPIError={setAPIError}
            formId={formDetails._id}
            getFormDetail={getFormDetail}
            formDetails={formDetails}
            formProperties={cloneDeep(formProperties)}
          />
          {APIError && <Error msg={APIError} />}
          {formProperties && (
            <div className='section-layout'>
              <section>
                <div className='section-header-area'>
                  <div className='d-flex gap-2 align-items-center'>
                    <img src={dragDropIcon} />
                    <p className='theme-text-black-1 theme-size-1_2 mb-0'>
                      {t('form_template_fields')}
                    </p>
                  </div>
                  <p className='text-center mb-0 theme-size-0_9'>
                    {t('form_drag_elements_onto_your_template')}
                  </p>
                </div>
                <FormFields setDraggedElement={setDraggedElement} />
              </section>
              <section>
                <div className='section-header-area'>
                  <div className='w-100 d-flex gap-2 justify-content-between align-items-center'>
                    {/* <FormButton text='Tablet editor' variant='red-1' /> */}
                    <p className='mb-0 w-50 theme-text-black-1 theme-size-0_9'>
                      {t('form_rearrange_elements')}
                    </p>
                    {DEV_ONLY && (
                      <FormButton
                        text='button_check_order'
                        variant='blue-2'
                        // disabled={!formUserElements.length}
                        onClick={validateAndCheckOrder}
                      />
                    )}
                    <FormButton
                      text='button_preview'
                      variant='blue-3'
                      // disabled={!formUserElements.length}
                      onClick={validateAndPreview}
                    />
                  </div>
                </div>
                <FormPreview
                  selectedField={selectedField}
                  setSelectedField={setSelectedField}
                  formProperties={formProperties}
                  draggedElement={draggedElement}
                  setFormProperties={setFormProperties}
                  direction={formDetails?.textDirection === 'right_to_left' ? 'rtl' : 'ltr'}
                  dataSourcesList={dataSourcesList ? dataSourcesList : []}
                  propertiesSectionRef={propertiesSectionRef}
                />
              </section>

              <section ref={propertiesSectionRef} id='properties-section'>
                <FormProperties
                  selectedField={selectedField}
                  formProperties={formProperties}
                  setFormProperties={setFormProperties}
                  toggleList={rightToggleList}
                  isNestedOption={false}
                  dataSourcesList={dataSourcesList ? dataSourcesList : []}
                />
              </section>
            </div>
          )}
        </div>
      )}
      {formDetails && showPreviewModal && (
        <PreviewModal
          setShowPreviewModal={setShowPreviewModal}
          formTitle={formDetails.title}
          formIcon={formDetails.formIcon ? formDetails.formIcon : ''}
          userFields={formUserElements}
        />
      )}
      {formDetails && showOrderModal && (
        <AlertModal
          show={showOrderModal}
          showCloseButton={true}
          closeModal={() => setShowOrderModal(false)}
          title='alert_order'
          data={ShowOrder({
            userFields: formUserElements,
            supervisorFields: formSupervisorElements,
          })}
          actionButtons={[
            { text: t('button_ok'), variant: 'grey-1', onClick: () => setShowOrderModal(false) },
          ]}
        />
      )}

      <AlertModal
        show={showErrorModal}
        showCloseButton={true}
        closeModal={() => setShowErrorModal(false)}
        title='alert_warning'
        message='alert_form_missing_properties'
        data={PrepareErrorList({ errorMessages: cloneDeep(errorsList) })}
        variant='delete'
        actionButtons={[
          { text: t('button_ok'), variant: 'grey-1', onClick: () => setShowErrorModal(false) },
        ]}
      />
    </>
  );
}
DesignForm.propTypes = {
  formDetails: PropTypes.object.isRequired,
  getFormDetail: PropTypes.func.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
};
export default DesignForm;
const ShowOrder = ({ userFields, supervisorFields }) => {
  return (
    <div className='d-flex flex-column gap-3 w-100'>
      {[userFields, supervisorFields].map((list, i) => (
        <div key={i} className='border-grey-1-h-1 d-flex flex-column gap-2 py-2'>
          {list.map((element, index) => (
            <div
              key={index}
              className={`d-flex gap-2 justify-content-between ${
                index < list.length - 1 ? 'border-bottom-grey-1-h-1' : ''
              } ${
                element.elementType === 'page'
                  ? 'theme-background-green-3'
                  : element.elementType === 'section'
                  ? 'theme-background-grey-13'
                  : ''
              }`}
            >
              <label className='w-25'>Element: {element.elementType}</label>
              <label className='w-25'>Page: {element.pageNumber}</label>
              <label className='w-25'>Section: {element.sectionNumber}</label>
              <label className='w-25'>Position: {element.position}</label>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

ShowOrder.propTypes = {
  userFields: PropTypes.array.isRequired,
  supervisorFields: PropTypes.array.isRequired,
};
