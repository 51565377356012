import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateSavedChanges, updateTriggerSaveFormChanges } from 'config/config';

const SwitchUrlConfirmContent = ({ toUrl, closeModal, toTab, changeTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const leavePage = () => {
    if (toUrl) {
      closeModal();
      updateSavedChanges(false);
      navigate(toUrl);
    } else if (toTab) {
      closeModal();
      updateSavedChanges(false);
      changeTab(toTab);
    }
  };

  const triggerFormSave = () => {
    updateTriggerSaveFormChanges(true);
    closeModal();
  };

  return (
    <div className='w-75 d-flex flex-column gap-2 align-items-center'>
      <label className='theme-size-1_5 theme-font-inter-medium theme-text-black-1'>
        {t('alert_common_title')}
      </label>
      <label className='theme-font-inter-regular'>{t('alert_form_unsaved_changes_message')}</label>
      <div className='w-100 d-flex gap-2 justify-content-around mt-3'>
        <label
          className='theme-size-1_3 theme-font-inter-medium theme-text-black-1 cursor-pointer'
          onClick={closeModal}
        >
          {t('button_cancel')}
        </label>
        <label
          className='theme-size-1_3 theme-font-inter-medium theme-text-red-1 cursor-pointer'
          onClick={leavePage}
        >
          {t('button_leave')}
        </label>
        <label
          className='theme-size-1_3 theme-font-inter-medium theme-text-green-1 cursor-pointer'
          onClick={triggerFormSave}
        >
          {t('button_save_as_draft')}
        </label>
      </div>
    </div>
  );
};

SwitchUrlConfirmContent.propTypes = {
  toTab: PropTypes.object,
  changeTab: PropTypes.func,
  toUrl: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};
export default SwitchUrlConfirmContent;
