import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DateTimeField = ({
  name,
  placeholder,
  displayName,
  icon,
  value,
  error,
  touched,
  shrink,
  handleChange,
  classes,
  type,
  timeIcon,
  unixTimeStamp,
}) => {
  const { t } = useTranslation();
  const shrinkField = shrink ? ' shrink-field' : '';
  const shrinkLabel = shrink ? ' shrink-label' : '';
  const [date, setDate] = useState(undefined);
  const [time, setTime] = useState(undefined);

  useEffect(() => {
    let dateString = '';
    if (type === 'dateAndTime' && date != undefined && time != undefined) {
      dateString = date + 'T' + time;
    } else if (type === 'time' && time != undefined) {
      dateString = getCurrentDate() + 'T' + time;
    } else if (type === 'date' && date != undefined) {
      dateString = date;
    }

    if (dateString.length > 0) {
      if (unixTimeStamp) {
        let result = Date.parse(dateString);
        handleChange(name, result);
      } else {
        handleChange(name, dateString);
      }
    }
  }, [date, time]);
  useEffect(() => {
    let currentDate = new Date();
    if (value) {
      currentDate = new Date(parseInt(value));
    }
    setDate(
      currentDate.getFullYear() +
        '-' +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        currentDate.getDate().toString().padStart(2, '0'),
    );
    setTime(
      currentDate.getHours().toString().padStart(2, '0') +
        ':' +
        currentDate.getMinutes().toString().padStart(2, '0'),
    );
  }, []);

  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month =
      currentDate.getMonth() + 1 < 10
        ? '0' + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let date = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();

    let dateString = year + '-' + month + '-' + date;
    return dateString;
  };

  const formatToLocalIsoDateString = () => {
    let date = null;
    if (isNaN(parseInt(value))) date = new Date();
    else date = new Date(parseInt(value));
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    return `${y}-${m < 10 ? '0' : ''}${m}-${d < 10 ? '0' : ''}${d}`;
  };

  const formatToLocalIsoTimeString = () => {
    if (isNaN(parseInt(value))) return new Date().toTimeString().slice(0, 8);
    return new Date(parseInt(value)).toTimeString().slice(0, 8);
  };

  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      {displayName && (
        <label
          htmlFor={name}
          className={!touched && error ? 'label-error' + shrinkLabel : shrinkLabel}
        >
          {t(displayName)}
        </label>
      )}
      {(type === 'dateAndTime' || type === 'date') && (
        <div className='custom-input-field'>
          <input
            type='date'
            onKeyDown={(e) => e.preventDefault()}
            id={name}
            name={name}
            className={!touched && error ? 'input-error' + shrinkField : shrinkField}
            placeholder={t(placeholder)}
            value={formatToLocalIsoDateString()}
            onChange={(e) => setDate(e.target.value)}
          />
          {icon && <img src={icon} className='icon' />}
        </div>
      )}

      {(type === 'dateAndTime' || type === 'time') && (
        <div className='custom-input-field mt-2'>
          <input
            type='time'
            onKeyDown={(e) => e.preventDefault()}
            id={name}
            name={name}
            className={!touched && error ? 'input-error' + shrinkField : shrinkField}
            placeholder={t(placeholder)}
            value={formatToLocalIsoTimeString()}
            onChange={(e) => setTime(e.target.value)}
          />
          {timeIcon && <img src={timeIcon} className='icon' />}
        </div>
      )}
      {!touched && error && <p className='form-field-error'>{t(error)}</p>}
    </div>
  );
};

DateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  shrink: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  timeIcon: PropTypes.string,
  classes: PropTypes.string,
  unixTimeStamp: PropTypes.bool,
};

export default DateTimeField;
