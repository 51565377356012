import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/form-components/TextField';
import { useTranslation } from 'react-i18next';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { addSegmentSchema } from './utils';
import Error from 'components/Error';
import { CallAPI } from 'actions/General';

const AddSegment = ({ close, getAllGroups }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    segmentName: '',
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };

  const submit = async () => {
    setLoading(true);
    try {
      await addSegmentSchema.validate(values, { abortEarly: false });
      let result = await CallAPI(
        'ADD_SEGMENT',
        { segmentName: values.segmentName, isDefault: false },
        null,
        setLoading,
        setApiError,
        null,
      );
      if (result.status) {
        getAllGroups();
        close();
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  return (
    <div className='add-segment'>
      <div className='title'>{t('text_add_segment')}</div>

      {apiError ? (
        <div className='mx-4 mt-3'>
          <Error msg={apiError} />{' '}
        </div>
      ) : (
        <></>
      )}
      <div className='mx-4'>
        <TextField
          shrink={true}
          classes='my-3'
          name='segmentName'
          placeholder='field_name'
          value={values.segmentName}
          handleChange={handleChange}
          error={errors.segmentName}
          touched={touched.segmentName}
        />

        <div className='d-flex justify-content-end gap-3 my-3'>
          <FormButton text='button_cancel' onClick={close} variant='white-1' />
          <FormLoadingButton
            loading={loading}
            text='button_create'
            onClick={submit}
            variant='green-1'
          />
        </div>
      </div>
    </div>
  );
};
AddSegment.propTypes = {
  close: PropTypes.func,
  getAllGroups: PropTypes.func,
};

export default AddSegment;
