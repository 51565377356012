import React from 'react';
import PropTypes from 'prop-types';
import guardIcon from 'assets/images/icons/guard-white.svg';

const Initials = ({ users, removeGuard }) => {
  // const avatarColors = ['#38CB89', '#FFA600'];
  function generateAvatarColor(initials) {
    const charCodeSum = initials.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const color = `hsl(${charCodeSum % 360}, 70%, 50%)`;

    return color;
  }
  return (
    <div className='user-list'>
      {users.map((user, index) => (
        <div
          key={index}
        >
          <div
            className='avatar-circle'
            style={{
              backgroundColor: generateAvatarColor(getInitials(user)), // avatarColors[index % avatarColors.length]
            }}
          >
            {getInitials(user)}
          </div>
          {index === users.length - 1 && !removeGuard && <img src={guardIcon} alt='Loading...' />}
        </div>
      ))}
    </div>
  );
};

const getInitials = (user) => {
  const { firstName, lastName } = user;
  let firstLetter;
  let secondLetter;
  !firstName[0] ? (firstLetter = '') : (firstLetter = firstName[0]);
  !lastName[0] ? (secondLetter = '') : (secondLetter = lastName[0]);
  const initials = `${firstLetter}${secondLetter}`;
  return initials.toUpperCase();
};

Initials.propTypes = {
  users: PropTypes.array.isRequired,
  removeGuard: PropTypes.bool
};

export default Initials;
