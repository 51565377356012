export const responseSetsTableHeader = [
  {
    name: 'name',
    displayName: 'table_header_response_set_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'choiceName',
  },
  {
    name: 'response_texts',
    displayName: 'table_header_response_texts',
    isSelected: true,
    type: 'text',
    associatedKey: 'choiceValue',
    populateFunc: 'populateResponseSetTexts',
  },
  {
    name: 'response_values',
    displayName: 'table_header_response_values',
    isSelected: true,
    type: 'text',
    associatedKey: 'choiceValue',
    populateFunc: 'populateResponseSetValues',
  },
  {
    name: 'edit_delete',
    displayName: '',
    type: 'string',
    isRequired: true,
    isSelected: true,
    populateFunc: 'populateResponseSetEditDeleteIcon',
    callbackFunc: 'editDeleteResponseSetClick',
  },
];
