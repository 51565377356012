import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const FormButton = ({
  text,
  variant,
  icon,
  classes,
  iconClasses,
  onClick,
  disabled,
  shrink,
  largeSize,
  minWidth,
  iconRight,
}) => {
  const { t } = useTranslation();
  const baseClass = `theme-button-${variant}`;

  const shrinkButton = shrink ? ' shrink-button' : '';
  const largeButton = largeSize ? ' lg-size' : '';
  const minWith = minWidth ? ' min-width' : '';

  return (
    <div
      className={`custom-form-button${shrinkButton}${largeButton}${minWith} ${baseClass}${
        disabled ? ' ' + baseClass + '-disabled' : ''
      }${classes ? ' ' + classes : ''}`}
      {...(!disabled && onClick && { onClick: (e) => onClick(e) })}
    >
      {!iconRight && (
        <Fragment>{icon && <img src={icon} className={iconClasses ?? ''} />}</Fragment>
      )}
      {text.startsWith('http') ? text : t(text)}
      {iconRight && <Fragment>{icon && <img src={icon} className={iconClasses ?? ''} />}</Fragment>}
    </div>
  );
};

FormButton.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  icon: PropTypes.string,
  classes: PropTypes.string,
  iconClasses: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  largeSize: PropTypes.bool,
  minWidth: PropTypes.bool,
  iconRight: PropTypes.bool,
};

export default FormButton;
