import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/form-components/TextField';
import searchIcon from '../../assets/images/icons/search.svg';
import { useTranslation } from 'react-i18next';
import Export from 'components/common-components/Export';
import FormActions from 'pages/forms/Actions';

function DashboardHeader({
  searchText,
  searchTextChanged,
  debouncedFunction,
  type,
  actionsCallBack,
  selectedRows,
}) {
  const { t } = useTranslation();

  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className=' d-flex  align-items-center gap-3  '>
        <TextField
          value={searchText}
          name='search'
          handleChange={searchTextChanged}
          placeholder={t('field_search')}
          icon={searchIcon}
          debounceFunc={debouncedFunction}
          shrink
        />
        <FormActions
          ids={selectedRows}
          isActionButton={false}
          actionsCallBack={actionsCallBack}
          type={type}
          actionData={{
            version: '',
            designersList: undefined,
            supervisorsList: undefined,
            creator: '',
            versionsList: [],
          }}
        />
      </div>
      <div className={`d-flex align-items-center gap-3`}>
        <Export
          type={'forms'}
          screenTab={type === 'submittedForms' ? 'forms' : 'bookmarks'}
          additionalDetail={{ supervisorOnly: true }}
        />
      </div>
    </div>
  );
}
DashboardHeader.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  searchTextChanged: PropTypes.func.isRequired,
  debouncedFunction: PropTypes.func.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
};
export default DashboardHeader;
