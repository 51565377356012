import React from 'react';
const CommingSoon = () => {
  return <div style={style}>COMMING SOON</div>;
};

const style = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: '3rem',
  color: '#38cb89',
};

export default CommingSoon;
