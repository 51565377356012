import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/form-components/TextField';
import searchIcon from '../../assets/images/icons/search.svg';
import TrashActions from './TrashActions';

function TrashListHeader({
  debounceFunc,
  searchText,
  setSearchText,
  selectedTab,
  selectedRows,
  getAllTrashData,
}) {
  const handleChange = (name, value) => {
    setSearchText(value);
  };
  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className=' d-flex  align-items-center gap-3  '>
        <TextField
          value={searchText}
          name='search'
          handleChange={handleChange}
          placeholder='field_search'
          icon={searchIcon}
          shrink
          debounceFunc={debounceFunc}
        />
        <TrashActions
          ids={selectedRows}
          isActionButton={false}
          type={selectedTab}
          actionsCallBack={getAllTrashData}
        />
      </div>
    </div>
  );
}
TrashListHeader.propTypes = {
  getAllTrashData: PropTypes.func.isRequired,
  debounceFunc: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
};
export default TrashListHeader;
