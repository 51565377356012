import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Dropdown, DropdownButton, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import TextField from 'components/form-components/TextField';
import { isRTL, permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';
import { CallAPI } from 'actions/General';
import { addDataSourceColumnSchema } from '../utils';

function ColumnActions({
  columns,
  dataSourceId,
  dataSourceName,
  fieldsList,
  refreshLists,
  allowRemoveKeyFilter,
}) {
  const { t } = useTranslation();

  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [editEnabledColumn, setEditEnabledColumn] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [values, setValues] = useState({ columnName: '' });
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [APIError, setAPIError] = useState('');

  const handleColumnNameChange = (field, value) => {
    if (!value.includes('.')) {
      setTouched({ ...touched, [field]: true });
      setErrors({ ...errors, [field]: undefined });
      setValues({ ...values, [field]: value });
    }
  };

  const verifyAPIData = () => {
    const allColumns = fieldsList.map((singleField) => singleField.name.toLowerCase());

    if (allColumns.indexOf(values.columnName.trim().toLowerCase()) > -1) {
      setErrors({ columnName: 'Column Name already exist' });
      return false;
    }
    return true;
  };

  const renameDataSourceColumn = async () => {
    try {
      setTouched({});
      await addDataSourceColumnSchema.validate(values, { abortEarly: false });
      const verifyData = verifyAPIData();

      if (verifyData) {
        const result = await CallAPI(
          'RENAME_COLUMN_IN_DATASOURCE',
          {
            dataSourceId,
            columnToRename: { id: columns[0].columnId, title: columns[0].name },
            columnNewName: values.columnName.trim(),
          },
          null,
          setLoader,
          setAPIError,
          null,
        );
        if (result.status) {
          refreshLists();
          closeEditPopup();
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
    }
  };

  const deleteDataSourceColumn = async () => {
    setDeleteConfirmationModal(false);
    const result = await CallAPI(
      'DELETE_COLUMN_IN_DATASOURCE',
      { dataSourceId, column: { id: columns[0].columnId, title: columns[0].name } },
      null,
      null,
      null,
      null,
    );
    if (result.status) {
      refreshLists();
    }
  };

  const closeEditPopup = () => {
    setEditEnabledColumn(null);
    setValues({ columnName: '' });
    setTouched({});
    setErrors({});
  };

  const setKeyFilter = async (bKeyFilter) => {
    const payload = {
      dataSource: { id: dataSourceId, title: dataSourceName },
      columnToSet: { id: columns[0].columnId, title: columns[0].name },
      bKeyFilter,
    };

    const result = await CallAPI('SET_DATA_SOURCE_KEY_FILTER', payload, null, null, null, null);
    if (result.status) {
      refreshLists();
    } else if (result.error) toast.error(result.error);
  };

  const setFilter = async (filter) => {
    const payload = {
      dataSource: { id: dataSourceId, title: dataSourceName },
      columnsToSet: columns.map((c) => ({ id: c.columnId, title: c.name })),
    };

    const API = filter ? 'ADD_DATA_SOURCE_FILTERS' : 'REMOVE_DATA_SOURCE_FILTERS';
    const result = await CallAPI(API, payload, null, null, null, null);
    if (result.status) {
      refreshLists();
    }
  };

  const isKeyFilterExist = () => fieldsList.some((f) => f.isKeyFilter);

  const isAnyKeyFilter = () => columns.some((c) => c.isKeyFilter);

  const areAllFilterAble = () => columns.every((c) => c.isFilter);

  const isNoneFilterAble = () => columns.every((c) => !c.isFilter);

  const isAllowedFilterCount = () =>
    fieldsList.filter((f) => f.isFilter).length + columns.length <= 4;

  useEffect(() => {
    let userActionsList = [
      {
        permission:
          columns.length === 1 &&
          !columns[0].isKeyFilter &&
          !columns[0].isFilter &&
          isPermissionAvailable(permissionKeys.field_work_datasource_create),
        name: 'action_edit',
        action: () => {
          setEditEnabledColumn(columns[0]);
          setValues({ columnName: columns[0].name });
        },
      },
      {
        permission: columns.length === 1 && !columns[0].isFilter && !isKeyFilterExist(),
        name: 'action_key_filter',
        action: () => setKeyFilter(true),
      },
      {
        permission: columns.length === 1 && columns[0].isKeyFilter && allowRemoveKeyFilter,
        name: 'action_remove_key_filter',
        action: () => setKeyFilter(false),
      },
      {
        permission:
          columns.length > 0 && !isAnyKeyFilter() && isNoneFilterAble() && isAllowedFilterCount(),
        name: 'action_filter',
        action: () => setFilter(true),
      },
      {
        permission: columns.length > 0 && !isAnyKeyFilter() && areAllFilterAble(),
        name: 'action_remove_filter',
        action: () => setFilter(false),
      },
      {
        permission:
          columns.length === 1 &&
          !columns[0].isKeyFilter &&
          !columns[0].isFilter &&
          isPermissionAvailable(permissionKeys.field_work_datasource_delete),
        name: 'action_delete',
        action: () => setDeleteConfirmationModal(true),
      },
    ];

    setDisplayedMenu(userActionsList);
  }, [columns]);

  const popover = (
    <Popover className='min-width-176' id='popover-basic'>
      <Popover.Header className='theme-background-white-2 theme-text-black-1' as='h3'>
        {t('button_edit_column')}
      </Popover.Header>
      <Popover.Body>
        {APIError && <Error msg={APIError} />}
        <TextField
          name='columnName'
          displayName=''
          placeholder={t('field_data_source_column_name')}
          handleChange={handleColumnNameChange}
          value={values.columnName}
          error={errors.columnName}
          touched={touched.columnName}
          classes='mb-3'
          shrink={true}
        />
        <div className='d-flex gap-2 justify-content-between'>
          <FormButton text='button_cancel' variant='white-1' onClick={() => closeEditPopup()} />
          <FormLoadingButton
            loading={loader}
            text='button_save'
            variant='green-1'
            onClick={() => renameDataSourceColumn()}
          />
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className='dropdown-actions'>
        <DropdownButton
          disabled={columns.length === 0 || displayedMenu.length === 0}
          as={ButtonGroup}
          drop='bottom'
          align={isRTL() ? 'end' : 'start'}
          variant='secondary'
          title={<FormButton text='button_action' variant='green-1' icon={dropdownIcon} />}
        >
          {displayedMenu.map((button, index) => (
            <Dropdown.Item
              onClick={button.action}
              key={index}
              className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
              disabled={!button.permission}
            >
              {t(button.name)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <>
        <OverlayTrigger
          trigger='click'
          onToggle={() => closeEditPopup()}
          show={!!editEnabledColumn}
          rootClose
          placement='bottom'
          overlay={popover}
        >
          <div className='py-3'></div>
        </OverlayTrigger>
      </>
      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_common_title')}
        message={t('alert_data_source_column_deletion_message')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setDeleteConfirmationModal(false),
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => deleteDataSourceColumn(),
          },
        ]}
      />
    </>
  );
}
ColumnActions.propTypes = {
  columns: PropTypes.array.isRequired,
  dataSourceId: PropTypes.string.isRequired,
  dataSourceName: PropTypes.string.isRequired,
  fieldsList: PropTypes.array.isRequired,
  refreshLists: PropTypes.func.isRequired,
  allowRemoveKeyFilter: PropTypes.bool.isRequired,
};
export default ColumnActions;
