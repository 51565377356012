import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Details = ({ displayValues }) => {
  const { t } = useTranslation();

  return (
    <div className='theme-font-jakartaSans-semi-regular mb-4'>
      <div className='d-flex mb-3'>
        <label className='col-6'>{t('text_form_status')}</label>
        {displayValues.status === 'published' ? (
          <label className='theme-button-green-3 rounded-full px-4 py-1'>
            {t('text_published')}
          </label>
        ) : (
          <label className='theme-button-white-2 rounded-full px-4 py-1'>{t('text_draft')}</label>
        )}
      </div>
      <div className='d-flex mb-4'>
        <label className='col-6'>{t('text_current_version')}</label>
        <label>{displayValues.version}</label>
      </div>
      <div className='d-flex mb-4'>
        <label className='col-6'>{t('text_last_updated_by')}</label>
        <label>{displayValues.lastUpdatedBy}</label>
      </div>
      <div className='d-flex'>
        <label className='col-6'>{t('text_last_updated')}</label>
        <label>
          {displayValues.lastUpdated && new Date(displayValues.lastUpdated).toLocaleString()}
        </label>
      </div>
    </div>
  );
};

Details.propTypes = {
  displayValues: PropTypes.object.isRequired,
};

export default Details;
