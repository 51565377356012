import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AlertModal from 'components/common-components/AlertModal';
import AttachmentsOverviewContent from 'pages/submissions/AttachmentsOverviewContent';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import submissionAttachment from 'assets/images/icons/submission-attachment.svg';
import attendanceMediaIcon from 'assets/images/icons/attendance-media.svg';
import attendanceMediaGreyIcon from 'assets/images/icons/attendance-media-grey.svg';
import TableUtils from './TableUtils';

const TableData = ({
  data,
  tableFields,
  props,
  loader,
  isActionsEnabled,
  onSelection,
  isSelectionEnabled,
  selectionKey,
  selectedRows,
  headerWidthMap,
}) => {
  let {
    actionsType,
    actionsCallBack,
    type,
    submissionsMediaObject,
    attendanceMediaNote,
    isSupervisorColumn,
    translateValueFunc,
  } = {
    ...props,
  };
  const { t } = useTranslation();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [mediaAndNotes, setMediaAndNotes] = useState({
    media: [],
    note: '',
    showTimeStamp: false,
    mediaTimeStampString: {},
    dialogType: '',
  });
  const showMedia = (mediaNotes, formVersionId, showTimeStamp, dialogType) => {
    const mediaTimeStampString = mediaNotes.mediaTimeStampString
      ? JSON.parse(mediaNotes.mediaTimeStampString)
      : {};
    setShowMediaModal(true);
    if (dialogType === 'submission') {
      setMediaAndNotes({
        ...mediaNotes,
        media: [...mediaNotes.media].map((media) => ({ ...media })),
        showTimeStamp,
        mediaTimeStampString,
        dialogType,
      });
    } else {
      setMediaAndNotes({
        ...mediaNotes,
        showTimeStamp: false,
        mediaTimeStampString: {},
        dialogType,
      });
    }
  };
  const closeModal = () => {
    setShowMediaModal(false);
    setMediaAndNotes({ media: [], note: '' });
  };

  return (
    <Fragment>
      <tbody>
        {data &&
          data.map((singleRecord, index) => {
            return (
              <tr
                key={index}
                className={`table-checkbox ${
                  type === 'submissions' && actionsCallBack ? 'cursor-pointer' : ''
                }`}
              >
                {isSelectionEnabled ? (
                  <td>
                    <SingleCheckBoxField
                      name={selectionKey.split('.').reduce((acc, curr) => acc[curr], singleRecord)}
                      value={
                        selectedRows
                          .map((e) => (e.id ? e.id : e))
                          .indexOf(
                            selectionKey.split('.').reduce((acc, curr) => acc[curr], singleRecord),
                          ) > -1
                          ? true
                          : false
                      }
                      handleChange={onSelection}
                      text={<></>}
                      classes=''
                    />
                  </td>
                ) : null}
                {tableFields.map((singleHeaderField, index1) => {
                  let value = singleHeaderField.associatedKey;
                  let callbackFunc = singleHeaderField.callbackFunc
                    ? props[singleHeaderField.callbackFunc]
                    : singleHeaderField.sendRowWithKeyValue
                    ? { row: singleHeaderField, key: singleHeaderField.name }
                    : null;
                  return singleHeaderField.isSelected ? (
                    <td
                      key={index1}
                      onClick={() => {
                        if (type === 'submissions') {
                          if (actionsCallBack && singleHeaderField.type !== 'status') {
                            actionsCallBack(singleRecord);
                          }
                        }
                      }}
                      className={
                        type === 'submissions' || type === 'data_source_filter_list' ? 'nowrap' : ''
                      }
                      style={
                        type === 'submissions' &&
                        singleHeaderField.associatedKey !== 'indexed' &&
                        singleHeaderField.type !== 'status' &&
                        headerWidthMap[singleHeaderField.name]
                          ? {
                              width: `${headerWidthMap[singleHeaderField.name]}px`,
                              overflow: 'hidden',
                            }
                          : {}
                      }
                    >
                      {type === 'submissions' ? (
                        <div
                          className={`d-flex justify-content-between gap-2 border-right-grey-10-h-2 pe-2 ${
                            isSupervisorColumn ? 'show-edit' : ''
                          }`}
                          style={
                            type === 'submissions' &&
                            singleHeaderField.associatedKey !== 'indexed' &&
                            singleHeaderField.type !== 'status' &&
                            headerWidthMap[singleHeaderField.name]
                              ? {
                                  width: `${headerWidthMap[singleHeaderField.name]}px`,
                                  overflow: 'hidden',
                                }
                              : { width: '100%' }
                          }
                        >
                          {singleHeaderField.populateFunc
                            ? TableUtils[singleHeaderField.populateFunc](
                                value
                                  ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                                  : singleRecord,
                                callbackFunc,
                                singleHeaderField.translateVal ? translateValueFunc : null,
                              )
                            : value && singleRecord
                            ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                            : ''}
                          {submissionsMediaObject &&
                          submissionsMediaObject[singleRecord.form_submission_id] &&
                          submissionsMediaObject[singleRecord.form_submission_id][
                            singleHeaderField.associatedKey
                          ] &&
                          (submissionsMediaObject[singleRecord.form_submission_id][
                            singleHeaderField.associatedKey
                          ]['media'].length > 0 ||
                            submissionsMediaObject[singleRecord.form_submission_id][
                              singleHeaderField.associatedKey
                            ]['note'] !== '') ? (
                            <img
                              onClick={() =>
                                showMedia(
                                  submissionsMediaObject[singleRecord.form_submission_id][
                                    singleHeaderField.associatedKey
                                  ],
                                  singleRecord.form_version_id,
                                  singleHeaderField.showMediaTimeStamp ?? false,
                                  'submission',
                                )
                              }
                              className='cursor-pointer'
                              src={submissionAttachment}
                            />
                          ) : null}
                        </div>
                      ) : type === 'attendance' &&
                        attendanceMediaNote &&
                        singleRecord &&
                        singleRecord._id &&
                        attendanceMediaNote[singleRecord._id] &&
                        attendanceMediaNote[singleRecord._id][singleHeaderField.name] ? (
                        <div className={`d-flex justify-content-between gap-2`}>
                          {singleHeaderField.populateFunc
                            ? TableUtils[singleHeaderField.populateFunc](
                                value
                                  ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                                  : singleRecord,
                                callbackFunc,
                                singleHeaderField.translateVal ? translateValueFunc : null,
                              )
                            : value && singleRecord
                            ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                            : ''}

                          <img
                            onClick={() => {
                              let singleObj =
                                attendanceMediaNote[singleRecord._id][singleHeaderField.name];
                              if (singleObj.media || singleObj.note) {
                                showMedia(
                                  {
                                    userName:
                                      singleRecord.firstName || singleRecord.lastName
                                        ? singleRecord.firstName + ' ' + singleRecord.lastName
                                        : '',
                                    note: singleObj.note ? singleObj.note : '',
                                    media: singleObj.media ? singleObj.media : [],
                                  },
                                  'form_version_id',
                                  false,
                                  'attendance',
                                );
                              }
                            }}
                            className='cursor-pointer'
                            src={
                              attendanceMediaNote[singleRecord._id][singleHeaderField.name].note ||
                              attendanceMediaNote[singleRecord._id][singleHeaderField.name].media
                                ? attendanceMediaIcon
                                : attendanceMediaGreyIcon
                            }
                          />
                        </div>
                      ) : singleHeaderField.populateFunc ? (
                        TableUtils[singleHeaderField.populateFunc](
                          value
                            ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                            : singleRecord,
                          callbackFunc,
                          singleHeaderField.translateVal
                            ? translateValueFunc
                            : singleHeaderField.filterKey
                            ? singleHeaderField.filterKey
                            : null,
                        )
                      ) : value && singleRecord ? (
                        value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                      ) : (
                        ''
                      )}
                    </td>
                  ) : null;
                })}
                {isActionsEnabled && (
                  <td className='d-flex justify-content-end align-items-center'>
                    {TableUtils['actionsList'](singleRecord, actionsType, actionsCallBack)}
                  </td>
                )}
              </tr>
            );
          })}
        {!loader && data && data.length === 0 && (
          <tr>
            <td colSpan={50} className='text-center'>
              {t('text_no_records_found')}
            </td>
          </tr>
        )}
      </tbody>
      <AlertModal
        show={showMediaModal}
        closeModal={closeModal}
        showCloseButton
        size='lg'
        actionButtons={[]}
      >
        {showMediaModal ? <AttachmentsOverviewContent mediaAndNotes={mediaAndNotes} /> : <></>}
      </AlertModal>
    </Fragment>
  );
};

TableData.propTypes = {
  selectedRows: PropTypes.array,
  data: PropTypes.array.isRequired,
  tableFields: PropTypes.array.isRequired,
  props: PropTypes.object.isRequired,
  loader: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  onSelection: PropTypes.func,
  headerWidthMap: PropTypes.object.isRequired,
};

export default TableData;
