import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import arrowRightIcon from 'assets/images/icons/arrow-right.svg';
import midaniLogoArabic from 'assets/images/midani-logo-arabic.svg';
import midaniLogoEnglish from 'assets/images/midani-logo-english.svg';
import logoutImage from 'assets/images/header/logout.svg';
import changeLanguageImage from 'assets/images/header/change-language.svg';
import switchCompanyImage from 'assets/images/header/switch-company.svg';
import selectArrow from 'assets/images/select-arrow.png';
import AlertModal from './common-components/AlertModal';
import Notification from './Notification';
import SelectField from './form-components/SelectField';
import SwitchCompany from 'pages/company/SwitchCompany';
import UnSavedChanges from 'pages/UnSavedChanges';
import { AuthReducer } from 'redux/auth';
import { CallAPI } from 'actions/General';
import { changeLanguage } from 'i18next';
import { isRTL } from 'config/config';
import i18n from 'i18n/config';
import { LanguageReducer } from 'redux/language';

const LanguageActions = LanguageReducer.actions;
const AuthActions = AuthReducer.actions;

const Header = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.language);
  const [locale, setLocale] = useState(i18n.language);
  const [showDeleteCompanyAlert, setShowDeleteCompanyAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  useEffect(() => {
    let dropdownOptions = [
      {
        value: 'language',
        displayValue: 'select_language',
        isNested: true,
        subOptions: [
          {
            value: 'en',
            isSelected: lang === 'en',
            displayValue: 'select_english',
            isNested: false,
          },
          {
            value: 'ar',
            isSelected: lang === 'ar',
            displayValue: 'select_arabic',
            isNested: false,
          },
        ],
        name: 'permissions',
        classes: 'px-4',
        subOptionsArrowClass: 'invisible',
        subOptionsSelectClass: 'header_language',
        icon: changeLanguageImage,
        handleChange: handleChange,
      },
      {
        value: 'logout',
        displayValue: 'select_logout',
        isNested: false,
        icon: logoutImage,
        optionClass: 'header_logout',
      },
    ];

    if (auth.totalCompanies && auth.totalCompanies > 1) {
      dropdownOptions.unshift({
        value: 'switch_company',
        displayValue: 'select_switch_company',
        isNested: false,
        icon: switchCompanyImage,
      });
    }
    setOptionsList(dropdownOptions);
  }, [lang]);

  useEffect(() => {
    changeLanguage(lang);
    document.dir = i18n.dir();
  }, [locale]);

  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showListCompanies, setShowListCompanies] = useState(false);
  const handleChange = (name, val) => {
    if (val === 'logout') {
      logout();
    } else if (val === 'switch_company') {
      showHideCompaniesList();
    } else if (val === 'delete') {
      setShowDeleteCompanyAlert(true);
    } else if (name === 'language') {
      changeLanguage(val);
      dispatch(LanguageActions.switchLanguage(i18n.language));
      setLocale(i18n.language);
    }
  };
  const showHideCompaniesList = () => {
    setShowListCompanies(!showListCompanies);
  };
  const deleteCompany = async () => {
    await CallAPI('DELETE_COMPANY', null, null, setLoader, null);
    navigate('/all_companies');
  };
  const logout = () => {
    dispatch(
      AuthActions.updateAuthState({
        loginToken: null,
        loginRefreshToken: null,
        authToken: null,
        refreshToken: null,
        profile: null,
      }),
    );
    navigate('/login', { relative: 'path' });
  };

  return (
    <Fragment>
      <UnSavedChanges />
      <div className='header'>
        <div className='header-left'>
          <img src={isRTL() ? midaniLogoArabic : midaniLogoEnglish} alt='logo' />
          <span className='title'>{t('MIDANI')}</span>
        </div>
        <div className='header-center'>
          {auth.breadcrumbs.icon && (
            <Fragment>
              <img src={auth.breadcrumbs.icon} alt='form' />
              <div className='custom-breadcrumb theme-font-jakartaSans-regular'>
                {auth.breadcrumbs.items.map((item, index) => {
                  return (
                    <div key={index} className='d-flex gap-2 align-items-center'>
                      <img src={arrowRightIcon} />
                      <label>{t(item)}</label>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
        </div>

        {auth.authToken && (
          <Notification authTokenInitializeDate={auth.authTokenInitializeDate ?? 0} />
        )}

        <div className='header-right'>
          <div className='user-icon'>
            <span className='text-uppercase'>
              {auth.profile && auth.profile.firstName ? auth.profile.firstName[0] : ''}
              {auth.profile && auth.profile.lastName ? auth.profile.lastName[0] : ''}
            </span>
          </div>
          <div className='user-info'>
            <span className='user-name text-capitalize'>
              {auth.profile && auth.profile.firstName
                ? auth.profile.firstName + ' ' + auth.profile.lastName
                : ''}
            </span>
            <span className='user-role text-capitalize'>
              {auth.profile && auth.profile.companyName ? auth.profile.companyName : ''} -{' '}
              {auth.profile && auth.profile.role ? t('select_' + auth.profile.role) : ''}
            </span>
          </div>
          <SelectField
            label={''}
            handleChange={handleChange}
            listValues={optionsList}
            icon={selectArrow}
            positionReversed
            isAutoClose={true}
          />
        </div>
      </div>
      <SwitchCompany
        show={showListCompanies}
        showCloseButton={true}
        closeModal={showHideCompaniesList}
      />
      {showDeleteCompanyAlert && (
        <AlertModal
          show={showDeleteCompanyAlert}
          showCloseButton={true}
          closeModal={() => setShowDeleteCompanyAlert(false)}
          variant='delete'
          message={'alert_delete_company'}
          actionButtons={[
            {
              text: t('button_confirm'),
              variant: 'red-1',
              loading: loader,
              onClick: () => deleteCompany('delete'),
            },
            {
              text: t('button_cancel'),
              variant: 'white-1',
              onClick: () => setShowDeleteCompanyAlert(false),
            },
          ]}
        />
      )}
    </Fragment>
  );
};

export default Header;
