import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleBox from 'components/toggler-components/ToggleBox';
import ToggleBoxHeader from 'components/toggler-components/ToggleBoxHeader';
import ToggleBoxBody from 'components/toggler-components/ToggleBoxBody';
import { cloneDeep } from 'lodash';
import { leftToggleList, supervisorFieldList, userFieldList } from './utils';
import FieldList from './FieldList';

function FormFields({ setDraggedElement }) {
  const [elementTypeList, setElementTypeList] = useState(leftToggleList);

  const leftToggleBoxOpen = (index) => {
    const elementsList = cloneDeep(elementTypeList);
    elementsList[index].isOpen = !elementsList[index].isOpen;
    setElementTypeList(elementsList);
  };
  return (
    <>
      {elementTypeList.map((toggleBox, index) => (
        <ToggleBox key={index}>
          <ToggleBoxHeader
            show={toggleBox.isOpen}
            toggleBoxOpen={() => leftToggleBoxOpen(index)}
            title={toggleBox.title}
          />
          <ToggleBoxBody show={toggleBox.isOpen}>
            {toggleBox.id === 1 && (
              <FieldList
                fieldCategory='user-field'
                fieldList={userFieldList}
                setDraggedElement={setDraggedElement}
                itemsDraggable
              />
            )}
            {toggleBox.id === 2 && (
              <FieldList
                fieldCategory='supervisor-field'
                fieldList={supervisorFieldList}
                setDraggedElement={setDraggedElement}
                itemsDraggable
              />
            )}
          </ToggleBoxBody>
        </ToggleBox>
      ))}
    </>
  );
}

FormFields.propTypes = {
  setSelectedField: PropTypes.func,
  setDraggedElement: PropTypes.func,
};
export default FormFields;
