import { AuthReducer } from 'redux/auth';
import activityLogIcon from 'assets/images/icons/activity-log-grey.svg';
// import announcementIcon from 'assets/images/icons/announcement.svg';
import trashIcon from 'assets/images/icons/trash-menu.svg';
import attendanceIcon from 'assets/images/icons/attendance.svg';
// import billingIcon from 'assets/images/icons/billing.svg';
import companySetupIcon from 'assets/images/icons/company-setup.svg';
import dashboardIcon from 'assets/images/icons/dashboard-grey.svg';
import dataSourceIcon from 'assets/images/icons/data-source.svg';
// import directoryIcon from 'assets/images/icons/directory.svg';
// import guestIcon from 'assets/images/icons/guest.svg';
import formGreyIcon from 'assets/images/icons/form-grey.svg';
import groupsIcon from 'assets/images/icons/groups.svg';
import helpIcon from 'assets/images/icons/help.svg';
import homeGreyIcon from 'assets/images/icons/home-grey.svg';
// import issuesIcon from 'assets/images/icons/issues.svg';
// import reportsIcon from 'assets/images/icons/reports.svg';
// import supportIcon from 'assets/images/icons/support.svg';
// import tasksIcon from 'assets/images/icons/tasks.svg';
import usersIcon from 'assets/images/icons/users.svg';
import profileIcon from 'assets/images/icons/users.svg';
import companyProfileIcon from 'assets/images/icons/company-profile.svg';
import responseSetIcon from 'assets/images/icons/response-set.svg';
import store from 'store/store';
import i18n from 'i18n/config';

const AuthActions = AuthReducer.actions;
export const DEV_ONLY =
  process.env.REACT_APP_ENVIRONMENT === 'local' || process.env.REACT_APP_ENVIRONMENT === 'develop';
// export const DEV_ONLY = false;
export const signatureStartingText = 'Sign@ture@Mid@ni_';
export const allAccessedRoles = ['owner', 'super_admin'];
export const updateSavedChanges = (value, type = null) => {
  store.dispatch(
    AuthActions.updateSavedChanges({ hasUnsavedChanges: value, unsavedChangedType: type }),
  );
};
export const isRTL = () => {
  if (i18n.language === 'ar') return true;
  else return false;
};
export const updateTriggerSaveFormChanges = (value) => {
  store.dispatch(
    AuthActions.updateTriggerSaveFormChanges({ triggerSaveFormChanges: { trigger: value } }),
  );
};

export const allFeaturesList = [
  'forms',
  'dashboard',
  'users',
  'company_setup',
  'billing',
  'support',
  'issues',
  'reports',
  'guests',
  'tasks',
  'attendance',
  'groups',
  'data_source',
  'response_set',
  'announcement',
  'activity_log',
  'directory',
  'access_profiles',
  // 'company_sections',
  'trash',
];

export const logout = () => {
  store.dispatch(
    AuthActions.updateAuthState({
      loginToken: null,
      loginRefreshToken: null,
      authToken: null,
      refreshToken: null,
      profile: null,
    }),
  );
};

export const permissionObj = {
  edit: { isSelected: true, displayName: 'CreateEditPermission' },
  view_only: { isSelected: true, displayName: 'ViewPermission' },
  delete: { isSelected: true, displayName: 'DeletePermission' },
};

export const permissionViewObj = {
  view_only: { isSelected: true, displayName: 'ViewPermission' },
};

export const permissionViewUpdateObj = {
  view_only: { isSelected: true, displayName: 'ViewPermission' },
  edit: { isSelected: true, displayName: 'UpdatePermission' },
};

export const allPermissionSet = {
  forms: Object.keys(permissionObj),
  issues: Object.keys(permissionObj),
  tasks: Object.keys(permissionObj),
  data_source: Object.keys(permissionObj),
  response_set: Object.keys(permissionObj),
  users: Object.keys(permissionObj),
  guests: Object.keys(permissionObj),
  groups: Object.keys(permissionObj),
  announcement: Object.keys(permissionObj),
  billing: Object.keys(permissionObj),
  support: Object.keys(permissionObj),
  activity_log: Object.keys(permissionViewObj),
  dashboard: Object.keys(permissionObj),
  home: Object.keys(permissionViewObj),
  attendance: Object.keys(permissionViewObj),
  reports: Object.keys(permissionViewObj),
  company_setup: Object.keys(permissionViewUpdateObj),
  access_profiles: Object.keys(permissionObj),
  // company_sections: Object.keys(permissionObj),
  trash: Object.keys(permissionViewUpdateObj),
};
export const rolePermissions = {
  home: {
    order: 1,
    displayName: 'sidebar_home',
    isNested: false,
    url: 'home',
    value: 'home',
    icon: homeGreyIcon,
    permissions: permissionViewObj,
  },
  field_work: {
    order: 2,
    displayName: 'sidebar_fieldwork',
    isNested: true,
    modules: {
      forms: {
        order: 1,
        displayName: 'sidebar_forms',
        url: 'forms',
        value: 'forms',
        icon: formGreyIcon,
        permissions: permissionObj,
      },
      // issues: {
      //   order: 2,
      //   displayName: 'sidebar_issues',
      //   url: 'issues',
      //   value: 'issues',
      //   icon: issuesIcon,
      //   permissions: permissionObj,
      // },
      // tasks: {
      //   order: 3,
      //   displayName: 'sidebar_tasks',
      //   url: 'tasks',
      //   value: 'tasks',
      //   icon: tasksIcon,
      //   permissions: permissionObj,
      // },
      data_source: {
        order: 4,
        displayName: 'sidebar_data_source',
        url: 'datasource',
        value: 'data_source',
        icon: dataSourceIcon,
        permissions: permissionObj,
      },
      response_set: {
        order: 5,
        displayName: 'sidebar_response_set',
        url: 'response_set',
        value: 'response_set',
        icon: responseSetIcon,
        permissions: permissionObj,
      },
    },
  },
  view_data: {
    order: 3,
    displayName: 'sidebar_view_data',
    isNested: true,
    modules: {
      dashboard: {
        order: 1,
        displayName: 'sidebar_reports',
        url: 'dashboard',
        value: 'dashboard',
        icon: dashboardIcon,
        permissions: permissionObj,
      },
      // reports: {
      //   order: 2,
      //   displayName: 'sidebar_reports',
      //   url: 'reports',
      //   value: 'reports',
      //   icon: reportsIcon,
      //   permissions: permissionViewObj,
      // },
      attendance: {
        order: 3,
        displayName: 'sidebar_attendance',
        url: 'attendance',
        value: 'attendance',
        icon: attendanceIcon,
        permissions: permissionViewObj,
      },
      activity_log: {
        order: 5,
        displayName: 'sidebar_activity_log',
        url: 'activity_log',
        value: 'activity_log',
        icon: activityLogIcon,
        permissions: permissionViewObj,
      },
    },
  },
  employees: {
    order: 4,
    displayName: 'sidebar_employees',
    isNested: true,
    modules: {
      users: {
        order: 1,
        displayName: 'sidebar_users',
        url: 'users',
        value: 'users',
        icon: usersIcon,
        permissions: permissionObj,
      },
      // guests: {
      //   order: 2,
      //   displayName: 'sidebar_guests',
      //   url: 'guests',
      //   value: 'guests',
      //   icon: guestIcon,
      //   permissions: permissionObj,
      // },
      groups: {
        order: 3,
        displayName: 'sidebar_groups',
        url: 'groups',
        value: 'groups',
        icon: groupsIcon,
        permissions: permissionObj,
      },
      // company_sections: {
      //   order: 3,
      //   displayName: 'sidebar_company_sections',
      //   url: 'sections',
      //   value: 'company_sections',
      //   icon: groupsIcon,
      //   permissions: permissionObj,
      // },
      // directory: {
      //   order: 4,
      //   displayName: 'sidebar_directory',
      //   url: 'directory',
      //   value: 'directory',
      //   icon: directoryIcon,
      //   permissions: permissionObj,
      // },
    },
  },
  system: {
    order: 5,
    displayName: 'sidebar_system',
    isNested: true,
    modules: {
      company_setup: {
        order: 1,
        displayName: 'sidebar_company_setup',
        url: 'company',
        value: 'company_setup',
        icon: companySetupIcon,
        permissions: permissionViewUpdateObj,
      },
      access_profiles: {
        order: 2,
        displayName: 'sidebar_view_profiles',
        url: 'profiles',
        value: 'access_profiles',
        icon: companyProfileIcon,
        permissions: permissionObj,
      },
      trash: {
        order: 3,
        displayName: 'sidebar_trash',
        url: 'trash',
        value: 'trash',
        icon: trashIcon,
        permissions: permissionViewObj,
      },
      // billing: {
      //   order: 2,
      //   displayName: 'sidebar_billing',
      //   url: 'billing',
      //   value: 'billing',
      //   icon: billingIcon,
      //   permissions: permissionObj,
      // },
      // support: {
      //   order: 3,
      //   displayName: 'sidebar_support',
      //   url: 'support',
      //   value: 'support',
      //   icon: supportIcon,
      //   permissions: permissionObj,
      // },
      // announcement: {
      //   order: 4,
      //   displayName: 'sidebar_announcement',
      //   url: 'announcement',
      //   value: 'announcement',
      //   icon: announcementIcon,
      //   permissions: permissionObj,
      // },
    },
  },
  other: {
    order: 6,
    displayName: '',
    isNested: true,
    modules: {
      help: {
        order: 6,
        displayName: 'sidebar_help',
        url: 'help',
        value: 'help',
        icon: helpIcon,
        permissions: permissionObj,
      },
      profile: {
        order: 7,
        displayName: 'sidebar_profile',
        url: 'profile',
        value: 'profile',
        icon: profileIcon,
        permissions: permissionObj,
      },
    },
  },
};

export const adminAccessLevel = [
  {
    value: 'owner',
    displayValue: 'select_owner',
    isNested: false,
  },
  {
    value: 'super_admin',
    displayValue: 'select_super_admin',
    isNested: false,
  },
  {
    value: 'admin',
    displayValue: 'select_admin',
    isNested: false,
  },
];

export const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const formatTimeDifference = (timestamp) => {
  const currentDate = new Date();
  const pastDate = new Date(timestamp);

  const timeDifference = currentDate - pastDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
};
export const permissionKeys = {
  home_read: 'home.read',
  trash_read: 'trash.read',

  field_work_forms_create: 'field_work.forms.edit',
  field_work_forms_read: 'field_work.forms.view_only',
  field_work_forms_delete: 'field_work.forms.delete',

  field_work_issues_create: 'field_work.issues.edit',
  field_work_issues_read: 'field_work.issues.view_only',
  field_work_issues_delete: 'field_work.issues.delete',

  field_work_tasks_create: 'field_work.tasks.edit',
  field_work_tasks_read: 'field_work.tasks.view_only',
  field_work_tasks_delete: 'field_work.tasks.delete',

  field_work_datasource_create: 'field_work.data_source.edit',
  field_work_datasource_read: 'field_work.data_source.view_only',
  field_work_datasource_delete: 'field_work.data_source.delete',

  field_work_response_set_create: 'field_work.response_set.edit',
  field_work_response_set_read: 'field_work.response_set.view_only',
  field_work_response_set_delete: 'field_work.response_set.delete',

  view_data_dashboard_read: 'view_data.dashboard.view_only',
  view_data_dashboard_create: 'view_data.dashboard.edit',
  view_data_reports_read: 'view_data.reports.view_only',
  view_data_attendance_read: 'view_data.attendance.view_only',
  view_data_activity_log_read: 'view_data.activity_log.view_only',

  employees_users_create: 'employees.users.edit',
  employees_users_read: 'employees.users.view_only',
  employees_users_delete: 'employees.users.delete',

  employees_guests_create: 'employees.guests.edit',
  employees_guests_read: 'employees.guests.view_only',
  employees_guests_delete: 'employees.guests.delete',

  employees_groups_create: 'employees.groups.edit',
  employees_groups_read: 'employees.groups.view_only',
  employees_groups_delete: 'employees.groups.delete',

  employees_directory_create: 'employees.directory.edit',
  employees_directory_read: 'employees.directory.view_only',
  employees_directory_delete: 'employees.directory.delete',

  system_company_setup_read: 'system.company_setup.view_only',
  system_company_setup_update: 'system.company_setup.edit',

  system_profiles_read: 'system.access_profiles.view_only',
  system_profiles_update: 'system.access_profiles.edit',
  system_profiles_delete: 'system.access_profiles.delete',

  system_billing_create: 'system.billing.edit',
  system_billing_read: 'system.billing.view_only',
  system_billing_delete: 'system.billing.delete',

  system_support_create: 'system.support.edit',
  system_support_read: 'system.support.view_only',
  system_support_delete: 'system.support.delete',

  system_announcement_create: 'system.announcement.edit',
  system_announcement_read: 'system.announcement.view_only',
  system_announcement_delete: 'system.announcement.delete',
};
export const notificationKey = {
  users_user_export_completed: 'notification_users_user_export_completed',
  users_user_export_started: 'notification_users_user_export_started',
  user_user_export_process_started: 'notification_user_user_export_process_started',
  users_archived_export_completed: 'notification_users_archived_export_completed',
  users_archived_export_started: 'notification_users_archived_export_started',
  user_archived_export_process_started: 'notification_user_archived_export_process_started',
  forms_forms_export_process_started: 'notification_forms_forms_export_process_started',
  forms_forms_export_started: 'notification_forms_forms_export_started',
  forms_forms_export_completed: 'notification_forms_forms_export_completed',
  forms_bookmarks_export_process_started: 'notification_forms_bookmarks_export_process_started',
  forms_bookmarks_export_started: 'notification_forms_bookmarks_export_started',
  forms_bookmarks_export_completed: 'notification_forms_bookmarks_export_completed',
  forms_archived_export_process_started: 'notification_forms_archived_export_process_started',
  forms_archived_export_started: 'notification_forms_archived_export_started',
  forms_archived_export_completed: 'notification_forms_archived_export_completed',
  submission_export_process_started: 'notification_submission_export_process_started',
  submission_all_export_process_started: 'notification_submission_export_process_started',
  submissions_real_data_export_started: 'notification_submissions_real_data_export_started',
  submissions_real_data_export_completed: 'notification_submissions_real_data_export_completed',
  submissions_test_data_export_started: 'notification_submissions_test_data_export_started',
  submissions_test_data_export_completed: 'notification_submissions_test_data_export_completed',
  submissions_archived_export_started: 'notification_submissions_archived_export_started',
  submissions_archived_export_completed: 'notification_submissions_archived_export_completed',
  submission_export_download_attachment_started:
    'notification_submission_export_download_attachment_started',
  submissions_real_data_download_attachment_started:
    'notification_submissions_real_data_download_attachment_started',
  submissions_real_data_download_attachment_completed:
    'notification_submissions_real_data_download_attachment_completed',
  submissions_test_data_download_attachment_started:
    'notification_submissions_test_data_download_attachment_started',
  submissions_test_data_download_attachment_completed:
    'notification_submissions_test_data_download_attachment_completed',
  submissions_archived_download_attachment_started:
    'notification_submissions_archived_download_attachment_started',
  submissions_archived_download_attachment_completed:
    'notification_submissions_archived_download_attachment_completed',
  submission_supervisor_field_updated: 'notification_submission_supervisor_field_updated',
  data_source_database_export_process_started: 'notification_data_source_export_process_started',
  data_source_export_process_started: 'notification_data_source_export_process_started',
  data_source_export_started: 'notification_data_source_export_started',
  data_source_export_completed: 'notification_data_source_export_completed',
  users_user_pending_approval: 'notification_users_user_pending_approval',
  users_user_joined_company: 'notification_users_user_joined_company',
  forms_form_published: 'notification_forms_form_published',
  'clock-in_attendance_time_sheet_export_completed':
    'notification_attendance_time_sheet_export_completed',
  'clock-in_attendance_time_sheet_export_started':
    'notification_attendance_time_sheet_export_started',
  'clock-in_attendance_time_sheet_export_process_started':
    'notification_attendance_time_sheet_export_process_started',
  form_design_design_export_process_started:
    'notification_form_design_design_export_process_started',
  forms_form_design_export_completed: 'notification_form_design_design_export_completed',
  forms_form_design_export_started: 'notification_form_design_design_export_started',
  data_source_import_started: 'notification_data_source_import_started',
  data_source_import_completed: 'notification_data_source_import_completed',
  import_process_failed: 'notification_import_process_failed',
  export_process_failed: 'notification_export_process_failed',
};
