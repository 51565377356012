import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import closeIcon from 'assets/images/icons/cross-grey.svg';
import TextField from 'components/form-components/TextField';

import SelectField from 'components/form-components/SelectField';
import NumberField from 'components/form-components/NumberField';

import RatingStarField from 'components/form-components/RatingStarField';
import DateTimeField from 'components/form-components/DateTimeField';
import SignatureField from 'components/form-components/SignatureField';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import { cloneDeep } from 'lodash';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

const AddSubmission = ({ title, supervisorList, handleClose, onSubmitForm, values, loading }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [notifyViaSms, setNotifyViaSms] = useState({});

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const getCurrentStatus = (field) => {
    let selectedValue = formValues[field.additionalDetail.elementId];
    let optionList = field.additionalDetail ? field.additionalDetail.optionList : [];
    let returnedValue = '';
    let filterObject = optionList.filter(
      (option) => option.value.toString() === selectedValue.toString(),
    );

    if (filterObject.length > 0) {
      returnedValue = filterObject[0].responseText;
    }
    return returnedValue;
  };

  const handleNotifySmsHandleChange = (field, value) => {
    setNotifyViaSms({ ...notifyViaSms, [field]: value });
  };

  const getStatusList = (field) => {
    let optionList = field.additionalDetail ? field.additionalDetail.optionList : [];
    let statusOptions = [];

    for (let opIndex = 0; opIndex < optionList.length; opIndex++) {
      statusOptions.push({
        displayValue: optionList[opIndex].responseText,
        value: optionList[opIndex].value,
      });
    }

    return statusOptions;
  };

  const updateFormData = (value, field) => {
    let key = field.additionalDetail.elementId;

    let displayName = '';
    if (field.type === 'status') {
      let optionList = getStatusList(field);
      let filterObject = optionList.filter((option) => option.value === value);

      if (filterObject.length > 0) {
        displayName = filterObject[0].displayValue;
      }
    }

    let tempFormData = cloneDeep(formData);
    let dataList = tempFormData.filter((tempData) => tempData.elementGlobalId === key);

    let dataObject = dataList.length > 0 ? dataList[0] : null;
    if (dataObject) {
      dataObject.valueField = value.toString();
      dataObject.displayName = displayName;
    } else {
      let newObject = {
        elementGlobalId: key,
        valueField: value.toString(),
        displayName: displayName,
        media: [],
        arrayField: [],
      };
      if (field.type === 'signature') {
        newObject.type = 'signature';
      }
      tempFormData.push(newObject);
    }
    setFormValues({ ...formValues, [field.additionalDetail.elementId]: value });
    setFormData(tempFormData);
  };
  const submit = async () => {
    const sendNotificationToInspector = Object.values(notifyViaSms).filter((v) => !!v).length > 0;

    onSubmitForm(
      formData,
      values.submissionId,
      values.formVersionId,
      values.mediaCustomFolderId,
      sendNotificationToInspector,
    );
  };

  return (
    <div
      className='modal custom-modal'
      style={{ direction: values?.direction === 'right_to_left' ? 'rtl' : 'ltr' }}
    >
      <div className='theme-width-633 mx-3 position-relative'>
        <div
          className='position-absolute top-0 end-0 mx-3 my-2 cursor-pointer'
          onClick={handleClose}
        >
          <img src={closeIcon} />
        </div>
        <div className='head'>
          <h1 className='theme-text-black-1 theme-font-inter-semi-bold theme-size-1_5 text-capitalize'>
            {t(`Update${title}`)}
          </h1>
        </div>
        <hr />
        {values.isAnyFieldMissing && (
          <div className='custom-input-box mx-auto'>
            <span className='label-error'>{t('text_field_not_contains_in_version')}</span>
          </div>
        )}
        {formValues && (
          <div className='body mb-2 gap-2 px-0'>
            <div className='gap-2 add-submission-modal '>
              {supervisorList.map((singleField, index) => {
                return singleField.type === 'status' ? (
                  <SelectField
                    key={index}
                    name={singleField.name}
                    listValues={getStatusList(singleField)}
                    placeholder={singleField.displayName}
                    label={getCurrentStatus(singleField)}
                    variant='white-1'
                    menuClasses='w-100'
                    handleChange={(rowData, value) => {
                      updateFormData(value, singleField);
                      getCurrentStatus(singleField);
                    }}
                    filedClasses='custom-input-box'
                    isAutoClose={true}
                  />
                ) : singleField.type === 'dateAndTime' ||
                  singleField.type === 'date' ||
                  singleField.type === 'time' ? (
                  <DateTimeField
                    key={index}
                    value={
                      formValues[singleField.additionalDetail.elementId]
                        ? formValues[singleField.additionalDetail.elementId].toString()
                        : undefined
                    }
                    name={singleField.name}
                    placeholder=''
                    type={singleField.type}
                    displayName={singleField.displayName}
                    classes='min-width-160'
                    handleChange={(name, value) => updateFormData(value, singleField)}
                    unixTimeStamp
                  />
                ) : singleField.type === 'number' ? (
                  <NumberField
                    key={index}
                    value={
                      formValues[singleField.additionalDetail.elementId]
                        ? parseInt(formValues[singleField.additionalDetail.elementId])
                        : undefined
                    }
                    name={singleField.name}
                    displayName={singleField.displayName}
                    classes='min-width-160'
                    handleChange={(key, value) => updateFormData(value, singleField)}
                  />
                ) : singleField.type === 'ratingSlider' ? (
                  <RatingStarField
                    key={index}
                    value={parseInt(formValues[singleField.additionalDetail.elementId])}
                    displayName={singleField.displayName}
                    numOfStars={singleField.additionalDetail.noOfStars}
                    handleChange={(value) => updateFormData(value, singleField)}
                  />
                ) : singleField.type === 'signature' ? (
                  <SignatureField
                    key={index}
                    name={singleField.name}
                    displayName={singleField.displayName}
                    onChange={(value) => updateFormData(value, singleField)}
                  />
                ) : (
                  <>
                    <TextField
                      key={index}
                      value={formValues[singleField.additionalDetail.elementId]}
                      name={singleField.name}
                      displayName={singleField.displayName}
                      placeholder={singleField.displayName}
                      classes='mt-1'
                      handleChange={(key, value) => updateFormData(value, singleField)}
                    />
                    <SingleCheckBoxField
                      name={`notify_${singleField.name}`}
                      value={notifyViaSms[`notify_${singleField.name}`] ?? false}
                      handleChange={handleNotifySmsHandleChange}
                      text={<span>{t('form_field_notify_via_sms')}</span>}
                    />
                  </>
                );
              })}
            </div>

            <FormLoadingButton
              onClick={submit}
              loading={loading}
              text='Submit'
              variant='green-1'
              classes='w-auto rounded-full theme-size-1_1 theme-font-jakartaSans-medium mt-4'
            />
          </div>
        )}
      </div>
    </div>
  );
};

AddSubmission.propTypes = {
  handleClose: PropTypes.func,
  onSubmitForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  supervisorList: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AddSubmission;
