import React from 'react';
import PropTypes from 'prop-types';
import arrowLeftIcon from '../../assets/images/icons/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { steps } from './utils';

const AddCompanyHeader = ({ step, setStep }) => {
  const { t } = useTranslation();

  return (
    <>
      {step > 0 ? (
        <div
          className='back theme-text-black-1 theme-font-inter-medium theme-size-1 cursor-pointer'
          onClick={() => setStep(step - 1)}
        >
          <img src={arrowLeftIcon} className='back-arrow' />
          {t('text_back')}
        </div>
      ) : (
        <></>
      )}
      <div className='head'>
        <h1 className='theme-text-black-1 theme-font-inter-semi-bold theme-size-1_5'>
          {t(steps[step])}
        </h1>
        <p className='theme-text-grey-1 theme-font-inter-regular theme-size-1'>
          {t('text_complete_registration')}
        </p>
        <p className='theme-text-grey-2 theme-font-inter-regular theme-size-1 mb-0'>
          <span className='theme-text-green-1 theme-font-inter-medium theme-size-1'>
            {t('text_step')}
            {` ${step} `}
          </span>
          {t('text_step_of_4')}
        </p>
      </div>
      <div className='progress'>
        <div className='remaining' style={{ '--percent': `${100 - step * 25}%` }}></div>
      </div>
    </>
  );
};
AddCompanyHeader.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
};
export default AddCompanyHeader;
