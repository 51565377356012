import React, { Fragment, useEffect, useState } from 'react';
import AttendanceMap from './attendance-map/AttendanceMap';
import AttendanceSetup from './attendance-setup/AttendanceSetup';
import AttendanceTableBody from './AttendanceTableBody';
import AttendanceTableHeader from './AttendanceTableHeader';
import Error from 'components/Error';
import Loader from 'components/common-components/Loader';
import TabBody from 'components/tab-components/TabBody';
import TabHeader from 'components/tab-components/TabHeader';
import TabLayout from 'components/tab-components/TabLayout';
import { tabList } from './utils';
import { CallAPI } from 'actions/General';
import { useParams } from 'react-router-dom';

const Attendance = () => {
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState(
    params.tab && params.tab === 'track' ? tabList[1] : tabList[0],
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterData, setFilterData] = useState({
    filterByDate: 'all',
    searchText: '',
    groupId: undefined,
    fromDate: undefined,
    toDate: undefined,
    attendanceState: undefined,
  });
  const [groupListing, setGroupListing] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');

  const getGroupListing = async () => {
    const result = await CallAPI('GET_ALL_GROUPS', {}, null, null, null, null);
    if (result.status) {
      setGroupListing(result.data);
    }
  };
  useEffect(() => {
    getGroupListing();
  }, []);

  return (
    <TabLayout>
      <TabHeader tabList={tabList} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <TabBody>
        {selectedTab.value === 'setup' ? (
          <AttendanceSetup />
        ) : (
          <Fragment>
            <div className='d-flex flex-column gap-4 p-4'>
              {loader ? <Loader color='green-1' /> : null}
              {apiError ? <Error msg={apiError} /> : null}

              <AttendanceTableHeader
                filterData={filterData}
                setFilterData={setFilterData}
                groupListing={groupListing}
                type={selectedTab.value}
                selectedRows={selectedRows}
              />
            </div>

            {selectedTab.value === 'map' ? (
              <AttendanceMap
                filterData={filterData}
                setLoader={setLoader}
                setApiError={setApiError}
              />
            ) : (
              <AttendanceTableBody
                filterData={filterData}
                setLoader={setLoader}
                setApiError={setApiError}
                type={selectedTab.value}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            )}
          </Fragment>
        )}
      </TabBody>
    </TabLayout>
  );
};

export default Attendance;
