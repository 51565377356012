import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { userAccessTabsList } from '../utils';
import GeneralHeader from 'pages/company-setup/company-general/GeneralHeader';
import { cloneDeep } from 'lodash';
import TabDivider from 'components/common-components/TabDivider';
import UserFormsAccess from './UserFormsAccess';

const UserAccess = ({ userId }) => {
  const { t } = useTranslation();
  const [tabsList, setTabsList] = useState(userAccessTabsList);

  const onHeaderClick = (tab) => {
    tabsList.map((singleSubTab) => {
      if (singleSubTab.id === tab.id) {
        if (singleSubTab.isSelected) {
          singleSubTab.isSelected = false;
        } else {
          singleSubTab.isSelected = true;
        }
      } else {
        singleSubTab.isSelected = false;
      }
    });
    setTabsList(cloneDeep(tabsList));
  };

  return (
    <div className='user-details'>
      <div className='theme-font-inter-regular theme-text-black-1 py-2 px-4'>
        {t('Here you can see all the deep details of the user')}.
      </div>
      <div className='p-3 pt-0'>
        <TabDivider />
        {tabsList.map((singleTab, index) => {
          return (
            <div key={index}>
              <GeneralHeader
                title={t(singleTab.title)}
                onClick={() => onHeaderClick(singleTab)}
                isSelected={singleTab.isSelected}
                icon={singleTab.isSelected ? singleTab.selectedIcon : singleTab.icon}
                showInfoIcon={false}
                classes={singleTab.isSelected ? 'svg-white' : ''}
              />
              {singleTab.isSelected ? (
                singleTab.name === 'forms' ? (
                  <UserFormsAccess userId={userId} />
                ) : singleTab.name === 'issues' ? (
                  <p>User Issues Access</p>
                ) : null
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
UserAccess.propTypes = {
  userId: PropTypes.string,
};
export default UserAccess;
