import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import closeIcon from 'assets/images/icons/cross-grey.svg';

const SignatureField = ({
  name,
  displayName,
  classes,
  onChange,
 
}) => {
  const { t } = useTranslation();
  let sigPad = {}

  const onDraw = () => {
    onChange(sigPad.toDataURL())
  }

  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      {displayName && (
        <label
          htmlFor={name}
        >
          {t(displayName)}
        </label>
      )}
      <div className='custom-input-field signature-container theme-height-300 w-100'>
        <SignatureCanvas  canvasProps={{  className: 'w-100 h-100'}} 
          ref={(ref) => { sigPad = ref }} 
          onEnd= {() => onDraw()}
          />
         <img className='signature-cross' src={closeIcon} onClick={() => 
          {
              sigPad.clear()
              onDraw()
          }} />
      </div>
      

    
    </div>
  );
};

SignatureField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  onSignatureDraw: PropTypes.string,
  classes: PropTypes.string,
  onChange: PropTypes.func
};

export default SignatureField;
