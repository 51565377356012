import React from 'react';
import appleLogo from 'assets/images/icons/apple-logo.svg';
import googlePlayLogo from 'assets/images/icons/google-play-logo.svg';
import { Link } from 'react-router-dom';

const DownloadPage = () => {
  return (
    <div className='d-flex flex-column gap-4 align-items-center theme-font-jakartaSans-medium'>
      <label className='theme-size-2_5 theme-text-black-1'>Download Our App</label>
      <div className='d-flex gap-2'>
        <Link to='https://www.apple.com/app-store'>
          <div className='d-flex gap-2 align-items-center theme-background-black-1 py-3 px-5 cursor-pointer rounded-md'>
            <img src={appleLogo} style={{ height: '2rem' }} />
            <label className='theme-text-white-1 theme-size-1_2 cursor-pointer'>App Store</label>
          </div>
        </Link>
        <Link to='https://play.google.com/store/apps'>
          <div className='d-flex gap-2 align-items-center theme-background-black-1 py-3 px-5 cursor-pointer rounded-md'>
            <img src={googlePlayLogo} style={{ height: '2rem' }} />
            <label className='theme-text-white-1 theme-size-1_2 cursor-pointer'>Google Play</label>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DownloadPage;
