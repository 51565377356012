export const validateNestedExportProperties = (values) => {
  if (values.auth.authType === 'bearer') {
    if (!values.auth.authValue) {
      return {
        ['auth.authValue']: !values.auth.authValue ? 'authValue is required' : undefined,
      };
    }
  } else if (values.auth.authType === 'api_auth') {
    if (
      !values.auth.authApiUrl ||
      !values.auth.responseAuthKeyName ||
      !values.auth.parameters.client_id ||
      !values.auth.parameters.client_name ||
      !values.auth.parameters.client_secret
    ) {
      return {
        ['auth.authApiUrl']: !values.auth.authApiUrl ? 'authApiUrl is required' : undefined,
        ['auth.responseAuthKeyName']: !values.auth.responseAuthKeyName
          ? 'responseAuthKeyName is required'
          : undefined,
        ['auth.parameters.client_id']: !values.auth.parameters.client_id
          ? 'client_id is required'
          : undefined,
        ['auth.parameters.client_name']: !values.auth.parameters.client_name
          ? 'client_name is required'
          : undefined,
        ['auth.parameters.client_secret']: !values.auth.parameters.client_secret
          ? 'client_secret is required'
          : undefined,
      };
    } else null;
  }
};
