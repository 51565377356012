import React from 'react';
import PropTypes from 'prop-types';
import { countryList } from '../../pages/auth-pages/utils';
import { useTranslation } from 'react-i18next';

const MobileNumberField = ({
  fieldProps,
  displayName,
  codeName,
  icon,
  value,
  error,
  touched,
  shrink,
  shrinkFieldOnly,
  handleChange,
  classes,
  isHorizontal,
}) => {
  const { t } = useTranslation();
  const flagMap = Object.fromEntries(countryList.map((c) => [c.value, c.icon]));

  const shrinkField = shrink || shrinkFieldOnly ? ' shrink-field' : '';
  const shrinkLabel = shrink ? ' shrink-label' : '';

  return (
    <div
      className={`custom-input-box${classes ? ' ' + classes : ''} ${
        isHorizontal ? 'd-flex align-items-center w-100' : ''
      }`}
    >
      {displayName && (
        <label
          htmlFor={fieldProps.name}
          className={`${!touched && error ? 'label-error' + shrinkLabel : shrinkLabel} ${
            isHorizontal ? 'min-width-176' : ''
          }`}
        >
          {t(displayName)}
        </label>
      )}
      <div className={`mobile-number-field ${isHorizontal ? 'w-100' : ''}`}>
        <div className='custom-input-field country-code'>
          <img src={flagMap[value.countryCode]} />
          <select
            name={codeName ?? 'countryCode'}
            value={value.countryCode}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            {countryList.map((country, index) => (
              <option key={index} value={country.value}>
                {country.value}
              </option>
            ))}
          </select>
        </div>
        <div className='custom-input-field mobile-number'>
          <input
            type='number'
            inputMode='numeric'
            name={fieldProps.name}
            placeholder={t(fieldProps.placeholder)}
            id={fieldProps.name}
            className={`${!touched && error ? 'input-error' + shrinkField : shrinkField}`}
            value={value.mobileNumber}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          {icon && <img src={icon} className='icon' />}
        </div>
      </div>
      {!touched && error && <p className='form-field-error'>{t('error ' + error)}</p>}
    </div>
  );
};

MobileNumberField.propTypes = {
  fieldProps: PropTypes.object.isRequired,
  codeName: PropTypes.string,
  displayName: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.object.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  shrink: PropTypes.bool,
  shrinkFieldOnly: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  isHorizontal: PropTypes.bool,
};

export default MobileNumberField;
