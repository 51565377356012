import {
  //allPermissionSet,
  rolePermissions,
} from './config';
import { cloneDeep } from 'lodash';
import store from 'store/store';

export const updatePermissionSet = (features) => {
  let listValues = [];
  let permissionKeyList = Object.keys(rolePermissions);

  Object.values(rolePermissions).map((singleModulePermission, keyIndex) => {
    if (singleModulePermission.modules) {
      let subModulesValuesList = Object.values(singleModulePermission.modules);

      let subMenu = [];

      let subModulesPermissionKeysList = Object.keys(singleModulePermission.modules);
      subModulesValuesList.map((singleSubModule, index) => {
        let subModulePermissionKey = features.filter(
          (feature) => feature === subModulesPermissionKeysList[index],
        );

        if (subModulePermissionKey.length > 0 || features.length == 0) {
          let subModulesKeys = Object.keys(singleSubModule.permissions);
          let subModulesValues = Object.values(singleSubModule.permissions);
          let subOptions = [];
          subModulesValues.map((singleSubPermission, permissionIndex) => {
            subOptions.push({
              key:
                permissionKeyList[keyIndex] +
                '.' +
                subModulesPermissionKeysList[index] +
                '.' +
                subModulesKeys[permissionIndex],
              value: subModulesKeys[permissionIndex],
              displayName: singleSubPermission.displayName,
              isCheckbox: false,
              subMenu: null,
              isChecked: false,
            });
          });

          subMenu.push({
            order: singleSubModule.order,
            url: singleSubModule.url,
            value: subModulesPermissionKeysList[index],
            displayName: singleSubModule.displayName,
            icon: singleSubModule.icon,
            permissions: subOptions,
          });
        }
      });

      if (subMenu.length > 0) {
        listValues.push({
          key: permissionKeyList[keyIndex],
          value: permissionKeyList[keyIndex],
          order: singleModulePermission.order,
          displayName: singleModulePermission.displayName,
          subMenu: subMenu,
        });
      }
    } else {
      let subModulesKeys = Object.keys(singleModulePermission.permissions);
      let subModulesValues = Object.values(singleModulePermission.permissions);

      let subOptions = [];
      subModulesValues.map((singleSubPermission, permissionIndex) => {
        subOptions.push({
          key: permissionKeyList[keyIndex] + '.' + subModulesKeys[permissionIndex],
          value: subModulesKeys[permissionIndex],
          displayName: singleSubPermission.displayName,
          isCheckbox: false,
          subMenu: null,
          isChecked: false,
        });
      });

      listValues.push({
        order: singleModulePermission.order,
        url: singleModulePermission.url,
        value: singleModulePermission.value,
        displayName: singleModulePermission.displayName,
        icon: singleModulePermission.icon,
        permissions: subOptions,
      });
    }
  });

  return listValues;
};

export const getUserPermissionSet = (companyPermissions, userPermission, isOwner) => {
  let listValues = [];

  let grantedPermissionKeys = [];

  userPermission &&
    userPermission.map((moduleObject) => {
      let moduleKey = moduleObject.module;

      moduleObject.moduleLevels.map((level) => {
        let levelKey = level.levelName;

        level.operations.map((operation) => {
          let permissionKey = moduleKey + '.' + levelKey + '.' + operation;

          grantedPermissionKeys.push(permissionKey);
        });
      });
    });

  let companyPermissionList = cloneDeep(companyPermissions);

  companyPermissionList.map((permissionModules) => {
    if (
      permissionModules.subMenu &&
      permissionModules.displayName &&
      !permissionModules.permissions
    ) {
      let subMenu = [];
      permissionModules.subMenu.map((subMenuItem) => {
        let subOptions = [];

        subMenuItem.permissions.map((permission) => {
          let isPermitted =
            isOwner ||
            (grantedPermissionKeys.length > 0 &&
              grantedPermissionKeys.filter((permKey) => permKey === permission.key).length > 0)
              ? true
              : false;

          permission.isCheckbox = false;
          permission.isChecked = isPermitted;

          if (isPermitted) subOptions.push(permission);
        });

        if (subOptions.length > 0) {
          subMenu.push({
            order: subMenuItem.order,
            url: subMenuItem.url,
            value: subMenuItem.value,
            displayName: subMenuItem.displayName,
            icon: subMenuItem.icon,
            subMenu: subOptions,
          });
        }
      });

      if (subMenu.length > 0) {
        listValues.push({
          order: permissionModules.order,
          value: permissionModules.value,
          displayName: permissionModules.displayName,
          subMenu: subMenu,
        });
      }
    } else if (permissionModules.permissions) {
      let subOptions = [];

      permissionModules.permissions.map((permission) => {
        permission.isCheckbox = false;
        permission.isChecked = true;
        subOptions.push(permission);
      });

      if (subOptions.length > 0) {
        listValues.push({
          order: permissionModules.order,
          url: permissionModules.url,
          value: permissionModules.value,
          displayName: permissionModules.displayName,
          icon: permissionModules.icon,
          permissions: subOptions,
        });
      }
    }
  });

  return listValues;
};

export const isPermissionAvailable = (permissionKey) => {
  let auth = store.getState().auth;

  let isPermitted = false;
  let companyPermissions = auth.companyPermissions;

  for (let i = 0; i < companyPermissions.length; i++) {
    let companyPermission = companyPermissions[i];
    if (
      companyPermission.subMenu &&
      companyPermission.displayName &&
      !companyPermission.permissions
    ) {
      let subMenu = companyPermission.subMenu;

      for (let j = 0; j < subMenu.length; j++) {
        let subMenuItem = subMenu[j];
        if (subMenuItem.subMenu) {
          let permissions = subMenuItem.subMenu;

          for (let k = 0; k < permissions.length; k++) {
            if (permissions[k].key === permissionKey) {
              isPermitted = true;
              break;
            }
          }

          if (isPermitted) break;
        }
      }

      if (isPermitted) break;
    }
  }

  return isPermitted;
};
