import axios from 'axios';
import store from '../store/store';
import { CallAPI } from '../actions/General';
import { AuthReducer } from '../redux/auth';
const AuthActions = AuthReducer.actions;
axios.interceptors.request.use(
  function (config) {
    // eslint-disable-next-line no-undef
    let apiPath = process.env.REACT_APP_API_PATH;
    let url = config.url;
    let auth = store.getState().auth;

    let isRetry = config._retry;
    let tokenType = config.headers.token_type;
    if (!isRetry) {
      config.url = apiPath + '/' + url;
      if (tokenType === 'external') config.url = url;
    } else {
      tokenType = config.headers['token-type'];
    }

    let authToken =
      tokenType === 'staticToken'
        ? process.env.REACT_APP_STATIC_TOKEN
        : tokenType === 'loginToken'
        ? auth.loginToken
        : tokenType === 'refresh'
        ? auth.refreshToken
        : tokenType === 'loginRefresh'
        ? auth.loginRefreshToken
        : tokenType !== 'external'
        ? auth.authToken
        : '';
    let headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + authToken,
      'token-type': tokenType,
    };
    config.headers = headers;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    // RETURN SUCCESS RESPONSE
    if (response.status === 200 || response.status === 201) {
      if (Object.keys(response.data).includes('success') && !response.data.success)
        return { data: undefined, error: response.data.error.msg };
      else return { data: response.data.data, error: '' };
    } else {
      // RETURN ERROR RESPONSE
      return { data: {}, error: response.error.msg };
    }
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      if (originalRequest.url.indexOf('refresh-token') < 1) {
        if (originalRequest.headers['token-type'] === 'loginToken')
          await CallAPI('LOGIN_REFRESH_TOKEN', {}, null, null, null, null);
        else await CallAPI('REFRESH_TOKEN', {}, null, null, null, null);
        originalRequest._retry = true;
        return new Promise((resolve) => {
          resolve(axios(originalRequest));
        });
      } else {
        store.dispatch(
          AuthActions.updateAuthState({
            loginToken: null,
            loginRefreshToken: null,
            authToken: null,
            refreshToken: null,
            profile: null,
          }),
        );
        window.location.href = '/login';
      }
    } else if (error.response && error.response.status === 403) {
      return {
        data: {},
        error: 'you are not authorized to perform this operation',
      };
    } else {
      return {
        data: {},
        // error: error.msg,
        error: error.response.data.error.msg ?? error.response.data.error,
      };
    }
    console.log('ERROR', error.response.data.error);
    return {
      data: {},
      error: error.response.data.error.msg ?? error.response.data.error,
      // error: 'error',
    };
    // return Promise.reject(error);
  },
);
