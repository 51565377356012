import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import smileIcon from 'assets/images/icons/smile.svg';
import ellipseIcon from 'assets/images/icons/ellipse.svg';
import starIcon from 'assets/images/icons/star.svg';
import { formTypeIconValues } from 'pages/forms/utils';
import { isRTL } from 'config/config';

const SelectBox = ({
  label,
  fieldProps,
  classes,
  menuClasses,
  icon,
  handleChange,
  isAutoClose,
  position,
  positionReversed,
  variant,
  placeholder,
  valueChanged,
  iconType,
}) => {
  const baseClass = variant
    ? ` custom-form-button theme-button-${variant ?? 'white-1'} min-width`
    : '';
  const { t } = useTranslation();
  const ratingTypeValues = [
    { value: 'star', labelImage: starIcon },
    { value: 'ellipse', labelImage: ellipseIcon },
    { value: 'smile', labelImage: smileIcon },
  ];

  const listValues =
    iconType === 'form' ? formTypeIconValues : iconType === 'rating' ? ratingTypeValues : [];

  const positionDirection = position
    ? position
    : (isRTL() && !positionReversed) || (!isRTL() && positionReversed)
    ? 'end'
    : 'start';

  return (
    <Dropdown
      drop={isRTL() ? 'down' : 'down'}
      autoClose={isAutoClose ? '' : 'outside'}
      className={`${classes} ${placeholder ? 'w-100 ' : ''}`}
      align={{ sm: positionDirection }}
      {...fieldProps}
    >
      <Dropdown.Toggle
        id='dropdown-autoclose-outside'
        className={`dropdownItem${baseClass} ${
          icon ? 'w-100 d-flex gap-2 justify-content-between align-items-center' : ''
        }`}
        variant='none'
      >
        {label ? (
          <img src={listValues.find((item) => item.value === label)?.labelImage} className='icon' />
        ) : (
          <span>{t('select_select')}</span>
        )}
        {icon && <img src={icon} className='icon' />}
      </Dropdown.Toggle>
      <Dropdown.Menu className={menuClasses} style={{ willChange: 'transform', padding: 0 }}>
        {listValues.map((singleValue, index) => {
          return (
            <Dropdown.Item
              key={index}
              onClick={() => (!handleChange ? null : valueChanged(singleValue.value))}
            >
              <img
                src={singleValue.labelImage}
                className='theme-width-25 theme-height-25'
                alt='Loading...'
              />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
SelectBox.propTypes = {
  label: PropTypes.string,
  position: PropTypes.string,
  positionReversed: PropTypes.bool,
  classes: PropTypes.string,
  menuClasses: PropTypes.string,
  fieldProps: PropTypes.object,
  icon: PropTypes.string,
  variant: PropTypes.string,
  selectedValues: PropTypes.array,
  handleSubValueChange: PropTypes.func,
  valueChanged: PropTypes.func,
  handleChange: PropTypes.func,
  rowData: PropTypes.object,
  isAutoClose: PropTypes.bool,
  showCheckBoxes: PropTypes.bool,
  placeholder: PropTypes.string,
  iconType: PropTypes.string.isRequired,
};

const SelectIconField = ({
  label,
  name,
  fieldProps,
  classes,
  menuClasses,
  icon,
  selectedValues,
  handleChange,
  isMultiple,
  isAutoClose,
  position,
  positionReversed,
  rowData,
  variant,
  showCheckBoxes,
  placeholder,
  isHorizontal,
  iconType,
}) => {
  const { t } = useTranslation();
  const valueChanged = (val) => {
    let rowDataVal = rowData ? rowData : name;
    if (isMultiple) {
      let values = [...selectedValues];
      if (values.indexOf(val) > -1) {
        values = values.splice(values.indexOf(val), 1);
      } else {
        values.push(val);
      }
      handleChange(rowDataVal, values);
    } else {
      handleChange(rowDataVal, val);
    }
  };

  return (
    <>
      {placeholder ? (
        <div className={`${isHorizontal ? 'd-flex align-items-center' : ''}`}>
          {placeholder && <span className='min-width-176'>{t(placeholder)}</span>}
          <SelectBox
            label={label}
            fieldProps={fieldProps}
            classes={classes}
            menuClasses={menuClasses}
            icon={icon}
            selectedValues={selectedValues}
            handleChange={handleChange}
            isAutoClose={isAutoClose}
            position={position}
            positionReversed={positionReversed}
            variant={variant}
            showCheckBoxes={showCheckBoxes}
            placeholder={placeholder}
            valueChanged={valueChanged}
            iconType={iconType}
          />
        </div>
      ) : (
        <SelectBox
          label={label}
          fieldProps={fieldProps}
          classes={classes}
          menuClasses={menuClasses}
          icon={icon}
          selectedValues={selectedValues}
          handleChange={handleChange}
          isAutoClose={isAutoClose}
          position={position}
          positionReversed={positionReversed}
          variant={variant}
          showCheckBoxes={showCheckBoxes}
          placeholder={placeholder}
          valueChanged={valueChanged}
          iconType={iconType}
        />
      )}
    </>
  );
};

SelectIconField.propTypes = {
  label: PropTypes.string,
  position: PropTypes.string,
  positionReversed: PropTypes.bool,
  classes: PropTypes.string,
  menuClasses: PropTypes.string,
  fieldProps: PropTypes.object,
  icon: PropTypes.string,
  variant: PropTypes.string,
  selectedValues: PropTypes.array,
  isMultiple: PropTypes.bool,
  handleChange: PropTypes.func,
  rowData: PropTypes.object,
  isAutoClose: PropTypes.bool,
  showCheckBoxes: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  iconType: PropTypes.string.isRequired,
};

export default SelectIconField;
